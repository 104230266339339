import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { Button, DatePicker, Form, Input, Select, Cascader, Tag } from 'antd';
import { FaTimes } from 'react-icons/fa';

import utils from '~/lib/utils';

const InputGroup = Input.Group;
const Option = Select.Option;
const { RangePicker } = DatePicker;

class DynamicFilter extends Component {
    state = {
        allFilters: {},
        filterSelected: {},
    };

    static defaultProps = {
        ignoreFilters: ['orderBy', 'sort'],
    };

    componentDidMount() {
        const { location } = this.props;
        const filter = Object.assign(
            {},
            this.props.filters.filter((filter) => {
                return !filter.hidden;
            })[0]
        );
        const queryStringParsed = queryString.parse(location.search);

        this.setState({
            filterSelected: filter,
            allFilters: queryStringParsed,
        });
    }

    setFilter = (event) => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                const { filterSelected, allFilters } = this.state;
                const { history, location, onSubmit } = this.props;
                const { filterTerm } = values;
                let search;

                const filterName = filterSelected.alias || filterSelected.key;

                if (Array.isArray(filterTerm)) {
                    search = {
                        [filterName]: filterTerm.filter(Boolean).join('-'),
                    };
                } else if (typeof filterTerm === 'object') {
                    const [start, end] = filterTerm;
                    search = {
                        [filterName]: `${start.format('YYYY-MM-DD')}|${end.format('YYYY-MM-DD')}`,
                    };
                } else {
                    search = {
                        [filterName]: filterTerm,
                    };
                }

                const fullSearch = {
                    ...allFilters,
                    ...search,
                };

                await history.push({
                    pathname: location.pathname,
                    search: queryString.stringify(fullSearch),
                });

                this.setState({ allFilters: fullSearch }, () => {
                    return onSubmit(true, false);
                });
            }
        });
    };

    removeAllFilters = async () => {
        const { history, location, onSubmit } = this.props;
        const allFilters: Object = this.filterIgnoredFilters(true);

        await history.push({
            pathname: location.pathname,
            search: queryString.stringify(allFilters),
        });

        this.setState({ allFilters }, () => {
            return onSubmit(true, true);
        });
    };

    removeFilter = async (filter) => {
        const { history, location, onSubmit } = this.props;
        const newAllFilters: Object = Object.assign(this.state.allFilters);

        delete newAllFilters[filter];

        await history.push({
            pathname: location.pathname,
            search: queryString.stringify(newAllFilters),
        });

        this.setState({ allFilters: newAllFilters }, () => {
            return onSubmit(true, true);
        });
    };

    getFilterTitle = (filter) => {
        const filterFinded = this.props.filters.find(
            (f: Object): boolean => {
                return [f.key, f.alias].includes(filter);
            }
        );

        if (filterFinded) {
            return filterFinded.title;
        }
    };

    getFilterValue = (filterKey) => {
        const { allFilters } = this.state;
        const { filters } = this.props;
        const filter = filters.find((filter) => {
            return filter.alias === filterKey || filter.key === filterKey;
        });

        if (filter?.type === 'select') {
            const option = filter.options.find((option) => {
                if (typeof option.value === 'boolean') {
                    return option.value === JSON.parse(allFilters[filterKey]);
                }

                return option.value === allFilters[filterKey];
            });

            return option?.name || option?.label || '';
        } else if (filter?.type === 'cascader') {
            const values = allFilters[filterKey].split('-');

            const items = values.reduce((result, item) => {
                const selected = (result.options || []).find((option) => {
                    return option.value === item;
                });

                if (!selected) {
                    return result;
                }

                result.options = selected.children;
                result.values.push(selected.label);

                return result;
            }, { values: [], options: filter.options });

            return items.values.join(' / ');
        }

        return allFilters[filterKey];
    };

    changeFilter = (value) => {
        this.props.form.resetFields();
        this.setState({ filterSelected: JSON.parse(value) });
    };

    changeInputValue = (event) => {
        const { value } = event.target;

        this.setState((prevState) => {
            return {
                filterSelected: {
                    ...prevState.filterSelected,
                    value,
                },
            };
        });
    };

    changeSelectValue = (value) => {
        this.setState((prevState) => {
            return {
                filterSelected: {
                    ...prevState.filterSelected,
                    value,
                },
            };
        });
    };

    changeRangePickerValue = ([momentStartDate, momentEndDate], [startDate, endDate]) => {
        console.log(momentStartDate, momentEndDate);
        console.log(startDate, endDate);
    };

    getFieldByType = () => {
        const { filterSelected } = this.state;

        switch (filterSelected.type) {
            case 'text':
            default:
                return (
                    <Input
                        onChange={this.changeInputValue}
                        style={{ width: window.innerWidth * 0.19 }}
                        type="text"
                    />
                );
            case 'date':
                return <RangePicker />;
            case 'select':
                return (
                    <Select
                        className="select-middle"
                        onChange={this.changeSelectValue}
                        style={{ width: window.innerWidth * 0.19 }}
                    >
                        {filterSelected.options.map((option) => {
                            return (
                                <Select.Option key={option.value}>
                                    {option.name || option.label}
                                </Select.Option>
                            );
                        })}
                    </Select>
                );
            case 'cascader':
                return (
                    <Cascader
                        className="select-middle"
                        placeholder=""
                        onChange={this.changeSelectValue}
                        style={{ width: window.innerWidth * 0.19 }}
                        options={filterSelected.options}
                    />
                );
        }
    };

    getType = () => {
        const { type } = this.state.filterSelected;

        return type;
    };

    filterIgnoredFilters = (reverse = false) => {
        const { allFilters } = this.state;
        const filters = Object.keys(allFilters).reduce((filters, filter) => {
            const filterInclude = this.props.ignoreFilters.includes(filter);

            if (reverse) {
                if (filterInclude) {
                    filters[filter] = allFilters[filter];
                }

                return filters;
            }

            if (!filterInclude) {
                filters[filter] = allFilters[filter];
            }

            return filters;
        }, {});

        return filters;
    };

    render() {
        const { filterSelected } = this.state;
        const allFilters = this.filterIgnoredFilters();
        const { filters, form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <div className="dynamicfilter-container">
                <Form onSubmit={this.setFilter} layout="inline" className="">
                    <InputGroup compact>
                        {getFieldDecorator('filterSelect', {
                            initialValue: JSON.stringify(
                                filters.filter((filter) => {
                                    return !filter.hidden;
                                })[0]
                            ),
                        })(
                            <Select
                                className="dynamicfilter-select"
                                onChange={this.changeFilter}
                                style={{ width: 160 }}
                            >
                                {filters.map((filter, i) => {
                                    if (!filter.hidden) {
                                        return (
                                            <Option key={i} value={JSON.stringify(filter)}>
                                                {filter.title}
                                            </Option>
                                        );
                                    }
                                    return false;
                                })}
                            </Select>
                        )}
                        {getFieldDecorator('filterTerm', {
                            rules: [
                                {
                                    required: true,
                                    message: ' ',
                                },
                            ],
                            initialValue: filterSelected.value,
                        })(this.getFieldByType())}
                        <Button
                            type="primary"
                            icon="search"
                            htmlType="submit"
                            style={{ padding: '0 15px' }}
                        />
                    </InputGroup>
                </Form>
                <div className="filter-tags" style={{ margin: '7px 0px 9px 0px' }}>
                    {Object.keys(allFilters).map((filter) => {
                        return (
                            <Tag
                                key={filter}
                                closable
                                color="#108ee9"
                                onClose={() => {
                                    return this.removeFilter(filter);
                                }}
                            >
                                {this.getFilterTitle(filter)}: {this.getFilterValue(filter)}
                            </Tag>
                        );
                    })}
                    {utils.hasItemsInArray(Object.keys(allFilters)) && (
                        <FaTimes
                            onClick={this.removeAllFilters}
                            style={{ verticalAlign: 'middle' }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(Form.create()(DynamicFilter));
