import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Form, Input, Select, Alert } from 'antd';

import HeaderInfo from '~/components/Header/HeaderInfo';
import Table from '~/components/Table/AntTable';
import { TitlePage } from '~/components/Header/TitlePage';

const { Search, Group } = Input;
const { Option } = Select;

class List extends Component {

    state = {
        error: false,
        suppliers: [],
        loading: true,
        actionsDisabled: true,
    };

    componentDidMount() {
        document.title = 'Grid2B | Pedidos';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async (refresh = false) => {
        try {
            await new Promise((resolve) => {
                setTimeout(() => { resolve(); }, 1000);
            });
        } catch (error) {
            this.setState({ error: true });
        } finally {
            this.setState({ loading: false });
        }
    };


    transformColumns = () => {
        const suppliers = this.state.suppliers.map((supplier) => {
            return {
                value: supplier.id,
                name: supplier.name,
            };
        });

        const options = {
            pending: ['Pendente', 'blue'],
            confirmed: ['Enviado', 'green'],
            cancelled: ['Cancelado', 'gray'],
            error: ['Verificar', 'red'],
            draft: ['Em aberto', 'orange'],
        };

        const statusOptions = Object.keys(options).map((value) => {
            const [name] = options[value];
            return { value, name };
        });

        return [
            {
                title: 'ID G2B',
                key: 'orderId',
                dataIndex: 'orderId',
                align: 'left',
                hidden: true,
            },
            {
                title: 'ID Externo',
                key: 'externalId',
                dataIndex: 'externalId',
                align: 'left',
                hidden: true,
            },
            {
                title: 'Data',
                key: 'createdAt',
                type: 'date',
                dataIndex: 'createdAt',
                align: 'left',
                sorter: true,
            },
            {
                title: 'Cliente',
                key: 'clientName',
                dataIndex: 'clientName',
                align: 'left',
                hidden: true,
            },
            {
                title: 'Valor',
                key: 'value',
                dataIndex: 'value',
                align: 'left',
                hidden: true,
            },
            {
                title: 'Distribuidor',
                key: 'supplierId',
                dataIndex: 'supplierId',
                align: 'left',
                type: 'select',
                options: suppliers,
                sorter: true,
            },
            {
                title: 'Origem',
                key: 'source',
                dataIndex: 'source',
                align: 'left',
                hidden: true,
            },
            {
                title: 'Situação',
                key: 'status',
                dataIndex: 'status',
                align: 'left',
                type: 'select',
                options: statusOptions,
                sorter: true,
            },
            {
                title: '',
                key: 'actions',
                dataIndex: 'actions',
                hidden: true,
                align: 'right',
            },
        ];
    };

    render() {

        const { error, loading } = this.state;
        const columns = this.transformColumns();
        const data = [];

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/dashboard">Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Pedidos</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage
                        title="Pedidos"
                        subtitle="Confira os pedidos inseridos na plataforma."
                    />
                    <div className="content-filters">
                        <div className="dynamicfilter-container">
                            <Group compact>
                                <Select style={{ width: 160 }} disabled defaultValue="Distribuidor">
                                    <Option value="Distribuidor">Distribuidor</Option>
                                </Select>
                                <Search style={{ width: window.innerWidth * 0.19 }} disabled enterButton />
                            </Group>
                        </div>
                        <HeaderInfo
                            showing={data.length}
                            total={0}
                            actions={
                                <Fragment>
                                    <Button
                                        type="primary"
                                        icon="plus"
                                        disabled
                                    >
                                        Criar c/ NFe
                                    </Button>
                                    <Button
                                        type="primary"
                                        icon="plus"
                                        disabled
                                    >
                                        Criar
                                    </Button>
                                </Fragment>
                            }
                        />
                    </div>
                    <div
                        className="infinite-container"
                        style={{ height: window.innerHeight - 307, overflow: 'auto' }}
                    >
                        <Table
                            columns={columns}
                            data={data}
                            onChange={this.handleSort}
                            error={error}
                            loading={loading}
                            pagination={false}
                            locale={!loading && {
                                emptyText: (
                                    <Alert
                                        message="Em breve, gerenciamento de pedidos para distribuidores através do Grid2B."
                                        type="info"
                                        showIcon
                                    />
                                ),
                            }}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Form.create()(List);