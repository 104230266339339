import qs from 'query-string';

import sort from '~/config/sort';

export async function handleSort(sorter, fetchData) {
    let queryString = '';
    const { history, location } = this.props;
    const { search } = location;
    const currentFilters = qs.parse(search);

    delete currentFilters.orderBy;
    delete currentFilters.sort;

    const order = sort[sorter.order];
    const field = sorter.field;

    queryString = qs.stringify({
        ...currentFilters,
        ...(order && { orderBy: field }),
        ...(order && { sort: order }),
    });

    await history.push({
        pathname: location.pathname,
        search: queryString,
    });

    await fetchData(true);
}
