import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Cascader, Col, Form, Input, Radio, Row, Select, InputNumber } from 'antd';

import { getSupplierConfig } from './New.actions';

import Loading from '~/components/Loading';
import Notification from '~/components/Notification/AntNotification';
import ScheduleTable from '~/components/ScheduleTable';
import { TitlePage } from '~/components/Header/TitlePage';

import ability from '~/ability';
import utils from '~/lib/utils';
import { paths } from '~/routes';
import {
    CategoryConnectorAPI,
    ConnectorAPI,
    InventoryAPI,
    ScheduleAPI,
    SupplierAPI,
} from '~/lib/api';

const { Option } = Select;
const { Item: FormItem } = Form;
const { Group: RadioGroup } = Radio;

const mapStateToProps = (state) => {
    const { currentUser } = state.user;

    return { currentUser };
};

class Edit extends Component {
    state = {
        availableSchedules: [],
        categoryConnectors: [],
        checkedDays: [],
        connectors: [],
        distributionCenters: [],
        inventories: [],
        initialLoading: true,
        updateLoading: false,
        updateStatusLoading: false,
        schedule: {},
        showScheduleTable: false,
        supplier: {},
        disableAllCentersOption: false,
    };

    componentDidMount() {
        document.title = 'Grid2B | Editar agendamento';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async (refresh = false) => {
        try {
            if (refresh) {
                this.setState({
                    error: false,
                    initialLoading: true,
                });
            }

            const { supplierId, scheduleId } = this.props.match.params;

            const [
                fetchSupplier,
                fetchSchedule,
                fetchCategoryConnectors,
                fetchConnectors,
                fetchInventories,
                fetchSupplierDistributionCenters,
            ] = await Promise.all([
                SupplierAPI.fetchSupplier(supplierId),
                ScheduleAPI.fetchSchedule(supplierId, scheduleId),
                CategoryConnectorAPI.fetchCategoryConnectors(supplierId),
                ConnectorAPI.fetchConnectors(),
                InventoryAPI.fetchInventories(),
                SupplierAPI.fetchSupplierDistributionCenters(supplierId),
            ]);

            const scheduleTransformed = this.transformSchedule(fetchSchedule.result);
            const categoryConnectorsTransformed = this.transformCategoryConnectors(
                fetchCategoryConnectors.result
            );
            const inventoriesTransformed = this.transformInventories(fetchInventories.result);

            const connectors = fetchConnectors.result.filter((connector) => {
                return connector.available;
            });

            const connector = connectors.find((connector) => {
                return connector.connectorId === scheduleTransformed.schedule.connectorId;
            });

            this.setState(
                {
                    categoryConnectors: categoryConnectorsTransformed,
                    connectors,
                    disableAllCentersOption: connector.template !== 'wdna',
                    distributionCenters: fetchSupplierDistributionCenters.result,
                    inventories: inventoriesTransformed,
                    initialLoading: false,
                    supplier: fetchSupplier.result,
                    ...scheduleTransformed,
                },
                () => {
                    return this.handleSelectedDays(fetchSchedule.result);
                }
            );
        } catch (error) {
            console.log('ERROR getInitialData: ', error);

            this.setState({
                errors: true,
                initialLoading: false,
            });
        }
    };

    handleSelectedDays = (schedule) => {
        const selectedRowKeys = [];

        if (schedule.schedules) {
            schedule.schedules.forEach((schedule) => {
                selectedRowKeys.push(schedule.scheduleId);

                schedule.days.forEach((day) => {
                    const fieldName = `schedules[${schedule.scheduleId}][${day}]`;
                    this.props.form.setFieldsValue({ [fieldName]: true });
                });
            });

            this.setState({ selectedRowKeys });
        }
    };

    transformSchedule = (schedule) => {
        const { filterDistributionCenters } = schedule.params;

        let filterPrice = ['distributionCenter', false];

        if (filterDistributionCenters) {
            filterPrice = [filterDistributionCenters.type];

            if (filterDistributionCenters.values) {
                filterPrice.push(filterDistributionCenters.values[0]);
            }
        }

        const scheduleTransformed = {
            name: schedule.name,
            // scheduleId: schedule.scheduleIdConfig.split('_')[0],
            categoryConnectorId: schedule.params.categoryConnectorId,
            connectorId: schedule.params.connectorId,
            delta: schedule.params.delta,
            defaultMargin: schedule.params.defaultMargin,
            filterDistributionCenters: filterPrice,
            inventoryId: schedule.params.inventoryId,
            state: schedule.params.state,
            priceTable: schedule.params.priceTable,
            status: schedule.status,
        };

        return {
            schedule: scheduleTransformed,
            showScheduleTable: true,
        };
    };

    transformCategoryConnectors = (categoryConnectors) => {
        const transformedCategoryConnectors = [
            {
                connectorId: false,
                name: 'Sem mapeamento',
            },
        ].concat(categoryConnectors);
        return transformedCategoryConnectors;
    };

    transformInventories = (inventories) => {
        const transformedInventories = [
            {
                inventoryId: false,
                name: 'Todos os produtos',
            },
        ].concat(inventories);
        return transformedInventories;
    };

    filterOption = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    handleScheduleTable = (option) => {
        this.setState({ showScheduleTable: Boolean(option) });
        this.uncheckScheduleDayUnavailable(option);
    };

    uncheckScheduleDayUnavailable = (option) => {
        const schedule = this.getScheduleSelected(option);

        this.setState((state) => {
            const days = schedule.days
                .map((scheduleDay) => {
                    const matchedDay = state.checkedDays.find((checkedDay) => {
                        return checkedDay === scheduleDay;
                    });

                    return matchedDay;
                })
                .filter((scheduleDay) => {
                    return scheduleDay;
                });

            return { checkedDays: days };
        });
    };

    getScheduleSelected = (scheduleId) => {
        const { availableSchedules } = this.state.supplier;
        const schedule = availableSchedules.find((availableSchedule) => {
            return availableSchedule.id === scheduleId;
        });

        return schedule;
    };

    handleScheduleDay = (checkedDay) => {
        return (event) => {
            const { checked } = event.target;

            if (checked) {
                return this.setState((state) => {
                    return {
                        checkedDays: state.checkedDays.concat(checkedDay),
                    };
                });
            }

            return this.setState((state) => {
                return {
                    checkedDays: state.checkedDays.filter((day) => {
                        return day !== checkedDay;
                    }),
                };
            });
        };
    };

    update = (event) => {
        event.preventDefault();
        const { currentUser } = this.props;
        const { supplierId } = this.props.match.params;
        const supplierConfig = currentUser?.suppliers?.find((supplierConfig) => {
            return supplierConfig.supplierId === supplierId;
        });

        if (
            ability.can('create', {
                status: supplierConfig.status || false,
                __type: 'SupplierSchedule',
            })
        ) {
            this.props.form.validateFields(async (error, values) => {
                if (error) {
                    return this.setState({
                        updateLoading: false,
                        updateStatusLoading: false,
                    });
                }

                try {
                    const { scheduleId, supplierId } = this.props.match.params;
                    const payload = this.transformPayload(values);

                    await ScheduleAPI.updateSchedule(supplierId, scheduleId, payload);

                    Notification('success', 'Agendamento atualizado!');
                } catch (error) {
                    console.log('ERROR save schedule: ', error);
                    Notification(
                        'error',
                        'Ocorreu um erro ao salvar o agendamento, tente novamente!'
                    );
                } finally {
                    this.setState({
                        updateLoading: false,
                        updateStatusLoading: false,
                    });
                }
            });

            return;
        }

        Notification('error', 'Ative o distribuidor para atualizar um agendamento!');
    };

    transformPayload = (params) => {
        const { paramsPayload } = this.state.supplier;
        const { status } = this.state.schedule;
        const { name, categoryConnector, connector, inventory, delta, defaultMargin } = params;
        const filterDistributionCenters = this.getFilterDistributionCenter(params.priceFilter);
        const configBySchedule = this.getConfigBySchedule(paramsPayload.schedule, params);

        const basePayload = {
            name,
            params: {
                ...(categoryConnector && { categoryConnectorId: categoryConnector }),
                connectorId: connector,
                delta,
                defaultMargin,
                ...(inventory && { inventoryId: inventory }),
                ...configBySchedule,
            },
            status,
        };

        if (params.priceFilter && !params.priceFilter.includes(false)) {
            basePayload.params = {
                ...basePayload.params,
                filterDistributionCenters,
            };
        }

        const schedules = Object.keys(params.schedules)
            .map((scheduleId) => {
                const schedule = params.schedules[scheduleId];
                const days = Object.keys(schedule).filter((day) => {
                    return schedule[day];
                });

                return {
                    scheduleId,
                    days,
                };
            })
            .filter((schedule) => {
                return schedule.days.length;
            });

        return Object.assign(basePayload, { schedules });
    };

    getFilterDistributionCenter(filter = []) {
        return filter.reduce((filterDistributionCenters, value) => {
            if (!Object.keys(filterDistributionCenters).length) {
                filterDistributionCenters.type = value;

                return filterDistributionCenters;
            }

            filterDistributionCenters.values = filterDistributionCenters.values
                ? filterDistributionCenters.values.push(value)
                : [value];

            return filterDistributionCenters;
        }, {});
    }

    getConfigBySchedule(schedule, data) {
        return schedule.reduce((payload, param) => {
            payload[param.key] = data[param.key];
            return payload;
        }, {});
    }

    getPriceFilterOptions = () => {
        const { distributionCenters, disableAllCentersOption } = this.state;
        const defaultRootOption = {
            value: 'lowerPrice',
            label: 'Menor preço',
            disabled: this.props.form.getFieldValue('inventory'),
        };
        const options = [
            {
                value: 'distributionCenter',
                label: 'Centro de distribuição',
                children: [
                    {
                        value: false,
                        label: 'Todos',
                        disabled: disableAllCentersOption,
                    },
                ].concat(
                    distributionCenters.map((stock) => {
                        return {
                            value: stock.distributionCenter,
                            label: stock.distributionCenter,
                        };
                    })
                ),
            },
        ];

        return [defaultRootOption].concat(options);
    };

    handleInventory = (inventorySelected) => {
        const priceFilter = this.props.form.getFieldValue('priceFilter');
        if (inventorySelected && Array.isArray(priceFilter) && priceFilter[0] === 'lowerPrice') {
            this.props.form.setFieldsValue({ priceFilter: [] });
        }
    }

    handleConnector = (connectorSelected) => {
        const { connectors } = this.state;
        const connector = connectors.find((connector) => {
            return connector.connectorId === connectorSelected;
        });

        const wdnaTemplate = connector.template === 'wdna';

        this.setState({ disableAllCentersOption: !wdnaTemplate });

        if (this.props.form.getFieldValue('inventory')) {
            return;
        }

        const [option, center] = this.props.form.getFieldValue('priceFilter');
        if (!wdnaTemplate && option === 'distributionCenter' && !center) {
            this.props.form.setFieldsValue({ priceFilter: ['lowerPrice'] });
        }      
    };

    handleCheckbox = (scheduleId, checked, index) => {
        const days = this.props.form.getFieldValue(`schedules[${scheduleId}]`);

        Object.keys(days).forEach((day) => {
            const fieldName = `schedules[${scheduleId}][${day}]`;
            this.props.form.setFieldsValue({ [fieldName]: index === 0 ? true : checked });
        });
    };

    render() {
        const {
            categoryConnectors,
            connectors,
            updateLoading,
            updateStatusLoading,
            inventories,
            initialLoading,
            schedule,
            supplier,
            distributionCenters,
        } = this.state;
        const { getFieldDecorator } = this.props.form;

        const { currentUser } = this.props;
        const { supplierId } = this.props.match.params;
        const supplierConfig = getSupplierConfig(currentUser, supplierId);

        const priceFilterProps = distributionCenters.length > 1
            ? { xs: 24, sm: 12, md: 8, xl: 6 } : { span: 0 };

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={paths.schedules}>Agendamentos</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Editar</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <Form onSubmit={this.update}>
                        <TitlePage
                            title="Agendamento"
                            subtitle="Crie e personalize agendamentos para exportação de produtos."
                            extraTitle={supplier.name}
                            actions={
                                <Fragment>
                                    {!initialLoading && (
                                        <Button
                                            icon="poweroff"
                                            htmlType="submit"
                                            loading={updateStatusLoading}
                                            style={{ marginRight: 10 }}
                                            onClick={() => {
                                                this.setState((prevState) => {
                                                    const updateStatusLoading = true;
                                                    const status = !this.state.schedule.status;
                                                    const schedule = { ...prevState.schedule, status };
                                                    return {...prevState, schedule, updateStatusLoading};
                                                });
                                            }}
                                        >
                                            {
                                                updateStatusLoading
                                                    ? !this.state.schedule.status ? 'Inativar' : 'Ativar'
                                                    : this.state.schedule.status ? 'Inativar' : 'Ativar'
                                            }
                                        </Button>
                                    )}
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={updateLoading}
                                        icon="save"
                                        onClick={() => {
                                            this.setState({ updateLoading: true });
                                        }}
                                    >
                                        Salvar
                                    </Button>
                                </Fragment>
                            }
                        />
                        <Loading loading={initialLoading}>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={8} xl={6}>
                                    <FormItem label="Nome">
                                        {getFieldDecorator('name', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Insira um nome para o agendamento.',
                                                },
                                            ],
                                            initialValue: schedule.name,
                                        })(<Input />)}
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={12} md={8} xl={6}>
                                    {utils.renderFields(
                                        supplier.paramsPayload?.schedule,
                                        this.props.form,
                                        schedule
                                    )}
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={8} xl={6}>
                                    <FormItem label="Mapeamento de categorias">
                                        {getFieldDecorator('categoryConnector', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:
                                                        'Selecione um mapeamento de categorias',
                                                },
                                            ],
                                            initialValue: schedule.categoryConnectorId || false,
                                        })(
                                            <Select
                                                filterOption={(input, option) => {
                                                    return utils.filterOptions(input, option);
                                                }}
                                                optionFilterProp="children"
                                                showSearch
                                                style={{ width: '100%' }}
                                            >
                                                {categoryConnectors.map((categoryConnector) => {
                                                    return (
                                                        <Option
                                                            value={categoryConnector.connectorId}
                                                        >
                                                            {categoryConnector.name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                                {(supplierConfig?.inventory || schedule.inventoryId) && (
                                    <Col xs={24} sm={12} md={8} xl={6}>
                                        <FormItem label="Inventário">
                                            {getFieldDecorator('inventory', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Selecione um inventário',
                                                    },
                                                ],
                                                initialValue: schedule.inventoryId || false,
                                            })(
                                                <Select
                                                    filterOption={(input, option) => {
                                                        return utils.filterOptions(input, option);
                                                    }}
                                                    onChange={this.handleInventory}
                                                    optionFilterProp="children"
                                                    showSearch
                                                    style={{ width: '100%' }}
                                                >
                                                    {inventories.map((inventory) => {
                                                        return (
                                                            <Option value={inventory.inventoryId}>
                                                                {inventory.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                )}
                                <Col xs={24} sm={12} md={8} xl={6}>
                                    <FormItem label="Conector">
                                        {getFieldDecorator('connector', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Selecione um conector',
                                                },
                                            ],
                                            initialValue: schedule.connectorId,
                                        })(
                                            <Select
                                                filterOption={(input, option) => {
                                                    return utils.filterOptions(input, option);
                                                }}
                                                optionFilterProp="children"
                                                onChange={this.handleConnector}
                                                showSearch
                                                style={{ width: '100%' }}
                                            >
                                                {connectors.map((connector) => {
                                                    return (
                                                        <Option value={connector.connectorId}>
                                                            {connector.connectorName}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                                {!this.props.form.getFieldValue('inventory') && (
                                    <Col xs={24} sm={12} md={8} xl={6}>
                                        <FormItem label="Margem de preço padrão">
                                            {getFieldDecorator('defaultMargin', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Insira uma margem padrão para os produtos',
                                                    },
                                                ],
                                                initialValue: (typeof schedule.defaultMargin === 'number') ? schedule.defaultMargin : 100,
                                            })(
                                                <InputNumber
                                                    min={0}
                                                    style={{ width: '100%' }}
                                                    parser={(value) => {
                                                        return value.replace('%', '');
                                                    }}
                                                    formatter={(value) => {
                                                        return `${value}%`;
                                                    }}
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                )}
                                <Col {...priceFilterProps}>
                                    <FormItem label="Filtro de preço">
                                        {getFieldDecorator('priceFilter', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Selecione um filtro de preço',
                                                },
                                            ],
                                            initialValue: (
                                                this.state.disableAllCentersOption &&
                                                schedule.filterDistributionCenters[0] === 'distributionCenter' &&
                                                !schedule.filterDistributionCenters[1]
                                            ) ? [] : schedule.filterDistributionCenters,
                                        })(
                                            <Cascader
                                                expandTrigger="hover"
                                                placeholder="Filtro de preço"
                                                options={this.getPriceFilterOptions()}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="Tipo de importação">
                                        {getFieldDecorator('delta', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Selecione um tipo de importação',
                                                },
                                            ],
                                            initialValue: schedule.delta,
                                        })(
                                            <RadioGroup
                                                name="importType"
                                                onChange={this.changeImportType}
                                                // value={supplierConfig.filterDelta}
                                                style={{ width: '100%' }}
                                            >
                                                <Col xs={24} md={12}>
                                                    <Radio value={true}>
                                                        <b>Delta</b>
                                                        <br />
                                                        <span style={{ whiteSpace: 'normal' }}>
                                                            Realiza a importação apenas dos produtos que
                                                            foram atualizados desde a última importação.
                                                        </span>
                                                    </Radio>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Radio value={false}>
                                                        <b>Full</b>
                                                        <br />
                                                        <span style={{ whiteSpace: 'normal' }}>
                                                            Realiza a importação de todos os produtos
                                                            deste distribuidor.
                                                        </span>
                                                    </Radio>
                                                </Col>
                                            </RadioGroup>
                                        )}
                                    </FormItem>
                                </Col>
                                {/* {showScheduleTable && (
                                    <Col span={24}>
                                        <ScheduleTable
                                            checkedDays={checkedDays}
                                            form={this.props.form}
                                            handleScheduleDay={(day) => this.handleScheduleDay(day)}
                                            supplierSchedule={(supplier.availableSchedules || [])}
                                        />
                                    </Col>
                                )} */}
                                <Col span={24}>
                                    <ScheduleTable
                                        rowSelection={{
                                            columnTitle: ' ',
                                            selectedRowKeys: this.state.selectedRowKeys,
                                            onSelect: (row, checked, changeRows) => {
                                                const maxTimes = this.state.supplier?.infos?.updates?.times_per_day || 1;

                                                if (checked && this.state.selectedRowKeys.length >= maxTimes) {
                                                    Notification(
                                                        'warn',
                                                        `Não selecione mais do que ${maxTimes} horário(s)!`,
                                                        `A frequência de atualização é de ${maxTimes}x ao dia. Para mais informações acesse a página de configuração do distribuidor`,
                                                        15
                                                    );
                                                    return;
                                                }

                                                this.setState({
                                                    selectedRowKeys: changeRows.map((row) => {
                                                        return row.key;
                                                    }),
                                                });

                                                this.handleCheckbox(row.key, checked);
                                            },
                                        }}
                                        multiple
                                        form={this.props.form}
                                        supplierSchedule={supplier.availableSchedules || []}
                                    />
                                </Col>
                            </Row>
                        </Loading>
                    </Form>
                </div>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps)(Form.create()(Edit));
