import React, { Component, Fragment } from 'react';
import qs from 'query-string';
import { Breadcrumb, Button, Form, Icon, Modal, Select, Empty } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { head, innerJoin } from 'ramda';

import DynamicFilter from '~/components/Filters/DynamicFilter';
import HeaderInfo from '~/components/Header/HeaderInfo';
import InfiniteScroll from '~/components/InfiniteScroller';
import Notification from '~/components/Notification/AntNotification';
import Table from '~/components/Table/AntTable';
import { DeleteConfirm } from '~/components/Confirm';
import { TitlePage } from '~/components/Header/TitlePage';

import ability from '~/ability';
import { ScheduleAPI, SupplierAPI } from '~/lib/api';
import { handleSort } from '~/lib/Sort';
import { loadMore } from '~/lib/InfiniteScroll';

const { Item: FormItem } = Form;
const { Option } = Select;

const style = {
    icon: {
        marginRight: 10,
        fontSize: 18,
        cursor: 'pointer',
    },
};

class Schedules extends Component {
    state = {
        connectors: [],
        error: false,
        fetchSchedules: {
            hasMore: false,
            pagination: {},
            result: [],
            total: 0,
        },
        loading: true,
        supplierModal: false,
        suppliers: [],
    };

    componentDidMount() {
        document.title = 'Grid2B | Agendamentos';

        this.getAndSetInitialData();
    }

    getAndSetInitialData = async (refresh = false, sorter = true) => {
        try {
            if (refresh) {
                this.setState({
                    error: false,
                    loading: true,
                });
            }

            const search = qs.parse(this.props.location.search);
            const filters = {
                ...search,
                orderBy: 'name',
                sort: 'asc',
            };

            if (!sorter) {
                delete filters.orderBy;
                delete filters.sort;
            }

            const [fetchSchedules, fetchSuppliers] = await Promise.all([
                ScheduleAPI.fetchAllSchedules(qs.stringify(filters)),
                SupplierAPI.fetchSuppliers(),
            ]);

            this.setState((state) => {
                return {
                    fetchSchedules: {
                        ...state.fetchSchedules,
                        ...fetchSchedules,
                        hasMore: Boolean(fetchSchedules.pagination.next),
                    },
                    loading: false,
                    suppliers: fetchSuppliers.result,
                };
            });
        } catch (error) {
            console.log('ERROR getAndSetInitialData: ', error);

            this.setState({
                error: true,
                loading: false,
            });
        }
    };

    execute = async (supplierId, supplierIdConfig) => {
        try {
            await ScheduleAPI.executeSchedule(supplierId, supplierIdConfig, {});

            Notification('success', 'Por favor, aguarde enquanto executamos o agendamento.');
        } catch (error) {
            console.log('ERROR execute: ', error);

            if (error.response && error.response.data.message === 'export-import-invalid') {
                Modal.info({
                    title: 'Fora da janela de atualização',
                    content: 'Para executar a primeira importação é necessário aguardar a próxima janela de atualização do distribuidor',
                });
            } else {
                Notification('error', 'Ocorreu um erro ao executar o agendamento');
            }
        }
    };

    delete = async (supplierIdConfig) => {
        try {
            const { supplierId } = this.props.match.params;

            await ScheduleAPI.deleteSchedule(supplierId, supplierIdConfig);

            this.setState((state) => {
                return {
                    fetchSchedules: {
                        ...state.fetchSchedules,
                        result: state.fetchSchedules.result.filter((schedule) => {
                            return schedule.supplierIdConfig !== supplierIdConfig;
                        }),
                    },
                };
            });

            Notification('success', 'Agendamento apagado!');
        } catch (error) {
            Notification('error', 'Não foi possível apagar o agendamento, tente novamente!');
        }
    };

    fetchSchedules = async () => {
        try {
            this.setState({ loading: true });

            const { search } = this.props.location;

            const schedules = await ScheduleAPI.fetchAllSchedules(search);

            this.setState({
                loading: false,
                fetchSchedules: schedules,
            });
        } catch (error) {
            console.log('ERROR fetchSchedules: ', error);
        }
    };

    loadMoreSchedules = () => {
        const loadMoreScoped = loadMore.bind(this);

        return loadMoreScoped('fetchSchedules', this.getAndSetInitialData);
    };

    handleSort = (pagination, filters, sorter) => {
        const handleSortScoped = handleSort.bind(this);

        return handleSortScoped(sorter, this.fetchSchedules);
    };

    handleSelectSupplierModal = () => {
        this.setState((prevState) => {
            return { supplierModal: !prevState.supplierModal };
        });
    };

    goToNewSchedule = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const supplierId = values.supplier;
                const valid = this.validateSupplier(supplierId);

                if (valid) {
                    return this.props.history.push(
                        `/dashboard/suppliers/${supplierId}/schedules/new`
                    );
                }

                Notification(
                    'error',
                    'Distribuidor inativo! Ative o distribuidor para criar um agendamento.'
                );
            }
        });
    };

    getSubject = (supplierId) => {
        const supplierSetting = this.props.currentUser?.suppliers?.find((supplier) => {
            return supplier.supplierId === supplierId;
        });
        const status = supplierSetting?.status || false;

        return {
            status,
            __type: 'Supplier',
        };
    };

    validateSupplier = (supplierId) => {
        return ability.can('select', this.getSubject(supplierId));
    };

    transformColumns = () => {
        const userSuppliers = this.props.currentUser?.suppliers || [];
        const supplierStatus = userSuppliers.reduce((result, supplier) => {
            result[supplier.supplierId] = supplier.status;
            return result;
        }, {});

        const suppliers = this.state.suppliers
            .filter((supplier) => {
                return supplierStatus[supplier.id];
            })
            .map((supplier) => {
                return {
                    value: supplier.id,
                    name: supplier.name,
                };
            });
        const columns = [
            {
                title: 'Nome',
                key: 'name',
                dataIndex: 'name',
                width: '20%',
                defaultSortOrder: 'ascend',
                sorter: true,
            },
            {
                title: 'Conector',
                key: 'connector',
                dataIndex: 'connector',
                hidden: true,
                width: '20%',
            },
            {
                title: 'Tipo',
                key: 'type',
                dataIndex: 'type',
                hidden: true,
                width: '10%',
            },
            {
                title: 'Inventário',
                key: 'inventory',
                dataIndex: 'inventory',
                hidden: true,
                width: '20%',
            },
            {
                title: 'Distribuidor',
                key: 'supplier',
                dataIndex: 'supplier',
                alias: 'supplierId',
                type: 'select',
                options: suppliers,
                width: '12%',
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                width: '8%',
                hidden: true,
                render: (status) => {
                    return status ? 'Ativo' : 'Inativo';
                },
            },
            {
                align: 'right',
                title: '',
                key: 'actions',
                dataIndex: 'actions',
                hidden: true,
                render: (schedule) => {
                    return (
                        <Fragment>
                            <a
                                href="Javascript:Void(0)" /* eslint-disable-line */
                                onClick={() => {
                                    return DeleteConfirm(
                                        () => {
                                            return this.execute(
                                                schedule.supplierId,
                                                schedule.supplierIdConfig
                                            );
                                        },
                                        { title: 'Deseja realmente executar esse agendamento?' }
                                    );
                                }}
                            >
                                <Icon type="play-circle" style={style.icon} />
                            </a>
                            <Link
                                to={`/dashboard/suppliers/${schedule.supplierId}/schedules/edit/${
                                    schedule.supplierIdConfig
                                }`}
                            >
                                <Icon type="edit" style={style.icon} />
                            </Link>
                            <a
                                href="Javascript:Void(0)" /* eslint-disable-line */
                                onClick={() => {
                                    return DeleteConfirm(
                                        () => {
                                            return this.delete(schedule.supplierIdConfig);
                                        },
                                        {
                                            title: 'Deseja realmente apagar esse agendamento?',
                                        }
                                    );
                                }}
                            >
                                <Icon
                                    style={Object.assign(
                                        {},
                                        { cursor: style.icon.cursor },
                                        { fontSize: style.icon.fontSize }
                                    )}
                                    type="delete"
                                />
                            </a>
                        </Fragment>
                    );
                },
            },
        ];

        return columns;
    };

    transformData = () => {
        const { suppliers } = this.state;
        const { result: schedules } = this.state.fetchSchedules;
        const data = schedules.map((schedule) => {
            const supplier = head(
                innerJoin(
                    (record, id) => {
                        return record.id === id;
                    },
                    suppliers,
                    [schedule.supplierId]
                )
            );

            if (supplier) {
                return {
                    key: schedule.supplierIdConfig,
                    name: schedule.name,
                    connector: schedule.params.connectorName,
                    type: schedule.params.delta ? 'Delta' : 'Full',
                    inventory: schedule.params.inventoryName || 'Todos os produtos',
                    supplier: supplier.name,
                    status: schedule.status,
                    actions: schedule,
                };
            }

            return false;
        });

        return data.filter((data) => {
            return data;
        });
    };

    render() {
        const { error, fetchSchedules, loading, suppliers } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { hasMore, total } = fetchSchedules;
        const columns = this.transformColumns();
        const data = this.transformData();

        const userSuppliers = this.props.currentUser?.suppliers || [];

        const supplierStatus = userSuppliers.reduce((result, supplier) => {
            result[supplier.supplierId] = supplier.status;
            return result;
        }, {});

        const activeSuppliers = suppliers.filter((supplier) => {
            return supplierStatus[supplier.id];
        });

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/dashboard">Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Agendamentos</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage
                        title="Agendamentos"
                        subtitle="Confira a lista de todos os seus agendamentos."
                    />
                    <div className="content-filters">
                        <DynamicFilter
                            onSubmit={() => {
                                return this.getAndSetInitialData(true, false);
                            }}
                            filters={columns}
                        />
                        <HeaderInfo
                            showing={data.length}
                            total={total}
                            actions={
                                <Button
                                    type="primary"
                                    className="success"
                                    icon="plus"
                                    onClick={this.handleSelectSupplierModal}
                                >
                                    Criar
                                </Button>
                            }
                        />
                    </div>
                    <Modal
                        closable
                        destroyOnClose
                        onCancel={this.handleSelectSupplierModal}
                        onOk={this.goToNewSchedule}
                        okText="Ir para agendamento"
                        title="Selecione o distribuidor"
                        visible={this.state.supplierModal}
                    >
                        <Form>
                            <FormItem label="Distribuidores">
                                {getFieldDecorator('supplier', {
                                    rules: [
                                        { required: true, message: 'Selecione um distribuidor' },
                                    ],
                                })(
                                    <Select
                                        notFoundContent={(
                                            <Empty
                                                description="Não há distribuidores ativos"
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            />
                                        )}
                                    >
                                        {activeSuppliers.map((supplier) => {
                                            return (
                                                <Option value={supplier.id}>{supplier.name}</Option>
                                            );
                                        })}
                                    </Select>
                                )}
                            </FormItem>
                        </Form>
                    </Modal>
                    <div
                        className="infinite-container"
                        style={{ height: window.innerHeight - 307, overflow: 'auto' }}
                    >
                        <InfiniteScroll
                            hasMore={hasMore}
                            initialLoad={false}
                            loadMore={this.loadMoreSchedules}
                            threshold={20}
                            useWindow={false}
                        >
                            <Table
                                columns={columns}
                                data={data}
                                discountInfiniteContainerHeight={306}
                                error={error}
                                loading={loading}
                                onChange={this.handleSort}
                                pagination={false}
                                refreshData={() => {
                                    return this.getAndSetInitialData(true);
                                }}
                            />
                        </InfiniteScroll>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentUser } = state.user;

    return { currentUser };
};

export default connect(mapStateToProps)(Form.create()(Schedules));
