import { get, put } from '~/services/api/rest/client';
import utils from '~/lib/utils';

/** supplier requests */
export const UserAPI = {
    fetchUser: () => {
        return get('/users');
    },
    updateUser: (data) => {
        return put('/users', data);
    },
    fetchUsersFromUserpool: (filters = {}) => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`admin/users/userpool?${queryString}`);
    },
    adminFetchUser: (filters = {}) => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`admin/users?${queryString}`);
    },
    adminConfirmSignUp: (data) => {
        return put('admin/users/confirm-signup', data);
    },
    adminEnableUser: (data) => {
        return put('admin/users/enable-user', data);
    },
    adminDisableUser: (data) => {
        return put('admin/users/disable-user', data);
    },
};
