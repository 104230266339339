import { del, get, post, put } from '~/services/api/rest/client';

/** connector requests */
export const ConnectorAPI = {
    fetchConnectors: (filters = '') => {
        return get(`/connectors${filters}`);
    },
    fetchConnector: (connectorId, filters = '') => {
        return get(`/connectors/${connectorId}${filters}`);
    },
    fetchMappingFields: () => {
        return get('/connectors/mapping-fields');
    },
    createConnector: (data) => {
        return post('/connectors', data);
    },
    updateConnector: (connectorId, data) => {
        return put(`/connectors/${connectorId}`, data);
    },
    deleteConnector: (connectorId) => {
        return del(`/connectors/${connectorId}`);
    },
};
