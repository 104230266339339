import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default class UnauthenticatedRoute extends React.Component {
    getComponent(params) {
        const { url, props, cProps, Component } = params;

        if (cProps.userToken === null) {
            if (url === '/') {
                return <Redirect to="/auth/login" />;
            }

            return <Component {...props} {...cProps} />;
        }

        return <Redirect to="/dashboard" />;
    }

    render() {
        const { component: Component, props: cProps, ...rest } = this.props;
        const { url } = this.props.computedMatch;

        return (
            <Route
                {...rest}
                render={(props) => {
                    return this.getComponent({ url, props, cProps, Component });
                }}
            />
        );
    }
}
