import React, { Component, Fragment } from 'react';
import { withRouter /*, Redirect*/ } from 'react-router-dom';
import { Form, Input, Icon, Button } from 'antd';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';

/** Components */
import Federated from '~/components/Buttons/FederatedButtons';
import Notification from '~/components/Notification/AntNotification';

/** Styles */
import {
    Description,
    DescriptionContainer,
    FormItemStyled as FormItem,
    SignInLink,
} from '../styles';

class Signup extends Component {
    state = {
        loading: false,
        newUser: null,
        username: '',
        password: '',
        passwordVisible: false,
        confirmPasswordVisible: false,
    };

    componentDidMount() {
        document.title = 'Grid2B | Cadastro';
    }

    signUp = (e) => {
        e.preventDefault();

        this.setState({ loading: true });
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                try {
                    const { password, email } = values;

                    await Auth.signUp({
                        username: email,
                        password,
                    });

                    this.props.history.push({
                        pathname: '/auth/confirm',
                        state: { email },
                    });
                } catch (error) {
                    console.log('ERROR Signup: ', error);

                    switch (error.code) {
                        case 'UsernameExistsException':
                            Notification(
                                'error',
                                'Já existe uma conta com o email informado. Por favor, utilize a opção de login ou recuperar senha.'
                            );
                            break;
                        default:
                            Notification(
                                'error',
                                'Ocorreu um erro ao se cadastrar, tente novamente'
                            );
                            break;
                    }
                }
            }

            this.setState({ loading: false });
        });
    };

    handleChange = (e) => {
        const state = {};
        const { name } = e.target;
        const errors = this.state.errors;

        state[name] = e.target.value;

        if (errors[name]) {
            delete errors[name];
        }

        const states = Object.assign({}, errors, state);
        this.setState(states);
    };

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('As duas senhas não coincidem!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    // validatePatternPassword =

    renderSignup() {

        const { passwordVisible } = this.state;
        const { confirmPasswordVisible } = this.state;
        const { getFieldDecorator } = this.props.form;
        const validateStatusAll = 'validating';

        return (
            <Form onSubmit={this.signUp}>
                <FormItem
                    validateStatus={validateStatusAll}
                >
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                type: 'email',
                                message: 'E-mail inválido!',
                            },
                            {
                                required: true,
                                message: 'Insira o email!',
                            },
                        ],
                    })(
                        <Input
                            placeholder="Email"
                        />
                    )}
                </FormItem>
                <FormItem
                    validateStatus={validateStatusAll}
                >
                    {getFieldDecorator('password', {
                        rules: [
                            {
                                required: true,
                                message: 'Insira a senha!',
                            },
                            {
                                validator: this.validateToNextPassword,
                            },
                            {
                                min: 6,
                                message: 'Mínimo 6 caracteres!',
                            },
                        ],
                    })(
                        <Input
                            placeholder="Senha"
                            suffix={
                                passwordVisible ? (
                                    <Icon
                                        type="eye-invisible"
                                        style={
                                            {
                                                color: '#a7adc2',
                                                fontSize: '18px'
                                            }
                                        }
                                        onClick={() => {
                                            return this.setState({
                                                passwordVisible: false,
                                            });
                                        }}
                                    />
                                ) : (
                                    <Icon
                                        type="eye"
                                        style={
                                            {
                                                color: '#a7adc2',
                                                fontSize: '18px'
                                            }
                                        }
                                        onClick={() => {
                                            return this.setState({
                                                passwordVisible: true,
                                            });
                                        }}
                                    />
                                )
                            }
                            type={passwordVisible ? 'text' : 'password'}
                        />
                    )}
                </FormItem>
                <FormItem
                    validateStatus={validateStatusAll}
                >
                    {getFieldDecorator('confirm', {
                        rules: [
                            {
                                required: true,
                                message: 'Confirme sua senha!',
                            },
                            {
                                validator: this.compareToFirstPassword,
                            },
                        ],
                    })(
                        <Input
                            placeholder="Confirme sua senha"
                            suffix={
                                confirmPasswordVisible ? (
                                    <Icon
                                        type="eye-invisible"
                                        style={
                                            {
                                                color: '#a7adc2',
                                                fontSize: '18px'
                                            }
                                        }
                                        onClick={() => {
                                            return this.setState({
                                                confirmPasswordVisible: false,
                                            });
                                        }}
                                    />
                                ) : (
                                    <Icon
                                        type="eye"
                                        style={
                                            {
                                                color: '#a7adc2',
                                                fontSize: '18px'
                                            }
                                        }
                                        onClick={() => {
                                            return this.setState({
                                                confirmPasswordVisible: true,
                                            });
                                        }}
                                    />
                                )
                            }
                            type={confirmPasswordVisible ? 'text' : 'password'}
                            onBlur={this.handleConfirmBlur}
                        />
                    )}
                </FormItem>
                <FormItem>
                    <Button loading={this.state.loading} type="primary" block htmlType="submit">
                        Criar conta
                    </Button>
                </FormItem>

                {/*/!** TODO: Register Facebook and Google **!/*/}
                {/*<Federated*/}
                {/*    col={24}*/}
                {/*    textAdd={'Criar conta com'}*/}
                {/*    // minWidth*/}
                {/*    onStateChange={this.props.onStateChange}*/}
                {/*/>*/}

                <DescriptionContainer last>
                    <Description>
                        Já possui uma conta? <SignInLink to="/auth/login">Entrar</SignInLink>
                    </Description>
                </DescriptionContainer>
            </Form>
        );
    }

    render() {
        return (
            <Fragment>
                {this.renderSignup()}
                {/* {this.state.newUser === null
                    ? this.renderSignup()
                    : <Redirect
                        to={{
                            pathname: '/auth/confirm',
                            state: {
                                username: this.state.username,
                                password: this.state.password,
                            },
                        }}
                    />
                } */}
            </Fragment>
        );
    }
}

Signup.propTypes = {
    onStateChange: PropTypes.func,
    federated: PropTypes.object,
};

export default withRouter(Form.create()(Signup));
