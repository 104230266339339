import { Auth } from 'aws-amplify';
import jwt from 'jsonwebtoken';

const userUtil = {
    addAdminProperty: (user) => {
        if (!user) return {};

        user.isAdmin = user['cognito:groups'].includes('g2b-api-admin');
        return user;
    },
    get: (jwtToken) => {
        const user = jwt.decode(jwtToken);
        return userUtil.addAdminProperty(user);
    },
    token: async () => {
        const session = await Auth.currentSession();
        return session.getIdToken();
    },
};

export default userUtil;
