import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import { Breadcrumb, Button, Icon, Modal, Input, Tooltip } from 'antd';

import DynamicFilter from '~/components/Filters/DynamicFilter';
import EditableTable from '~/components/Table/EditableTable';
import HeaderInfo from '~/components/Header/HeaderInfo';
import InfiniteScroll from '~/components/InfiniteScroller';
import Notification from '~/components/Notification/AntNotification';
import { TitlePage } from '~/components/Header/TitlePage';

import utils from '~/lib/utils';
import { CatalogAPI, SupplierAPI } from '~/lib/api';
import { del, post, put } from '~/services/api/rest/client';
import { loadMore } from '~/lib/InfiniteScroll';
import { paths } from '~/routes';

const confirm = Modal.confirm;
const { TextArea } = Input;
const Search = Input.Search;

class Edit extends Component {
    state = {
        catalog: {},
        catalogItemsData: {
            hasMore: false,
            result: [],
            total: 0,
        },
        categories: [],
        categoriesToImport: '',
        category: '',
        createLoading: false,
        error: false,
        importLoading: false,
        isLoading: false,
        loading: true,
        search: '',
        showCategoryModal: false,
        tableLoading: false,
        updateLoading: false,
    };

    componentDidMount() {
        document.title = 'Grid2B | Catálogo de categorias';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async (refresh = false, sorter = true) => {
        try {
            if (refresh) {
                this.setState({
                    error: false,
                    loading: true,
                });
            }

            const search = qs.parse(this.props.location.search);
            const filters = {
                ...search,
                orderBy: 'name',
                sort: 'asc',
            };

            if (!sorter || filters.name) {
                delete filters.orderBy;
                delete filters.sort;
            }

            const [catalogData, catalogItemsData, suppliers] = await Promise.all([
                CatalogAPI.fetchCatalog(this.props.match.params.catalogId),
                CatalogAPI.fetchCatalogItems(
                    this.props.match.params.catalogId,
                    qs.stringify(filters)
                ),
                SupplierAPI.fetchSuppliers(),
            ]);

            this.setState((state) => {
                return {
                    catalogItemsData: {
                        ...state.catalogItemsData,
                        ...catalogItemsData,
                        hasMore: Boolean(catalogItemsData.pagination.next),
                    },
                    catalog: catalogData.result,
                    loading: false,
                    suppliers: suppliers.result,
                };
            });
        } catch (error) {
            console.log('ERROR getAndSetInitialData: ', error);
            this.setState({
                error: true,
                loading: false,
            });
        }
    };

    fetchCatalogItems = async (options = {}) => {
        const search = qs.parse(this.props.location.search);
        const queryString = {
            ...search,
            ...options,
            orderBy: 'name',
            sort: 'asc',
        };

        if (queryString.name) {
            delete queryString.orderBy;
            delete queryString.sort;
        }

        const catalogData = await CatalogAPI.fetchCatalogItems(
            this.props.match.params.catalogId,
            qs.stringify(queryString)
        );

        return catalogData;
    };

    handleCategoriesToImport = (e) => {
        this.setState({
            categoriesToImport: e.target.value,
        });
    };

    importAndSetCategory = async () => {
        try {
            this.setState({ importLoading: true });
            const categoriesToImport = this.transformCategoriesToImport();
            const categoriesImported = await this.importCategories(categoriesToImport);

            this.setState((state) => {
                return {
                    catalogItemsData: {
                        ...state.catalogItemsData,
                        result: categoriesImported.concat(state.catalogItemsData.result),
                    },
                    categoriesToImport: '',
                    importLoading: false,
                };
            });
            this.hideImportCategoryModal();

            Notification('success', 'Lista de categorias importada!');

            return true;
        } catch (error) {
            this.setState({ importLoading: false });
            Notification('error', 'Ocorreu um erro na importação de categorias, tente novamente!');

            return false;
        }
    };

    accumulatedAndRemoveRepeated(matrixCategories) {
        const categories = matrixCategories.reduce((groupedCategories, arrayCategories) => {
            groupedCategories = groupedCategories.concat(arrayCategories);
            return groupedCategories;
        }, []);

        const categoriesFilteredRepeated = categories.filter((category, index) => {
            return categories.indexOf(category) === index;
        });

        return categoriesFilteredRepeated;
    }

    transformCategoriesToImport = () => {
        const categoriesToImport = this.state.categoriesToImport.split('\n');
        const categoriesToImportFiltered = categoriesToImport.filter((category) => {
            return category;
        });
        return categoriesToImportFiltered;
    };

    filterCategoriesRepeated = (newCategories) => {
        const categoriesOld = this.state.catalogItemsData.result;
        const categories = newCategories.filter((category) => {
            const exists = categoriesOld.find((oldCategories) => {
                return oldCategories.categoryId === category.categoryId;
            });

            return Boolean(!exists);
        });

        return categories;
    };

    createAndSetCategory = async (value) => {
        if (value) {
            try {
                this.setState({ createLoading: true });
                let category = await this.createCategory(value);
                category = this.filterCategoriesRepeated(category);

                this.setState((state) => {
                    return {
                        catalogItemsData: {
                            ...state.catalogItemsData,
                            result: category.concat(state.catalogItemsData.result),
                        },
                        createLoading: false,
                    };
                });
                Notification('success', 'Categoria criada!');

                return true;
            } catch (error) {
                this.setState({ createLoading: false });
                Notification('error', 'Ocorreu um erro ao criar a categoria, tente novamente!');

                return false;
            }
        }
    };

    setLoadingState = (stateName, loading) => {
        this.setState({
            [stateName]: loading,
        });
    };

    createCategory = async (value) => {
        try {
            const { catalogId } = this.props.match.params;
            const { result } = await post(
                `/categories/catalogs/${catalogId}/items`,
                this.transformCreateCategoryPayload(value)
            );

            return result;
        } catch (error) {
            console.log('ERROR createCategory: ', error);
            throw error;
        }
    };

    importCategories = async (categories) => {
        try {
            const { catalogId } = this.props.match.params;
            const { result } = await post(
                `/categories/catalogs/${catalogId}/items/import`,
                this.transformImportCategoriesPayload(categories)
            );

            return result;
        } catch (error) {
            console.log('ERROR importCategories: ', error);
            throw error;
        }
    };

    getCategoriesToCreate = (categories, categoryHierachy) => {
        const categorySplitted = categoryHierachy.split('##');
        let categoryLevel = '';

        const categoriesToCreate = categorySplitted
            .map((item, index) => {
                categoryLevel = this.getCategoryLevel(item, categoryLevel, index);

                const results = categories.filter((category) => {
                    return category.name === categoryLevel;
                });

                if (results.length === 0) {
                    return categoryLevel;
                }

                return null;
            })
            .filter((category) => {
                return category;
            });

        return categoriesToCreate;
    };

    getCategoryLevel(item, categoryLevel, index) {
        if (index === 0) {
            categoryLevel = item;
        } else {
            categoryLevel += `##${item}`;
        }

        return categoryLevel;
    }

    updateCategory = async (data, row) => {
        try {
            this.setState({ updateLoading: true });

            const categoryId = data.key;
            const { catalogId } = this.props.match.params;
            const { result } = await put(`categories/catalogs/${catalogId}/items/${categoryId}`, {
                name: row.name,
            });

            const newCategory = Array.isArray(result) ? result : [result];

            this.filterOldCategory(categoryId, newCategory);

            Notification('success', 'Categoria atualizada!');
        } catch (error) {
            console.log('ERROR updateCategory: ', error);

            this.setState({ updateLoading: false });

            const errors = {
                'Category name already exists': 'Categoria já existe!',
            };

            Notification(
                'error',
                errors[(error?.response?.data?.details)]
                    ? errors[(error?.response?.data?.details)]
                    : 'Ocorreu um erro ao atualizar a categoria, tente novamente!'
            );
        }
    };

    filterOldCategory = (categoryId, newCategories) => {
        const { catalogItemsData } = this.state;
        const categoriesUpdated = catalogItemsData.result.map((category) => {
            newCategories.forEach((newCategory) => {
                if (newCategory.categoryId === category.categoryId) {
                    category = newCategory;
                }
            });

            return category;
        });

        this.setState((prevState) => {
            return {
                catalogItemsData: {
                    ...prevState.catalogItemsData,
                    result: categoriesUpdated,
                },
                updateLoading: false,
            };
        });
    };

    setCategory = (category) => {
        this.setState((state) => {
            return {
                catalogItemsData: {
                    ...state.catalogItemsData,
                    result: category.concat(state.catalogItemsData.result),
                },
            };
        });
    };

    transformCreateCategoryPayload(category) {
        return {
            name: category.replace(/^(#+)|(#+)$|#(?=##)/g, ''),
            createParents: true,
        };
    }

    transformImportCategoriesPayload(categories) {
        const { type } = this.state.catalog;
        const payloadBase = {
            categories: categories.map((category) => {
                return { name: category };
            }),
            createParents: true,
        };

        if (type === 'default') {
            return {
                ...payloadBase,
                type: 'default',
            };
        }

        return payloadBase;
    }

    deleteCategory = async (categoryId, itemsToBeDeleted) => {
        try {
            const { catalogId } = this.props.match.params;
            await del(`/categories/catalogs/${catalogId}/items/${categoryId}`);

            this.removeCategoriesDeletedFromState(itemsToBeDeleted);

            Notification('success', 'Categoria(s) apagada(s)!');
        } catch (error) {
            console.log('ERROR deleteCategory: ', error);

            if (error?.response?.data?.details) {
                const errors = [];
                let connectorsGrouped = {};
                const { dependencies } = error.response.data.details;

                dependencies.forEach((dependency) => {
                    if (dependency.connectors && Array.isArray(dependency.connectors)) {
                        dependency.connectors.forEach((connector) => {
                            const { suppliers } = this.state;
                            const supplier = suppliers.find((supplier) => {
                                return supplier.id === connector.supplierId;
                            });

                            connectorsGrouped = {
                                ...connectorsGrouped,
                                [connector.connectorId]: `${connector.name} (${supplier.name})`,
                            };
                        });
                    }
                });

                Object.keys(connectorsGrouped).forEach((connectorId) => {
                    errors.push(<li>{connectorsGrouped[connectorId]}</li>);
                });

                Notification(
                    'error',
                    'Ocorreu um erro ao apagar a categoria!',
                    <Fragment>
                        <span>Ela está sendo usada nos seguintes mapeamentos de categorias.</span>
                        <ul>{errors}</ul>
                    </Fragment>
                );

                return;
            }

            Notification('error', 'Ocorreu um erro ao apagar a categoria, tente novamente!');
        }
    };

    removeCategoriesDeletedFromState(itemsToBeDeleted) {
        itemsToBeDeleted.forEach((category) => {
            return this.removeCategoryState(category.categoryId);
        });
    }

    getCategoryName = (categories, categoryId) => {
        const category = categories.find((category) => {
            return category.categoryId === categoryId;
        });

        if (category) {
            return category.name;
        }

        return null;
    };

    getCategoriesChildren = (categories, categoryNameParent) => {
        const categoriesFound = categories.filter((category) => {
            const categoryName = `##${category.name}##`;
            return categoryName.indexOf(`##${categoryNameParent}##`) !== -1;
        });

        return categoriesFound;
    };

    removeCategoryState(categoryId) {
        this.setState((state) => {
            return {
                catalogItemsData: {
                    ...state.catalogItemsData,
                    result: state.catalogItemsData.result.filter((category) => {
                        return category.categoryId !== categoryId;
                    }),
                },
            };
        });
    }

    showDeleteConfirm = async (categoryId) => {
        try {
            this.setState({ loading: true });

            const { catalogId } = this.props.match.params;

            const checkCatalogItem = await CatalogAPI.checkCatalogItem(catalogId, categoryId);
            const items = checkCatalogItem.result;

            let hasMore = false;

            if (items.length > 10) {
                hasMore = true;
            }

            const content = items.slice(0, 10).map((item, index) => {
                return (
                    <li>
                        {utils.normalizeCategoryName(item.name)}
                        {index === 9 && hasMore && (
                            <span style={{ fontWeight: 'bold' }}>
                                {' '}
                                e mais {items.length - 10} categorias...
                            </span>
                        )}
                    </li>
                );
            });

            this.setState({ loading: false });

            confirm({
                title: 'Deseja realmente apagar essa(s) categoria(s)?',
                content: <ul>{content}</ul>,
                okText: 'Sim',
                cancelText: 'Não',
                width: 500,
                onOk: () => {
                    return this.deleteCategory(categoryId, checkCatalogItem.result);
                },
                onCancel: () => {},
            });
        } catch (error) {
            console.log('ERROR showDeleteConfirm: ', error);

            this.setState({ loading: false });
        }
    };

    contentDeleteConfirm(categories) {
        return (
            <Fragment>
                <span>As seguintes categorias serão deletadas: </span>
                {this.contentDeleteConfirmList(categories)}
            </Fragment>
        );
    }

    contentDeleteConfirmList(categories) {
        return (
            <ul>
                {categories.map((category) => {
                    return this.contentDeleteConfirmLi(category.name);
                })}
            </ul>
        );
    }

    contentDeleteConfirmLi(categoryName) {
        return <li>{categoryName.replace(/##/g, ' / ')}</li>;
    }

    openImportCategoryModal = () => {
        this.setState({ showCategoryModal: true });
    };

    hideImportCategoryModal = () => {
        this.setState({ showCategoryModal: false });
    };

    loadMoreCatalogItems = loadMore.bind(
        this,
        'catalogItemsData',
        this.fetchCatalogItems.bind(this)
    );

    validateInputValue = (event) => {
        const { name, value } = event.target;
        const category = value.replace(/#(?=##)|^(#+)|(#+)$/, '');

        this.setState({ [name]: category });
    };

    createTableColumns() {
        return [
            {
                title: 'Título',
                className: 'overflow',
                dataIndex: 'name',
                key: 'name',
                editable: true,
                width: '95%',
                render: (text, row, index) => {
                    if (index === 0) {
                        return {
                            children: text,
                            props: { colSpan: 2 },
                        };
                    }
                    return {
                        children: (
                            <Tooltip title={utils.normalizeCategoryName(text.name)}>
                                {utils.normalizeCategoryName(text.name)}
                            </Tooltip>
                        ),
                    };
                },
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                width: '5%',
                align: 'right',
                hidden: true,
            },
        ];
    }

    transformData() {
        const createField = [
            {
                key: 'searchable',
                name: (
                    <Search
                        enterButton="Criar"
                        placeholder="Utilize o caractere '##' para separar os níveis. ex: Informática##Monitor"
                        onSearch={(value) => {
                            return this.createAndSetCategory(value);
                        }}
                        suffix={
                            this.state.createLoading && (
                                <Icon type="loading" style={{ margin: '0 10px' }} />
                            )
                        }
                    />
                ),
            },
        ];

        const categories = this.state.catalogItemsData.result.map((category) => {
            return {
                key: category.categoryId,
                name: {
                    name: category.name,
                    lastName: category.lastName,
                },
            };
        });
        const dataSource = createField.concat(categories);

        return dataSource;
    }

    transformCategoryName(categoryName, reverse = false) {
        if (reverse) return categoryName.replace(/ \/ /g, '##');

        return categoryName.replace(/##/g, ' / ');
    }

    render() {
        const {
            catalog,
            catalogItemsData,
            categoriesToImport,
            error,
            importLoading,
            loading,
            showCategoryModal,
            updateLoading,
        } = this.state;
        const { hasMore } = catalogItemsData;
        const columns = this.createTableColumns();
        const data = this.transformData();

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={paths.categories}>Catálogo de categorias</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Editar</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage
                        title="Catálogo de categorias"
                        subtitle="Crie e/ou importe novas categorias para esse catálogo."
                        extraTitle={catalog.name}
                    />
                    <div className="content-filters">
                        <DynamicFilter onSubmit={this.getAndSetInitialData} filters={columns} />
                        <HeaderInfo
                            showing={catalogItemsData.result.length}
                            total={catalogItemsData.total}
                            actions={
                                <Button
                                    type="primary"
                                    className="success"
                                    icon="upload"
                                    onClick={this.openImportCategoryModal}
                                >
                                    Importar
                                </Button>
                            }
                        />
                    </div>
                    <Modal
                        destroyOnClose
                        visible={showCategoryModal}
                        title="Importar categorias"
                        onOk={this.importAndSetCategory}
                        onCancel={this.hideImportCategoryModal}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                loading={importLoading}
                                disabled={categoriesToImport.length === 0}
                                onClick={this.importAndSetCategory}
                            >
                                Importar
                            </Button>,
                        ]}
                    >
                        <p>
                            Cole na caixa abaixo as categorias separando as sub-categorias por '##'
                            exemplo:
                        </p>
                        <TextArea
                            rows={5}
                            value={categoriesToImport}
                            onChange={this.handleCategoriesToImport}
                            placeholder={
                                'Informática\nInformática##Monitor\nEletro##Cozinha##Refrigerador'
                            }
                        />
                    </Modal>
                    <div
                        className="infinite-container"
                        style={{ height: window.innerHeight - 307, overflow: 'auto' }}
                    >
                        <InfiniteScroll
                            hasMore={hasMore}
                            initialLoad={false}
                            loadMore={this.loadMoreCatalogItems}
                            threshold={20}
                            useWindow={false}
                        >
                            <EditableTable
                                columns={columns}
                                data={data}
                                error={error}
                                loading={loading}
                                updateLoading={updateLoading}
                                onDelete={(id) => {
                                    return this.showDeleteConfirm(id);
                                }}
                                onSave={this.updateCategory}
                                pagination={false}
                                refreshData={() => {
                                    return this.getAndSetInitialData(true);
                                }}
                            />
                        </InfiniteScroll>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Edit;
