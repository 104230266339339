import styled from 'styled-components';
import { Steps, Typography } from 'antd';

const { Step } = Steps;

const Label = styled(Typography.Text)`
    display: block;
`;

const Description = styled(Label)``;

const Item = styled.div`
    margin-bottom: 14px;
`;

const Link = styled.a`
    color: #1890ff;

    &:hover,
    &:focus {
        color: #55a8f5;
    }
`;

const StepsStyled = styled(Steps)`
    .ant-steps-item-content {
        width: auto !important;
    }
`;

const StepStyled = styled(Step)``;

export { Item, Description, Label, Link, StepsStyled, StepStyled };
