import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthBox from '~/pages/Auth/AuthBox';
import AuthenticatedRoute from './AuthenticatedRoute';
import DashboardBox from '~/pages/Dashboard/Box';
import NotFound from '~/pages/NotFound';
import UnauthenticatedRoute from './UnauthenticatedRoute';

export default class Routes extends Component {
    render() {
        return (
            <Switch>
                <AuthenticatedRoute
                    path="/dashboard"
                    component={DashboardBox}
                    props={this.props.childProps}
                />
                <UnauthenticatedRoute
                    path="/auth"
                    component={AuthBox}
                    props={this.props.childProps}
                />
                <Route component={NotFound} />
            </Switch>
        );
    }
}
