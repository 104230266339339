import { del, get, post, put } from '~/services/api/rest/client';
import utils from '~/lib/utils';

/** categoryConnector API */
export const CategoryConnectorAPI = {
    fetchCategoryConnectors: (supplierId, filters = '') => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`/categories/connectors?supplierId=${supplierId}&${queryString}`);
    },
    fetchCategoryConnector: (categoryConnectorId, filters = '') => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`/categories/connectors/${categoryConnectorId}?${queryString}`);
    },
    createCategoryConnector: (data) => {
        return post('/categories/connectors', data);
    },
    deleteCategoryConnector: (categoryConnectorId) => {
        return del(`categories/connectors/${categoryConnectorId}`);
    },
    updateCategoryConnector: (categoryConnectorId, data) => {
        return put(`categories/connectors/${categoryConnectorId}`, data);
    },
};

/** category API */
export const CatalogAPI = {
    fetchCatalogs: (filters = '') => {
        return get(`/categories/catalogs${filters}`);
    },
    fetchCatalog: (catalogId, filters = '') => {
        return get(`/categories/catalogs/${catalogId}?${filters}`);
    },
    createCatalog: (data) => {
        return post('/categories/catalogs', data);
    },
    deleteCatalog: (catalogId) => {
        return del(`categories/catalogs/${catalogId}`);
    },
    updateCatalog: (catalogId, data) => {
        return put(`categories/catalogs/${catalogId}`, data);
    },
    fetchCatalogItems: (catalogId, filters = {}) => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`/categories/catalogs/${catalogId}/items?${queryString}`);
    },
    createCatalogItem: (catalogId, data) => {
        return post(`/categories/catalogs/${catalogId}/items`, data);
    },
    importCatalogItems: (catalogId, data) => {
        return post(`/categories/catalogs/${catalogId}/items/import`, data);
    },
    exportCatalogItems: (catalogId, filters = '') => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`/categories/catalogs/${catalogId}/items/export?${queryString}`);
    },
    updateCatalogItem: (catalogId, categoryId, data) => {
        return put(`/categories/catalogs/${catalogId}/items/${categoryId}`, data);
    },
    deleteCatalogItem: (catalogId, categoryId) => {
        return del(`/categories/catalogs/${catalogId}/items/${categoryId}`);
    },
    checkCatalogItem: (catalogId, categoryId) => {
        return get(`/categories/catalogs/${catalogId}/items/${categoryId}`);
    },
};
