import React from 'react';
import { Table } from 'antd';

import ErrorLoading from '~/components/ErrorLoading/ErrorData';
import Loading from '~/components/Loading';
import hasError from '~/components/HOC/HasError';

const AntTable = (props) => {
    const {
        columns, data, loading, onChange, pagination, rowSelection, rowClassName, onRow, locale,
    } = props;
    let defaultTableProps = {
        columns,
        dataSource: data,
        id: 'table-responsive',
        locale: locale || { emptyText: ' ' },
        onChange,
        pagination,
        rowClassName,
        onRow,
    };

    if (rowSelection) {
        defaultTableProps = Object.assign({}, defaultTableProps, { rowSelection });
    }

    return (
        <Loading loading={loading}>
            <Table { ...defaultTableProps } />
        </Loading>
    );
};

AntTable.defaultProps = {
    infiniteScroll: false,
};

export default hasError(ErrorLoading)(AntTable);
