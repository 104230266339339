import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Row, Col, Icon } from 'antd';
import config from '~/config';

/** Styles */
import {
    FacebookButton,
    GoogleButton,
} from './styles';

const FacebookSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="9px" height="20px">
        <image  x="0px" y="0px" width="9px" height="20px"  href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAUCAQAAAD10N/eAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfjDAULEhdZWMRKAAAAxElEQVQY04XOsUrDYBSG4edPkwhVBAcRXIrg4uji5CS4ujh7Dd6AuzdS8EK8BQcHBx0qUqpLiyHQ5jikqZQOfc/2LOdLYdnAjXN9lWhvEMP4jnlELGEnnmJVDi5dg7FXvZbO7GLm0VBqqQdmnk3IwFSDxgJy9ypXCuy5c2E/xa9GoUSoLeQpVlu7cu9qBw4ljZHaVJzGUTxEFRHjuI3jOMm9YaLB3MhnN6KUIVPS0dqLzEZbKEn/1JehVLRT4cOL0pcf+AOWVVG56BD4ZwAAAABJRU5ErkJggg==" />
    </svg>
);

const GoogleSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="21px" height="21px">
        <image  x="0px" y="0px" width="21px" height="21px"  href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAAB3RJTUUH4wwFCykU/ON6yAAAA/hJREFUOMuNlFuIlVUUx39rf/v7zmXOmWmcZpgSbznZMGJCQ9IQowklaFkPg3h5MgSJbg8RiG8h+RQVidSDRBRqRgk9qJUVlZUFEkhTWdLYOOZwatK5nDnf8XyXvXo4jg42puthw9rs/Wf9L3tLuLwHASLjqHkpgoCQQXQpSo+qrAS6gCKQAiURTuD0SxW+FmHYiSMmqt8FZAbQOxG2qfI4YPjf0t9F2OXEHYiJRqZA7TWnVgKvq9LJTZV0oLILdIGKPHcVNPKQIAW4X2E/0H5zgJdnhW+dyIcigiJk0yrWbPiV8ODCeerx2gyAqcBRRD5Q1XMoGYwsE9WNCh3AF4husi4pOU+xLmFn94tY0x6SZpItruZ3iy9cZgBwRoTnVeVwNq1F4hJQAU8OeS55w6lsNvBe1c+WJvwC7y9cz+G5G0hNhCQf2850PPtN+Pnslqi/GQLF+OmfGNaoM/3FaIJfZi1hNLgFzzkQMKQ4FQIXM1Scz8t3byefVDAKmCpWAl1t51ZaChsHNCzOk+p3bUic7FDf9DfHo/S3LWXbslcYybdjXVxXcRodT1Ma43EUAQwKWKfmASYNEjhpWDuId2v1t0sH5uzPV6ucmHcfL/TspOwVabn09yxFVl3HrMurGtCSRelAQCOD+I5sT+moP7tcCSRiYHIN50fn0J6UUWnsEHjzBhET0eC0BXL1HjQxiOhwML8MPjSeHccvgQYeKAbI3zhjmSYLRFO9QTGkzT/XWiRJYx2KC9ir8s3wumSK9rSt9JIFhoC7PBxiUo7Vbl+5ubbMr3iVqLW2iNkKiUSopBdAjlyRUevkFO4QzXZdMVCSihXV457nHgLHO5VOdoWL78kZHiy64EiQO0l5zqsEY6uRtPE06KPi8gCqEpPk/nJe3LjP1Bq6VGJQDyfRsOgn3HvB5I7tCTuze8NFOIS8xCfV6GpDXHJ2Eht20XB+O6m9QK31LZSUYGwteK2r/IkFB8X5hfqkljQYeFoGjxT8p8Le3QNx09YsKVbclFxfqeiToKeEWCVuA1LUH8F5ZZMbeaI3O7L+XZX4NiRBNMD55T+cP9jrffZwn7voMmfzJn7MosVpLswXlfWINonaCibMYKJZokG3YJ9xZvIlP+xqMUlr3S5RksKZHS4z/qks3rcJl6nh21pfNsy+7Yw2XCff/1D/KptBUFPFi9rIjK4jM7acuDixN8kNPitpOuq19S1BbYo17pRN/BEVXQFkZshOw5VMA6I+zo4RF74Hf+Ij4/JbkfFRkeq12dM9COsUjt8w5Cji8jUVdsf5n7Zghi8KFxEm/vPzAxwFflA1K0TcI0Av0AS4aWk/p6KHMHoIvB9xubiuTALAvzB50B/jIPacAAAAAElFTkSuQmCC" />
    </svg>
);

const FacebookIcon = props => <Icon component={FacebookSvg} {...props} />;
const GoogleIcon = props => <Icon component={GoogleSvg} {...props} />;

class FederatedButtons extends Component {
    constructor() {
        super();
        this.state = {
            facebook: false,
            google: false,
        };
        this.config = Auth.configure();
        this.googleAuth = this.googleAuth.bind(this);
        this.facebookAuth = this.facebookAuth.bind(this);
    }

    googleAuth() {
        const url_to_google = this.getUrl('Google');

        window.location.assign(url_to_google);
    }

    facebookAuth() {
        const url_to_facebook = this.getUrl('Facebook');

        window.location.assign(url_to_facebook);
    }

    getUrl(provider) {
        const {
            domain,
            redirectSignIn,
            responseType,
        } = this.config.oauth;
        const clientId = this.config.userPoolWebClientId;
        const url = `https://${domain}/oauth2/authorize?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${clientId}&identity_provider=${provider}`;

        return url;
    }

    render() {
        return (
            <Row>
                <Col span={this.props.col}>
                    <FacebookButton
                        block
                        onClick={this.facebookAuth}
                    >
                        <FacebookIcon/>
                        {this.props.textAdd} Facebook
                    </FacebookButton>
                </Col>
                <Col span={this.props.col}>
                    <GoogleButton
                        block
                        onClick={this.googleAuth}
                    >
                        <GoogleIcon/>
                        {this.props.textAdd} Google
                    </GoogleButton>
                </Col>
            </Row>
        );
    }
}

export default FederatedButtons;
