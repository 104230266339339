import {
    GET_CURRENT_USER,
    SET_CURRENT_USER,
    SET_TOKEN,
    SET_CREDITS,
    UNSET_CURRENT_USER,
    UPDATE_SUPPLIERS_SETTINGS,
} from '~/store/actions/user';

const initialState = {
    currentUser: {
        id: '',
        name: '',
        birthday: '',
        genre: '',
        typePerson: '',
        cpf: '',
        cnpj: '',
        email: '',
        credits: 0,
        address: {
            zipcode: '',
            number: '',
            zipCode: '',
            city: '',
            street: '',
            district: '',
            state: '',
        },
        mapping: {},
        mainPhone: '',
        additionalPhone: null,
        created: '',
        updated: '',
        suppliers: [],
    },
};

function currentUser(state = initialState, action) {
    switch (action.type) {
        case SET_TOKEN: {
            const { token } = action;
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    token,
                },
            };
        }
        case SET_CREDITS: {
            const { credits } = action;
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    credits,
                },
            };
        }
        case SET_CURRENT_USER: {
            const { user } = action;
            return {
                ...state,
                currentUser: user,
            };
        }
        case UNSET_CURRENT_USER: {
            window.localStorage.removeItem('accountId');
            return {
                ...state,
                currentUser: {},
            };
        }
        case GET_CURRENT_USER: {
            return state.currentUser;
        }
        case UPDATE_SUPPLIERS_SETTINGS: {
            const { settings } = action;

            const hasSupplierSettings = state.currentUser?.suppliers?.find((supplierSettings) => {
                return supplierSettings.supplierId === settings.supplierId;
            });

            if (hasSupplierSettings) {
                const suppliers = state.currentUser?.suppliers?.map((currentSettings) => {
                    if (currentSettings.supplierId === settings.supplierId) {
                        return settings;
                    }

                    return currentSettings;
                });

                return {
                    currentUser: {
                        ...state.currentUser,
                        suppliers,
                    },
                };
            }

            return {
                currentUser: {
                    ...state.currentUser,
                    suppliers: [...(state.currentUser.suppliers || []), settings],
                },
            };
        }
        default:
            return state;
    }
}

export default currentUser;
