import {
    FaBell,
    FaCalendarAlt,
    FaDolly,
    FaList,
    FaSyncAlt,
    FaTachometerAlt,
    FaTags,
    FaUsers,
    FaExchangeAlt,
    FaFolderOpen,
    FaFile,
} from 'react-icons/fa';
// import SupplierIcon from 'images/hotel-supplier.svg';
// import ClientList from '~/containers/Dashboard/Clients/List';
// import NewClient from '~/containers/Dashboard/Clients/New';
// import NewFilter from '~/containers/Dashboard/Suppliers/ProductList/New';
// import SupplierProduct from '~/containers/Dashboard/Suppliers/ProductList/New';
import AccountBox from '~/pages/Dashboard/Account/Box';
// import Billing from '~/containers/Dashboard/Account/Billing';
import CategoryPatterns from '~/pages/Dashboard/Category/Catalog/List';
import Changelog from '~/pages/Dashboard/Changelog';
import Checkout from '~/pages/Dashboard/Checkout/Checkout';
import ConnectorsList from '~/pages/Dashboard/Connectors/List';
import EditCategoryCatalog from '~/pages/Dashboard/Category/Catalog/Edit';
import EditCategoryConnector from '~/pages/Dashboard/Suppliers/CategoryConnector/Edit';
import EditConnector from '~/pages/Dashboard/Connectors/Edit';
import EditSchedule from '~/pages/Dashboard/Suppliers/Schedule/Edit';
import ListCategoryConnectors from '~/pages/Dashboard/Suppliers/CategoryConnector/List';
import NewConnector from '~/pages/Dashboard/Connectors/New';
import NewSchedule from '~/pages/Dashboard/Suppliers/Schedule/New';
import Painel from '~/pages/Dashboard/Painel';
import Products from '~/pages/Dashboard/Products/List';
import Profile from '~/pages/Dashboard/Account/Profile';
import Schedules from '~/pages/Dashboard/Schedules/List';
// import Settings from '~/containers/Dashboard/Account/Settings';
import ShowCategoryCatalog from '~/pages/Dashboard/Category/Catalog/Show';
import SupplierSchedules from '~/pages/Dashboard/Suppliers/Schedule/List';
import SupplierSettings from '~/pages/Dashboard/Suppliers/Settings';
import SuppliersList from '~/pages/Dashboard/Suppliers/List';
import TransactionList from '~/pages/Dashboard/Transactions/List';
import TransactionShow from '~/pages/Dashboard/Transactions/Show';
import ListUsers from '~/pages/Dashboard/Users/List';
import InventoryProducts from '~/pages/Dashboard/Inventory/Edit';
import InventoryList from '~/pages/Dashboard/Inventory/List';
import OrdersList from '~/pages/Dashboard/Orders/List';

const base = '/dashboard';
export const paths = {
    base,
    account: `${base}/account`,
    categories: `${base}/categorycatalogs`,
    changelog: `${base}/changelog`,
    clients: `${base}/clients`,
    connectors: `${base}/connectors`,
    products: `${base}/products`,
    schedules: `${base}/schedules`,
    suppliers: `${base}/suppliers`,
    transactions: `${base}/transactions`,
    users: `${base}/users`,
    inventories: `${base}/inventories`,
    orders: `${base}/orders`,
};

export const account = {
    action: 'read',
    component: AccountBox,
    hidden: true,
    path: paths.account,
    title: 'Minha Conta',
    type: 'MyAccount',
    children: [
        {
            path: '',
            title: 'Perfil',
            component: Profile,
        },
        {
            path: '/checkout',
            title: 'Créditos',
            component: Checkout,
        },
        // , {
        //     path: '/settings',
        //     title: 'Preferências',
        //     component: Settings,
        // }, {
        //     path: '/billing',
        //     title: 'Pagamentos',
        //     component: Billing,
        // }
    ],
};

const routes = [
    {
        action: 'read',
        component: Painel,
        hidden: true,
        icon: FaTachometerAlt,
        path: '/dashboard',
        title: 'Painel',
        type: 'Panel',
    },
    account,
    {
        action: 'read',
        component: SuppliersList,
        icon: FaDolly,
        path: paths.suppliers,
        title: 'Distribuidores',
        type: 'Supplier',
    },
    // {
    //     action: 'read',
    //     component: ClientList,
    //     icon: (
    //         <Icon
    //             type='user'
    //             style={{
    //                 fontSize: 16,
    //                 marginRight: 0
    //             }}
    //         />
    //     ),
    //     path: paths.clients,
    //     title: 'Clientes',
    //     type: 'Client',
    // },
    // {
    //     action: 'create',
    //     component: NewClient,
    //     hidden: true,
    //     path: `${paths.clients}/new`,
    //     type: 'Client',
    // },
    {
        action: 'read',
        component: ListUsers,
        icon: FaUsers,
        path: paths.users,
        title: 'Usuários',
        type: 'Users',
    },
    {
        action: 'read',
        component: Products,
        icon: FaList,
        path: paths.products,
        search: '?orderBy=createdAt&sort=desc',
        title: 'Produtos',
        type: 'Products',
    },
    {
        action: 'read',
        component: ConnectorsList,
        icon: FaExchangeAlt,
        path: paths.connectors,
        title: 'Conectores',
        type: 'Connector',
    },
    {
        action: 'create',
        component: NewConnector,
        hidden: true,
        path: `${paths.connectors}/new`,
        type: 'Connector',
    },
    {
        action: 'update',
        component: EditConnector,
        hidden: true,
        path: `${paths.connectors}/edit/:connectorId`,
        type: 'Connector',
    },
    {
        action: 'read',
        component: Schedules,
        icon: FaCalendarAlt,
        path: paths.schedules,
        title: 'Agendamentos',
        type: 'Schedule',
    },
    {
        action: 'read',
        component: CategoryPatterns,
        icon: FaTags,
        path: paths.categories,
        title: 'Categorias',
        type: 'Catalog',
    },
    {
        action: 'update',
        component: EditCategoryCatalog,
        hidden: true,
        path: `${paths.categories}/edit/:catalogId/categories`,
        type: 'Catalog',
    },
    {
        action: 'update',
        component: ShowCategoryCatalog,
        hidden: true,
        path: `${paths.categories}/show/:catalogId/categories`,
        type: 'Catalog',
    },
    {
        action: 'read',
        component: TransactionList,
        icon: FaSyncAlt,
        path: paths.transactions,
        title: 'Transações',
        type: 'Transaction',
    },
    {
        path: paths.changelog,
        title: 'Changelog',
        icon: FaBell,
        component: Changelog,
        hidden: true,
    },
    {
        action: 'read',
        component: ListCategoryConnectors,
        hidden: true,
        path: `${paths.suppliers}/:supplierId/categories`,
        type: 'CategoryConnector',
    },
    {
        action: 'update',
        component: EditCategoryConnector,
        hidden: true,
        path: `${paths.suppliers}/:supplierId/categories/edit/:connectorId/items`,
        type: 'CategoryConnector',
    },
    {
        action: 'read',
        component: SupplierSettings,
        hidden: true,
        path: `${paths.suppliers}/:supplierId/settings`,
        type: 'SupplierSetting',
    },
    {
        action: 'read',
        component: SupplierSchedules,
        hidden: true,
        path: `${paths.suppliers}/:supplierId/schedules`,
        type: 'SupplierSchedule',
    },
    {
        action: 'new',
        component: NewSchedule,
        hidden: true,
        path: `${paths.suppliers}/:supplierId/schedules/new`,
        type: 'SupplierSchedule',
    },
    {
        action: 'edit',
        component: EditSchedule,
        hidden: true,
        path: `${paths.suppliers}/:supplierId/schedules/edit/:scheduleId`,
        type: 'SupplierSchedule',
    },
    {
        action: 'read',
        component: TransactionShow,
        hidden: true,
        path: `${paths.transactions}/show/:transactionId`,
        type: 'Transaction',
    },
    // {
    //     action: 'read',
    //     component: Checkout,
    //     hidden: true,
    //     path: '/dashboard/checkout',
    //     type: 'Checkout',
    // },
    {
        action: 'read',
        component: InventoryList,
        icon: FaFolderOpen,
        title: 'Inventários',
        path: paths.inventories,
        type: 'Inventory',
    },
    {
        action: 'read',
        component: InventoryProducts,
        hidden: true,
        path: `${paths.inventories}/:inventoryId/products`,
        type: 'Inventory',
    },
    {
        action: 'read',
        component: OrdersList,
        icon: FaFile,
        path: paths.orders,
        title: 'Pedidos',
        type: 'Order',
    },
];

export default routes;
