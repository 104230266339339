import styled from 'styled-components';
import { Card } from 'antd';

export default styled(Card)`
    border-radius: 10px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    &:hover {
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
    }
    .ant-card-cover {
        height: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 25px;
        
        >img {
             width: auto;
             max-height: 42px;
             max-width: 100%;
        }
    }
`;
