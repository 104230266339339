import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import 'babel-polyfill';

import Routes from '~/components/Routes';

import ability, { defineRulesFor } from '~/ability';
import user from '~/lib/user';
import { fetchCurrentUser, unsetCurrentUser } from '~/store/actions/user';
import { setUserToken } from '~/store/actions/auth';

const mapStateToProps = (state) => {
    const { userToken, isLoadingUserToken } = state.auth;
    return { userToken, isLoadingUserToken };
};

class AppBox extends Component {
    componentDidMount() {
        this.checkUser();
    }

    checkUser = async () => {
        try {
            const { jwtToken: userToken } = await user.token();
            this.updateRulesFor(userToken);
            this.props.dispatch(fetchCurrentUser());

            this.props.dispatch(setUserToken(userToken, false));
        } catch (e) {
            // console.log(e);
            this.props.dispatch(setUserToken(null, false));
        }
    };

    updateRulesFor = (userToken) => {
        const authenticatedUser = user.get(userToken);
        const { rules } = defineRulesFor(authenticatedUser);
        ability.update(rules);
    };

    updateUserToken = (userToken) => {
        this.props.dispatch(setUserToken(userToken, false));
    };

    handleLogout = () => {
        const { dispatch } = this.props;

        Auth.signOut();
        dispatch(unsetCurrentUser());
        dispatch(setUserToken(null, false));
    };

    render() {
        const { userToken, isLoadingUserToken } = this.props;
        const childProps = {
            userToken: userToken,
            updateUserToken: this.updateUserToken,
            handleLogout: this.handleLogout,
        };

        return (
            !isLoadingUserToken && (
                <div className="wrapper">
                    <Routes childProps={childProps} />
                </div>
            )
        );
    }
}

export default withRouter(connect(mapStateToProps)(AppBox));
