import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Breadcrumb, Row, Col, List, Icon } from 'antd';
import { TitlePage } from '~/components/Header/TitlePage';
import { paths } from '~/routes';

const Container = styled.div`
    position: relative;
    background-color: black;
    &:hover .container-image { opacity: 0.3; }
    &:hover .container-middle { opacity: 1; }
`;

const Image = styled.img`
    opacity: 1;
    display: flex;
    max-width: 200px;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
`;

const Middle = styled.div`
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
`;

class Painel extends Component {
    componentDidMount() {
        document.title = 'Grid2B | Painel';
    }

    renderListItem(item) {

        const extra = (
            <a
                href={`https://youtu.be/${item.videoId}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Container>
                    <Image
                        className="container-image"
                        alt="logo"
                        src={`https://img.youtube.com/vi/${item.videoId}/mqdefault.jpg`}
                    />
                    <Middle className="container-middle">
                        <Icon
                            style={{ fontSize: '50px', color: 'white' }}
                            type="play-circle"
                        />
                    </Middle>
                </Container>
            </a>
        );

        return (
            <List.Item
                key={item.title}
                style={{ flexDirection: 'row-reverse' }}
                extra={extra}
            >
                <List.Item.Meta
                    style={{ marginLeft: 10 }}
                    title={
                        <a
                            href={`https://youtu.be/${item.videoId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {item.title}
                        </a>
                    }
                    description={item.description}
                />
            </List.Item>
        );
    };

    render() {
        const data = [
            {
                title: 'Entenda o Grid2B em menos de 5 minutos', 
                description: 'Saiba o que o Grid2B oferece para seus negócios em um vídeo rápido com menos de 5 minutos.', 
                videoId: 'EU4bRXNyZTM',
            },
            {
                title: 'Visão geral do painel Grid2B', 
                description: 'Dropshipping, Cross Docking e Venda Direta dos principais fornecedores do Brasil. Nesse vídeo mostramos uma visão geral sobre a plataforma Grid2B.', 
                videoId: 'mi5VccXSqPo',
            },
            {
                title: 'Criar conta, ativar distribuidor e exportar produtos',
                description: 'Jogo rápido, mostrando o processo mais simples desde a crição da conta no Grid2B até a ativação do distribuidor e primeira exportação de produtos.',
                videoId: 'N-lnbcw-sRM',
            },
            {
                title: 'Integrando com o Bling',
                description: 'Gerando sua API Key no Bling, criando o conector e agendamento para integração dos produtos.',
                videoId: '6dtX42GA1p0',
            },
            {
                title: 'Diferenças entre Cross Docking, Dropshipping e Venda Direta',
                description: 'Grid2B esclarecendo as vantagens e diferenças entre as modalidades de Cross Docking, Dropshipping e Venda Direta.',
                videoId: 'miUgMya5aG0',
            },
        ];

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Início
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage
                        title="Vídeos"
                        subtitle="Fique por dentro dos recursos e novidades da plataforma."
                    />
                    <Row>
                        <Col span={12}>
                            <List
                                itemLayout="horizontal"
                                size="large"
                                dataSource={data}
                                renderItem={this.renderListItem}
                            />
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}

export default Painel;
