import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default class AuthenticatedRoute extends React.Component {
    render() {
        const { component: Component, props: cProps, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={(props) => {
                    return cProps.userToken !== null ? (
                        <Component {...props} {...cProps} />
                    ) : (
                        <Redirect to={'/auth/login'} />
                    );
                }}
            />
        );
    }
}
