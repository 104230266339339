import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';

export const Title = styled(Typography.Text)`
    color: #1890ff !important;
    margin-bottom: 14px;
    font-weight: 600;
    display: block;
`;

export const TextGroup = styled.div`
    margin-bottom: 14px;
`;

export const Tag = styled.span`
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 5px;
    border-radius: 5px;
    font-size: 9pt;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    display: inline-block;
`;

export const Text = styled(Typography.Text)`
    display: flex;
`;

export const LinkStyled = styled(Link)`
    color: #1890ff;
`;
