import React, { Component } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { Tabs, Breadcrumb } from 'antd';

import { account } from '~/routes';

const TabPane = Tabs.TabPane;

class AccountBox extends Component {
    render() {
        const { match } = this.props;

        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/dashboard">Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Minha conta</Breadcrumb.Item>
                </Breadcrumb>
                <div className="card-container">
                    <Switch>
                        <Tabs type="card" activeKey={this.props.location.pathname}>
                            {account.children.map((route) => {
                                return (
                                    <TabPane
                                        key={`${match.url}${route.path}`}
                                        tab={
                                            <Link
                                                to={`${match.url}${route.path}`}
                                                style={{ display: 'block', padding: '0 16px' }}
                                            >
                                                {route.title}
                                            </Link>
                                        }
                                    >
                                        <Route
                                            exact
                                            path={`${match.path}${route.path}`}
                                            component={route.component}
                                        />
                                    </TabPane>
                                );
                            })}
                        </Tabs>
                    </Switch>
                </div>
            </div>
        );
    }
}

export default AccountBox;
