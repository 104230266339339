// @flow

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, Row, Col, Alert } from 'antd';

/** Interfaces */
import { SupplierInterface, UserInterface } from '~/lib/interfaces';

/** Components */
import Loading from '~/components/Loading';
import { TitlePage } from '~/components/Header/TitlePage';
import Card from '~/components/Card';

/** List.actions */
import { getAndSetInitialData, getSupplierActions } from './List.actions';

import config from '~/config';

type Props = {
    currentUser: UserInterface,
    match: {
        url: string,
    },
};

type State = {
    error: boolean,
    hasMore: boolean,
    loading: boolean,
    suppliers: SupplierInterface[],
};

class SupplierList extends Component<Props, State> {
    state = {
        error: false,
        hasMore: true,
        loading: true,
        suppliers: [],
    };

    getAndSetInitialData = getAndSetInitialData.bind(this);
    getSupplierActions = getSupplierActions.bind(this);

    componentDidMount() {
        document.title = 'Grid2B | Distribuidores';
        this.getAndSetInitialData();
    }

    render() {
        const { loading, suppliers: currentSuppliers } = this.state;

        const incomingSuppliers = [
            // {
            //     name: 'All Nations',
            //     logoUrl: 'suppliers/allnations_logo.png',
            //     commingSoon: true,
            // },
        ];

        const suppliers = currentSuppliers.concat(
            currentSuppliers.length ? incomingSuppliers : [],
        );

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/dashboard">Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Distribuidores</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage
                        title="Distribuidores"
                        subtitle="Distribuidores disponíveis na plataforma."
                        unffixed
                    />
                    <Loading loading={loading}>
                        <Row gutter={20} type="flex">
                            {!loading && (
                                suppliers.map((supplier, index) => {
                                    return (
                                        <Col key={`supplier-${index}`} xs={24} sm={12} xl={8} xxl={6} style={{ 'display': 'flex', flexDirection: 'column' }}>
                                            <Card
                                                style={{ marginBottom: 20, height: '100%' }}
                                                bodyStyle={{ borderTop: '1px solid #F5F5F5' }}
                                                cover={
                                                    <img src={`${config.app.cdn}/dashboard/${supplier.logoUrl}`} alt={supplier.name}/>
                                                }
                                            >
                                                {supplier.commingSoon && (
                                                    <Alert
                                                        message="Disponível em breve!"
                                                        style={{ marginBottom: 20, fontSize: 12, padding: '8px 8px' }}
                                                        showIcon={false}
                                                        type="info"
                                                    />
                                                )}
                                                {this.getSupplierActions(supplier)}
                                            </Card>
                                        </Col>
                                    );
                                })
                            )}
                        </Row>
                    </Loading>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ user }) => {
    const { currentUser } = user;

    return { currentUser };
};

export default connect(mapStateToProps)(SupplierList);
