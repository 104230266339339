export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';

export function addPaymentMethod({
    birthdayDate,
    cardHolderName,
    cardNumber,
    document,
    expiryMonth,
    expiryYear,
    phone,
    securityCode,
    cardToken,
}) {

    if (!cardHolderName) {
        return { payload: undefined, type: ADD_PAYMENT_METHOD };
    }

    const payload = {
        birthdayDate,
        cardHolderName,
        cardExpiry: `${expiryMonth}/${expiryYear}`,
        cardNumber: cardNumber.replace(/\D/g, ''),
        document: document.replace(/\D/g, ''),
        expiryMonth: expiryMonth,
        expiryYear: expiryYear,
        areaCode: phone.replace(/\D/g, '').substring(0, 2),
        phone: phone.replace(/\D/g, '').substring(2),
        completePhone: phone,
        securityCode: securityCode.replace(/\D/g, ''),
        cardToken,
    };

    return {
        payload,
        type: ADD_PAYMENT_METHOD,
    };
}

export function getPaymentMethods(paymentMethods) {
    return {
        paymentMethods,
        type: GET_PAYMENT_METHODS,
    };
}
