import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import {
    transformColumns,
    transformData,
    getAndSetInitialData,
    loadMoreUsers,
    handleUserSort,
} from './List.actions';

import DynamicFilter from '~/components/Filters/DynamicFilter';
import InfiniteScroll from '~/components/InfiniteScroller';
import Table from '~/components/Table/AntTable';
import { TitlePage } from '~/components/Header/TitlePage';
import HeaderInfo from '~/components/Header/HeaderInfo';

class List extends Component {
    state = {
        error: false,
        loading: true,
        fetchUsersFromUserpool: {
            hasMore: false,
            pagination: {},
            result: [],
        },
    };

    getAndSetInitialData = getAndSetInitialData.bind(this);
    transformColumns = transformColumns.bind(this);
    transformData = transformData.bind(this);
    loadMoreUsers = loadMoreUsers.bind(this);
    handleUserSort = handleUserSort.bind(this);

    componentDidMount() {
        document.title = 'Grid2B | Usuários';
        this.getAndSetInitialData();
    }

    render() {
        const { error, loading } = this.state;
        const { hasMore, total } = this.state.fetchUsersFromUserpool;
        const columns = this.transformColumns();
        const data = this.transformData();

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/dashboard">Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Usuários</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage title="Usuários" subtitle="Confira a lista de usuários." />
                    <div className="content-filters">
                        <DynamicFilter
                            onSubmit={() => {
                                return this.getAndSetInitialData(true);
                            }}
                            filters={[
                                ...columns,
                                {
                                    title: 'Habilitado',
                                    key: 'enabled',
                                    type: 'select',
                                    options: [
                                        { name: 'Sim', value: true },
                                        { name: 'Não', value: false },
                                    ],
                                },
                            ]}
                        />
                        <HeaderInfo
                            showing={data.length}
                            total={total}
                        />
                    </div>
                    <div
                        className="infinite-container"
                        style={{ height: window.innerHeight - 307, overflow: 'auto' }}
                    >
                        <InfiniteScroll
                            hasMore={hasMore}
                            initialLoad={false}
                            loadMore={this.loadMoreUsers}
                            threshold={20}
                            useWindow={false}
                        >
                            <Table
                                columns={columns}
                                data={data}
                                error={error}
                                onChange={this.handleUserSort}
                                loading={loading}
                                pagination={false}
                                refreshData={() => {
                                    return this.getAndSetInitialData(true);
                                }}
                            />
                        </InfiniteScroll>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default List;
