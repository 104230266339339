import { UserAPI } from '~/lib/api';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_CREDITS = 'SET_CREDITS';
export const UNSET_CURRENT_USER = 'UNSET_CURRENT_USER';
export const UPDATE_SUPPLIERS_SETTINGS = 'UPDATE_SUPPLIERS_SETTINGS';

async function setAccountId(email) {
    try {
        const users = await UserAPI.fetchUsersFromUserpool({ email });
        if (!users.result || !users.result.length) {
            return;
        }

        const user = users.result[0].attributes.find((attr) => {
            return attr.name === 'custom:user_id';
        });

        if (user && user.value) {
            window.localStorage.setItem('accountId', user.value);
        }

    } catch (error) {
        console.log(error);
    }
}

export function setCurrentUser(user) {
    return {
        type: SET_CURRENT_USER,
        user,
    };
}

export function setToken(token) {
    return {
        type: SET_TOKEN,
        token,
    };
}

export function setCredits(credits) {
    return {
        type: SET_CREDITS,
        credits,
    };
}

export function updateSuppliersSettings(settings) {
    return {
        type: 'UPDATE_SUPPLIERS_SETTINGS',
        settings,
    };
}

export function unsetCurrentUser() {
    return {
        type: UNSET_CURRENT_USER,
    };
}

export function fetchCurrentUser(data = {}) {
    return async (dispatch) => {
        try {
            if (data.accountEmail) {
                await setAccountId(data.accountEmail);
            }

            const { result } = await UserAPI.fetchUser();
            dispatch(setCurrentUser(result));
        } catch (error) {
            console.log(error);
        }
    };
}
