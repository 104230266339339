import React, { Fragment, useState, useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import Loading from '~/components/Loading';
import { CreditBuy, Export } from './components';
import { TitlePage } from '~/components/Header/TitlePage';

import { TransactionAPI, EventAPI } from '~/lib/api';
import { paths } from '~/routes';

export default function Show(props) {
    const [transaction, setTransaction] = useState({});
    const [state, setState] = useState({
        error: false,
        loading: true,
    });
    const [transactionId, setTransactionId] = useState(
        props.match.params.transactionId
    );

    async function getAndSetInitialData(refresh = false) {
        try {
            if (refresh) {
                setState({
                    error: false,
                    loading: true,
                });
            }

            const transaction = await TransactionAPI.fetchTransaction(transactionId);

            setTransaction(transaction.result);
            setState({ ...state, loading: false });
        } catch (error) {
            console.log('ERROR getAndSetInitialData: ', error);

            setState({
                error: true,
                loading: false,
            });
        }
    }

    useEffect(() => {
        document.title = 'Grid2B | Detalhes da Transação';
    }, []);

    useEffect(() => {
        getAndSetInitialData(true);
        const eventId = EventAPI.bind('export-finished', (data) => {
            if (props?.match?.params?.transactionId === data.transactionId) {
                getAndSetInitialData(true);
            }
        });

        return () => { EventAPI.unbind(eventId); };
    }, [transactionId]);

    useEffect(() => {
        if (props?.match?.params?.transactionId !== transactionId) {
            setTransactionId(props.match.params.transactionId);
        }
    });

    return (
        <Fragment>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>
                    <Link to="/dashboard">Painel</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={paths.transactions}>Transações</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Detalhes da Transação</Breadcrumb.Item>
            </Breadcrumb>
            <div className="inside-container">
                <TitlePage
                    title="Acompanhe o status da sua transação"
                    subtitle="Sempre que houver alteração de status ficará aqui registrado e você também será notificado."
                    unffixed
                />
                <Loading loading={state.loading}>
                    {transaction.type === 'export' && (
                        <Export
                            status={transaction.status}
                            details={transaction.details}
                            events={transaction.events}
                            transactionId={transaction.transactionId}
                        />
                    )}
                    {transaction.type === 'credits-buy' && (
                        <CreditBuy
                            status={transaction.status}
                            details={transaction.details}
                            events={transaction.events}
                            transactionId={transaction.transactionId}
                        />
                    )}
                </Loading>
            </div>
        </Fragment>
    );
}
