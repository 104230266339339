import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Cascader, Col, Form, Input, Radio, Row, Select, InputNumber } from 'antd';

import { create, getAndSetInitialData, getSupplierConfig, transformPayload } from './New.actions';

import Loading from '~/components/Loading';
import Notification from '~/components/Notification/AntNotification';
import ScheduleTable from '~/components/ScheduleTable';
import { TitlePage } from '~/components/Header/TitlePage';

import ability from '~/ability';
import utils from '~/lib/utils';
import { paths } from '~/routes';

const { Group: RadioGroup } = Radio;
const { Item: FormItem } = Form;
const { Option } = Select;

class New extends Component {
    state = {
        availableSchedules: [],
        categoryConnectors: [],
        connectors: [],
        createLoading: false,
        distributionCenters: [],
        error: false,
        inventories: [],
        loading: true,
        selectedRowKeys: [],
        supplier: {},
        disableAllCentersOption: true,
    };

    componentDidMount() {
        document.title = 'Grid2B | Novo agendamento';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async (refresh = false) => {
        try {
            if (refresh) {
                this.setState({
                    error: false,
                    loading: true,
                });
            }

            const { supplierId } = this.props.match.params;
            const data = await getAndSetInitialData(supplierId);

            const { setFieldsValue } = this.props.form;
            if (data.distributionCenters.length === 0) {
                setFieldsValue({ priceFilter: ['lowerPrice'] });
            } else if (data.distributionCenters.length === 1) {
                const priceFilter = [
                    'distributionCenter',
                    data.distributionCenters[0].distributionCenter,
                ];
                setFieldsValue({ priceFilter });
            }

            this.setState({ ...data, loading: false });
        } catch (error) {
            console.log('ERROR getAndSetInitialData: ', error);

            this.setState({
                error: true,
                loading: false,
            });
        }
    };

    create = (event) => {
        event.preventDefault();
        const { currentUser } = this.props;
        const { supplierId } = this.props.match.params;
        const supplierConfig = getSupplierConfig(currentUser, supplierId);

        if (
            ability.can('create', {
                status: supplierConfig?.status || false,
                __type: 'SupplierSchedule',
            })
        ) {
            this.props.form.validateFields(async (error, values) => {
                if (!error) {
                    try {
                        this.setState({ createLoading: true });

                        const { supplier } = this.state;
                        const payload = transformPayload(supplier, values);

                        if (!payload.schedules.length) {
                            return Notification('error', 'Selecione pelo menos 1 horário!');
                        }

                        await create(supplier, payload);

                        this.props.form.resetFields();

                        Notification('success', 'Agendamento criado!');
                    } catch (error) {
                        console.log('ERROR create schedule: ', error);
                        Notification(
                            'error',
                            'Ocorreu um erro ao salvar o agendamento, tente novamente!'
                        );
                    } finally {
                        this.setState({ createLoading: false });
                    }
                }
            });

            return;
        }

        Notification('error', 'Ative o distribuidor para criar um agendamento!');
    };

    getPriceFilterOptions = () => {
        const { distributionCenters, disableAllCentersOption } = this.state;
        const defaultRootOption = {
            value: 'lowerPrice',
            label: 'Menor preço',
            disabled: this.props.form.getFieldValue('inventory'),
        };
        const options = [
            {
                value: 'distributionCenter',
                label: 'Centro de distribuição',
                children: [
                    {
                        value: false,
                        label: 'Todos',
                        disabled: disableAllCentersOption,
                    },
                ].concat(
                    distributionCenters.map((stock) => {
                        return {
                            value: stock.distributionCenter,
                            label: stock.distributionCenter,
                        };
                    })
                ),
            },
        ];

        return [defaultRootOption].concat(options);
    };

    handleInventory = (inventorySelected) => {
        const priceFilter = this.props.form.getFieldValue('priceFilter');
        if (inventorySelected && Array.isArray(priceFilter) && priceFilter[0] === 'lowerPrice') {
            this.props.form.setFieldsValue({ priceFilter: [] });
        }
    }

    handleConnector = (connectorSelected) => {
        const { connectors } = this.state;
        const connector = connectors.find((connector) => {
            return connector.connectorId === connectorSelected;
        });

        const wdnaTemplate = connector.template === 'wdna';

        this.setState({ disableAllCentersOption: !wdnaTemplate });

        if (this.props.form.getFieldValue('inventory')) {
            return;
        }

        const [option, center] = this.props.form.getFieldValue('priceFilter');
        if (!wdnaTemplate && option === 'distributionCenter' && !center) {
            this.props.form.resetFields(['priceFilter']);
        }
    };

    handleCheckbox = (scheduleId, checked, index) => {
        const days = this.props.form.getFieldValue(`schedules[${scheduleId}]`);

        Object.keys(days).forEach((day) => {
            const fieldName = `schedules[${scheduleId}][${day}]`;
            this.props.form.setFieldsValue({ [fieldName]: index === 0 ? true : checked });
        });
    };

    render() {
        const {
            categoryConnectors,
            connectors,
            createLoading,
            inventories,
            loading,
            supplier,
            distributionCenters,
        } = this.state;
        const { getFieldDecorator } = this.props.form;

        const { currentUser } = this.props;
        const { supplierId } = this.props.match.params;
        const supplierConfig = getSupplierConfig(currentUser, supplierId);

        const priceFilterProps = distributionCenters.length > 1
            ? { xs: 24, sm: 12, md: 8, xl: 6 } : { span: 0 };

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={paths.schedules}>Agendamentos</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Novo</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <Form onSubmit={this.create}>
                        <TitlePage
                            title="Agendamento"
                            subtitle="Crie e personalize agendamentos para exportação de produtos."
                            actions={
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={createLoading}
                                    icon="save"
                                >
                                    Salvar
                                </Button>
                            }
                        />
                        <Loading loading={loading}>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={8} xl={6}>
                                    <FormItem label="Nome">
                                        {getFieldDecorator('name', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Insira um nome para o agendamento.',
                                                },
                                            ],
                                        })(<Input />)}
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    {utils.renderFields(
                                        supplier.paramsPayload?.schedule,
                                        this.props.form
                                    )}
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={8} xl={6}>
                                    <FormItem label="Mapeamento de categorias">
                                        {getFieldDecorator('categoryConnector', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:
                                                        'Selecione um mapeamento de categorias',
                                                },
                                            ],
                                            initialValue: categoryConnectors[0]?.connectorId,
                                        })(
                                            <Select
                                                filterOption={(input, option) => {
                                                    return utils.filterOptions(input, option);
                                                }}
                                                optionFilterProp="children"
                                                showSearch
                                                style={{ width: '100%' }}
                                            >
                                                {categoryConnectors.map((categoryConnector) => {
                                                    return (
                                                        <Option
                                                            key={categoryConnector.connectorId}
                                                            value={categoryConnector.connectorId}
                                                        >
                                                            {categoryConnector.name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                                {supplierConfig?.inventory && (
                                    <Col xs={24} sm={12} md={8} xl={6}>
                                        <FormItem label="Inventário">
                                            {getFieldDecorator('inventory', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Selecione um inventário.',
                                                    },
                                                ],
                                                initialValue: inventories[0]?.inventoryId,
                                            })(
                                                <Select
                                                    filterOption={(input, option) => {
                                                        return utils.filterOptions(input, option);
                                                    }}
                                                    onChange={this.handleInventory}
                                                    optionFilterProp="children"
                                                    showSearch
                                                    style={{ width: '100%' }}
                                                >
                                                    {inventories.map((inventory) => {
                                                        return (
                                                            <Option value={inventory.inventoryId}>
                                                                {inventory.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                )}
                                <Col xs={24} sm={12} md={8} xl={6}>
                                    <FormItem label="Conector">
                                        {getFieldDecorator('connector', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Selecione um conector.',
                                                },
                                            ],
                                            initialValue: connectors.find((connector) => {
                                                return connector.connectorName === 'Grid2B';
                                            })?.connectorId,
                                        })(
                                            <Select
                                                filterOption={(input, option) => {
                                                    return utils.filterOptions(input, option);
                                                }}
                                                onChange={this.handleConnector}
                                                optionFilterProp="children"
                                                showSearch
                                                style={{ width: '100%' }}
                                            >
                                                {connectors.map((connector) => {
                                                    return (
                                                        <Option value={connector.connectorId}>
                                                            {connector.connectorName}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                                {!this.props.form.getFieldValue('inventory') && (
                                    <Col xs={24} sm={12} md={8} xl={6}>
                                        <FormItem label="Margem de preço padrão">
                                            {getFieldDecorator('defaultMargin', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Insira uma margem padrão para os produtos',
                                                    },
                                                ],
                                                initialValue: 100,
                                            })(
                                                <InputNumber
                                                    min={0}
                                                    style={{ width: '100%' }}
                                                    parser={(value) => {
                                                        return value.replace('%', '');
                                                    }}
                                                    formatter={(value) => {
                                                        return `${value}%`;
                                                    }}
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                )}
                                <Col {...priceFilterProps}>
                                    <FormItem label="Filtro de preço">
                                        {getFieldDecorator('priceFilter', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Selecione um filtro de preço',
                                                },
                                            ],
                                            initialValue: ['lowerPrice'],
                                        })(
                                            <Cascader
                                                expandTrigger="hover"
                                                placeholder="Filtro de preço"
                                                options={this.getPriceFilterOptions()}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="Tipo de importação">
                                        {getFieldDecorator('delta', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Selecione um tipo de importação.',
                                                },
                                            ],
                                            initialValue: true,
                                        })(
                                            <RadioGroup
                                                name="importType"
                                                onChange={this.changeImportType}
                                                // value={supplierConfig.filterDelta}
                                                style={{ width: '100%' }}
                                            >
                                                <Col xs={24} md={12}>
                                                    <Radio value={true}>
                                                        <b>Delta</b>
                                                        <br />
                                                        <span style={{ whiteSpace: 'normal' }}>
                                                            Realiza a importação apenas dos produtos que
                                                            foram atualizados desde a última importação.
                                                        </span>
                                                    </Radio>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Radio value={false}>
                                                        <b>Full</b>
                                                        <br />
                                                        <span style={{ whiteSpace: 'normal' }}>
                                                            Realiza a importação de todos os produtos
                                                            deste distribuidor.
                                                        </span>
                                                    </Radio>
                                                </Col>
                                            </RadioGroup>
                                        )}
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <ScheduleTable
                                        rowSelection={{
                                            columnTitle: ' ',
                                            selectedRowKeys: this.state.selectedRowKeys,
                                            onSelect: (row, checked, changeRows) => {
                                                const maxTimes = this.state.supplier?.infos?.updates?.times_per_day || 1;

                                                if (checked && this.state.selectedRowKeys.length >= maxTimes) {
                                                    Notification(
                                                        'warn',
                                                        `Não selecione mais do que ${maxTimes} horário(s)!`,
                                                        `A frequência de atualização é de ${maxTimes}x ao dia. Para mais informações acesse a página de configuração do distribuidor`,
                                                        15
                                                    );
                                                    return;
                                                }

                                                this.setState({
                                                    selectedRowKeys: changeRows.map((row) => {
                                                        return row.key;
                                                    }),
                                                });

                                                this.handleCheckbox(row.key, checked);
                                            },
                                        }}
                                        multiple
                                        form={this.props.form}
                                        supplierSchedule={supplier.availableSchedules || []}
                                    />
                                </Col>
                            </Row>
                        </Loading>
                    </Form>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentUser } = state.user;

    return { currentUser };
};

export default connect(mapStateToProps)(Form.create()(New));
