import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Col, Form, Icon, Input, Modal, Row, Select, Table, Tooltip } from 'antd';
import Switch from 'react-bootstrap-switch';
import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { FaSort } from 'react-icons/fa';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/github';

import Can from '~/components/Can';
import Loading from '~/components/Loading';
import Notification from '~/components/Notification/AntNotification';
import { TitlePage } from '~/components/Header/TitlePage';

import { ConnectorAPI } from '~/lib/api/connector';
import {
    connectorScopes,
    connectorFormats,
    connectorTypes,
    connectorTemplates,
} from '~/config/types';
import { paths } from '~/routes';
import fieldsDescriptions from './fieldsDescriptions';

const InputGroup = Input.Group;
const FormItem = Form.Item;
const Option = Select.Option;

function dragDirection({
    dragIndex,
    hoverIndex,
    initialClientOffset,
    clientOffset,
    sourceClientOffset,
}) {
    const hoverMiddleY = (initialClientOffset.y - sourceClientOffset.y) / 2;
    const hoverClientY = clientOffset.y - sourceClientOffset.y;
    if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
        return 'downward';
    }
    if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
        return 'upward';
    }
}

class BodyRow extends PureComponent {
    render() {
        const {
            isOver,
            connectDragSource,
            connectDropTarget,
            moveRow,
            dragRow,
            clientOffset,
            sourceClientOffset,
            initialClientOffset,
            ...restProps
        } = this.props;
        const style = { ...restProps.style, cursor: 'move' };

        let className = restProps.className;
        if (isOver && initialClientOffset) {
            const direction = dragDirection({
                dragIndex: dragRow.index,
                hoverIndex: restProps.index,
                initialClientOffset: initialClientOffset,
                clientOffset: clientOffset,
                sourceClientOffset: sourceClientOffset,
            });
            if (direction === 'downward') {
                className += ' drop-over-downward';
            }
            if (direction === 'upward') {
                className += ' drop-over-upward';
            }
        }

        return connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style} />)
        );
    }
}

const rowSource = {
    beginDrag(props) {
        return {
            index: props.index,
        };
    },
};

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        sourceClientOffset: monitor.getSourceClientOffset(),
    };
})(
    DragSource('row', rowSource, (connect, monitor) => {
        return {
            connectDragSource: connect.dragSource(),
            dragRow: monitor.getItem(),
            clientOffset: monitor.getClientOffset(),
            initialClientOffset: monitor.getInitialClientOffset(),
        };
    })(BodyRow)
);

const tempKeys = [];

const generateTempKey = () => {
    const tempKey = `${Math.random()}`;
    tempKeys.push(tempKey);
    return tempKey;
};

const isTempKey = (key) => {
    return tempKeys.includes(key);
};

class Connector extends PureComponent {
    state = {
        callbackModal: false,
        callbackOptions: `{
    "headers": {
        "Content-Type": "application/json"
    },
    "body": {
        "file": "{{fileUrl}}"
    }
}`,
        emailCheck: null,
        fields: [],
        loading: true,
        mappingSourceFields: [],
        type: 'custom',
        template: 'custom',
        urlCallbackCheck: null,
    };

    components = {
        body: {
            row: DragableBodyRow,
        },
    };

    async componentDidMount() {
        document.title = 'Grid2B | Novo conector';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async () => {
        try {
            const { result: mappingSourceFields } = await ConnectorAPI.fetchMappingFields();

            this.setState({
                loading: false,
                mappingSourceFields,
            });
        } catch (error) {
            this.setState({
                error: true,
                loading: false,
            });
        }
    };

    save = (event) => {
        event.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ submitLoading: true });
                const payload = this.makePayload(values);

                try {
                    const { result: connector } = await ConnectorAPI.createConnector(payload);
                    this.props.history.push(`/dashboard/connectors/edit/${connector.connectorId}`);

                    Notification('success', 'Conector criado!');
                } catch (error) {
                    console.log('ERROR save: ', error);

                    Notification('error', 'Ocorreu um erro ao criar o conector, tente novamente!');
                } finally {
                    this.setState({ submitLoading: false });
                }
            }
        });
    };

    makePayload = (values) => {
        const { format, name: connectorName, scope, type, template } = values;
        const payloadBase = {
            connectorName,
            type: type || this.state.type,
            template: template || this.state.template,
            scope,
            format,
        };

        switch (template) {
            case 'bling':
            case 'wdna': {
                // const { appId, token } = values;
                // const params = {
                //     appId,
                //     token,
                // };

                delete payloadBase.format;

                return Object.assign(payloadBase);
            }
            case 'custom': {
                const { callbackOptions, mappingSourceFields } = this.state;
                const { email, urlCallback } = values;
                const fields = mappingSourceFields
                    .filter((field) => {
                        return Boolean(field.status);
                    })
                    .map((field, index) => {
                        return {
                            order: index + 1,
                            source: isTempKey(field.key) ? null : field.key,
                            target: field.target || null,
                            separator: field.separator || null,
                            value: field.value || null,
                            status: Boolean(field.status),
                        };
                    });
                const mapping = {
                    type,
                    fields,
                };
                const notifications = [];

                if (email) {
                    notifications.push({ type: 'email', value: email });
                }

                if (urlCallback) {
                    notifications.push({
                        type: 'callback',
                        value: urlCallback,
                        options: Object.assign(JSON.parse(callbackOptions), { method: 'post' }),
                    });
                }

                return Object.assign(payloadBase, { mapping, notifications });
            }
            default:
                const fields = this.state.mappingSourceFields
                    .filter((field) => {
                        return Boolean(field.status);
                    })
                    .map((field, index) => {
                        return {
                            order: index + 1,
                            source: field.key,
                            target: field.target || null,
                            separator: field.separator || null,
                            status: Boolean(field.status),
                        };
                    });
                const mapping = {
                    type: 'custom',
                    fields,
                };

                return Object.assign(payloadBase, { mapping });
        }
    };

    moveRow = (dragIndex, hoverIndex) => {
        const { mappingSourceFields } = this.state;
        const dragRow = mappingSourceFields[dragIndex];

        this.setState(
            update(this.state, {
                mappingSourceFields: {
                    $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
                },
            })
        );
    };

    checkEmail = (event) => {
        const { checked } = event.target;
        if (!checked) this.props.form.resetFields(['email']);
        this.setState({ emailCheck: checked });
    };

    checkUrlCallback = (event) => {
        const { checked } = event.target;
        if (!checked) this.props.form.resetFields(['urlCallback']);
        this.setState({ urlCallbackCheck: checked });
    };

    handleTemplate = (template) => {
        if (template === 'wdna') {
            this.props.form.setFieldsValue({ format: 'xml' });
        }

        this.setState({ template });
    };

    changeField = (fieldName) => {
        return (event) => {
            const { name, value } = event.target;
            this.setState((prevState) => {
                return {
                    mappingSourceFields: prevState.mappingSourceFields.map((field) => {
                        if (field.key === name) {
                            return { ...field, [fieldName]: value };
                        }
                        return field;
                    }),
                };
            });
        };
    }

    handleSwitch(element, state) {
        this.setState((prevState) => {
            return {
                mappingSourceFields: prevState.mappingSourceFields.map((field) => {
                    if (field.key === element.props.name) {
                        return {
                            ...field,
                            status: state,
                        };
                    }

                    return field;
                }),
            };
        });
    }

    handleCallbackModal = () => {
        this.setState((state) => {
            return { callbackModal: !state.callbackModal };
        });
    };

    handleCustomFieldAdd = () => {
        this.setState((prevState) => {
            const newField = { key: generateTempKey() };
            const mappingSourceFields = [newField, ...prevState.mappingSourceFields];
            return { ...prevState, mappingSourceFields };
        });
    }

    handleCustomFieldDel = (key) => {
        this.setState((prevState) => {
            const mappingSourceFields = prevState.mappingSourceFields.filter((field) => {
                return field.key !== key;
            });
            return { ...prevState, mappingSourceFields };
        });
    }

    changeCallbackOptions = (value) => {
        this.setState({ callbackOptions: value });
    };

    confirmCallbackModal = () => {
        try {
            const { callbackOptions } = this.state;
            if (callbackOptions && callbackOptions.trim().length > 0) {
                JSON.parse(callbackOptions);
            }

            this.handleCallbackModal();
        } catch (error) {
            Notification('error', 'Existem erros de sintaxe, verifique!');
        }
    };

    transformColumns = () => {
        const columns = [
            {
                title: 'Ordem',
                key: 'order',
                dataIndex: 'order',
                width: '10%',
                align: 'left',
                render: () => {
                    return <FaSort />;
                },
            },
            {
                title: 'Campo de Origem',
                key: 'source',
                dataIndex: 'source',
                width: '35%',
                align: 'left',
                render: (source) => {
                    const field = isTempKey(source) ? 'NENHUM' : source;

                    if (!fieldsDescriptions[field]) {
                        return (
                            <Row>
                                <Col sm={24}>
                                    {field}
                                </Col>
                            </Row>
                        );
                    }

                    return (
                        <Row>
                            <Col sm={22}>
                                {field}
                            </Col>
                            <Col sm={2}>
                                <Tooltip title={fieldsDescriptions[field]}>
                                    <Icon
                                        type="question-circle"
                                        style={{ cursor: 'pointer' }}
                                    />
                                </Tooltip>
                            </Col>
                        </Row>
                    );
                },
            },
            {
                title: 'Campo de Destino',
                key: 'target',
                dataIndex: 'target',
                width: '40%',
                align: 'left',
                render: (field) => {
                    if (isTempKey(field.key)) {
                        return (
                            <Row gutter={16}>
                                <Col sm={24} md={15}>
                                    <Input
                                        name={field.key}
                                        value={field.target}
                                        onChange={this.changeField('target')}
                                    />
                                </Col>
                                <Col sm={22} md={7}>
                                    <Input
                                        name={field.key}
                                        value={field.value}
                                        onChange={this.changeField('value')}
                                        placeholder="Valor padrão"
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Icon
                                        type="delete"
                                        style={{ cursor: 'pointer', padding: '9px 0px' }}
                                        onClick={() => {
                                            this.handleCustomFieldDel(field.key);
                                        }}
                                    />
                                </Col>
                            </Row>
                        );
                    }

                    if (!field.useSeparator) {
                        return (
                            <Input
                                name={field.key}
                                onChange={this.changeField('target')}
                                value={field.target}
                            />
                        );
                    }

                    return (
                        <Row gutter={16}>
                            <Col sm={24} md={16}>
                                <Input
                                    name={field.key}
                                    value={field.target}
                                    onChange={this.changeField('target')}
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Input
                                    name={field.key}
                                    value={field.separator}
                                    onChange={this.changeField('separator')}
                                    placeholder="Separador"
                                />
                            </Col>
                        </Row>
                    );
                },
            },
            {
                title: 'Utilizar Campo',
                key: 'useField',
                dataIndex: 'useField',
                align: 'right',
                width: '15%',
                render: (field) => {
                    return (
                        <Switch
                            onText="Sim"
                            offText="Não"
                            labelText="Utilizar campo"
                            name={field.key}
                            onChange={(el, state) => {
                                return this.handleSwitch(el, state);
                            }}
                            defaultValue={false}
                            value={Boolean(field.status)}
                        />
                    );
                },
            },
        ];

        return columns;
    };

    transformData = () => {
        const { mappingSourceFields } = this.state;

        return mappingSourceFields.map((field) => {
            return {
                source: field.key,
                target: field,
                useField: field,
            };
        });
    };

    render() {
        const { callbackOptions, loading, submitLoading, type, template } = this.state;
        const { getFieldDecorator } = this.props.form;
        const columns = this.transformColumns();
        const data = this.transformData();

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={paths.connectors}>Conectores</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Novo</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <Form onSubmit={this.save} layout="vertical">
                        <TitlePage
                            title="Conectores"
                            subtitle="Faça o mapeamento de campos para este conector."
                            actions={
                                <Fragment>
                                    <Button
                                        type="primary"
                                        icon="plus"
                                        htmlType="button"
                                        style={{ marginRight: 10 }}
                                        onClick={this.handleCustomFieldAdd}
                                    >
                                        Criar campo
                                    </Button>
                                    <Button
                                        type="primary"
                                        icon="save"
                                        htmlType="submit"
                                        style={{ marginRight: 10 }}
                                        loading={submitLoading}
                                    >
                                        Salvar
                                    </Button>
                                </Fragment>
                            }
                        />
                        <div className="connectors-form">
                            <Row gutter={16}>
                                <Col sm={24} md={10}>
                                    <FormItem>
                                        <label htmlFor="name">Nome</label>
                                        {getFieldDecorator('name', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Insira o nome do conector!',
                                                },
                                            ],
                                        })(<Input />)}
                                    </FormItem>
                                </Col>
                                <Can do="create-with-type" this={{ __type: 'Connector' }}>
                                    <Col sm={24} md={5}>
                                        <FormItem>
                                            <label htmlFor="type">Tipo</label>
                                            {getFieldDecorator('type', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Selecione o tipo do conector!',
                                                    },
                                                ],
                                                initialValue: type,
                                            })(
                                                <Select>
                                                    {connectorTypes.map(({ label, value }) => {
                                                        return (
                                                            <Option key={value} value={value}>{label}</Option>
                                                        );
                                                    })}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Can>
                                <Can do="create-with-template" this={{ __type: 'Connector' }}>
                                    <Col sm={24} md={5}>
                                        <FormItem>
                                            <label htmlFor="template">Template</label>
                                            {getFieldDecorator('template', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                            'Selecione o template do conector!',
                                                    },
                                                ],
                                                initialValue: type,
                                            })(
                                                <Select onChange={this.handleTemplate}>
                                                    {connectorTemplates.map(({ label, value }) => {
                                                        return (
                                                            <Option key={value} value={value}>{label}</Option>
                                                        );
                                                    })}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Can>
                            </Row>
                            <Row gutter={16}>
                                <Col sm={24} md={10}>
                                    <FormItem>
                                        <label>Conteúdo</label>
                                        {getFieldDecorator('scope', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Selecione o conteúdo!',
                                                },
                                            ],
                                        })(
                                            <Select>
                                                {connectorScopes.map((scope) => {
                                                    const disabled = scope.value === 'order';
                                                    return (
                                                        <Option
                                                            key={scope.value}
                                                            disabled={disabled}
                                                            value={scope.value}
                                                        >
                                                            {scope.label}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                                <Col sm={24} md={10}>
                                    <FormItem>
                                        <label htmlFor="format">Formato</label>
                                        {getFieldDecorator('format', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Selecione o formato!',
                                                },
                                            ],
                                            initialValue: 'xml',
                                        })(
                                            <Select>
                                                {connectorFormats.map((format) => {
                                                    const disabled =
                                                        type === 'wdna' && format.value === 'json';
                                                    return (
                                                        <Option
                                                            key={format.value}
                                                            disabled={disabled}
                                                            value={format.value}
                                                        >
                                                            {format.label}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            {template === 'wdna' && (
                                <Row gutter={16}>
                                    <Col sm={24} md={10}>
                                        <label for="appId">AppId</label>
                                        {getFieldDecorator('appId')(<Input />)}
                                    </Col>
                                    <Col sm={24} md={10}>
                                        <label for="token">Token</label>
                                        {getFieldDecorator('token')(<Input />)}
                                    </Col>
                                </Row>
                            )}
                            {template === 'bling' && (
                                <Row gutter={16}>
                                    <Col sm={24} md={10}>
                                        <label for="appId">API key</label>
                                        {getFieldDecorator('apikey')(<Input />)}
                                    </Col>
                                </Row>
                            )}
                            {['custom', 'default'].includes(template) && (
                                <Fragment>
                                    <Row gutter={16}>
                                        <Col sm={24} md={10}>
                                            <FormItem>
                                                <label>Notificações de exportação</label>
                                                <InputGroup compact>
                                                    {getFieldDecorator('email')(
                                                        <Input addonBefore="Email" />
                                                    )}
                                                </InputGroup>
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={10}>
                                            <FormItem>
                                                <label>&nbsp;</label>
                                                <InputGroup compact>
                                                    {getFieldDecorator('urlCallback')(
                                                        <Input
                                                            addonAfter={
                                                                <Icon
                                                                    onClick={this.handleCallbackModal}
                                                                    type="question-circle"
                                                                    theme="filled"
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                            }
                                                            addonBefore="URL de callback"
                                                            // disabled={!this.state.urlCallbackCheck}
                                                        />
                                                    )}
                                                </InputGroup>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Loading loading={loading}>
                                        <Table
                                            columns={columns}
                                            dataSource={data}
                                            components={this.components}
                                            onRow={(record, index) => {
                                                return {
                                                    index,
                                                    moveRow: this.moveRow,
                                                };
                                            }}
                                            pagination={false}
                                        />
                                    </Loading>
                                </Fragment>
                            )}
                        </div>
                        <Modal
                            title="Informações sobre notificação via callback"
                            visible={this.state.callbackModal}
                            // onOk={this.handleCallbackModal}
                            onCancel={this.handleCallbackModal}
                            footer={
                                <Button type="primary" onClick={this.confirmCallbackModal}>
                                    Confirmar
                                </Button>
                            }
                        >
                            <p>
                                Quando o processo de exportação finalizar, será feito uma requisição
                                para a URL configurada. Está requisição será feita com o verbo HTTP{' '}
                                <strong>POST</strong> e utilizará os parâmetros do editor.{' '}
                                <strong>OBS: </strong>chave "file" é obrigatória para receber a url
                                da importação
                            </p>
                            <AceEditor
                                mode="json"
                                theme="github"
                                onChange={this.changeCallbackOptions}
                                value={callbackOptions}
                                name="json-texteditor-callback"
                            />
                        </Modal>
                    </Form>
                </div>
            </Fragment>
        );
    }
}

const WrappedConnector = Form.create()(Connector);
export default DragDropContext(HTML5Backend)(WrappedConnector);
