import React from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';

import Login from './SignIn/Login';
import Signup from './SignUp/Signup';
import ConfirmCode from './SignUp/ConfirmCode';

import UnauthenticatedRoute from '~/components/Routes/UnauthenticatedRoute';

import config from '~/config';
import { AuthContainer, AuthRow, Logo, LogoContainer, Wrapper, BackgroundPerson } from './styles';

export default class AuthBox extends React.Component {
    render() {
        return (
            <Wrapper
                id="auth-box"
                style={{
                    backgroundImage: `url(${config.app.cdn}/dashboard/background-center.png)`,
                }}
            >
                <BackgroundPerson
                    style={{
                        backgroundImage: `url(${config.app.cdn}/dashboard/background-bottom_right.png)`,
                        backgroundPosition: 'bottom right'
                    }}>
                </BackgroundPerson>
                <BackgroundPerson
                    style={{
                        backgroundImage: `url(${config.app.cdn}/dashboard/background-top_left.png)`,
                        backgroundPosition: 'top left'
                    }}>
                </BackgroundPerson>
                <AuthRow>
                    <AuthContainer>
                        <LogoContainer>
                            <a href={`${config.links.home}`}>
                                <Logo
                                    src={`${config.app.cdn}/dashboard/logo-grid2b.png`}
                                    alt="Grid2B Logo"
                                    title="Grid2B Logo"
                                />
                            </a>
                        </LogoContainer>
                        <Switch>
                            <UnauthenticatedRoute
                                path={`${this.props.match.url}/login`}
                                props={this.props}
                                component={Login}
                            />
                            <Route
                                path={`${this.props.match.url}/signup`}
                                render={() => {
                                    return <Signup {...this.props} />;
                                }}
                            />
                            <Route
                                location={this.props.location}
                                key={this.props.location.key}
                                path={`${this.props.match.url}/confirm`}
                                component={ConfirmCode}
                            />
                        </Switch>
                    </AuthContainer>
                </AuthRow>
            </Wrapper>
        );
    }
}

AuthBox.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
};
