import styled from 'styled-components';
import { Button } from 'antd';

const ButtonStyled = styled(Button)`
    background-color: #fff;
    border-color: #e9e9e9;
    color: #8c909e;
    margin: 5px 0;    
    &:focus,
    &:active,
    &:hover {
        background-color: #fff;
        border-color: #2373d9;
        color: #2373d9;
        .anticon {
            border-right-color: #2373d9;
        }        
    }
    &.ant-btn {
        border-radius: 5px;
        font-size: 17px;
        height: 44px;
        line-height: 20px;
        margin: 0 0 15px;
        padding-bottom: 11px;
        padding-top: 11px;
    }
    .anticon {
        background-color: #fff;
        border-bottom-left-radius: 4px;
        border-right: 1px solid #e9e9e9;
        border-top-left-radius: 4px;
        bottom: 0;
        left: 0;
        position: absolute;
        right: auto;
        top: 0;
        width: 52px;
    }
`;

export const FacebookButton = styled(ButtonStyled)`
    background-color: #4a6ea8;
    border-color: #4a6ea8;
    color: #fff;    
    font-weight: 500;
    &:focus,
    &:active,
    &:hover {
        background-color: #3B5998;
        border-color: #3B5998;
        color: #fff;
        
        .anticon {
            border-right-color: #3B5998;
        }   
    }
    .anticon {
        background-color: #3a5285;
        border-right-color: #3a5285;
        padding-bottom: 12px;
        padding-top: 12px;
    }
`;

export const GoogleButton = styled(ButtonStyled)`
    .anticon {
        padding-bottom: 11.5px;
        padding-top: 11.5px;
    }
`;
