import { combineReducers } from 'redux';
import auth from './auth';
import transaction from './transactions';
import user from './user';

export default combineReducers({
    auth,
    transaction,
    user,
});
