import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Input, Icon, Button, Spin, Alert } from 'antd';
import { Auth } from 'aws-amplify';
import { FaLongArrowAltLeft } from 'react-icons/fa';

/** Styles */
import {
    Description,
    DescriptionContainer,
    FormItemStyled as FormItem,
    LinkStyled as Link,
    SignInLink as LinkActive,
} from '../styles';

/** components */
import Notification from '~/components/Notification/AntNotification';

/** Actions */
import { fetchCurrentUser } from '~/store/actions/user';
import { setUserToken } from '~/store/actions/auth';

/** Libs */
import userUtil from '~/lib/user';

import ability, { defineRulesFor } from '~/ability';

class ConfirmCode extends Component {
    state = {
        loading: false,
        reSendConfirmationCodeLoading: false,
    };

    componentDidMount() {
        document.title = 'Grid2B | Confirmação de cadastro';
    }

    confirmUser = (event) => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                this.setState({ loading: true });
                const { email, confirmationCode } = values;
                try {
                    await Auth.confirmSignUp(email, confirmationCode);

                    // await this.signIn();

                    // this.props.history.push('/dashboard');

                    Notification('success', 'Usuário confirmado com sucesso!', undefined, 15);

                    this.props.history.push('/auth/login');
                } catch (error) {
                    this.setState({ loading: false });

                    switch (error.code) {
                        case 'UserNotFoundException':
                            Notification('error', 'Usuário não encontrado!');
                            break;
                        case 'NotAuthorizedException':
                            if (error.message === 'User cannot be confirm. Current status is CONFIRMED') {
                                Notification('error', 'Conta já confirmada!');
                                break;
                            }

                            Notification('error', 'Usuário desativado! Entre em contato com nossa equipe');
                            break;
                        default:
                            Notification('error', 'Ocorreu um erro ao confirmar seu email!');
                            break;
                    }
                }
            }
        });
    }

    reSendConfirmationCode = (event) => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll(['email'], async (err, values) => {
            if (!err) {
                try {
                    this.setState({ reSendConfirmationCodeLoading: true });

                    const { email } = values;
                    await Auth.resendSignUp(email);

                    Notification('success', 'Código de confirmação enviado para seu email');
                } catch (error) {
                    switch (error.code) {
                        case 'UserNotFoundException':
                            Notification('error', 'Usuário não encontrado!');
                            break;
                        case 'NotAuthorizedException':
                            Notification('error', 'Usuário desativado! Entre em contato com nossa equipe');
                            break;
                        case 'LimitExceededException':
                            Notification(
                                'warning',
                                'Limite de tentativas excedido para esta operação. Tente novamente mais tarde',
                            );
                            break;
                        default:
                            Notification('error', 'Ocorreu um erro ao enviar seu código de confirmação!');
                            break;
                    }
                } finally {
                    this.setState({ reSendConfirmationCodeLoading: false });
                }
            }
        });
    }

    signIn = async () => {
        const user = await Auth.signIn(
            this.getHistoryState('email'),
            this.getHistoryState('password')
        );

        const { dispatch, updateUserToken } = this.props;
        const userToken = user.signInUserSession.idToken.jwtToken;
        this.updateRulesFor(userToken);
        dispatch(setUserToken(userToken, false));
        dispatch(fetchCurrentUser());
        updateUserToken(userToken);
        ability.update(userToken);
    }

    updateRulesFor = (userToken) => {
        const authorizedUser = userUtil.get(userToken);
        const { rules } = defineRulesFor(authorizedUser);
        ability.update(rules);
    }

    getHistoryState = (key) => {
        const { state } = this.props.location;

        return state ?.[key];
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const validateStatusAll = 'validating';

        return (
            <Fragment>
                <Form onSubmit={this.confirmUser}>
                    <DescriptionContainer>
                        <Description>Confirmação de usuário</Description>
                    </DescriptionContainer>
                    <DescriptionContainer>
                        <Description>
                            <Alert description="Informe o código de confirmação enviado para seu email" type="warning"/>
                        </Description>
                    </DescriptionContainer>
                    <FormItem
                        validateStatus={validateStatusAll}
                    >
                        {getFieldDecorator('email', {
                            rules: [{
                                required: true, message: 'Insira seu email!',
                            }],
                            initialValue: this.getHistoryState('email'),
                        })(
                            <Input
                                disabled={Boolean(this.getHistoryState('email'))}
                                placeholder='Email'
                            />
                        )}
                    </FormItem>
                    <FormItem
                        validateStatus={validateStatusAll}
                    >
                        {getFieldDecorator('confirmationCode', {
                            rules: [{
                                required: true, message: 'Insira o código de confirmação!',
                            }],
                        })(
                            <Input placeholder='Código de confirmação' type="text"/>
                        )}
                    </FormItem>
                    <FormItem>
                        <Button loading={this.state.loading} type="primary" block htmlType="submit">Confirmar</Button>
                    </FormItem>
                    <FormItem>
                        <DescriptionContainer last>
                            <Description>
                                Não tem um código? <LinkActive to='#' onClick={this.reSendConfirmationCode}>Clique aqui</LinkActive>
                                {this.state.reSendConfirmationCodeLoading && (
                                    <Spin
                                        indicator={
                                            <Icon type='loading' />
                                        }
                                        spinning
                                        style={{
                                            color: '#fff',
                                            marginLeft: 5,
                                        }}
                                    />
                                )}
                            </Description>
                        </DescriptionContainer>
                    </FormItem>
                    <DescriptionContainer last>
                        <Description>
                            <Link to='login'><FaLongArrowAltLeft /> Voltar para o login</Link>
                        </Description>
                    </DescriptionContainer>
                </Form>
            </Fragment>
        );
    }
}

const WrappedConfirmCode = Form.create()(ConfirmCode);

export default withRouter(
    connect()(WrappedConfirmCode)
);
