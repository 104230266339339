import { del, get, post, put } from '~/services/api/rest/client';
import utils from '~/lib/utils';

/** inventory requests */
export const InventoryAPI = {
    fetchInventories: (filters = '') => {
        return get(`/inventories/${filters}`);
    },
    fetchInventory: (inventoryId) => {
        return get(`/inventories/${inventoryId}`);
    },
    createInventory: (data) => {
        return post('/inventories', data);
    },
    updateInventory: (inventoryId, data) => {
        return put(`/inventories/${inventoryId}`, data);
    },
    deleteInventory: (inventoryId) => {
        return del(`/inventories/${inventoryId}`);
    },
    fetchInventoryProducts: (inventoryId, filters = {}) => {
        const query = utils.stringifyQueryString(filters);
        return get(`/inventories/${inventoryId}/products?${query}`);
    },
    updateInventoryProducts: (inventoryId, data, filters = {}) => {
        const query = utils.stringifyQueryString(filters);
        return put(`/inventories/${inventoryId}/products?${query}`, data);
    },
};
