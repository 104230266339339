import { notification } from 'antd';

export default (type, message, description, duration = undefined) => {
    if (duration === undefined) {
        const defaultDuration = {
            success: 10,
            info: 15,
            warning: 15,
            error: 15,
        };

        duration = defaultDuration[type]; 
    }

    notification[type]({
        key: (message + description),
        message,
        description,
        duration,
    });
};
