import React from 'react';
import ReactDOM from 'react-dom';
import { LocaleProvider } from 'antd';
import pt_BR from 'antd/lib/locale-provider/pt_BR';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import 'moment/locale/pt-br';

import AppBox from '~/pages/AppBox';
import config from '~/config';
import store from '~/store';
import '~/styles/css/index.css';
import { unregister } from '~/registerServiceWorker';

Amplify.configure({
    Auth: {
        region: config.cognito.REGION,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        oauth: config.oauth,
    },
});

ReactDOM.render(
    <LocaleProvider locale={pt_BR}>
        <Provider store={store}>
            <Router>
                <AppBox />
            </Router>
        </Provider>
    </LocaleProvider>,
    document.getElementById('app-container')
);

unregister();
