import React, { Component } from 'react';

import { get } from '~/services/api/rest/client';

export default function(ComponentWithSupplier) {
    class WithSupplier extends Component {
        state = {
            supplier: {},
        };

        componentDidMount() {
            this.getAndSetInitialData();
        }

        getAndSetInitialData = async () => {
            const data = await this.getInitialData();
            this.setInitialData(data);
        };

        getInitialData = async () => {
            try {
                const pathState = this.props.location.state;
                if (this.hasSupplierName(pathState)) {
                    return {
                        supplier: {
                            name: pathState.supplierName,
                        },
                    };
                }

                const supplier = await this.getSupplier();

                return { supplier };
            } catch (error) {
                console.log('ERROR getInitialData: ', error);
            }
        };

        getSupplier = async () => {
            try {
                const { supplierId } = this.props.match.params;
                const { result } = await get(`suppliers/${supplierId}`);

                return result;
            } catch (error) {
                console.log('ERROR getSupplier: ', error);
                throw error;
            }
        };

        hasSupplierName = (pathState) => {
            if (pathState && pathState.supplierName) {
                return true;
            }

            return false;
        };

        setInitialData = (data) => {
            this.setState({ ...data });
        };

        render() {
            const props = {
                ...this.props,
                ...this.state,
            };

            return <ComponentWithSupplier {...props} />;
        }
    }

    return WithSupplier;
}
