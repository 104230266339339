import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Route } from 'react-router-dom';
import { Button, Form, Icon, Input } from 'antd';
import qs from 'query-string';

import ability, { defineRulesFor } from '~/ability';

/** Containers */
import PasswordRecovery from './PasswordRecovery';
import PasswordRenew from './PasswordRenew';
import SocialLogin from '../FederatedAuth';

/** Components */
import Federated from '~/components/Buttons/FederatedButtons';
import Notification from '~/components/Notification/AntNotification';

/** Styles */
import {
    Description,
    DescriptionContainer,
    FormItemStyled as FormItem,
    LinkStyled as Link,
    SignUpLink,
} from '../styles';

/** Actions */
import { fetchCurrentUser } from '~/store/actions/user';
import { setUserToken } from '~/store/actions/auth';

/** Utils */
import userUtil from '~/lib/user';

class Login extends Component {
    state = {
        loading: false,
        passwordVisible: false
    };

    componentDidMount() {
        this.setTitle();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setTitle();
        }
    }

    setTitle = () => {
        if (this.props.location.pathname === '/auth/login') {
            document.title = 'Grid2B | Login';
        }
    };

    signIn = (event) => {
        event.preventDefault();

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                const { email, password, accountEmail } = values;

                this.setState({ loading: true });

                try {
                    const user = await Auth.signIn(email, password);
                    const { dispatch, updateUserToken, history } = this.props;
                    const userToken = user.signInUserSession.idToken.jwtToken;
                    this.updateRulesFor(userToken);
                    dispatch(setUserToken(userToken, false));
                    dispatch(fetchCurrentUser({ accountEmail }));
                    updateUserToken(userToken);
                    ability.update(userToken);

                    history.push('/dashboard');
                } catch (error) {
                    this.setState({ loading: false });

                    switch (error.code) {
                        case 'UserNotConfirmedException':
                            // Notification('error', 'Usuário não ativado!');
                            this.props.history.push({
                                pathname: '/auth/confirm',
                                state: { email },
                            });
                            break;
                        case 'UserNotFoundException':
                            Notification('error', 'Usuário não cadastrado!');
                            break;
                        case 'NotAuthorizedException': {
                            const message = {
                                'Password attempts exceeded': 'Limite de tentativas excedido para esta operação. Tente novamente mais tarde',
                                'User is disabled': 'Usuário desativado! Entre em contato com nossa equipe.',
                                'Incorrect username or password.': 'Email ou senha incorreto!',
                            };

                            Notification(
                                'error',
                                message[error.message] || 'Por favor, aguarde a ativação do seu cadastro pela nossa equipe.'
                            );
                            break;
                        }
                        default:
                            Notification('error', 'Ocorreu um erro ao logar, tente novamente!');
                            break;
                    }
                }
            }
        });
    };

    updateRulesFor = (userToken) => {
        const authorizedUser = userUtil.get(userToken);
        const { rules } = defineRulesFor(authorizedUser);
        ability.update(rules);
    };

    render() {
        const { passwordVisible } = this.state;
        const { getFieldDecorator } = this.props.form;
        const queryParams = qs.parse(this.props.location.search);
        const isMaster = queryParams.master || '';
        const validateStatusAll = 'validating';

        return (
            <Fragment>
                <Route
                    exact
                    path={`${this.props.match.url}/login`}
                    render={() => {
                        return (
                            <Form onSubmit={this.signIn}>
                                {
                                    isMaster && (
                                        <FormItem>
                                            {getFieldDecorator('accountEmail', {
                                                rules: [
                                                    {
                                                        type: 'email',
                                                        message: 'Formato de email inválido!',
                                                    },
                                                    {
                                                        required: true,
                                                        message: 'Por favor, insira o email da conta!',
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    autoFocus
                                                    prefix={
                                                        <Icon
                                                            type="mail"
                                                            style={{ color: 'rgba(0,0,0,.25)' }}
                                                        />
                                                    }
                                                    placeholder="Email da conta"
                                                />
                                            )}
                                        </FormItem>
                                    )
                                }
                                <FormItem
                                    validateStatus={validateStatusAll}
                                >
                                    {getFieldDecorator('email', {
                                        rules: [
                                            {
                                                type: 'email',
                                                message: 'Formato de email inválido!',
                                            },
                                            {
                                                required: true,
                                                message: 'Por favor, insira seu email!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Email"
                                        />
                                    )}
                                </FormItem>
                                <FormItem
                                    validateStatus={validateStatusAll}
                                >
                                    {getFieldDecorator('password', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Por favor, insira sua senha!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Senha"
                                            suffix={
                                                passwordVisible ? (
                                                    <Icon
                                                        type="eye-invisible"
                                                        style={{color: '#a7adc2', fontSize: '18px'}}
                                                        onClick={() => {
                                                            return this.setState({
                                                                passwordVisible: false,
                                                            });
                                                        }}
                                                    />
                                                ) : (
                                                    <Icon
                                                        type="eye"
                                                        style={{color: '#a7adc2', fontSize: '18px'}}
                                                        onClick={() => {
                                                            return this.setState({
                                                                passwordVisible: true,
                                                            });
                                                        }}
                                                    />
                                                )
                                            }
                                            type={passwordVisible ? 'text' : 'password'}
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    <Button loading={this.state.loading} type="primary" block htmlType="submit">
                                        Entrar
                                    </Button>
                                </FormItem>
                                <FormItem style={{marginBottom: '45px'}}>
                                    <Link to={`${this.props.location.pathname}/password-recovery`} >
                                        Esqueceu sua senha?
                                    </Link>
                                </FormItem>

                                {/** TODO: Login Facebook and Google **/}
                                {/*<DescriptionContainer direction='column'>*/}
                                {/*    <Federated col={24} textAdd={'Logar com'} />*/}
                                {/*</DescriptionContainer>*/}

                                <DescriptionContainer last>
                                    <Description>
                                        Não possui uma conta?{' '}
                                        <SignUpLink to={`${this.props.match.url}/signup`}>
                                            Cadastre-se aqui
                                        </SignUpLink>
                                    </Description>
                                </DescriptionContainer>
                            </Form>
                        );
                    }}
                />
                <Route
                    exact
                    path={`${this.props.match.url}/login/social`}
                    component={SocialLogin}
                />
                <Route
                    exact
                    path={`${this.props.match.url}/login/password-recovery`}
                    component={PasswordRecovery}
                />
                <Route
                    exact
                    path={`${this.props.match.url}/login/password-renew`}
                    component={PasswordRenew}
                />
            </Fragment>
        );
    }
}

export default connect()(Form.create()(Login));
