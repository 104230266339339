import React from 'react';

import { Wrapper, IconStyled, Title } from './styles';

const NotFound = () => {
    return (
        <Wrapper>
            <IconStyled type="frown" />
            <Title>404 - Página não encontrada</Title>
        </Wrapper>
    );
};

export default NotFound;
