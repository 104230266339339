import Notification from '~/components/Notification/AntNotification';
import { DeleteConfirm } from '~/components/Confirm';

import utils from '~/lib/utils';
import { SupplierAPI, ProductAPI } from '~/lib/api';
import { updateSuppliersSettings, setCurrentUser, fetchCurrentUser } from '~/store/actions/user';

export async function getAndSetInitialData(refresh = false) {
    try {
        if (refresh) {
            this.setState({
                error: false,
                loading: true,
            });
        }

        const fetchSupplier = await SupplierAPI.fetchSupplier(this.props.match.params.supplierId);
        this.setState({
            loading: false,
            supplier: fetchSupplier.result,
        });

        const fetchSupplierSettings = await SupplierAPI.fetchSupplierSettings(
            this.props.match.params.supplierId
        );
        this.setState({
            settingsLoading: false,
            settings: fetchSupplierSettings.result,
        });
    } catch (error) {
        console.log('ERROR getAndSetInitialData: ', error);

        this.setState({
            error: true,
            loading: false,
        });
    }
}

async function loadProducts({ supplierId }) {
    try {
        await ProductAPI.loadProducts({ supplierId });
        Notification('success', 'A importação do seu inventário de produtos foi iniciada');
        return true;

    } catch (error) {
        if (error.response.status === 422) {
            Notification('success', 'O seu inventário de produtos já foi importado!');
            return false;
        }

        if (error.response.status === 401) {
            Notification('error', 'Não autorizado, verifique suas configurações!');
            return;
        }

        Notification('error', 'Ocorreu um erro ao importar o seu inventário, tente novamente!');
    }
};

export function saveSupplierSettings(status) {
    return (event) => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                try {
                    this.setState({ saveLoading: true });

                    const { supplier } = this.state;
                    const payload = makePayload(values, status, supplier);

                    const settings = await SupplierAPI.saveSupplierSettings(
                        this.props.match.params.supplierId,
                        payload
                    );
                    this.props.dispatch(updateSuppliersSettings(settings.result));

                    this.setState({
                        saveLoading: false,
                        settings: settings.result,
                    });

                    Notification('success', `Distribuidor ${status ? 'Ativado' : 'Inativado'}`);

                    if (status) {
                        this.confirmInventoryUse();
                    }
                } catch (error) {
                    console.log('ERROR saveSupplierSettings: ', error);

                    this.setState({ saveLoading: false });

                    if (error.response.status === 401) {
                        Notification(
                            'error',
                            'Falha na autenticação do usuário, confira suas credenciais e tente novamente!'
                        );
                        return;
                    }

                    Notification(
                        'error',
                        'Ocorreu um erro ao salvar a configuração, tente novamente!'
                    );
                }
            }
        });
    };
}

function confirmInventoryUse() {
    DeleteConfirm(
        async () => {
            const result = await loadProducts({ supplierId: this.props.match.params.supplierId });

            if (result === false) {
                this.setState((prevState) => {
                    return { settings: { ...prevState.settings, inventory: true } };
                });
            }

            if (result === undefined) {
                return;
            }

            const currentUser = { ...this.props.currentUser };
            currentUser.supplierBackgroundProcess = true;
            const setting = currentUser.suppliers.find((supplier) => {
                return supplier.supplierId === this.props.match.params.supplierId;
            }) || {};
            setting.inventory = true;
            this.props.dispatch(setCurrentUser(currentUser));

            this.setState((prevState) => {
                return { settings: { ...prevState.settings, inventory: true } };
            });

            setTimeout(() => {
                this.props.dispatch(fetchCurrentUser());
            }, 90000);
        },
        { title: 'Deseja gerenciar preços para o consumidor final pelo Grid2b?' }
    );
}

export { confirmInventoryUse };

function makePayload(params, status, supplier) {
    const { paramsPayload } = supplier;
    const payload = paramsPayload.supplier.reduce((payload, param) => {
        payload[param.key] = params[param.key];
        return payload;
    }, {});

    return Object.assign(payload, { status });
}

export function createParamsPayload() {
    const { settings } = this.state;
    const { form } = this.props;
    const { paramsPayload } = this.state.supplier;

    return utils.renderFields(paramsPayload.supplier, form, settings);
}
