import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import { Breadcrumb, Button, Form, Icon } from 'antd';
import { head, innerJoin } from 'ramda';

import DynamicFilter from '~/components/Filters/DynamicFilter';
import HeaderInfo from '~/components/Header/HeaderInfo';
import Notification from '~/components/Notification/AntNotification';
import Table from '~/components/Table/AntTable';
import { DeleteConfirm } from '~/components/Confirm';
import { TitlePage } from '~/components/Header/TitlePage';

import utils from '~/lib/utils';
import { SupplierAPI, ScheduleAPI } from '~/lib/api/supplier';
import { handleSort } from '~/lib/Sort';
import { loadMore } from '~/lib/InfiniteScroll';
import { paths } from '~/routes';

const style = {
    icon: {
        marginRight: 10,
        fontSize: 18,
        cursor: 'pointer',
    },
};

class List extends Component {
    constructor() {
        super();
        this.state = {
            fetchSchedules: {
                hasMore: false,
                pagination: {},
                result: [],
                total: 0,
            },
            error: false,
            loading: true,
            supplier: {},
        };
    }

    componentDidMount() {
        document.title = 'Grid2B | Agendamentos';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async (refresh = false) => {
        try {
            if (refresh) {
                this.setState({
                    errors: false,
                    loading: true,
                });
            }

            const { supplierId } = this.props.match.params;
            const search = qs.parse(this.props.location.search);
            const filters = {
                ...search,
                orderBy: 'name',
                sort: 'asc',
                supplierId,
            };
            const [fetchSupplier, fetchSchedules] = await Promise.all([
                SupplierAPI.fetchSupplier(supplierId),
                ScheduleAPI.fetchSchedules(supplierId, qs.stringify(filters)),
            ]);

            this.setState((prevState) => {
                return {
                    fetchSchedules: {
                        ...prevState.fetchSchedules,
                        ...fetchSchedules,
                        hasMore: Boolean(fetchSchedules.pagination.next),
                    },
                    loading: false,
                    supplier: fetchSupplier.result,
                };
            });
        } catch (error) {
            console.log('ERROR getAndSetInitialData: ', error);
            this.setState({
                errors: true,
                loading: false,
            });
        }
    };

    execute = async (supplierIdConfig) => {
        try {
            const { supplierId } = this.props.match.params;
            await ScheduleAPI.executeSchedule(supplierId, supplierIdConfig, {});

            // Notification('success', '');
        } catch (error) {
            console.log('ERROR execute: ', error);

            // Notification('error', '');
        }
    };

    delete = async (supplierIdConfig) => {
        try {
            const { supplierId } = this.props.match.params;

            await ScheduleAPI.deleteSchedule(supplierId, supplierIdConfig);

            this.setState((state) => {
                return {
                    fetchSchedules: {
                        ...state.fetchSchedules,
                        result: state.fetchSchedules.result.filter((schedule) => {
                            return schedule.supplierIdConfig !== supplierIdConfig;
                        }),
                    },
                };
            });

            Notification('success', 'Agendamento apagado!');
        } catch (error) {
            Notification('error', 'Não foi possível apagar o agendamento, tente novamente!');
        }
    };

    loadMoreSchedules = () => {
        const loadMoreScoped = loadMore.bind(this);

        return loadMoreScoped('fetchSchedules', this.getAndSetInitialData);
    };

    handleSort = (pagination, filters, sorter) => {
        const handleSortScoped = handleSort.bind(this);

        return handleSortScoped(sorter, this.getAndSetInitialData);
    };

    transformColumns = () => {
        const columns = [
            {
                title: 'Nome',
                dataIndex: 'name',
                key: 'name',
                width: '40%',
                defaultSortOrder: 'ascend',
                sorter: true,
            },
            {
                title: 'Horário',
                dataIndex: 'hour',
                key: 'hour',
                width: '25%',
            },
            {
                title: 'Dias',
                dataIndex: 'days',
                key: 'days',
                width: '25%',
            },
            {
                title: '',
                dataIndex: 'actions',
                hidden: true,
                align: 'right',
                render: (schedule) => {
                    return (
                        <Fragment>
                            <a
                                href="Javascript:Void(0)" /* eslint-disable-line */
                                onClick={() => {
                                    return DeleteConfirm(
                                        () => {
                                            return this.execute(schedule.supplierIdConfig);
                                        },
                                        {
                                            title: 'Deseja realmente executar esse agendamento?',
                                        }
                                    );
                                }}
                            >
                                <Icon type="play-circle" style={style.icon} />
                            </a>
                            <Link to={`${this.props.match.url}/edit/${schedule.supplierIdConfig}`}>
                                <Icon type="edit" style={style.icon} />
                            </Link>
                            <a
                                href="Javascript:Void(0)" /* eslint-disable-line */
                                onClick={() => {
                                    return DeleteConfirm(
                                        () => {
                                            return this.delete(schedule.supplierIdConfig);
                                        },
                                        {
                                            title: 'Deseja realmente apagar esse agendamento?',
                                        }
                                    );
                                }}
                            >
                                <Icon
                                    style={Object.assign(
                                        {},
                                        { cursor: style.icon.cursor },
                                        { fontSize: style.icon.fontSize }
                                    )}
                                    type="delete"
                                />
                            </a>
                        </Fragment>
                    );
                },
            },
        ];

        return columns;
    };

    transformData = () => {
        const { fetchSchedules, supplier } = this.state;
        const schedulesTransformed = fetchSchedules.result.map((schedule) => {
            const days = schedule.days.reduce((days, shortDay) => {
                const day = utils.sort(utils.days(), 'asc', 'shortDay').find((day) => {
                    return day.shortDayEn === shortDay;
                });

                if (Boolean(days)) {
                    return `${days} - ${day.shortDay}`;
                }

                return `${day.shortDay}`;
            }, '');

            const availableSchedule = head(
                innerJoin(
                    (record, id) => {
                        return record.id === id;
                    },
                    supplier.availableSchedules,
                    [head(schedule.scheduleIdConfig.split('_'))]
                )
            );

            return {
                key: schedule.supplierIdConfig,
                name: schedule.name,
                hour: `De: ${availableSchedule?.startTime} - Até: ${availableSchedule?.endTime}`,
                days,
                actions: schedule,
            };
        });

        return schedulesTransformed;
    };

    render() {
        const { error, loading, supplier, fetchSchedules } = this.state;
        const { hasMore, total } = fetchSchedules;
        const columns = this.transformColumns();
        const data = this.transformData();

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={paths.suppliers}>Distribuidores</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={`${paths.suppliers}/${this.props.match.params.supplierId}/settings`}
                        >
                            {supplier.name}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Agendamentos</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage
                        title="Agendamentos"
                        subtitle="Confira seus agendamentos para este distribuidor."
                    />
                    <div className="content-filters">
                        <DynamicFilter
                            onSubmit={() => {
                                return this.getAndSetInitialData(true);
                            }}
                            filters={columns}
                        />
                        <HeaderInfo
                            showing={fetchSchedules.result.length}
                            total={total}
                            actions={
                                <Link to={`${this.props.match.url}/new`}>
                                    <Button
                                        type="primary"
                                        className="success"
                                        icon="plus"
                                        onClick={this.handleCreateListProductsImportModal}
                                    >
                                        Criar
                                    </Button>
                                </Link>
                            }
                        />
                    </div>
                    <Table
                        columns={columns}
                        data={data}
                        discountInfiniteContainerHeight={306}
                        error={error}
                        hasMore={hasMore}
                        infiniteScroll
                        loadMore={this.loadMoreSchedules}
                        loading={loading}
                        onChange={this.handleSort}
                        pagination={false}
                        refreshData={() => {
                            return this.getAndSetInitialData(true);
                        }}
                    />
                </div>
            </Fragment>
        );
    }
}

export default Form.create()(List);
