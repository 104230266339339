// @flow

import React, { Fragment, useState } from 'react';
import { Affix } from 'antd';

import { Title, SubTitle } from './styles';

type Style = {
    backgroundColor: string,
    transition: string,
};

type Props = {
    actions?: Array<React$Element<any>>,
    extraTitle?: string,
    subtitle: string,
    title: string,
    unffixed: boolean,
};

type State = {
    style: Style,
};

function TitlePage(props: Props) {
    const initialStyle = {
        transition: 'all .2s ease-in-out',
    };
    const [style: State, setStyle] = useState(initialStyle);

    const fixedTitlePage = (affixed: boolean) => {
        if (affixed) {
            return setStyle({
                ...style,
                boxShadow: '0px 6px 8px -6px rgba(0, 0, 0, 0.2)',
                padding: '20px 10px',
                backgroundColor: '#FFF',
            });
        }

        return setStyle(initialStyle);
    };

    const renderTitle = () => {
        const { title, extraTitle } = props;

        if (extraTitle) {
            return (
                <Fragment>
                    <span className='title'>{title}</span>
                    <span className='extra-title'>{` : ${extraTitle}`}</span>
                </Fragment>
            );
        }

        return <span className='extra-title'>{title}</span>;
    };

    const div = (        
        <div className='content-header' style={style}>
            <div className='title-page'>
                <Title>{renderTitle()}</Title>
                <SubTitle>{props.subtitle}</SubTitle>
            </div>
            <div className='page-action'>
                {props.actions}
            </div>
        </div>
    );

    return props.unffixed ? div : (<Affix offsetTop={0} onChange={fixedTitlePage}>{div}</Affix>);
}

export { TitlePage };
