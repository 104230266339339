import { Modal } from 'antd';

const { confirm } = Modal;

export const DeleteConfirm = (callback, options = {}) => {
    confirm({
        title: options.title,
        okText: options.okText || 'Sim',
        cancelText: options.cancelText || 'Não',
        onOk() {
            return callback();
        },
        onCancel() { },
    });
};
