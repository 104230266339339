import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Icon, Timeline } from 'antd';

class Changelog extends Component {
    render() {
        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/dashboard">Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Changelog</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <h2>Changelog</h2>
                    <p>
                        Acompanha as últimas atualizações do sistema e fique por dentro das
                        novidades!
                    </p>
                    <Timeline>
                        <Timeline.Item color="green">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </Timeline.Item>
                        <Timeline.Item color="red">
                            Sed pellentesque lectus sed lorem sagittis rhoncus.
                        </Timeline.Item>
                        <Timeline.Item>
                            <p>Phasellus tristique enim sit amet iaculis aliquet.</p>
                            <p>Nunc placerat dui id leo pellentesque consectetur.</p>
                        </Timeline.Item>
                        <Timeline.Item
                            dot={<Icon type="clock-circle-o" style={{ fontSize: '18px' }} />}
                            color="red"
                        >
                            <b>10/10/2010 10:10:10</b> Sed ac quam in est porttitor laoreet.
                        </Timeline.Item>
                        <Timeline.Item>
                            Quisque in ex tincidunt velit scelerisque iaculis.
                        </Timeline.Item>
                        <Timeline.Item color="green">
                            Sed egestas purus vitae nunc pretium vulputate.
                        </Timeline.Item>
                        <Timeline.Item color="red">
                            <p>Donec facilisis dolor quis sollicitudin tincidunt.</p>
                            <p>
                                Phasellus mattis mauris hendrerit, dignissim risus id, sodales
                                lorem.
                            </p>
                            <p>Donec quis metus sed turpis finibus rhoncus.</p>
                        </Timeline.Item>
                    </Timeline>
                </div>
            </div>
        );
    }
}

export default Changelog;
