import { del, get, post, put } from '~/services/api/rest/client';
import utils from '~/lib/utils';

/** supplier requests */
export const SupplierAPI = {
    fetchSuppliers: (filters = '') => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`/suppliers?${queryString}`);
    },

    fetchSupplier: (supplierId, filters = '') => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`/suppliers/${supplierId}?${queryString}`);
    },
    fetchSupplierSettings: (supplierId, filters = '') => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`/suppliers/${supplierId}/settings?${queryString}`);
    },
    fetchSupplierDistributionCenters: (supplierId) => {
        return get(`/suppliers/${supplierId}/distribution-centers`);
    },
    saveSupplierSettings: (supplierId, data) => {
        return post(`/suppliers/${supplierId}/settings`, data);
    },
};

/** schedules requests */
export const ScheduleAPI = {
    fetchAllSchedules: (filters = '') => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`/schedules?${queryString}`);
    },
    fetchSchedules: (supplierId, filters = '') => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`/suppliers/${supplierId}/schedules?${queryString}`);
    },

    fetchSchedule: (supplierId, scheduleId, filters = '') => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`/suppliers/${supplierId}/schedules/${scheduleId}?${queryString}`);
    },

    createSchedule: (supplierId, data) => {
        return post(`/suppliers/${supplierId}/schedules`, data);
    },

    deleteSchedule: (supplierId, scheduleId) => {
        return del(`/suppliers/${supplierId}/schedules/${scheduleId}`);
    },

    updateSchedule: (supplierId, scheduleId, data) => {
        return put(`/suppliers/${supplierId}/schedules/${scheduleId}`, data);
    },
    executeSchedule: (supplierId, scheduleId, data) => {
        return post(`suppliers/${supplierId}/schedules/${scheduleId}/execute`, data);
    },
};
