import React from 'react';
import styled from 'styled-components';

import InputNumber from './Number';

const Input = styled(InputNumber)`
    .ant-input-number-input {
        text-align: right;
    }
`;

const thousandsSeparator = '․';
const decimalSeparator = ',';
let dollar = 'R$';

const currency = (value) => {
    if (typeof value === 'number') {
        value = value.toFixed(2);
    }

    return value
        .replace(/[^\d]/g, '')
        .replace(/(?<![0-9])0+/, '')
        .replace(/\B(?=([0-9]{2}$))/g, '.')
        .padStart(4, '0.00');
};

const formatter = (value) => {
    const separator = thousandsSeparator;
    const regexp = /\B(?=(\d{3})+(?!\d))/g;
    return `${dollar} ${currency(value)}`.replace(regexp, separator).trim();
};

const parser = (value) => {
    const separator = thousandsSeparator;
    const regexp = new RegExp(`[${dollar}]\\s?|(${separator}*)`, 'g');
    return currency(value.replace(regexp, ''));
};

const InputMoney = ({ hideDollarSign = false, ...props }, ref) => {
    if (hideDollarSign) {
        dollar = '';
    }

    return (
        <Input
            {...props}
            ref={ref}
            decimalSeparator={decimalSeparator}
            formatter={formatter}
            parser={parser}
            precision={2}
            step={0.1}
        />
    );
};

export default React.forwardRef(InputMoney);
