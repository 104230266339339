import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Icon, Modal } from 'antd';

import Can from '~/components/Can';
import DynamicFilter from '~/components/Filters/DynamicFilter';
import HeaderInfo from '~/components/Header/HeaderInfo';
import InfiniteScroll from '~/components/InfiniteScroller';
import Notification from '~/components/Notification/AntNotification';
import Table from '~/components/Table/AntTable';
import { TitlePage } from '~/components/Header/TitlePage';

import { ConnectorAPI } from '~/lib/api/connector';
import { paths } from '~/routes';

const confirm = Modal.confirm;
const style = {
    icon: {
        marginRight: 10,
        fontSize: 18,
        cursor: 'pointer',
    },
};
const showDeleteConfirm = (deleteFunc, id) => {
    confirm({
        title: 'Deseja realmente apagar esse conector?',
        okText: 'Sim',
        cancelText: 'Não',
        onOk() {
            return deleteFunc(id);
        },
        onCancel() {},
    });
};

class ConectorsList extends Component {
    state = {
        error: false,
        fetchConnectors: {
            hasMore: false,
            pagination: {},
            result: [],
            total: 0,
        },
        loading: true,
    };

    async componentDidMount() {
        document.title = 'Grid2B | Conectores';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async (refresh = false) => {
        try {
            if (refresh) {
                this.setState({
                    loading: true,
                    error: false,
                });
            }

            const fetchConnectors = await ConnectorAPI.fetchConnectors(this.props.location.search);

            this.setState((prevState) => {
                return {
                    fetchConnectors: {
                        ...prevState.fetchConnectors,
                        ...fetchConnectors,
                        hasMore: Boolean(fetchConnectors.pagination.next),
                    },
                    loading: false,
                    total: fetchConnectors.total,
                };
            });
        } catch (error) {
            this.setState({
                error: true,
                loading: false,
            });
        }
    };

    delete = async (connectorId) => {
        try {
            await ConnectorAPI.deleteConnector(connectorId);

            this.setState((prevState) => {
                return {
                    fetchConnectors: {
                        ...prevState.fetchConnectors,
                        result: prevState.fetchConnectors.result.filter((connector) => {
                            return connector.connectorId !== connectorId;
                        }),
                    },
                };
            });

            Notification('success', 'Conector apagado com sucesso!');
        } catch (error) {
            console.log('ERROR delete: ', error);

            if (error?.response?.data?.details) {
                const { dependencies } = error.response.data.details;
                const errors = dependencies.reduce((errors, error) => {
                    const liError = <li>{error.name}</li>;
                    errors.push(liError);

                    return errors;
                }, []);

                Notification(
                    'error',
                    'Ocorreu um erro ao apagar o conector!',
                    <Fragment>
                        <span>Ele está sendo usado nos seguintes agendamentos.</span>
                        <ul>{errors}</ul>
                    </Fragment>
                );

                return;
            }

            Notification('error', 'Ocorreu um erro ao apagar o conector, tente novamente!');
        }
    };

    getMappingType = (type) => {
        switch (type) {
            case 'wdna':
                return 'WDNA';
            case 'custom':
                return 'Personalizado';
            default:
                return 'Padrão';
        }
    };

    transformColumns = () => {
        return [
            {
                title: 'Título',
                alias: 'connectorName',
                key: 'title',
                dataIndex: 'title',
                width: '50%',
                align: 'left',
            },
            {
                title: 'Tipo',
                key: 'type',
                dataIndex: 'type',
                type: 'select',
                options: [
                    { value: 'default', name: 'Padrão' },
                    { value: 'custom', name: 'Personalizado' },
                ],
                width: '40%',
                align: 'left',
            },
            {
                title: '',
                key: 'actions',
                dataIndex: 'actions',
                hidden: true,
                align: 'right',
                width: '10%',
                render: (connector) => {
                    return (
                        <Fragment>
                            <Link
                                to={`${this.props.location.pathname}/edit/${connector.connectorId}`}
                                style={{ marginLeft: 10 }}
                            >
                                <Icon
                                    type="edit"
                                    style={Object.assign(
                                        {},
                                        { cursor: style.icon.cursor },
                                        { fontSize: style.icon.fontSize }
                                    )}
                                />
                            </Link>
                            <Can
                                do="delete"
                                this={Object.assign(connector, { __type: 'Connector' })}
                            >
                                <a
                                    href="Javascript:Void(0);" /* eslint-disable-line */
                                    onClick={() => {
                                        return showDeleteConfirm(
                                            this.delete,
                                            connector.connectorId
                                        );
                                    }}
                                    style={{ marginLeft: 10 }}
                                >
                                    <Icon
                                        type="delete"
                                        style={Object.assign(
                                            {},
                                            { cursor: style.icon.cursor },
                                            { fontSize: style.icon.fontSize }
                                        )}
                                    />
                                </a>
                            </Can>
                        </Fragment>
                    );
                },
            },
        ];
    };

    transformData = () => {
        const { result: connectors } = this.state.fetchConnectors;
        const data = connectors.map((connector) => {
            return {
                key: connector.connectorId,
                id: connector.connectorId,
                title: connector.connectorName,
                type: this.getMappingType(connector.type),
                actions: connector,
            };
        });

        return data;
    };

    render() {
        const { error, fetchConnectors, loading } = this.state;
        const { hasMore, total } = fetchConnectors;
        const columns = this.transformColumns();
        const data = this.transformData();

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/dashboard">Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Conectores</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage
                        title="Conectores"
                        subtitle="Personalize ou crie novos mapeamentos de campos para exportação."
                    />
                    <div className="content-filters">
                        <DynamicFilter
                            onSubmit={() => {
                                return this.getAndSetInitialData(true);
                            }}
                            filters={columns}
                        />
                        <HeaderInfo
                            showing={data.length}
                            total={total}
                            actions={
                                <Link to={`${paths.connectors}/new`}>
                                    <Button type="primary" icon="plus">
                                        Criar
                                    </Button>
                                </Link>
                            }
                        />
                    </div>
                    <div
                        className="infinite-container"
                        style={{ height: window.innerHeight - 307, overflow: 'auto' }}
                    >
                        <InfiniteScroll
                            hasMore={hasMore}
                            initialLoad={false}
                            loadMore={this.loadMoreConnectors}
                            threshold={20}
                            useWindow={false}
                        >
                            <Table
                                columns={columns}
                                data={data}
                                error={error}
                                loading={loading}
                                refreshData={() => {
                                    return this.getAndSetInitialData(true);
                                }}
                                pagination={false}
                            />
                        </InfiniteScroll>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ConectorsList;
