import React, { Component, Fragment } from 'react';
import { AutoComplete, Button, Table, Input, Icon, InputNumber, Tooltip, Form } from 'antd';

import Can from '~/components/Can';
import ErrorLoading from '~/components/ErrorLoading/ErrorData';
import Loading from '~/components/Loading';
import hasError from '~/components/HOC/HasError';
import { Column } from '~/components/Column';

const EditableContext = React.createContext();
const style = {
    icon: {
        marginRight: 10,
        fontSize: 18,
        cursor: 'pointer',
    },
};

class EditableCell extends Component {
    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber />;
        }

        if (this.props.inputType === 'autocomplete') {
            const { onHandleSearch, ...restProps } = this.props.fieldOptions;

            return <AutoComplete {...restProps} onChange={this.onInputChange(onHandleSearch)} />;
        }

        return <Input />;
    };

    onInputChange = (onHandleSearch) => {
        return (value) => {
            const hashPattern = new RegExp('^[1-9]-[a-f0-9]{32}', 'g');

            if (value.match(hashPattern)) {
                return;
            }

            const filter = value ? { name: value } : { orderBy: 'name', sort: 'asc' };
            onHandleSearch(filter);
        };
    };

    validateCategory = (rule, value, callback) => {
        const hasValue = Boolean(value);

        if (hasValue) {
            const { dataSource } = this.props.fieldOptions;
            const hashPattern = new RegExp('^[1-9]-[a-f0-9]{32}', 'g');
            const category = dataSource.find((category) => {
                return category.value === value;
            });

            if (!category || !value.match(hashPattern)) {
                callback('Categoria inválida!');
            }
        }

        callback();
    };

    render() {
        const { editing, dataIndex, title, inputType, record, index, ...restProps } = this.props;

        return (
            <EditableContext.Consumer>
                {(form) => {
                    const { getFieldDecorator } = form;
                    return (
                        <td {...restProps}>
                            {editing ? (
                                <Column>
                                    <Tooltip title={record[dataIndex].category}>
                                        {record[dataIndex].category}
                                    </Tooltip>
                                    {getFieldDecorator(dataIndex, {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Selecione uma categoria G2B',
                                            },
                                            { validator: this.validateCategory },
                                        ],
                                        initialValue: record[dataIndex]?.grid2bCategory?.value,
                                    })(this.getInput())}
                                </Column>
                            ) : (
                                restProps.children
                            )}
                        </td>
                    );
                }}
            </EditableContext.Consumer>
        );
    }
}

class EditableTable extends Component {
    state = { editingKey: '' };

    isEditing = (record) => {
        return record.key === this.state.editingKey;
    };

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    save(form, key) {
        form.validateFields(async (error, row) => {
            if (error) {
                return;
            }

            try {
                await this.props.onSave(key, row);
                this.setState({ editingKey: '' });
            } catch (error) {
                this.setState({ editingKey: '' });
            }
        });
    }

    edit(key) {
        this.setState({ editingKey: key });
    }

    addPropRenderToColumns = () => {
        const columns = this.props.columns.map((column) => {
            if (column.key === 'actions') {
                return {
                    ...column,
                    render: (text, record, index) => {
                        const editable = this.isEditing(record);
                        return (
                            <Fragment>
                                {editable ? (
                                    <span>
                                        <EditableContext.Consumer>
                                            {(form) => {
                                                return (
                                                    <Button
                                                        loading={this.props.updateLoading}
                                                        onClick={() => {
                                                            return this.save(form, record);
                                                        }}
                                                        size="small"
                                                        style={{ marginRight: 8 }}
                                                        type="primary"
                                                    >
                                                        Salvar
                                                    </Button>
                                                );
                                            }}
                                        </EditableContext.Consumer>
                                        <Button
                                            onClick={() => {
                                                return this.cancel(record.key);
                                            }}
                                            size="small"
                                            type="default"
                                        >
                                            Cancelar
                                        </Button>
                                    </span>
                                ) : (
                                    <Can
                                        do="update"
                                        this={Object.assign(record, { __type: 'Product' })}
                                    >
                                        {/* eslint-disable-next-line */}
                                        <a onClick={() => this.edit(record.key)}>
                                            <Icon type="edit" style={style.icon} />
                                        </a>
                                    </Can>
                                )}
                            </Fragment>
                        );
                    },
                };
            }
            return column;
        });

        return columns;
    };

    render() {
        const { loading, onChange } = this.props;
        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const columns = this.addPropRenderToColumns().map((column) => {
            if (!column.editable) {
                return column;
            }

            return {
                ...column,
                onCell: (record) => {
                    return {
                        record,
                        inputType: column.type,
                        dataIndex: column.dataIndex,
                        title: column.title,
                        fieldOptions: column.fieldOptions,
                        editing: this.isEditing(record),
                    };
                },
            };
        });

        return (
            <Loading loading={loading}>
                <EditableContext.Provider value={this.props.form}>
                    <Table
                        columns={columns}
                        components={components}
                        dataSource={this.props.data}
                        id="table-responsive"
                        locale={{ emptyText: ' ' }}
                        onChange={onChange}
                        pagination={this.props.pagination}
                        rowClassName="editable-row"
                    />
                </EditableContext.Provider>
            </Loading>
        );
    }
}

export default hasError(ErrorLoading)(Form.create()(EditableTable));
