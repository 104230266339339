import { SET_USER_TOKEN } from '../actions/auth';

const initialState = {
    userToken: null,
    isLoadingUserToken: true,
};

function auth(state = initialState, action) {
    switch (action.type) {
        case SET_USER_TOKEN: {
            const { userToken, isLoadingUserToken } = action;
            window.localStorage.setItem('user', userToken);

            return {
                userToken,
                isLoadingUserToken,
            };
        }
        default:
            return state;
    }
}

export default auth;