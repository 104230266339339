/* eslint-disable */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Script from 'react-load-script';
import Cards from 'react-credit-cards';
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Form,
    Input,
    Radio,
    Row,
    Select,
    Skeleton,
    Steps,
    Collapse,
    Alert,
    Modal,
    Typography,
    Card,
} from 'antd';

const { Title, Text } = Typography;

import CreditCard from '~/components/PaymentMethods/CreditCard';
import Notification from '~/components/Notification/AntNotification';
import Loading from '~/components/Loading';
import { TitlePage } from '~/components/Header/TitlePage';
import InputNumber from '~/components/Input/Number';
import InputMoney from '~/components/Input/Money';

import utils from '~/lib/utils';
import { getPaymentMethods, addPaymentMethod } from '~/store/actions/transactions';

import { setCurrentUser } from '~/store/actions/user';

import { PaymentAPI } from '~/lib/api/';
import { paths } from '~/routes';
import config from '~/config';

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const Step = Steps.Step;

const mapStateToProps = (state) => {
    const { paymentMethods } = state.transaction;
    const { currentUser } = state.user;
    return {
        paymentMethods,
        currentUser
    };
};

class Checkout extends Component {

    state = {
        loading: true,
        modalPayment: false,
        paymentSelected: 0,
        senderHash: null,
        session: '',
        step: 0,
        total: 0,
        avaliablePayments: false,
        paymentMethod: null,
        onlineDebitBank: null,
        transaction: {},
        successModal: false,
        selectedPlan: '',
        plans: [],
        warn: false,
        error: false,
    };

    componentDidMount() {
        this.getAndSetInitialData();
        this.checkCurrentUserData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentUser.id !== prevProps.currentUser.id) {
            this.checkCurrentUserData()
        }
    }

    async getAndSetInitialData() {
        try {
            const [
                { result: { session } },
                { result: { plans } },
            ] = await Promise.all([
                PaymentAPI.getSession(),
                PaymentAPI.getInfo(),
            ]);

            this.setState({ session, plans }, () => {
                this.initializePagSeguro(session);
                this.checkCurrentUserData();
            });
        } catch (error) {
            this.setState({
                loading: false,
                warn: true,
                step: -1,
            });
        }
    }

    checkCurrentUserData() {
        const validUser = this.props.currentUser?.name
        const hasSession = this.state.session !== '';

        if (validUser && hasSession) {
            const selectedPlan = this.props.currentUser?.plan?.planId || '';
            this.setState({ step: 1, loading: false });
        } else if (hasSession && this.props.currentUser?.id) {
            this.setState({ loading: false });
        }
    }

    handlePriceChange = (price) => {
        const [onDemand] = this.state.plans;
        const creditQuantity = price / onDemand.creditValue;
        this.props.form.setFieldsValue(
            { creditQuantity }, this.verifyQuantity
        );
    };

    handleQuantityChange = (quantity) => {
        const [onDemand] = this.state.plans;
        const creditPrice = quantity * onDemand.creditValue ;
        this.props.form.setFieldsValue(
            { creditPrice }, this.verifyQuantity
        );
    };

    verifyQuantity = () => {
        this.props.form.validateFields(['creditPrice'], (errors, values) => {
            if (!errors && this.state.step === 1) {
                this.setState({ step: 2 });
            }
        });
    };

    handlePaymentMethodChange = (paymentMethod) => {

        if (paymentMethod === 'bank-billet') {
            return this.setState({ paymentMethod, step: 3, onlineDebitBank: null })
        }

        if (paymentMethod === 'credit-card') {
            this.openModalPayment();
        } else {
            this.props.dispatch(addPaymentMethod({}));
        }

        if (paymentMethod !== 'online-debit') {
            return this.setState({ paymentMethod, step: 2, onlineDebitBank: null })
        }

        this.setState({ paymentMethod, step: 2 });
    }

    openModalPayment = () => {
        this.setState({ modalPayment: true });
    };

    handleScriptLoad = () => {
        const sessionId = this.state.session;
        if (sessionId) {
            this.initializePagSeguro(sessionId);
        }
    };

    initializePagSeguro(sessionId) {
        /* eslint-disable */
        PagSeguroDirectPayment.setSessionId(sessionId);
        PagSeguroDirectPayment.onSenderHashReady((response) => {
            if (response?.status === 'error') {
                console.log(response.message);
                return false;
            }

            this.setState({ senderHash: response?.senderHash });
            this.fetchPaymentMethods();
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }

            this.setState({ loading: true, step: -1 });

            try {
                const params = {
                    senderHash: this.state.senderHash,
                    quantity: values.creditQuantity,
                };

                let response = {};

                if (this.state.selectedPlan) {
                    const [creditCard] = this.props.paymentMethods;
                    response = await PaymentAPI.signIn({
                        senderHash: this.state.senderHash,
                        planId: this.state.selectedPlan,
                        creditCardToken: creditCard.cardToken,
                        creditCardHolderName: creditCard.cardHolderName,
                        creditCardHolderCPF: creditCard.document,
                        creditCardHolderBirthDate: creditCard.birthdayDate,
                        creditCardHolderPhone: creditCard.areaCode + creditCard.phone,
                    });
                } else if (this.state.paymentMethod === 'bank-billet') {
                    response = await PaymentAPI.bankBillet(params);
                } else if (this.state.paymentMethod === 'online-debit') {
                    response = await PaymentAPI.onlineDebit(
                        { ...params, bank: this.state.onlineDebitBank }
                    );
                } else if (this.state.paymentMethod === 'credit-card') {
                    const [creditCard] = this.props.paymentMethods;
                    response = await PaymentAPI.creditCard({
                        ...params,
                        creditCardToken: creditCard.cardToken,
                        creditCardHolderName: creditCard.cardHolderName,
                        creditCardHolderCPF: creditCard.document,
                        creditCardHolderBirthDate: creditCard.birthdayDate,
                        creditCardHolderPhone: creditCard.areaCode + creditCard.phone,
                    });
                }

                this.setState({ 
                    loading: false,
                    successModal: true,
                    transaction: response.result,
                });
            } catch (error) {
                this.setState({ loading: false, error: true });
            } finally {
                this.props.dispatch(addPaymentMethod({}));
            }

        });
    };

    fetchPaymentMethods = () => {
        const total = this.props.form.getFieldValue('creditPrice') || 0;

        /* eslint-disable */
        PagSeguroDirectPayment.getPaymentMethods({
            amount: total,
            success: (response) => {
                const options = response?.paymentMethods?.ONLINE_DEBIT?.options || {};
                const avaliablePayments = Object.values(options).filter((option) => {
                    return option.status === 'AVAILABLE';
                })
                
                this.setState({ avaliablePayments });
            },
            error: (error) => console.log(error),
        });
    }

    handlePlanChange = (event) => {
        const { value } = event.target;

        this.setState(({ step, paymentMethod }) => {
            return {
                selectedPlan: value,
                ...((value && step < 2) && { step: 2 }),
                ...((value && paymentMethod !== 'credit-card') && { paymentMethod: null })
            }
        }, () => {
            this.props.form.setFieldsValue({
                creditPrice: null,
                creditQuantity: null,
            });
        });
    }

    render() {
        const { form, currentUser } = this.props;
        const { getFieldDecorator } = form;
        const { step, loading, plans, selectedPlan } = this.state;

        const creditPrice = Number(this.props.form.getFieldValue('creditPrice')) || 0;
        const creditQuantity = Number(this.props.form.getFieldValue('creditQuantity')) || 0;

        const errors = form.getFieldError('creditPrice');

        const paymentMethods = {
            'bank-billet': 'Boleto',
            'online-debit': 'Débito Online',
            'credit-card': 'Cartão de Crédito',
        };

        const paymentMethod = paymentMethods[this.state.paymentMethod];

        plans.forEach((plan) => {
            const currentCard = currentUser?.plan?.planId === plan.id;
            const className = currentCard ? 'plan-enabled-card' : 'plan-disabled-card';
            const hasSelected = currentUser?.plan?.planId;
            plan.className = hasSelected ? className : '';
        });

        const [onDemand, plan1, plan2, plan3] = plans;

        const currentPlan = plans.find((plan) => {
            return plan.id === selectedPlan;
        });

        const { name } = plans.find((plan) => {
            return plan.id === currentUser?.plan?.planId;
        }) || {};

        const buyInformation = (
            <div className="buy-information">
                {selectedPlan ? (
                    <span>
                        <strong>Descrição: </strong>
                        {currentPlan.name}
                    </span>
                ) : (
                    <span>
                        <strong>Quantidade de créditos: </strong>
                        {(this.state.transaction?.details?.quantity || creditQuantity).toFixed(2)}
                    </span>
                )}
                <span>
                    <strong>Valor da compra: </strong>
                    {utils.toCurrency(
                        selectedPlan ? currentPlan.price : (this.state.transaction?.details?.totalPrice || creditPrice)
                    )}
                    {paymentMethod === 'Boleto' && (
                        <Text type="secondary"> + 1,00 (taxa)</Text>
                    )}
                </span>
                <span>
                    <strong>
                        Método de pagamento:{' '}
                    </strong>
                    {paymentMethod}
                </span>
            </div>
        );

        const PlanTitle = ({ children, value, isRadioBtn }) => {
            const title = <Title level={3}>{children}</Title>;
            if (!isRadioBtn) {
                return title;
            }
            return <Radio className="radio-title-plan" value={value}>{title}</Radio>;
        };

        return (
            <Fragment>
                <Script url={config.endpoints.pagseguro} onLoad={this.handleScriptLoad}/>
                <TitlePage
                    title="Comprar créditos"
                    unffixed
                    actions={[
                        (currentUser?.plan?.planId && currentPlan?.name) && (
                            <Fragment key={1}>
                                <Title level={3} style={{ fontSize: 22, fontWeight: 'normal', marginRight: 10 }} type="secondary">Plano atual:</Title>
                                <Title level={3} style={{ fontSize: 22, marginTop: 0 }}>{name}</Title>
                            </Fragment>
                        )
                    ]}
                />
                <Divider/>
                {this.state.warn && (
                    <Alert
                        showIcon
                        type="warning"
                        description="Compra de créditos indisponível no momento, tente novamente mais tarde."
                    />
                )}
                {this.state.error && (
                    <Alert
                        showIcon
                        type="error"
                        description="Não foi possível concluir sua compra neste momento, em breve entraremos em contato com mais informações."
                    />
                )}
                <Loading loading={loading}>
                    <div className="inside-container">
                        <Row>
                            <Col span={24}>
                                <Form layout="vertical" onSubmit={this.handleSubmit}>
                                    <Steps direction="vertical" size="small" current={step}>
                                        <Step
                                            title={<h3>Finalize seu cadastro</h3>}
                                            description={
                                                <Fragment>
                                                    <div className="subtitle">
                                                        <span>
                                                            Para prosseguir é necessário informar dados adicionais ao seu perfil.
                                                        </span>
                                                    </div>
                                                    {(!loading && step === 0) && (
                                                        <Link to={paths.account}>
                                                            <Button type="primary">Acessar meu perfil</Button>
                                                        </Link>
                                                    )}
                                                    <Divider />
                                                </Fragment>
                                            }
                                        >
                                        </Step>
                                        <Step
                                            title={<h3>Insira a quantidade de créditos</h3>}
                                            description={
                                                <Fragment>
                                                    <div className="subtitle">
                                                        <span>
                                                            Insira a quantidade de créditos para compra,
                                                            o valor será gerado automaticamente.
                                                        </span>
                                                    </div>
                                                    {step >= 1 && (
                                                        <Row gutter={{ sm: 12, md: 32, xl: 24 }}>
                                                            <Radio.Group
                                                                value={selectedPlan}
                                                                onChange={this.handlePlanChange}
                                                                className="card-radio-group"
                                                            >
                                                                <Col xs={24} sm={16} md={12} xl={6}>
                                                                    <Card>
                                                                        <Card.Grid style={{ width: "100%" }}>
                                                                            <PlanTitle isRadioBtn value={onDemand.id}>
                                                                                {onDemand.name}
                                                                            </PlanTitle>
                                                                            <Text>
                                                                                Realize a compra de créditos quando quiser e de acordo com sua necessidade.
                                                                            </Text>
                                                                            <Form.Item label="" style={{ marginTop: 40, marginBottom: 0, paddingBottom: 0 }}>
                                                                                <Form.Item className="credit-input-item">
                                                                                    {getFieldDecorator('creditPrice', {
                                                                                        ...(!selectedPlan && {
                                                                                            validateFirst: true,
                                                                                            rules: [
                                                                                                {
                                                                                                    message: 'Campo obrigatório',
                                                                                                    required: true,
                                                                                                },
                                                                                                {
                                                                                                    validator: (rule, value, callback) => callback((value < 199) || undefined),
                                                                                                    message: "Valor mínimo R$199,00"
                                                                                                },
                                                                                                {
                                                                                                    validator: (rule, value, callback) => callback((value > 2000) || undefined),
                                                                                                    message: "Valor máximo R$4.000,00"
                                                                                                }
                                                                                            ],
                                                                                        })
                                                                                    })(
                                                                                        <InputMoney
                                                                                            onChange={this.handlePriceChange}
                                                                                            disabled={Boolean(selectedPlan)}
                                                                                        />
                                                                                    )}
                                                                                    <div className="ant-input-group-addon">Valor</div>
                                                                                </Form.Item>
                                                                                <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}>=</span>
                                                                                <Form.Item className="credit-input-item">
                                                                                    {getFieldDecorator('creditQuantity', {})(
                                                                                        <InputNumber
                                                                                            precision={2}
                                                                                            decimalSeparator=","
                                                                                            onChange={this.handleQuantityChange}
                                                                                            parser={(value) => {
                                                                                                return value.replace(/[^\d.,]/g, '');
                                                                                            }}
                                                                                            disabled={Boolean(selectedPlan)}
                                                                                        />
                                                                                    )}
                                                                                    <div className="ant-input-group-addon">Créditos</div>
                                                                                </Form.Item>
                                                                            </Form.Item>
                                                                            {errors?.length > 0 && (
                                                                                <Alert
                                                                                    type="warning"
                                                                                    message={errors[0]}
                                                                                    style={{ marginTop: 10 }}
                                                                                    showIcon
                                                                                />
                                                                            )}
                                                                        </Card.Grid>
                                                                    </Card>
                                                                </Col>
                                                                <Col xs={24} sm={16} md={12} xl={6}>
                                                                    <Card className={plan1.className}>
                                                                        <Card.Grid style={{ width: "100%" }}>
                                                                            <PlanTitle isRadioBtn={!plan1.className} value={plan1.id}>
                                                                                {plan1.name}
                                                                            </PlanTitle>
                                                                            <Text>
                                                                                Garanta 1.999 créditos por mês, economizando 50% em relação a compra sob demanda.
                                                                            </Text>
                                                                            <Title style={{ marginTop: 40, marginBottom: 0 }}>
                                                                                {utils.toCurrency(plan1.price)}
                                                                            </Title>
                                                                            <Text type="secondary">Lançados mensalmente no seu cartão de crédito durante 12 meses.</Text>
                                                                        </Card.Grid>
                                                                    </Card>
                                                                </Col>
                                                                <Col xs={24} sm={16} md={12} xl={6}>
                                                                    <Card className={plan2.className}>
                                                                        <Card.Grid style={{ width: "100%" }}>
                                                                            <PlanTitle isRadioBtn={!plan2.className} value={plan2.id}>
                                                                                {plan2.name}
                                                                            </PlanTitle>
                                                                            <Text>
                                                                                Garanta 5.999 créditos por mês, economizando 67% em relação a compra sob demanda.
                                                                            </Text>
                                                                            <Title style={{ marginTop: 40, marginBottom: 0 }}>
                                                                                {utils.toCurrency(plan2.price)}
                                                                            </Title>
                                                                            <Text type="secondary">Lançados mensalmente no seu cartão de crédito durante 12 meses.</Text>
                                                                        </Card.Grid>
                                                                    </Card>
                                                                </Col>
                                                                <Col xs={24} sm={16} md={12} xl={6}>
                                                                    <Card className={plan3.className}>
                                                                        <Card.Grid style={{ width: "100%" }}>
                                                                            <PlanTitle isRadioBtn={!plan3.className} value={plan3.id}>
                                                                                {plan3.name}
                                                                            </PlanTitle>
                                                                            <Text>
                                                                                Garanta 15.998 créditos por mês, economizando 75% em relação a compra sob demanda.
                                                                            </Text>
                                                                            <Title style={{ marginTop: 40, marginBottom: 0 }}>
                                                                                {utils.toCurrency(plan3.price)}
                                                                            </Title>
                                                                            <Text type="secondary">Lançados mensalmente no seu cartão de crédito durante 12 meses.</Text>
                                                                        </Card.Grid>
                                                                    </Card>
                                                                </Col>
                                                            </Radio.Group>
                                                            <Col xs={24}>
                                                                <Alert
                                                                    showIcon
                                                                    type="info"
                                                                    message="1 crédito é consumido a cada 6 produtos processados em uma atualização Delta, 3 produtos em uma atualização Full ou R$ 25,00 de pedidos inseridos na plataforma."
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <Divider />
                                                </Fragment>
                                            }
                                        />
                                        <Step
                                            title={<h3>Selecione o método de pagamento</h3>}
                                            description={
                                                <Fragment>
                                                    <div className="subtitle">
                                                        <span>
                                                            Principais informações sobre a compra.
                                                        </span>
                                                    </div>
                                                    {step >= 2 && (
                                                        <div className="buy-information">
                                                            <CreditCard
                                                                open={this.state.modalPayment}
                                                                closeModal={() => {
                                                                    return this.setState({
                                                                        modalPayment: false,
                                                                        step: this.props.paymentMethods[0]?.cardNumber ? 3 : 2,
                                                                    });
                                                                }}
                                                                creditCard={this.props.paymentMethods}
                                                            />
                                                            <Collapse
                                                                className="payment-method-collapse"
                                                                expandIcon={({ isActive }) => {
                                                                    return (
                                                                        <Radio checked={isActive}/>
                                                                    );
                                                                }}
                                                                onChange={this.handlePaymentMethodChange}
                                                                activeKey={this.state.paymentMethod}
                                                                bordered={false}
                                                                accordion
                                                            >
                                                                <Collapse.Panel
                                                                    header="Boleto"
                                                                    key="bank-billet"
                                                                    disabled={this.state.paymentMethod === 'bank-billet'}
                                                                    style={{ display: (selectedPlan ? 'none' : 'initial' ) }}
                                                                >
                                                                    <Alert type="info" message="Haverá um acréscimo de R$1,00 ao valor total da compra relativo a taxa de emissão do boleto"/>
                                                                </Collapse.Panel>
                                                                <Collapse.Panel
                                                                    header="Débito Online"
                                                                    key="online-debit"
                                                                    disabled={this.state.paymentMethod === 'online-debit'}
                                                                    style={{ display: (selectedPlan ? 'none' : 'initial' ) }}
                                                                >
                                                                    {!this.state.avaliablePayments && (
                                                                        <Col xs={24} sm={12} md={8} lg={6}>
                                                                            <Skeleton active/>
                                                                        </Col>
                                                                    )}
                                                                    {this.state.avaliablePayments.length === 0 && (
                                                                        <p>Opções de pagamento não disponíveis</p>
                                                                    )}
                                                                    {this.state.avaliablePayments.length > 0 && (
                                                                        <Radio.Group
                                                                            onChange={(event) => {
                                                                                if (event.target.value) {
                                                                                    return this.setState({
                                                                                        onlineDebitBank: event.target.value,
                                                                                        step: 3,
                                                                                    });
                                                                                }
                                                                            }}
                                                                            value={this.state.onlineDebitBank}
                                                                            style={{ paddingLeft: 25 }}
                                                                        >
                                                                            {(this.state.avaliablePayments || []).map((option, index) => {
                                                                                return (
                                                                                    <Radio
                                                                                        key={index}
                                                                                        style={{ display: 'block', margin: '10px 0px' }}
                                                                                        value={option.name}
                                                                                        checked={this.state.onlineDebitBank === option.name}
                                                                                    >
                                                                                        {option.displayName}
                                                                                    </Radio>
                                                                                );
                                                                            })}
                                                                        </Radio.Group>
                                                                    )}
                                                                </Collapse.Panel>
                                                                <Collapse.Panel
                                                                    header="Cartão de Crédito"
                                                                    key="credit-card"
                                                                    disabled={this.state.paymentMethod === 'credit-card'}
                                                                >
                                                                    {(!this.state.modalPayment && (!this.props.paymentMethods.length || !this.props.paymentMethods[0])) && (
                                                                        <Button
                                                                            onClick={this.openModalPayment}
                                                                        >
                                                                            Inserir cartão de crédito
                                                                        </Button>
                                                                    )}
                                                                    {(this.props.paymentMethods.length > 0 && this.props.paymentMethods[0]) && (
                                                                        <Col xs={24} md={12} xl={8}>
                                                                            <Row style={{ marginBottom: 25 }}>
                                                                                <Cards
                                                                                    number={this.props.paymentMethods[0].cardNumber}
                                                                                    name={this.props.paymentMethods[0].cardHolderName}
                                                                                    expiry={this.props.paymentMethods[0].cardExpiry}
                                                                                    cvc={this.props.paymentMethods[0].securityCode}
                                                                                    locale={{ valid: 'VÁLIDO ATÉ' }}
                                                                                />
                                                                            </Row>
                                                                            <Row>
                                                                                <Col xs={{ span: 24 }} lg={{ span: 16, offset: 4 }} style={{ minHeight: 35 }}>
                                                                                    <Button onClick={this.openModalPayment} type="link" size="small">Editar</Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    )}
                                                                </Collapse.Panel>
                                                            </Collapse>
                                                        </div>
                                                    )}
                                                    <Divider />
                                                </Fragment>
                                            }
                                        >
                                        </Step>
                                        <Step
                                            title={<h3>Resumo da compra</h3>}
                                            description={
                                                <Fragment>
                                                    <div className="subtitle">
                                                        <span>
                                                            Principais informações sobre a compra.
                                                        </span>
                                                    </div>
                                                    {step >= 3 && buyInformation}
                                                    <Button
                                                        disabled={step !== 3 || Boolean(errors?.length)}
                                                        htmlType="submit"
                                                        className="finish-buy"
                                                        size="large"
                                                        type="primary"
                                                    >
                                                        Finalizar compra
                                                    </Button>
                                                    <Divider />
                                                </Fragment>
                                            }
                                        />
                                    </Steps>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Loading>
                <Modal
                    title="Compra concluída"
                    className="payment-sucess-modal"
                    visible={this.state.successModal}
                    footer={[
                        <Link
                            key='close'
                            to={paths.account} 
                            style={{ display: selectedPlan ? 'initial' : 'none' }}
                        >
                            <Button onClick={() => this.props.dispatch(setCurrentUser(this.state.transaction))}>
                                Fechar
                            </Button>
                        </Link>,
                        <Button
                            key='paymentLink'
                            onClick={() => {
                                window.open(this.state.transaction?.details?.paymentLink, '_blank')
                            }}
                            style={{ display: this.state.transaction?.details?.paymentLink ? 'initial' : 'none' }}
                        >
                            Link p/ pagamento
                        </Button>,
                        <Button
                            key='transactions'
                            type="primary"
                            onClick={() => {
                                const url = `${paths.transactions}/show/${this.state.transaction.transactionId}`;
                                window.location.href = url;
                            }}
                            style={{ display: this.state?.transaction?.transactionId ? 'initial' : 'none' }}
                        >
                            Detalhes da Transação
                        </Button>
                    ]}
                >
                    <p>
                        <Typography.Text type="secondary">
                            Os créditos adquiridos estarão disponíveis após o recebimento do pagamento, o prazo pode variar de acordo com a forma de pagamento.
                        </Typography.Text>
                    </p>
                    <div className="subtitle">
                        <span>
                            Informações sobre a compra.
                        </span>
                    </div>
                    {buyInformation}
                </Modal>
            </Fragment>
        );
    }
}

const WrappedCheckout = Form.create()(Checkout);
export default connect(mapStateToProps)(WrappedCheckout);
