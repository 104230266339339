import { get } from '~/services/api/rest/client';
import utils from '~/lib/utils';

/** transaction requests */
export const TransactionAPI = {
    fetchTransactions: (filters = '') => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`/transactions?${queryString}`);
    },

    fetchTransaction: (transactionId, filters = '') => {
        const queryString = utils.stringifyQueryString(filters);
        return get(`/transactions/${transactionId}?${queryString}`);
    },
};
