import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Col, Form, Icon, Input, Modal, Row, Select, Table, Tooltip } from 'antd';
import Switch from 'react-bootstrap-switch';
import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { FaSort } from 'react-icons/fa';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/github';

import Can from '~/components/Can';
import Loading from '~/components/Loading';
import Notification from '~/components/Notification/AntNotification';
import { TitlePage } from '~/components/Header/TitlePage';

import ability from '~/ability';
import utils from '~/lib/utils';
import { ConnectorAPI } from '~/lib/api/connector';
import {
    connectorTypes,
    connectorScopes,
    connectorFormats,
    connectorTemplates,
} from '~/config/types';
import { paths } from '~/routes';
import { put } from '~/services/api/rest/client';
import fieldsDescriptions from './fieldsDescriptions';

const InputGroup = Input.Group;
const FormItem = Form.Item;
const Option = Select.Option;

function dragDirection({
    dragIndex,
    hoverIndex,
    initialClientOffset,
    clientOffset,
    sourceClientOffset,
}) {
    const hoverMiddleY = (initialClientOffset.y - sourceClientOffset.y) / 2;
    const hoverClientY = clientOffset.y - sourceClientOffset.y;
    if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
        return 'downward';
    }
    if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
        return 'upward';
    }
}

class BodyRow extends Component {
    render() {
        const {
            isOver,
            connectDragSource,
            connectDropTarget,
            moveRow,
            dragRow,
            clientOffset,
            sourceClientOffset,
            initialClientOffset,
            ...restProps
        } = this.props;
        const style = { ...restProps.style, cursor: 'move' };

        let className = restProps.className;
        if (isOver && initialClientOffset) {
            const direction = dragDirection({
                dragIndex: dragRow.index,
                hoverIndex: restProps.index,
                initialClientOffset: initialClientOffset,
                clientOffset: clientOffset,
                sourceClientOffset: sourceClientOffset,
            });
            if (direction === 'downward') {
                className += ' drop-over-downward';
            }
            if (direction === 'upward') {
                className += ' drop-over-upward';
            }
        }

        return connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style} />)
        );
    }
}

const rowSource = {
    beginDrag(props) {
        return {
            index: props.index,
        };
    },
};

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        sourceClientOffset: monitor.getSourceClientOffset(),
    };
})(
    DragSource('row', rowSource, (connect, monitor) => {
        return {
            connectDragSource: connect.dragSource(),
            dragRow: monitor.getItem(),
            clientOffset: monitor.getClientOffset(),
            initialClientOffset: monitor.getInitialClientOffset(),
        };
    })(BodyRow)
);

class SaveAs extends Component {
    state = {
        saveWithoutRedirectLoading: false,
        saveWithRedirectLoading: false,
    };

    saveAs = (redirect) => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                const { onCancel, saveAs } = this.props;
                this.setState({
                    ...(redirect
                        ? { saveWithRedirectLoading: true }
                        : { saveWithoutRedirectLoading: true }),
                });

                try {
                    await saveAs(values, redirect);
                    onCancel();
                } catch (error) {
                    console.log('ERROR saveAs: ', error);
                } finally {
                    this.setState({
                        ...(redirect
                            ? { saveWithRedirectLoading: false }
                            : { saveWithoutRedirectLoading: false }),
                    });
                }
            }
        });
    };

    render() {
        const { saveWithRedirectLoading, saveWithoutRedirectLoading } = this.state;
        const { visible, onOk, onCancel } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                visible={visible}
                title="Salvar como"
                onOk={onOk}
                onCancel={onCancel}
                footer={[
                    <Button key="back" onClick={onCancel}>
                        Cancelar
                    </Button>,
                    <Button
                        key="submitSave"
                        type="primary"
                        loading={saveWithoutRedirectLoading}
                        onClick={() => {
                            return this.saveAs(false);
                        }}
                    >
                        Salvar
                    </Button>,
                    <Button
                        key="submitSaveRedirect"
                        type="primary"
                        loading={saveWithRedirectLoading}
                        onClick={() => {
                            return this.saveAs(true);
                        }}
                    >
                        Salvar e ir para conector
                    </Button>,
                ]}
            >
                <label htmlFor="name">Nome</label>
                {getFieldDecorator('name', {
                    rules: [
                        {
                            required: true,
                            message: 'Insira o nome do conector!',
                        },
                    ],
                })(<Input />)}
            </Modal>
        );
    }
}

const SaveAsModal = Form.create()(SaveAs);

const tempKeys = [];

const generateTempKey = () => {
    const tempKey = `${Math.random()}`;
    tempKeys.push(tempKey);
    return tempKey;
};

const isTempKey = (key) => {
    return tempKeys.includes(key);
};

class Edit extends Component {
    state = {
        callbackOptions: '',
        connector: {
            connectorId: '',
            connectorName: '',
            format: '',
            mapping: {},
            notificatons: [],
            scope: '',
            template: 'custom',
            type: '',
        },
        emailCheck: null,
        fields: [],
        loading: true,
        mappingSourceFields: [],
        showSaveAs: false,
        submitLoading: false,
        type: 'custom',
        urlCallbackCheck: null,
    };

    components = {
        body: {
            row: DragableBodyRow,
        },
    };

    componentDidMount() {
        document.title = 'Grid2B | Edição de conector';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async () => {
        try {
            const { connectorId } = this.props.match.params;
            const [{ result: connector }, { result: mappingSourceFields }] = await Promise.all([
                ConnectorAPI.fetchConnector(connectorId),
                ConnectorAPI.fetchMappingFields(),
            ]);
            const sourceFieldsTransformed = this.transformMappingSourceFields(
                connector,
                mappingSourceFields
            );
            const callbackOptions = this.transformCallbackOptions(connector.notifications);

            this.setState({
                callbackOptions,
                connector,
                loading: false,
                mappingSourceFields: sourceFieldsTransformed,
                type: connector.type,
            });
        } catch (error) {
            console.log('ERROR getAndSetInitialData: ', error);

            this.setState({
                error: true,
                loading: false,
            });
        }
    };

    transformCallbackOptions = (notifications = []) => {
        const callback = notifications.find((notification) => {
            return notification.type === 'callback';
        });

        if (!callback) {
            return JSON.stringify({
                headers: { 'Content-Type': 'application/json' },
                body: { file: '{{fileUrl}}' },
            }, null, 4);
        };

        delete callback.options.method;

        return JSON.stringify(callback.options, null, 4);
    };

    saveAs = (name, redirect) => {
        return new Promise((resolve, reject) => {
            this.props.form.validateFields(async (err, values) => {
                if (!err) {
                    const valuesSaveAs = Object.assign(values, name);
                    const payload = this.makePayload(valuesSaveAs, true);

                    try {
                        const { result: connector } = await ConnectorAPI.createConnector(payload);
                        Notification('success', 'Conector criado!');

                        if (redirect) {
                            await this.props.history.push(paths.base);
                            this.props.history.push(
                                `${paths.connectors}/edit/${connector.connectorId}`
                            );
                            return;
                        }

                        this.props.history.push('/dashboard/connectors');
                        resolve();
                    } catch (error) {
                        Notification('error', 'Ocorreu um erro ao tentar salvar. Tente novamente!');
                        reject();
                    }
                }
            });
        });
    };

    update = (event) => {
        event.preventDefault();

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    this.setState({ submitLoading: true });

                    const { connectorId } = this.props.match.params;
                    const payload = this.makePayload(values);
                    await put(`/connectors/${connectorId}`, payload);

                    Notification('success', 'Conector atualizado!');

                    this.props.history.push('/dashboard/connectors');
                } catch (error) {
                    this.setState({ submitLoading: false });

                    Notification(
                        'error',
                        'Ocorreu um erro ao atualizar o conector, tente novamente!'
                    );
                }
            }
        });
    };

    makePayload = (values, saveAs = false) => {
        const { format, name: connectorName, scope, type, template } = values;
        const payloadBase = {
            connectorName,
            type,
            scope,
            format,
            template,
        };

        console.log(template);
        switch (template) {
            case 'wdna': {
                const { appId, token } = values;
                const params = {
                    appId,
                    token,
                };

                delete payloadBase.format;
                const cannotChangeType = ability.cannot('update-with-type', {
                    type,
                    __type: 'Connector',
                });

                if (cannotChangeType) {
                    payloadBase.type = 'custom';
                }

                return Object.assign(payloadBase, { params });
            }
            case 'bling': {
                const { apikey } = values;
                const params = {
                    apikey,
                };

                delete payloadBase.format;
                const cannotChangeType = ability.cannot('update-with-type', {
                    type,
                    __type: 'Connector',
                });

                if (cannotChangeType) {
                    payloadBase.type = 'custom';
                }

                return Object.assign(payloadBase, { params });
            }
            case 'custom': {
                const { callbackOptions, mappingSourceFields } = this.state;
                const { email, urlCallback } = values;
                const fields = mappingSourceFields
                    .filter((field) => {
                        return Boolean(field.status);
                    })
                    .map((field, index) => {
                        return {
                            order: index + 1,
                            source: isTempKey(field.key) ? null : field.key,
                            target: field.target || null,
                            separator: field.separator || null,
                            value: field.value || null,
                            status: Boolean(field.status),
                        };
                    });
                const mapping = {
                    type: 'custom',
                    fields,
                };
                const notifications = [];

                if (email) {
                    notifications.push({ type: 'email', value: email });
                }

                if (urlCallback) {
                    notifications.push({
                        type: 'callback',
                        value: urlCallback,
                        options: Object.assign(JSON.parse(callbackOptions), { method: 'post' }),
                    });
                }

                const cannotChangeType = ability.cannot('update-with-type', {
                    type,
                    __type: 'Connector',
                });

                if (cannotChangeType) {
                    payloadBase.type = 'custom';
                }

                return Object.assign(payloadBase, { mapping, notifications });
            }
            default:
                const fields = this.state.mappingSourceFields
                    .filter((field) => {
                        return Boolean(field.status);
                    })
                    .map((field, index) => {
                        return {
                            order: index + 1,
                            source: field.key,
                            target: field.target,
                            status: Boolean(field.status),
                        };
                    });
                const mapping = {
                    type,
                    fields,
                };

                return Object.assign(payloadBase, { mapping });
        }
    };

    transformMappingSourceFields = (connector, mappingSourceFields) => {
        if (connector?.mapping?.fields && utils.hasItemsInArray(connector.mapping.fields)) {
            const mappingSourceFieldsTransformed = mappingSourceFields.map((field) => {
                connector.mapping.fields.forEach((connectorField) => {
                    if (connectorField.source === field.key) {
                        field.order = connectorField.order;
                        field.target = connectorField.target;
                        field.status = connectorField.status;
                        field.separator = connectorField.separator;
                    }
                });

                return field;
            });
            const withOrder = mappingSourceFieldsTransformed
                .concat(connector.mapping.fields
                    .filter((connectorField) => {
                        return !connectorField.source;
                    })
                    .map((connectorField) => {
                        const key = generateTempKey();
                        return { ...connectorField, key };
                    })
                )
                .filter((field) => {
                    return field.order;
                })
                .sort((fieldA, fieldB) => {
                    return fieldA.order - fieldB.order;
                });
            const withoutOrder = mappingSourceFieldsTransformed.filter((field) => {
                return !field.order;
            });

            return withOrder.concat(withoutOrder);
        }

        return mappingSourceFields;
    };

    moveRow = (dragIndex, hoverIndex) => {
        const { mappingSourceFields } = this.state;
        const dragRow = mappingSourceFields[dragIndex];

        this.setState(
            update(this.state, {
                mappingSourceFields: {
                    $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
                },
            })
        );
    };

    saveAsModal = () => {
        this.setState({ showSaveAs: true });
    };

    handleTemplate = (template) => {
        if (template === 'wdna') {
            this.props.form.setFieldsValue({ format: 'xml' });
        }

        this.setState((prevState) => {
            return {
                connector: {
                    ...prevState.connector,
                    template,
                },
            };
        });
    };

    changeField = (fieldName, isArray = false, index) => {
        return (event) => {
            const { name, value } = event.target;

            this.setState((prevState) => {
                return {
                    mappingSourceFields: prevState.mappingSourceFields.map((field) => {
                        if (field.key === name) {
                            return this.updateField(
                                field,
                                fieldName,
                                isArray,
                                index,
                                value
                            );
                        }

                        return field;
                    }),
                };
            });
        };
    }

    updateField = (...args) => {
        const [field, fieldName, isArray, index, newValue] = args;

        return isArray
            ? this.updateArrayField(field, fieldName, index, newValue)
            : this.updateSingleField(field, fieldName, newValue);
    }

    updateSingleField = (field, fieldName, newValue) => {
        return {
            ...field,
            [fieldName]: newValue,
        };
    }

    updateArrayField = (field, fieldName, index, newValue) => {
        const newValues = (field[fieldName] || new Array(field.separatorQuantity).fill(''))
            .map((oldValue, _index) => {
                return _index === index
                    ? newValue
                    : oldValue;
            });

        return {
            ...field,
            [fieldName]: newValues,
        };
    }

    handleSwitch(element, state) {
        this.setState((prevState) => {
            return {
                mappingSourceFields: prevState.mappingSourceFields.map((field) => {
                    if (field.key === element.props.name) {
                        return {
                            ...field,
                            status: state,
                        };
                    }

                    return field;
                }),
            };
        });
    }

    handleCallbackModal = () => {
        this.setState((state) => {
            return { callbackModal: !state.callbackModal };
        });
    };

    handleCustomFieldAdd = () => {
        this.setState((prevState) => {
            const newField = { key: generateTempKey() };
            const mappingSourceFields = [newField, ...prevState.mappingSourceFields];
            return { ...prevState, mappingSourceFields };
        });
    }

    handleCustomFieldDel = (key) => {
        this.setState((prevState) => {
            const mappingSourceFields = prevState.mappingSourceFields.filter((field) => {
                return field.key !== key;
            });
            return { ...prevState, mappingSourceFields };
        });
    }

    changeCallbackOptions = (value) => {
        this.setState({ callbackOptions: value });
    };

    confirmCallbackModal = () => {
        try {
            const { callbackOptions } = this.state;
            if (callbackOptions?.trim()?.length > 0) {
                JSON.parse(callbackOptions);
            }

            this.handleCallbackModal();
        } catch (error) {
            Notification('error', 'Existem erros de sintaxe, verifique!');
        }
    };

    getNotificationValue = (connector, notificationType) => {
        const notification = connector?.notifications?.find((notification) => {
            return notification.type === notificationType;
        });

        return notification?.value;
    };

    transformColumns = () => {
        return [
            {
                title: 'Ordem',
                key: 'order',
                dataIndex: 'order',
                width: '10%',
                align: 'left',
                render: () => {
                    return <FaSort />;
                },
            },
            {
                title: 'Campo de Origem',
                key: 'source',
                dataIndex: 'source',
                width: '35%',
                align: 'left',
                render: (source) => {
                    const field = isTempKey(source) ? 'NENHUM' : source;

                    if (!fieldsDescriptions[field]) {
                        return (
                            <Row>
                                <Col sm={24}>
                                    {field}
                                </Col>
                            </Row>
                        );
                    }

                    return (
                        <Row>
                            <Col sm={22}>
                                {field}
                            </Col>
                            <Col sm={2}>
                                <Tooltip title={fieldsDescriptions[field]}>
                                    <Icon
                                        type="question-circle"
                                        style={{ cursor: 'pointer' }}
                                    />
                                </Tooltip>
                            </Col>
                        </Row>
                    );
                },
            },
            {
                title: 'Campo de Destino',
                key: 'target',
                dataIndex: 'target',
                width: '40%',
                align: 'left',
                render: (field) => {
                    if (isTempKey(field.key)) {
                        return (
                            <Row gutter={16}>
                                <Col sm={24} md={15}>
                                    <Input
                                        name={field.key}
                                        value={field.target}
                                        onChange={this.changeField('target')}
                                    />
                                </Col>
                                <Col sm={22} md={7}>
                                    <Input
                                        name={field.key}
                                        value={field.value}
                                        onChange={this.changeField('value')}
                                        placeholder="Valor padrão"
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Icon
                                        type="delete"
                                        style={{ cursor: 'pointer', padding: '9px 0px' }}
                                        onClick={() => {
                                            this.handleCustomFieldDel(field.key);
                                        }}
                                    />
                                </Col>
                            </Row>
                        );
                    }

                    if (!field.useSeparator) {
                        return (
                            <Input
                                name={field.key}
                                onChange={this.changeField('target')}
                                value={field.target}
                            />
                        );
                    }

                    const separatorFields = new Array(field.separatorQuantity).fill(0).map((_, index) => {
                        return (
                            <Col sm={24} md={field.separatorQuantity === 1 ? 8 : 7}>
                                <Input
                                    name={field.key}
                                    value={
                                        Array.isArray(field.separator)
                                            ? field.separator[index]
                                            : field.separator
                                    }
                                    onChange={this.changeField('separator', (field.separatorQuantity > 1), index)}
                                    placeholder="Separador"
                                />
                            </Col>
                        );
                    });

                    return (
                        <Row gutter={16}>
                            <Col sm={24} md={field.separatorQuantity === 1 ? 16 : 10}>
                                <Input
                                    name={field.key}
                                    value={field.target}
                                    onChange={this.changeField('target')}
                                />
                            </Col>
                            { separatorFields }
                        </Row>
                    );
                },
            },
            {
                title: 'Utilizar Campo',
                key: 'useField',
                dataIndex: 'useField',
                align: 'right',
                width: '15%',
                render: (field) => {
                    return (
                        <Switch
                            defaultValue={false}
                            labelText="Utilizar campo"
                            name={field.key}
                            offText="Não"
                            onChange={(el, state) => {
                                return this.handleSwitch(el, state);
                            }}
                            onText="Sim"
                            value={Boolean(field.status)}
                        />
                    );
                },
            },
        ];
    };

    transformData = () => {
        const { mappingSourceFields } = this.state;
        const data = mappingSourceFields.map((field) => {
            return {
                source: field.key,
                target: field,
                useField: field,
            };
        });

        return data;
    };

    render() {
        const { callbackOptions, connector, loading, showSaveAs, submitLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        const columns = this.transformColumns();
        const data = this.transformData();

        const templateOptions = connectorTemplates.find((connectorTemplate) => {
            return connectorTemplate.value === connector.template;
        });
        const connectorFormatsOptions = connectorFormats.filter((connectorFormat) => {
            return templateOptions?.formats.includes(connectorFormat.value);
        });

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={paths.connectors}>Conectores</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Editar</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <Form onSubmit={this.update} layout="vertical">
                        <TitlePage
                            title="Conectores"
                            extraTitle={connector?.connectorName}
                            subtitle="Faça o mapeamento de campos para este conector."
                            actions={
                                <Fragment>
                                    <Can
                                        do="save"
                                        this={Object.assign(connector, { __type: 'Connector' })}
                                    >
                                        {connector.template === 'custom' && (
                                            <Button
                                                type="primary"
                                                icon="plus"
                                                htmlType="button"
                                                style={{ marginRight: 10 }}
                                                onClick={this.handleCustomFieldAdd}
                                            >
                                                Criar campo
                                            </Button>
                                        )}
                                        <Button
                                            icon="save"
                                            htmlType="submit"
                                            loading={submitLoading}
                                            style={{ marginRight: 10 }}
                                            type="primary"
                                        >
                                           Salvar
                                        </Button>
                                    </Can>
                                    <Button type="primary" icon="plus" onClick={this.saveAsModal}>
                                        Salvar Como
                                    </Button>
                                </Fragment>
                            }
                        />
                        <SaveAsModal
                            visible={showSaveAs}
                            saveAs={this.saveAs}
                            onCancel={() => {
                                return this.setState((prevState) => {
                                    return {
                                        showSaveAs: !prevState.showSaveAs,
                                    };
                                });
                            }}
                        />
                        <div className="connectors-form">
                            <Row gutter={16}>
                                <Col sm={24} md={10}>
                                    <FormItem>
                                        <label htmlFor="name">Nome</label>
                                        {getFieldDecorator('name', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Insira o nome do conector!',
                                                },
                                            ],
                                            initialValue: connector?.connectorName,
                                        })(<Input disabled={!!(connector.type && ability.cannot('save', { type: connector.type, __type: 'Connector' }))}/>)}
                                    </FormItem>
                                </Col>
                                <Can
                                    do="edit-with-type"
                                    this={Object.assign(connector, { __type: 'Connector' })}
                                >
                                    <Col sm={24} md={5}>
                                        <FormItem>
                                            <label htmlFor="type">Tipo</label>
                                            {getFieldDecorator('type', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Selecione o tipo do conector!',
                                                    },
                                                ],
                                                initialValue: connector?.type,
                                            })(
                                                <Select>
                                                    {connectorTypes.map(({ label, value }) => {
                                                        return (
                                                            <Option key={value} value={value}>{label}</Option>
                                                        );
                                                    })}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Can>
                                <Can
                                    do="edit-with-template"
                                    this={Object.assign(connector, { __type: 'Connector' })}
                                >
                                    <Col sm={24} md={5}>
                                        <FormItem>
                                            <label htmlFor="template">Template</label>
                                            {getFieldDecorator('template', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                            'Selecione o template do conector!',
                                                    },
                                                ],
                                                initialValue: connector?.template,
                                            })(
                                                <Select onChange={this.handleTemplate}>
                                                    {connectorTemplates.map(({ label, value }) => {
                                                        return (
                                                            <Option key={value} value={value}>{label}</Option>
                                                        );
                                                    })}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Can>
                            </Row>
                            <Row gutter={16}>
                                <Col sm={24} md={10}>
                                    <FormItem>
                                        <label>Conteúdo</label>
                                        {getFieldDecorator('scope', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Selecione o conteúdo!',
                                                },
                                            ],
                                            initialValue: connector?.scope,
                                        })(
                                            <Select>
                                                {connectorScopes.map((scope) => {
                                                    const disabled = scope.value === 'order';
                                                    return (
                                                        <Option
                                                            key={scope.value}
                                                            disabled={disabled}
                                                            value={scope.value}
                                                        >
                                                            {scope.label}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                                <Can
                                    do="edit-with-format"
                                    this={Object.assign(connector, { __type: 'Connector' })}
                                >
                                    <Col sm={24} md={10}>
                                        <FormItem>
                                            <label htmlFor="format">Formato</label>
                                            {getFieldDecorator('format', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Selecione o formato!',
                                                    },
                                                ],
                                                initialValue: templateOptions?.formats.includes(connector?.format)
                                                    ? connector?.format
                                                    : connectorFormatsOptions?.[0]?.value,
                                            })(
                                                <Select>
                                                    {connectorFormatsOptions.map((format) => {
                                                        return (
                                                            <Option
                                                                key={format.value}
                                                                value={format.value}
                                                            >
                                                                {format.label}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Can>
                            </Row>
                            {connector.template === 'wdna' && (
                                <Row gutter={16}>
                                    <Col sm={24} md={10}>
                                        <FormItem>
                                            <label for="appId">AppId</label>
                                            {getFieldDecorator('appId', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Insira o appId',
                                                    },
                                                ],
                                                initialValue: connector?.params?.appId,
                                            })(<Input />)}
                                        </FormItem>
                                    </Col>
                                    <Col sm={24} md={10}>
                                        <FormItem>
                                            <label for="token">Token</label>
                                            {getFieldDecorator('token', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Insira o token',
                                                    },
                                                ],
                                                initialValue: connector?.params?.token,
                                            })(<Input />)}
                                        </FormItem>
                                    </Col>
                                </Row>
                            )}
                            {connector.template === 'bling' && (
                                <Row gutter={16}>
                                    <Col sm={24} md={10}>
                                        <FormItem>
                                            <label htmlFor="apikey">API key</label>
                                            {getFieldDecorator('apikey', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Insira a API key',
                                                    },
                                                ],
                                                initialValue: connector?.params?.apikey,
                                            })(<Input />)}
                                        </FormItem>
                                    </Col>
                                </Row>
                            )}
                            {connector.template === 'custom' && (
                                <Fragment>
                                    <Row gutter={16}>
                                        <Col sm={24} md={10}>
                                            <FormItem>
                                                <label>Notificações de exportação</label>
                                                <InputGroup compact>
                                                    {getFieldDecorator('email', {
                                                        initialValue: this.getNotificationValue(
                                                            connector,
                                                            'email'
                                                        ),
                                                    })(
                                                        <Input
                                                            addonBefore="Email"
                                                            // disabled={!this.state.emailCheck}
                                                        />
                                                    )}
                                                </InputGroup>
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={10}>
                                            <FormItem>
                                                <label>&nbsp;</label>
                                                <InputGroup compact>
                                                    {getFieldDecorator('urlCallback', {
                                                        initialValue: this.getNotificationValue(
                                                            connector,
                                                            'callback'
                                                        ),
                                                    })(
                                                        <Input
                                                            addonAfter={
                                                                <Icon
                                                                    onClick={
                                                                        this.handleCallbackModal
                                                                    }
                                                                    type="question-circle"
                                                                    theme="filled"
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                            }
                                                            addonBefore="URL de callback"
                                                        />
                                                    )}
                                                </InputGroup>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Loading loading={loading}>
                                        <Table
                                            columns={columns}
                                            dataSource={data}
                                            components={this.components}
                                            onRow={(record, index) => {
                                                return {
                                                    index,
                                                    moveRow: this.moveRow,
                                                };
                                            }}
                                            pagination={false}
                                        />
                                    </Loading>
                                </Fragment>
                            )}
                        </div>
                        <Modal
                            title="Informações sobre notificação via callback"
                            visible={this.state.callbackModal}
                            onCancel={this.handleCallbackModal}
                            footer={
                                <Button type="primary" onClick={this.confirmCallbackModal}>
                                    Confirmar
                                </Button>
                            }
                        >
                            <p>
                                Quando o processo de exportação finalizar, será feito uma requisição
                                para a URL configurada. Está requisição será feita com o verbo HTTP{' '}
                                <strong>POST</strong> e utilizará os parâmetros do editor.{' '}
                                <strong>OBS: </strong>chave "file" é obrigatória para receber a url
                                da importação
                            </p>
                            <AceEditor
                                mode="json"
                                theme="github"
                                onChange={this.changeCallbackOptions}
                                value={callbackOptions}
                                name="json-texteditor-callback"
                            />
                        </Modal>
                    </Form>
                </div>
            </Fragment>
        );
    }
}

const WrappedEdit = Form.create()(Edit);
export default DragDropContext(HTML5Backend)(WrappedEdit);
