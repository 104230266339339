import React from 'react';
import { Table, Checkbox } from 'antd';
// import { weekdays } from 'moment';

const weekDays = [
    { name: 'HORÁRIO', shortName: 'schedule' },
    { name: 'DOMINGO', shortName: 'SUN' },
    { name: 'SEGUNDA', shortName: 'MON' },
    { name: 'TERÇA', shortName: 'TUE' },
    { name: 'QUARTA', shortName: 'WED' },
    { name: 'QUINTA', shortName: 'THU' },
    { name: 'SEXTA', shortName: 'FRI' },
    { name: 'SÁBADO', shortName: 'SAT' },
];

const ScheduleTable = (props) => {
    const getSelectedHour = () => {
        return props.form.getFieldValue('hour');
    };

    // const getSelectedRow = () => {
    //     return props.form.getFieldValue('schedules');
    // }

    const getSupplierSchedule = (scheduleId) => {
        const schedule = props.supplierSchedule.find((schedule) => {
            return schedule.id === scheduleId;
        });

        return schedule;
    };

    const getAvailableDays = (scheduleId) => {
        const supplierSchedule = getSupplierSchedule(scheduleId);
        const availableDays = weekDays.map((weekDay) => {
            const available = supplierSchedule.days.find((scheduleDay) => {
                return scheduleDay === weekDay.shortName;
            });

            weekDay.available = Boolean(available);

            return weekDay;
        });

        return availableDays;
    };

    const isChecked = (dayShortName) => {
        const { checkedDays } = props;

        const day = checkedDays.find((daySelected) => {
            return daySelected === dayShortName;
        });
        return Boolean(day);
    };

    const generateColumns = () => {
        const { getFieldDecorator } = props.form;
        const columns = weekDays.map((day, index) => {
            if (index === 0) {
                return {
                    align: 'left',
                    title: day.name,
                    dataIndex: day.shortName,
                    key: day.shortName,
                    width: 200,
                };
            }

            return {
                title: day.name,
                dataIndex: day.shortName,
                key: day.shortName,
                render: ({ shortName, schedule, available, multiple }) => {
                    if (multiple) {
                        return getFieldDecorator(`schedules[${schedule}][${shortName}]`, {
                            initialValue: props.form.getFieldValue(
                                `schedules[${schedule}][${shortName}]`
                            ),
                        })(
                            <Checkbox
                                defaultChecked
                                checked={props.form.getFieldValue(
                                    `schedules[${schedule}][${shortName}]`
                                )}
                                disabled={!available}
                            />
                        );
                    }

                    return (
                        <Checkbox
                            checked={isChecked(shortName)}
                            disabled={!available}
                            name={shortName}
                            onChange={props.handleScheduleDay(shortName)}
                        />
                    );
                },
                width: 120,
            };
        });

        return columns;
    };

    const generateData = () => {
        if (props.multiple) {
            const data = props.supplierSchedule.map((schedule) => {
                const row = getAvailableDays(schedule.id).reduce((days, currentDay) => {
                    if (!Object.keys(days).length) {
                        days['key'] = schedule.id;
                        days['schedule'] = `Entre ${schedule.startTime} e ${schedule.endTime}`;

                        return days;
                    }

                    days[currentDay.shortName] = {
                        shortName: currentDay.shortName,
                        schedule: schedule.id,
                        available: currentDay.available,
                        multiple: true,
                    };

                    return days;
                }, {});

                return row;
            });

            return data;
        }

        const schedule = getSupplierSchedule(getSelectedHour());
        const data = getAvailableDays(schedule.id).reduce((days, currentDay) => {
            if (!Object.keys(days).length) {
                days['key'] = schedule.id;
                days['schedule'] = `Entre ${schedule.startTime} e ${schedule.endTime}`;

                return days;
            }

            days[currentDay.shortName] = {
                shortName: currentDay.shortName,
                schedule: schedule.id,
                available: currentDay.available,
            };

            return days;
        }, {});

        return [data];
    };

    const columns = generateColumns();
    const data = generateData();

    return (
        <div className="schedule-table" style={{ overflow: 'auto' }}>
            <Table
                {...props.rowSelection && { rowSelection: props.rowSelection }}
                columns={columns}
                dataSource={data}
                id="table-responsive"
                pagination={false}
                style={{ margin: '20px 0' }}
            />
        </div>
    );
};

export default ScheduleTable;

ScheduleTable.defaultProps = {
    checkedDays: [],
};
