import React from 'react';
import antd from 'antd';
import styled from 'styled-components';

const Input = styled(antd.InputNumber)`
    .ant-input-number-handler-wrap {
        display: none;
    }
`;

const InputNumber = function (props, ref) {
    return (
        <Input {...props} ref={ref}/>
    );
};

export default React.forwardRef(InputNumber);
