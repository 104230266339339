import React, { Component } from 'react';
import { Tag } from 'antd';

class HeaderInfo extends Component {
    render() {
        const { actions, showing, total } = this.props;

        return (
            <div className='supplier-products-information'>
                <div className='details'>
                    {actions}
                </div>
                <div style={{ margin: '9px 0' }}>
                    <Tag>Exibindo: {showing} de {total}</Tag>
                </div>
            </div>
        );
    }
}

export default HeaderInfo;