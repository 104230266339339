import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50vh;

    i {
        font-size: 48px;
    }
`;

const Description = styled.p`
    font-size: 18px;
    margin: 20px 0;

    a {
        font-weight: bold;
    }
`;

const ErrorLoading = (props) => {
    /* eslint-disable */
    return (
        <Wrapper>
            <Icon type="frown" />
            <Description>
                Falha ao buscar dados, clique{' '}
                <a href="Javascript:Void(0)" onClick={props.onClick}>
                    aqui{' '}
                </a>
                para tentar novamente!
            </Description>
        </Wrapper>
    );
    /* eslint-enable */
};

export default ErrorLoading;
