import update from 'immutability-helper';

export const loadMore = async function(field, fetchMore, callback) {
    try {
        const { pagination } = this.state[field];
        const option = {
            ...(pagination.next && {
                page: pagination.next,
            }),
        };

        this.setState((state) => {
            return {
                [field]: {
                    ...state[field],
                    hasMore: false,
                },
                loading: true,
            };
        });

        const data = await fetchMore(option);

        this.setState(
            (state) => {
                return {
                    [field]: update(state[field], {
                        hasMore: { $set: Boolean(data?.pagination?.next) },
                        pagination: { $set: data.pagination },
                        result: { $push: data.result },
                        total: { $set: data.total },
                    }),
                    loading: false,
                };
            },
            typeof callback === 'function' ? callback : () => {}
        );
    } catch (error) {
        console.log(`ERROR loadMore: ${field}`, error);
    }
};
