import { get, post } from '~/services/api/rest/client';

export const PaymentAPI = {
    getSession: () => {
        return get('/payments/session');
    },
    bankBillet: (data) => {
        return post('/payments/bank-billet', data);
    },
    onlineDebit: (data) => {
        return post('/payments/online-debit', data);
    },
    creditCard: (data) => {
        return post('/payments/credit-card', data);
    },
    signIn: (data) => {
        return post('/payments/sign-in', data);
    },
    getInfo: () => {
        return get('/payments/info');
    },
};
