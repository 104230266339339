import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Tag, Tooltip } from 'antd';
import qs from 'query-string';
import { FaCheck, FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

import Notification from '~/components/Notification/AntNotification';
import { DeleteConfirm } from '~/components/Confirm';

import { UserAPI } from '~/lib/api';
import { loadMore } from '~/lib/InfiniteScroll';
import { handleSort } from '~/lib/Sort';

export async function getAndSetInitialData(refresh) {
    try {
        if (refresh) {
            this.setState({
                error: false,
                loading: true,
            });
        }

        const { search } = this.props.location;
        const fetchUsersFromUserpool = await UserAPI.adminFetchUser(search);

        this.setState((prevState) => {
            return {
                loading: false,
                fetchUsersFromUserpool: {
                    ...prevState.fetchUsersFromUserpool,
                    ...fetchUsersFromUserpool,
                    hasMore: Boolean(fetchUsersFromUserpool.pagination.next),
                },
            };
        });
    } catch (error) {
        console.log('ERROR getAndSetInitialData: ', error);

        this.setState({
            error: true,
            loading: false,
        });
    }
}

async function fetchUsersFromUserpool(options = {}) {
    try {
        const search = this?.props?.location?.search;
        const query = Object.assign(options, qs.parse(search));
        const users = await UserAPI.adminFetchUser(query);

        return users;
    } catch (error) {
        console.log('ERROR fetchUsersFromUserpool: ', error);

        throw error;
    }
}

export function handleUserSort(pagination, filters, sorter) {
    const handleSortScoped = handleSort.bind(this);

    return handleSortScoped(sorter, getAndSetInitialData.bind(this));
}

export function loadMoreUsers() {
    const loadMoreScoped = loadMore.bind(this);

    return loadMoreScoped('fetchUsersFromUserpool', fetchUsersFromUserpool.bind(this));
}

async function adminConfirmSignUp(user) {
    const username = user.userName;

    try {
        await UserAPI.adminConfirmSignUp({ username });

        this.setState((prevState) => {
            return {
                users: prevState.fetchUsersFromUserpool.result.map((prevUser) => {
                    if (prevUser.userName === username) {
                        prevUser.status = 'CONFIRMED';
                    }

                    return prevUser;
                }),
            };
        });

        Notification('success', `Usuário '${user.email}' confirmado!`);
    } catch (error) {
        console.log('ERROR adminConfirmSignUp: ', error);

        Notification(
            'error',
            `Ocorreu um erro ao confirmar usuário '${user.email}', tente novamente!`
        );
    }
}

async function adminEnableUser(user) {
    const username = user.userName;

    try {
        await UserAPI.adminEnableUser({ username });

        this.setState((prevState) => {
            return {
                users: prevState.fetchUsersFromUserpool.result.map((prevUser) => {
                    if (prevUser.userName === username) {
                        prevUser.enabled = true;
                    }

                    return prevUser;
                }),
            };
        });

        Notification('success', `Usuário '${user.email}' ativado!`);
    } catch (error) {
        console.log('ERROR adminEnableUser: ', error);

        Notification(
            'error',
            `Ocorreu um erro ao ativar usuário '${user.email}', tente novamente!`
        );
    }
}

async function adminDisableUser(user) {
    const username = user.userName;

    try {
        await UserAPI.adminDisableUser({ username });

        this.setState((prevState) => {
            return {
                users: prevState.fetchUsersFromUserpool.result.map((prevUser) => {
                    if (prevUser.userName === username) {
                        prevUser.enabled = false;
                    }

                    return prevUser;
                }),
            };
        });

        Notification('success', `Usuário '${user.email}' inativado!`);
    } catch (error) {
        console.log('ERROR adminDisableUser: ', error);

        Notification(
            'error',
            `Ocorreu um erro ao inativar usuário '${user.email}', tente novamente!`
        );
    }
}

export function transformColumns() {

    const selfURL = window.location.pathname + window.location.search;

    const columns = [
        {
            align: 'left',
            dataIndex: 'id',
            key: 'id',
            title: 'ID Grid2B',
            hidden: true,
        },
        {
            align: 'center',
            dataIndex: 'email',
            key: 'email',
            title: 'Email',
        },
        {
            align: 'center',
            dataIndex: 'createdAt',
            type: 'date',
            sorter: true,
            hidden: true,
            key: 'createdAt',
            title: 'Criado',
        },
        {
            align: 'center',
            dataIndex: 'updatedAt',
            type: 'date',
            sorter: true,
            hidden: true,
            key: 'updatedAt',
            title: 'Atualizado',
        },
        {
            align: 'center',
            dataIndex: 'status',
            type: 'select',
            options: [
                { value: 'confirmed', name: 'Confirmado' },
                { value: 'unconfirmed', name: 'Não confirmado' },
            ],
            key: 'status',
            title: 'Status',
            render: (statusObj: { enabled: string, status: string }) => {
                const status = {};

                switch (statusObj.status) {
                    case 'EXTERNAL_PROVIDER':
                        status.color = 'blue';
                        status.name = 'Provedor externo';
                        break;
                    case 'CONFIRMED':
                        status.color = 'green';
                        status.name = 'Confirmado';
                        break;
                    case 'UNCONFIRMED':
                    default:
                        status.color = 'red';
                        status.name = 'Não Confirmado';
                        break;
                }

                return (
                    <Fragment>
                        {statusObj.enabled ? 'Ativo' : 'Inativo'} |{' '}
                        <Tag color={status.color}>{status.name}</Tag>
                    </Fragment>
                );
            },
        },
        {
            align: 'right',
            title: '',
            dataIndex: 'actions',
            hidden: true,
            render: (user: User) => {
                const actions = [];

                if (user.status === 'UNCONFIRMED') {
                    actions.push(
                        <Tooltip key="confirm" title="Confirmar">
                            <Link
                                to={selfURL}
                                onClick={() => {
                                    return DeleteConfirm(
                                        () => {
                                            return adminConfirmSignUp.bind(this)(user);
                                        },
                                        {
                                            title: `Deseja realmente confirmar o cadastro do usuário '${
                                                user.userName
                                            }' ?`,
                                        }
                                    );
                                }}
                                style={{ fontSize: 18 }}
                            >
                                <FaCheck />
                            </Link>
                        </Tooltip>
                    );
                }

                if (user.enabled) {
                    actions.push(
                        <Tooltip key="inactivate" title="Inativar">
                            <Link
                                to={selfURL}
                                onClick={() => {
                                    return DeleteConfirm(
                                        () => {
                                            return adminDisableUser.bind(this)(user);
                                        },
                                        {
                                            title: `Deseja realmente inativar o cadastro do usuário '${user.email}' ?`,
                                        }
                                    );
                                }}
                                style={{
                                    fontSize: 18,
                                    marginLeft: 10,
                                }}
                            >
                                <FaThumbsDown />
                            </Link>
                        </Tooltip>
                    );
                } else {
                    actions.push(
                        <Tooltip key="activate" title="Ativar">
                            <Link
                                to={selfURL}
                                onClick={() => {
                                    return DeleteConfirm(
                                        () => {
                                            return adminEnableUser.bind(this)(user);
                                        },
                                        {
                                            title: `Deseja realmente ativar o cadastro do usuário '${user.email}' ?`,
                                        }
                                    );
                                }}
                                style={{
                                    fontSize: 18,
                                    marginLeft: 10,
                                }}
                            >
                                <FaThumbsUp />
                            </Link>
                        </Tooltip>
                    );
                }

                return actions;
            },
        },
    ];

    return columns;
}

export function transformData() {
    const { fetchUsersFromUserpool } = this.state;
    const data = fetchUsersFromUserpool.result.map((user) => {
        return {
            key: user.id,
            id: user.id,
            username: user.userName,
            email: user.email,
            createdAt: new Date(user.createdAt).toLocaleString(),
            updatedAt: user.updatedAt && new Date(user.updatedAt).toLocaleString(),
            status: {
                enabled: user.enabled,
                status: user.status,
            },
            actions: user,
        };
    });

    return data;
}
