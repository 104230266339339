import React from 'react';
import moment from 'moment';
import qs from 'query-string';
import { Form, Input, Select } from 'antd';
import InputMask from 'react-input-mask';
import 'moment/locale/pt-br';

const { Item: FormItem } = Form;
const { Option } = Select;

moment.locale('pt-br');
const ptBR = moment();

moment.locale('en-us');
const enUS = moment();

const utils = {
    days: () => {
        const ptBRShortDays = ptBR.localeData().weekdaysShort();
        const ptBRDays = ptBR.localeData().weekdays();
        const enUSShortDays = enUS.localeData().weekdaysShort();

        const days = ptBRDays.map((day, index) => {
            const d = {
                order: index,
                day: day.toUpperCase(),
                shortDay: ptBRShortDays[index].toUpperCase(),
                shortDayEn: enUSShortDays[index].toUpperCase(),
            };

            return d;
        });

        return days;
    },
    months: (() => {
        return ptBR
            .localeData()
            .months()
            .map((month, index) => {
                return {
                    value: `${index + 1 < 10 ? 0 : ''}${index + 1}`,
                    name: `${`${index + 1 < 10 ? 0 : ''}${index + 1}`} - ${month.replace(
                        /^\w/,
                        (chr) => {
                            return chr.toUpperCase();
                        }
                    )}`,
                };
            });
    })(),
    years: (() => {
        const year = new Date().getFullYear();
        return Array.apply(null, new Array(11)).map((_, index) => {
            return {
                value: year + index,
                name: year + index,
            };
        });
    })(),
    toCurrency: (value, currency = 'BRL') => {
        return value.toLocaleString('pt-BR', { style: 'currency', currency });
    },
    timeZone: (datetime) => {
        return moment(datetime).format('DD/MM/YY HH:MM:SS');
    },
    capitalize: (word) => {
        const wordDowncased = word.toLowerCase();
        if (wordDowncased) {
            return wordDowncased.replace(/^\w/, (chr) => {
                return chr.toUpperCase();
            });
        }

        return '';
    },
    transformFieldName: (fieldName = '') => {
        return fieldName.split(/(?=[A-Z])/).reduce((prev, cur) => {
            if (prev === '') {
                return cur;
            }

            cur = `${prev} ${cur.toLowerCase()}`;
            return cur;
        }, '');
    },
    mapArrayToQuery: (array) => {
        const queryString = array.reduce((query, current) => {
            const searchKey = array.filter((search) => {
                return search.key === current.key;
            });
            const key = new RegExp(searchKey[0].key, 'g');

            if (query.match(key)) {
                return query;
            }

            if (searchKey.length > 1) {
                query += searchKey.reduce((prev, cur) => {
                    if (prev === '') {
                        if (cur.type === 'date') {
                            prev += `${query === '' ? '?' : '&'}${
                                current.key
                            }=${current.startValue.format('YYYY-MM-DD')}|${current.endValue.format(
                                'YYYY-MM-DD'
                            )}|`;
                            return prev;
                        }

                        prev += `${query === '' ? '?' : '&'}${cur.key}=${cur.term}`;
                        return prev;
                    }

                    if (cur.type === 'date') {
                        prev += `${cur.startValue.format('YYYY-MM-DD')}|${cur.endValue.format(
                            'YYYY-MM-DD'
                        )}`;
                        return prev;
                    }

                    prev += `|${cur.term}`;
                    return prev;
                }, '');

                return query;
            }

            if (current.type === 'date') {
                const startValue = current.startValue
                    ? current.startValue.format('YYYY-MM-DD')
                    : current.startValue;
                const endValue = current.endValue
                    ? current.endValue.format('YYYY-MM-DD')
                    : current.endValue;
                query += `${query === '' ? '?' : '&'}${current.key}=${startValue}|${endValue}`;
                return query;
            }

            query += `${query === '' ? '?' : '&'}${current.key}=${current.term}`;
            return query;
        }, '');

        return encodeURI(queryString);
    },
    orderBy: (a, b, column, date = false) => {
        if (date) {
            const date1 = moment(a[column], 'DD/MM/YYYY').format('MM/DD/YYYY');
            const date2 = moment(b[column], 'DD/MM/YYYY').format('MM/DD/YYYY');

            if (date1 > date2) return 1;
            if (date1 < date2) return -1;

            return 0;
        }

        if (b[column] < a[column]) return 1;
        if (b[column] > a[column]) return -1;

        return 0;
    },
    cpfValidator: (document) => {
        let Soma;
        let Resto;
        Soma = 0;
        if (document === '00000000000') return false;
        if (document === '11111111111') return false;
        if (document === '22222222222') return false;
        if (document === '33333333333') return false;
        if (document === '44444444444') return false;
        if (document === '55555555555') return false;
        if (document === '66666666666') return false;
        if (document === '77777777777') return false;
        if (document === '88888888888') return false;
        if (document === '99999999999') return false;
        if (document === '01234567890') return false;

        for (let i = 1; i <= 9; i++)
            Soma = Soma + parseInt(document.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if (Resto === 10 || Resto === 11) Resto = 0;
        if (Resto !== parseInt(document.substring(9, 10))) return false;

        Soma = 0;
        for (let i = 1; i <= 10; i++)
            Soma = Soma + parseInt(document.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if (Resto === 10 || Resto === 11) Resto = 0;
        if (Resto !== parseInt(document.substring(10, 11))) return false;
        return true;
    },
    cnpjValidator: (cnpj) => {
        let tamanho;
        let numeros;
        let digitos;
        let soma;
        let pos;
        let resultado;

        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj === '') return false;

        if (cnpj.length !== 14) return false;

        // Elimina CNPJs invalidos conhecidos
        if (
            cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999'
        )
            return false;

        tamanho = cnpj.length - 2;
        numeros = cnpj.substring(0, tamanho);
        digitos = cnpj.substring(tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado !== Number(digitos.charAt(0))) return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado !== Number(digitos.charAt(1))) return false;

        return true;
    },
    removeAccents(string = '') {
        return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    filterOptions: (input, option) => {
        const optionText = utils.removeAccents(option.props.children).toLowerCase();
        const inputText = utils.removeAccents(input).toLowerCase();

        return optionText.indexOf(inputText) >= 0;
    },
    hasItemsInArray: (array) => {
        return array.length > 0;
    },
    sort: (arr, sort = 'asc', property = null) => {
        return arr.sort((a, b) => {
            const greater = () => {
                return sort === 'asc' ? 1 : -1;
            };
            const less = () => {
                return sort === 'asc' ? -1 : 1;
            };

            if (!property) {
                if (a < b) return less();
                if (a > b) return greater();
            }

            if (a[property] < b[property]) return less();
            if (a[property] > b[property]) return greater();

            return 0;
        });
    },
    normalizeCategoryName: (categoryName = '') => {
        const category = categoryName.replace(/##/g, ' / ');

        return category;
    },
    stringifyQueryString: (filters) => {
        const parsed = typeof filters === 'object' ? filters : qs.parse(filters);
        const stringified = qs.stringify(parsed);

        return stringified;
    },
    renderFields: (fields = [], form, options = {}) => {
        const { getFieldDecorator } = form;

        return fields.map((field) => {
            switch (field.type) {
                case 'text':
                default: {
                    return (
                        <FormItem key={field.key} label={field.label} {...options.grid}>
                            {getFieldDecorator(`${field.key}`, {
                                rules: [
                                    {
                                        required: field.required,
                                        message: `Campo '${field.label}' não pode ser vazio!`,
                                    },
                                ],
                                initialValue: options[field.key],
                            })(
                                field.mask ? (
                                    <InputMask
                                        mask={field.mask}
                                        maskChar={null}
                                        disabled={false}
                                        type={field.type}
                                    >
                                        {(inputProps) => {
                                            return <Input {...inputProps} />;
                                        }}
                                    </InputMask>
                                ) : (
                                    <Input type={field.type} />
                                )
                            )}
                        </FormItem>
                    );
                }
                case 'select': {
                    return (
                        <FormItem key={field.key} label={field.label} {...options.grid}>
                            {getFieldDecorator(`${field.key}`, {
                                rules: [
                                    {
                                        required: field.required,
                                        message: `Selecione um ${field.label.toLowerCase()}.`,
                                    },
                                ],
                                initialValue: options[field.key],
                            })(
                                <Select
                                    filterOption={(input, option) => {
                                        return utils.filterOptions(input, option);
                                    }}
                                    optionFilterProp="children"
                                    showSearch
                                    style={{ width: '100%' }}
                                >
                                    {field.options.map((option, index) => {
                                        return (
                                            <Option key={index} value={option.value}>
                                                {option.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            )}
                        </FormItem>
                    );
                }
            }
        });
    },
    getSortOrder: (search) => {
        const { orderBy, sort } = qs.parse(search);
        const sortOrder = {
            column: orderBy,
            direction: sort === 'asc' ? 'ascend' : 'descend',
        };

        return sortOrder;
    },
    toFixed: (param) => {
        const isNumber = typeof param === 'number';
        const result = isNumber ? param : Number(param);
        return (result || 0).toFixed(2);
    },
};

export default utils;
