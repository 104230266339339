import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import qs from 'query-string';
import { decode } from 'he';
import debounce from 'debounce-promise';
import { Breadcrumb, Tooltip, Typography } from 'antd';

import DynamicFilter from '~/components/Filters/DynamicFilter';
import HeaderInfo from '~/components/Header/HeaderInfo';
import InfiniteScroll from '~/components/InfiniteScroller';
import Notification from '~/components/Notification/AntNotification';
import Table from './components/Table';
import { Avatar } from '~/components/Avatar';
import { Column } from '~/components/Column';
import { TitlePage } from '~/components/Header/TitlePage';

import ability from '~/ability';
import config from '~/config';
import utils from '~/lib/utils';
import { CatalogAPI, ProductAPI, SupplierAPI } from '~/lib/api';
import { handleSort } from '~/lib/Sort';
import { loadMore } from '~/lib/InfiniteScroll';

class Products extends Component {
    constructor() {
        super();
        this.state = {
            catalogItems: [],
            error: false,
            fetchSuppliers: {
                result: [],
            },
            fetchProductsData: {
                hasMore: true,
                pagination: {},
                result: [],
                total: 0,
            },
            loading: true,
            searchLoading: false,
            updateLoading: false,
        };

        this.onCatalogItemSearchDebounced = debounce(this.onCatalogItemSearch, 500);
    }

    async componentDidMount() {
        document.title = 'Grid2B | Produtos';

        this.getAndSetInitialData();
    }

    getAndSetInitialData = async (refresh = false) => {
        try {
            if (refresh) {
                this.setState({
                    error: false,
                    loading: true,
                });
            }

            const [fetchProductsData, fetchSuppliers, catalogItems] = await Promise.all([
                await this.fetchProducts(),
                await SupplierAPI.fetchSuppliers(),
                await CatalogAPI.fetchCatalogItems(config.catalog.G2B),
            ]);

            this.setState((state) => {
                return {
                    catalogItems: catalogItems.result,
                    fetchSuppliers,
                    fetchProductsData: {
                        ...state.fetchProductsData,
                        ...fetchProductsData,
                        hasMore: Boolean(fetchProductsData.pagination.next),
                    },
                    loading: false,
                };
            });
        } catch (error) {
            console.log('ERROR getAndSetInitialData: ', error);

            this.setState({
                error: true,
                loading: false,
            });
        }
    };

    prepareQueryString = (queryString, options = {}, sorter = true) => {
        const queryObject = qs.parse(queryString);

        if (
            !sorter &&
            (queryObject.title || queryObject.category || queryObject.reference)
        ) {
            delete queryObject.orderBy;
            delete queryObject.sort;
        }

        const filters = Object.assign({}, queryObject, options);
        const { page, ...filtersWithoutPage } = filters;

        this.props.history.push({
            search: qs.stringify(filtersWithoutPage),
        });

        return filters;
    }

    fetchProducts = (options = {}, sorter) => {
        const filters = this.prepareQueryString(this.props.location.search, options, sorter);

        return ProductAPI.fetchProducts(filters);
    };

    onSubmitSearch = async (_, sorter) => {
        this.setState({ loading: true });

        try {
            const data = await this.fetchProducts({}, sorter);

            this.setState({
                fetchProductsData: {
                    ...data,
                    hasMore: Boolean(data?.pagination?.next),
                },
                loading: false,
            });
        } catch (error) {
            Notification(
                'error',
                'Desculpe',
                'Ocorreu um erro em sua busca, verifique sua conexão com a internet e tente novamente',
                10
            );
        }
    }

    onCatalogItemSearch = async (filters = {}) => {
        try {
            const catalogItems = await CatalogAPI.fetchCatalogItems(config.catalog.G2B, filters);
            this.setState({ catalogItems: catalogItems.result });
        } catch (error) {
            console.error('ERROR onCatalogItemSearch: ', error);
        }
    };

    updateSupplierProduct = async (row, record) => {
        try {
            this.setState({ updateLoading: true });

            const { supplier, key } = row;
            const g2bCategoryId = record.category;
            const data = {
                categoriesGrid2b: [
                    {
                        categoryId: g2bCategoryId,
                        catalogId: config.catalog.G2B,
                    },
                ],
            };

            await ProductAPI.updateSupplierProduct(supplier.id, key, data);

            this.updateStateG2BCategoryProduct(key, g2bCategoryId);

            Notification('success', 'Categoria G2B atualizada!');
        } catch (error) {
            Notification('error', 'Ocorreu um erro ao atualizar categoria G2B!');
        } finally {
            this.setState({ updateLoading: false });
        }
    };

    updateStateG2BCategoryProduct = (reference, categoryId) => {
        const category = this.state.catalogItems.find((category) => {
            return category.categoryId === categoryId;
        });

        if (category) {
            category.main = true;

            const alterG2BCategoryProduct = (products) => {
                const updatedProducts = products.map((product) => {
                    if (product.reference === reference) {
                        return {
                            ...product,
                            categoriesGrid2b: [category],
                        };
                    }

                    return product;
                });

                return updatedProducts;
            };

            this.setState((prevState) => {
                return {
                    fetchProductsData: {
                        ...prevState.fetchProductsData,
                        result: alterG2BCategoryProduct(prevState.fetchProductsData.result),
                    },
                };
            });
        }
    };

    loadMoreProducts = () => {
        const loadMoreScoped = loadMore.bind(this);

        return loadMoreScoped('fetchProductsData', this.fetchProducts);
    };

    handleSort = (pagination, filters, sorter) => {
        const handleSortScoped = handleSort.bind(this);

        return handleSortScoped(sorter, this.getAndSetInitialData);
    };

    transformFilters = () => {
        const defaultFilters = this.transformColumns();
        const grid2bFilter = {
            title: 'Categoria Grid2B',
            key: 'categoriesGrid2b',
            type: 'select',
            options: [{ value: true, name: 'Sim' }, { value: false, name: 'Não' }],
        };

        if (ability.can('update', 'Product')) {
            defaultFilters.splice(4, 0, grid2bFilter);
        }

        return defaultFilters;
    };

    transformColumns = () => {
        const sortOrder = utils.getSortOrder(this.props.location.search);
        const suppliers = this.state.fetchSuppliers.result.map((supplier) => {
            return {
                value: supplier.id,
                name: supplier.name,
            };
        });

        const columns = [
            {
                align: 'center',
                title: 'Imagem',
                dataIndex: 'image',
                hidden: true,
                render: ({ imageMain, title }) => {
                    return (
                        <Avatar
                            shape="square"
                            size={48}
                            src={
                                (imageMain) ||
                                `${config.app.cdn}/dashboard/no_image_96x96.jpg`
                            }
                            alt={title}
                        />
                    );
                },
            },
            {
                title: 'Título',
                key: 'title',
                dataIndex: 'title',
                className: 'overflow',
                width: '30%',
                sorter: true,
                render: ({ title, grids = [] }) => {
                    const groupedGrids =  grids.map((grid) => {
                        return grid.value;
                    });

                    const productTitle = groupedGrids.length ? `${title} - ${groupedGrids.join(' | ')}` : title;

                    return <Tooltip title={productTitle}>{productTitle}</Tooltip>;
                },
            },
            {
                title: 'Referência',
                key: 'reference',
                dataIndex: 'reference',
            },
            {
                title: 'Categoria',
                key: 'category',
                dataIndex: 'category',
                className: 'overflow',
                width: '30%',
                sorter: true,
                editable: true,
                type: 'autocomplete',
                fieldOptions: {
                    autoFocus: true,
                    dataSource: this.state.catalogItems.map((category) => {
                        return {
                            value: category.categoryId,
                            text: utils.normalizeCategoryName(category.name),
                        };
                    }),
                    placeholder: 'Selecione uma categoria G2B',
                    onHandleSearch: this.onCatalogItemSearchDebounced,
                },
                render: ({ category, grid2bCategory }) => {
                    return (
                        <Column>
                            <Tooltip title={category}>{category}</Tooltip>
                            <Tooltip title={grid2bCategory.text}>
                                <Typography.Text code>G2B</Typography.Text>
                                {grid2bCategory.text || '...'}
                            </Tooltip>
                        </Column>
                    );
                },
            },
            {
                title: 'Adicionado',
                key: 'createdAt',
                dataIndex: 'createdAt',
                type: 'date',
                defaultSortOrder: 'descend',
                sorter: true,
                hidden: true,
            },
            {
                title: 'Distribuidor',
                key: 'supplier',
                dataIndex: 'supplier',
                alias: 'supplierId',
                type: 'select',
                options: suppliers,
                sorter: false,
                render: (supplier) => {
                    return supplier?.name;
                },
            },
        ];

        if (ability.can('update', 'Products')) {
            columns.push({
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                width: '5%',
                align: 'right',
                hidden: true,
            });
        }

        return columns.map((column) => {
            if (column.key === sortOrder.column) {
                return {
                    ...column,
                    sortOrder: sortOrder.direction,
                };
            }

            return column;
        });
    };

    transformData = () => {
        const {
            fetchSuppliers: { result: suppliers },
        } = this.state;
        const { result: products } = this.state.fetchProductsData;
        const data = products.map((product) => {
            const title = decode(product.title);
            const grid2bCategory = product.categoriesGrid2b?.find(({ main }) => {
                return main;
            });
            const grid2bCategoryNormalized = utils.normalizeCategoryName(grid2bCategory?.name);
            const supplier = suppliers.find((supplier) => {
                return supplier.id === product.supplierId;
            });

            return {
                key: product.reference,
                image: {
                    title: product.title,
                    imageMain: product.thumbnail,
                },
                title: {
                    title,
                    grids: product.grids,
                },
                reference: product.reference,
                category: {
                    category: utils.normalizeCategoryName(product.category),
                    grid2bCategory: {
                        value: grid2bCategory?.categoryId,
                        text: grid2bCategoryNormalized,
                    },
                },
                createdAt: moment(product.createdAt).format('DD/MM/YYYY'),
                supplier: {
                    id: supplier?.id,
                    name: supplier?.name,
                },
            };
        });

        return data;
    };

    render() {
        const { error, fetchProductsData, loading, updateLoading } = this.state;
        const { hasMore, total } = fetchProductsData;
        const filters = this.transformFilters();
        const columns = this.transformColumns();
        const data = this.transformData();

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/dashboard">Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Produtos</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage title="Produtos" subtitle="Confira a lista de todos os produtos." />
                    <div className="content-filters">
                        <DynamicFilter
                            onSubmit={this.onSubmitSearch}
                            filters={filters}
                        />
                        <HeaderInfo showing={data.length} total={total} />
                    </div>
                    <div
                        className="infinite-container"
                        style={{
                            height: window.innerHeight - 306,
                            overflow: 'auto',
                        }}
                    >
                        <InfiniteScroll
                            hasMore={hasMore && !loading}
                            initialLoad={false}
                            loadMore={this.loadMoreProducts}
                            threshold={20}
                            useWindow={false}
                        >
                            <Table
                                columns={columns}
                                data={data}
                                error={error}
                                loading={loading}
                                onChange={this.handleSort}
                                onSave={this.updateSupplierProduct}
                                pagination={false}
                                refreshData={() => {
                                    return this.getAndSetInitialData(true);
                                }}
                                updateLoading={updateLoading}
                            />
                        </InfiniteScroll>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Products;
