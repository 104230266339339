import React from 'react';
import styled from 'styled-components';
import { Icon, Spin } from 'antd';

const Container = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;

    .ant-spin-nested-loading {
        width: 100% !important;
    }
`;

const Loading = ({ children, loading }) => {
    return (
        <Container>
            <Spin
                indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
                spinning={loading}
            >
                {children}
            </Spin>
        </Container>
    );
};

export default Loading;
