import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Typography, Icon } from 'antd';
import qs from 'query-string';
import * as R from 'ramda';

import InfiniteScroll from '~/components/InfiniteScroller';
import Table from '~/components/Table/AntTable';
import { TitlePage } from '~/components/Header/TitlePage';

import { TransactionAPI, EventAPI } from '~/lib/api';
import { loadMore } from '~/lib/InfiniteScroll';
import { paths } from '~/routes';
import utils from '~/lib/utils';

import types from './types';

export default class List extends Component {
    state = {
        error: false,
        loading: true,
        transactions: {
            hasMore: false,
            result: [],
            pagination: {},
            total: 0,
        },
    };

    componentDidMount() {
        document.title = 'Grid2B | Transações';

        this.getAndSetInitialData();
        EventAPI.bind('export-finished', () => {
            this.getAndSetInitialData(true);
        });
    }

    getAndSetInitialData = async (refresh = false) => {
        try {
            if (refresh) {
                this.setState({
                    error: false,
                    loading: true,
                });
            }

            const newTransactions = await TransactionAPI.fetchTransactions(
                this.props.location.search
            );

            this.setState((prevState) => {
                return {
                    loading: false,
                    transactions: {
                        ...prevState.transactions,
                        ...newTransactions,
                        hasMore: Boolean(newTransactions.pagination.next),
                    },
                };
            });
        } catch (error) {
            console.log('ERROR getAndSetInitialData: ', error);

            this.setState({
                error: true,
                loading: false,
            });
        }
    };

    fetchTransactions = async (options = {}) => {
        try {
            const { search } = this.props.location;
            const searchParsed = qs.parse(search);
            const filters = {
                ...searchParsed,
                ...options,
            };

            const transactions = await TransactionAPI.fetchTransactions(filters);

            return transactions;
        } catch (error) {
            console.log('ERROR fetchTransactions: ', error);

            throw error;
        }
    };

    loadMoreTransactions = () => {
        const loadMoreScoped = loadMore.bind(this);

        return loadMoreScoped('transactions', this.fetchTransactions);
    };

    transformColumns = () => {
        const columns = [
            {
                align: 'center',
                title: 'Tipo',
                dataIndex: 'type',
                render: (type) => {
                    return <span>{types[type]}</span>;
                },
            },
            {
                align: 'center',
                title: 'Créditos',
                dataIndex: 'values',
                render: ({ type, credits }) => {
                    const tagProps = {};
                    switch (type) {
                        case 'export':
                            tagProps.color = 'red';
                            tagProps.operation = '-';
                            break;

                        case 'credits-buy':
                            tagProps.color = 'green';
                            tagProps.operation = '+';
                            break;

                        default:
                            tagProps.color = 'white';
                            tagProps.operation = '';
                            break;
                    }

                    return (
                        <Typography.Text style={{ color: tagProps.color }}>
                            {credits > 0 && (
                                <span style={{ marginRight: 3 }}>{tagProps.operation}</span>
                            )}
                            {utils.toFixed(credits)}
                        </Typography.Text>
                    );
                },
            },
            {
                align: 'center',
                title: 'Data',
                dataIndex: 'date',
                type: 'date',
            },
            {
                align: 'center',
                title: 'Status',
                dataIndex: 'status',
                render: (statusText) => {
                    const status = {};

                    switch (statusText) {
                        case 'pending':
                            status.color = '#1890ff';
                            status.icon = 'clock-circle';
                            break;
                        case 'success':
                        case 'warning':
                            status.color = '#52c41a';
                            status.icon = 'check-circle';
                            break;
                        case 'error':
                            status.color = '#f5222d';
                            status.icon = 'close-circle';
                            break;
                        default:
                            break;
                    }
                    return (
                        <Fragment>
                            {statusText !== 'warning' && (
                                <Icon
                                    type={status.icon}
                                    style={{ color: status.color, fontSize: 16 }}
                                />
                            )}
                            {statusText === 'warning' && (
                                <Icon
                                    type="info-circle"
                                    style={{ color: '#ff9d09', fontSize: 16, marginLeft: 5 }}
                                />
                            )}
                        </Fragment>
                    );
                },
            },
            {
                align: 'center',
                title: '',
                dataIndex: 'actions',
                hidden: true,
                render: (transactionId) => {
                    return (
                        <Link style={{ color: '#1890ff' }} to={`${paths.transactions}/show/${transactionId}`}>
                            Ver detalhes
                        </Link>
                    );
                },
            },
        ];

        return columns;
    };

    getCredits = (transaction) => {
        const [status, type] = R.props(['status', 'type'], transaction);
        const events = R.prop('events', transaction);
        const descriptionEquals = R.propEq('description');

        const getCreditsByTransactionType = (descriptionEvent) => {
            const event = R.find(descriptionEquals(descriptionEvent))(events);
            const credits = R.prop('credits', event.metadata);

            return credits || 0;
        };

        if (type === 'export') {
            if (['error', 'pending'].includes(status)) {
                return 0;
            }

            if (['success', 'warning'].includes(status)) {
                return getCreditsByTransactionType('export-finished');
            }
        }

        if (type === 'credits-buy') {
            return transaction.details.quantity;
        }

        return ' - ';
    };

    transformData = (transactions) => {
        const data = transactions.result.map((transaction) => {
            const credits = this.getCredits(transaction);

            return {
                key: transaction.transactionId,
                type: transaction.type,
                values: { type: transaction.type, credits },
                date: new Date(transaction.createdAt).toLocaleString(),
                status: transaction.status,
                actions: transaction.transactionId,
            };
        });

        return data;
    };

    render() {
        const { error, loading, transactions } = this.state;
        const { hasMore } = transactions;
        const columns = this.transformColumns();
        const data = this.transformData(transactions);

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/dashboard">Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Transações</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage
                        title="Transações"
                        subtitle="Confira a lista de suas transações."
                        // actions={
                        //     <Link to='/dashboard/checkout' style={{ float: 'right' }}>
                        //         <Button type='primary'>Comprar créditos</Button>
                        //     </Link>
                        // }
                    />
                    {/* <DynamicFilter filters={columns} onSubmit={() => { }} /> */}
                    <div
                        className="infinite-container"
                        style={{ height: window.innerHeight - 224, overflow: 'auto' }}
                    >
                        <InfiniteScroll
                            hasMore={hasMore}
                            initialLoad={false}
                            loadMore={this.loadMoreTransactions}
                            threshold={20}
                            useWindow={false}
                        >
                            <Table
                                columns={columns}
                                data={data}
                                error={error}
                                loading={loading}
                                refreshData={() => {
                                    return this.getAndSetInitialData(true);
                                }}
                                pagination={false}
                            />
                        </InfiniteScroll>
                    </div>
                </div>
            </Fragment>
        );
    }
}
