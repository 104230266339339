import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Form } from 'antd';

import { SignUpLink } from './Login';
import { SignInLink } from './SignUp';

const {Item: FormItem } = Form;

const Wrapper = styled.div`
    font-family: 'PF DIN Display Pro', sans-serif;
    background-color: #030636;
    bottom: 0;
    display: block;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position: center;

    /*.has-error .ant-form-explain {
        color: #8c909e;
        // margin-top: 1px;
    }*/
    
    .ant-form-explain {
        margin-top: 2px;
        margin-bottom: -2px;
    }
`;

const BackgroundPerson = styled.div`
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: table;
    width: 100%;
    height: 100%;
    bottom: 0;
`;

const AuthRow = styled.div`
    display: block;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    top: 15%;
    z-index: 12;
`;

const AuthContainer = styled.div`
    background-color: #fff;
    box-shadow: 0px 0px 106px 0px rgba(61, 72, 207, 0.7);
    border-radius: 11px;
    margin: 0 auto;
    max-width: 410px;
    padding: 60px 30px 30px;
    
    & > * {
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;    
    }
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => {
        return props.direction || 'row';
    }};
    justify-content: center;
    margin-bottom: ${(props) => {
        return props.last ? 0 : 15;
    }}px;
`;

const Description = styled.span`    
    font-weight: 500;
    color: ${(props) => {
        return props.color || '#8c909e';
        // return props.color || '#fff';
    }};
    font-size: ${(props) => {
        switch (props.size) {
            case 'small':
                return 12;
            case 'large':
                return 16;
            case 'default':
            default:
                return 14;
        }
    }}px;
    text-align: center;
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

const Logo = styled.img`
    width: 188px;
    max-width: 100%;
    height: 100%;
    margin-bottom: 60px.
`;

const FormItemStyled = styled(FormItem)`
    margin-bottom: 15px;
    .ant-btn-primary {                 
        background: linear-gradient(to left, rgb(71,60,198) 0%, rgb(51,92,209) 49%, rgb(30,124,220) 100%);
        border: none;
        font-size: 16px;
        height: 48px;
        letter-spacing: 4px;
        line-height: 20px;
        padding-bottom: 13px;
        padding-top: 13px;
        text-transform: uppercase;
        font-weight: 500;
        
        &:active, &:focus, &:hover {
            background: linear-gradient(to left, rgb(51,92,209) 0%, rgb(30,124,220) 49%, rgb(71,60,198) 100%);
          
        }
    }
    .ant-form-item-control {
        line-height: normal;
    }
    .ant-input {
        background-color: #f1f5fb;
        border-color: #f1f5fb;
        border-radius: 5px;
        box-shadow: 0 0 2px rgba(0, 0, 0, .3);
        // box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
        color: #b0b4c3;
        font-size: 16px;
        font-weight: 300;
        height: 48px;
        line-height: 20px;
        padding: 13px 15px;
        
        &:active, &:focus, &:hover {
            border-color: #2373d9;
        }
    } 
`;


const LinkStyled = styled(Link)`
    color: #a7adc2;
    text-align: center;
    width: 100%;
    display: table;
    font-weight: 300;
    
    &&:active, &:focus, &:hover {
        color: #1d1d1d;
    }
`;

export {
    AuthContainer,
    AuthRow,
    Description,
    DescriptionContainer,
    FormItemStyled,
    LinkStyled,
    Logo,
    LogoContainer,
    SignInLink,
    SignUpLink,
    Wrapper,
    BackgroundPerson,
};
