import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Link, Switch } from 'react-router-dom';
import { Layout, Menu, Tooltip, Icon, Skeleton, notification } from 'antd';
import debounce from 'debounce-promise';
import styled from 'styled-components';

import NotFound from '~/pages/NotFound';

import ability from '~/ability';
import config from '~/config';
import routes, { paths } from '~/routes';
import { setCredits, fetchCurrentUser } from '~/store/actions/user';
import { EventAPI } from '~/lib/api';

const UserSkeleton = styled(Skeleton)`
    .ant-skeleton-paragraph {
        li { margin: 12px 0px; }
        float: right;
        margin: 0px;
        width: 20%;
    }
`;

const { Header, Content, Sider } = Layout;
const INACTIVE_USER_TIME_THRESHOLD = 300000;

class DashboardBox extends Component {
    constructor(props) {
        super(props);
        this.state = { collapsed: false };
        this.userActivityTimeout = true;
    }

    componentDidMount() {
        EventAPI.start();
        console.log('EventAPI: started');
        EventAPI.bind('credits-update', this.creditsUpdateEvent);
        EventAPI.bind('export-finished', this.exportFinishedEvent);
        // this.loadWidgetScript();
        window.addEventListener('mousemove', this.handleWithInactiveUser);
        window.addEventListener('scroll', this.handleWithInactiveUser);
        window.addEventListener('keydown', this.handleWithInactiveUser);
        window.addEventListener('resize', this.handleWithInactiveUser);
    }

    componentWillUnmount() {
        EventAPI.stop();
        console.log('EventAPI: stopped');
        // this.removeWidgetScript();
        window.removeEventListener('mousemove', this.handleWithInactiveUser);
        window.removeEventListener('scroll', this.handleWithInactiveUser);
        window.removeEventListener('keydown', this.handleWithInactiveUser);
        window.removeEventListener('resize', this.handleWithInactiveUser);
        clearTimeout(this.userActivityTimeout);
    }

    loadWidgetScript = () => {
        const iframe = document.getElementById('jsd-widget');
        if (iframe) {
            iframe.style.display = 'block';
            return;
        }

        const script = document.createElement('script');

        script.setAttribute('data-jsd-embedded', '');
        script.setAttribute('data-key', '290027f6-fb49-4f04-ad15-a1e16d22d7ce');
        script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
        script.setAttribute('src', 'https://jsd-widget.atlassian.com/assets/embed.js');

        script.onload = () => {
            var event = document.createEvent('Event');
            event.initEvent('DOMContentLoaded', true, true);
            window.document.dispatchEvent(event);
        };

        document.body.appendChild(script);
    }

    removeWidgetScript = () => {
        const iframe = document.getElementById('jsd-widget');
        if (iframe) {
            iframe.style.display = 'none';
        }
    }

    handleWithInactiveUser = debounce(() => {
        if (!this.userActivityTimeout) {
            console.log('EventAPI: restared');
            EventAPI.start();
            this.props.dispatch(fetchCurrentUser());
        }

        if (typeof this.userActivityTimeout === 'number') {
            clearTimeout(this.userActivityTimeout);
        }

        this.userActivityTimeout = setTimeout(() => {
            console.log('EventAPI: closed due to inactivity');
            this.userActivityTimeout = false;
            EventAPI.pause();
        }, INACTIVE_USER_TIME_THRESHOLD);
    }, 1000);

    creditsUpdateEvent = ({ credits }) => {
        const { dispatch } = this.props;
        dispatch(setCredits(credits));
    }

    exportFinishedEvent = ({ transactionId }) => {
        notification.info({
            message: 'Exportação concluída',
            description: (
                <a
                    href={`${paths.transactions}/show/${transactionId}`}
                    onClick={(event) => {
                        const href = event.target.getAttribute('href');
                        this.props.history.push(href);
                        event.preventDefault();
                        return false;
                    }}
                >
                    Clique aqui para ver mais detalhes
                </a>
            ),
            duration: 15,
        });
    };

    onCollapse = (collapsed) => {
        this.setState({ collapsed });
    };

    render() {
        const { currentUser } = this.props;
        let username = (currentUser.username || currentUser.name || '').split(' ')[0].substring(0, 15);

        if (currentUser.login && currentUser.login.loginId !== currentUser.id) {
            username = `${username} | ${currentUser.login.email}`;
        }

        return (
            <Layout>
                <Sider
                    style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }}
                    breakpoint="lg"
                    collapsible
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}
                >
                    <div className="logo">
                        <Link to={paths.base} id="Grid2B">
                            {this.state.collapsed ? (
                                <img
                                    src={`${config.app.cdn}/dashboard/logo-negative.png`}
                                    alt="Grid2B Logo"
                                    title="Grid2B Logo"
                                />
                            ) : (
                                <img
                                    src={`${config.app.cdn}/dashboard/logo-small.png`}
                                    alt="Grid2B Logo"
                                    title="Grid2B Logo"
                                />
                            )}
                        </Link>
                    </div>
                    <Menu theme="dark" selectedKeys={[this.props.location.pathname]} mode="inline">
                        {routes.map((route) => {
                            if (!route.hidden && ability.can(route.action, route.type)) {
                                return (
                                    <Menu.Item key={route.path}>
                                        <Tooltip title={route.title} placement="right">
                                            <Link
                                                to={{
                                                    pathname: route.path,
                                                    search: route.search,
                                                }}
                                            >
                                                <route.icon />{' '}
                                                {!this.state.collapsed && (
                                                    <span className="menu-item-name">
                                                        {route.title}
                                                    </span>
                                                )}
                                            </Link>
                                        </Tooltip>
                                    </Menu.Item>
                                );
                            }

                            return false;
                        })}
                    </Menu>
                </Sider>
                <Layout
                    style={{ marginLeft: !this.state.collapsed ? 200 : 80, transition: 'all .2s' }}
                >
                    <Header>
                        {Boolean(currentUser.id) ? (
                            <Fragment>
                                <Link
                                    to={`${this.props.match.url}/account/checkout`}
                                    title="Compra de créditos"
                                    className="header-icon"
                                >
                                    <div className="header-icon">
                                        <Icon type="star"/>
                                        <span style={{ marginLeft: 7, lineHeight: 'initial', fontWeight: 'initial' }}>
                                            {(
                                                typeof currentUser?.credits === 'number'
                                                    ? currentUser.credits
                                                    : 0
                                            ).toFixed(2)}
                                        </span>
                                    </div>
                                </Link>
                                <Link
                                    to={`${this.props.match.url}/account`}
                                    title="Minha Conta"
                                    className="header-icon"
                                >
                                    <Icon type="user" />
                                    <span style={{ marginLeft: 7, lineHeight: 'initial', fontWeight: 'initial' }}>
                                        {username}
                                    </span>
                                </Link>
                            </Fragment>
                        ) : (
                            <UserSkeleton
                                active={true}
                                title={false}
                                paragraph={{
                                    rows: 1,
                                    width: '100%',
                                }}
                            />
                        )}                       
                        <Link
                            to="#"
                            className="header-icon"
                            title="Sair"
                            onClick={this.props.handleLogout}
                        >
                            <Icon type="poweroff" />
                        </Link>
                    </Header>
                    <Content style={{ margin: '0 16px' }}>
                        <Switch>
                            {routes.map((route) => {
                                if (route.children) {
                                    return (
                                        ability.can(route.action, route.type) && (
                                            <Route
                                                path={route.path}
                                                key={route.path}
                                                component={route.component}
                                            />
                                        )
                                    );
                                }

                                return (
                                    ability.can(route.action, route.type) && (
                                        <Route
                                            exact
                                            path={route.path}
                                            key={route.path}
                                            component={route.component}
                                        />
                                    )
                                );
                            })}

                            <Route component={NotFound} />
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

const mapStateToProps = ({ user }) => {
    const { currentUser } = user;

    return { currentUser };
};

export default connect(mapStateToProps)(DashboardBox);
