import { getToken } from '~/services/api/rest/client';
import config from '~/config';

export const EventAPI = {
    _websocket: null,
    _handlers: {},
    async start () {
        if (this._websocket && this._websocket.readyState === WebSocket.OPEN) {
            return;
        }

        const hash = await getToken();
        const endpoint = config.endpoints.websocket;
        this._websocket = new WebSocket(`${endpoint}?hash=${hash}`);

        this._websocket.onmessage = (event) => {

            const message = JSON.parse(event.data);
            const { event: type, data } = message;

            Object.keys((this || {})._handlers || {})
                .filter((eventId) => {
                    const handler = ((this || {})._handlers || {})[eventId];
                    return handler.event === type;
                }).map((eventId) => {
                    const handler = ((this || {})._handlers || {})[eventId];
                    return handler.callback(data, eventId);
                });
        };
    },
    pause () {
        if (this._websocket && this._websocket.readyState !== WebSocket.CLOSED) {
            this._websocket.close();
        }
    },
    stop () {
        this.pause();
        this._handlers = {};
    },
    bind (event, callback) {
        const eventId = Math.random().toString(16).substring(2);
        this._handlers[eventId] = { event, callback };
        return eventId;
    },
    unbind (eventId) {
        delete this._handlers[eventId];
    },
};
