import styled from 'styled-components';
import { Icon } from 'antd';

export const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    justify-content: center;
    position: relative;
    text-transform: uppercase;
    width: 100%;
`;

export const IconStyled = styled(Icon)`
    color: #616161;
    font-size: 48px;
    margin-bottom: 20px;
`;

export const Title = styled.h1`
    color: #616161;
    font-size: 36px;
`;
