import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';

const store = ['local', 'development'].includes(process.env.REACT_APP_ENV)
    ? createStore(
        rootReducer,
        composeWithDevTools(applyMiddleware(thunk)),
    )
    : createStore(
        rootReducer,
        applyMiddleware(thunk),
    );

export default store;
