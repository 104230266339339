import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';

import ability from '~/ability';
import { SupplierAPI } from '~/lib/api';

export async function getAndSetInitialData(refresh: boolean = false) {
    try {
        if (refresh) {
            this.setState({
                error: false,
                loading: true,
            });
        }

        const fetchSuppliers = await SupplierAPI.fetchSuppliers({ active: true });

        this.setState({
            loading: false,
            suppliers: fetchSuppliers.result,
        });
    } catch (error) {
        this.setState({
            error: true,
            loading: false,
        });
    }
}

function getSubject(supplierId, currentUser) {
    const supplierSetting = currentUser?.suppliers?.find((supplier) => {
        return supplier.supplierId === supplierId;
    });
    const status = supplierSetting?.status || false;

    return {
        status,
        __type: 'Supplier',
    };
}

export function getSupplierActions(supplier) {
    const actions = [
        <div key="action-0" style={ { marginBottom: 14 } }>
            <Link
                to={{
                    pathname: `${this.props.match.url}/${supplier.id}/settings`,
                    state: { supplierName: supplier.name },
                }}
                title="Configurações"
                disabled={!supplier.active}
                style={{ display: 'block' }}
            >
                <Icon type="setting" style={{ marginRight: 10 }}/>
                Configurações
            </Link>
        </div>,
        <div key="action-1" style={ { marginBottom: 14 } }>
            <Link
                to={`/dashboard/products?supplierId=${supplier.id}`}
                title="Produtos do distribuidor"
                disabled={!supplier.active}
                style={{ display: 'block' }}
            >
                <Icon type="shopping" style={{ marginRight: 10 }}/>
                Produtos do Distribuidor
            </Link>
        </div>,
    ];

    if (ability.can('show-actions', getSubject(supplier.id, this.props.currentUser))) {
        actions.splice(
            1,
            0,
            <div key="action-2" style={ { marginBottom: 14 } }>
                <Link
                    to={{
                        pathname: `${this.props.match.url}/${supplier.id}/categories`,
                        state: { supplierName: supplier.name },
                    }}
                    title="Categorias"
                    disabled={!supplier.active}
                    style={{ display: 'block' }}
                >
                    <Icon type="tag" style={{ marginRight: 10 }}/>
                    Mapeamentos de categorias
                </Link>
            </div>,
            <div key="action-3" style={ { marginBottom: 14 } }>
                <Link
                    to={{
                        pathname: '/dashboard/schedules',
                        search: `?supplierId=${supplier.id}`,
                    }}
                    title="Agendamentos"
                    disabled={!supplier.active}
                    style={{ display: 'block' }}
                >
                    <Icon type="calendar" style={{ marginRight: 10 }}/>
                    Agendamentos
                </Link>
            </div>
        );
    }

    return actions;
}
