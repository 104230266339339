import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Col, Form, Icon, Input, Modal, Row, Select } from 'antd';

import Can from '~/components/Can';
import DynamicFilter from '~/components/Filters/DynamicFilter';
import HeaderInfo from '~/components/Header/HeaderInfo';
import InfiniteScroll from '~/components/InfiniteScroller';
import Notification from '~/components/Notification/AntNotification';
import Table from '~/components/Table/AntTable';
import { DeleteConfirm } from '~/components/Confirm';
import { TitlePage } from '~/components/Header/TitlePage';

import ability from '~/ability';
import { CatalogAPI } from '~/lib/api';
import { catalogTypes } from '~/config/types';
import { handleSort } from '~/lib/Sort';
import { loadMore } from '~/lib/InfiniteScroll';
import { paths } from '~/routes';

const { Item: FormItem } = Form;
const { Option } = Select;
const style = {
    icon: {
        marginRight: 10,
        fontSize: 18,
        cursor: 'pointer',
    },
};

class List extends Component {
    state = {
        fetchCatalogs: {
            hasMore: false,
            pagination: {},
            result: [],
            total: 0,
        },
        catalogs: [],
        createCatalogLoading: false,
        defaultType: 'custom',
        error: false,
        loading: true,
        showCreateCatalogModal: false,
        total: 0,
    };

    async componentDidMount() {
        document.title = 'Grid2B | Catálogo de categorias';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async (refresh = false) => {
        try {
            if (refresh) {
                this.setState({
                    error: false,
                    loading: true,
                });
            }

            const fetchCatalogs = await CatalogAPI.fetchCatalogs(this.props.location.search);

            this.setState((prevState) => {
                return {
                    fetchCatalogs: {
                        ...prevState.fetchCatalogs,
                        ...fetchCatalogs,
                        hasMore: Boolean(fetchCatalogs.pagination.next),
                    },
                    loading: false,
                };
            });
        } catch (error) {
            this.setState({
                error: true,
                loading: false,
            });
        }
    };

    delete = async (catalogId) => {
        try {
            await CatalogAPI.deleteCatalog(catalogId);

            this.setState((prevState) => {
                return {
                    fetchCatalogs: {
                        ...prevState.fetchCatalogs,
                        result: prevState.fetchCatalogs.result.filter((catalog) => {
                            return catalog.catalogId !== catalogId;
                        }),
                    },
                };
            });

            Notification('success', 'Catálogo apagado!');
        } catch (error) {
            console.log('ERROR delete: ', error);

            if (error?.response?.data?.details) {
                const { dependencies } = error.response.data.details;
                const errors = dependencies.reduce((errors, error) => {
                    const liError = <li>{error.name}</li>;
                    errors.push(liError);

                    return errors;
                }, []);

                Notification(
                    'error',
                    'Ocorreu um erro ao apagar o catálogo!',
                    <Fragment>
                        <span>Ele está sendo usado nos seguintes mapeamentos de categorias.</span>
                        <ul>{errors}</ul>
                    </Fragment>
                );

                return;
            }

            Notification('error', 'Ocorreu um erro ao apagar o catálogo, tente novamente!');
        }
    };

    getCatalogType = (type) => {
        switch (type) {
            case 'custom':
                return 'Personalizado';
            case 'default':
            default:
                return 'Padrão';
        }
    };

    createCatalog = () => {
        this.props.form.validateFields(async (error, values) => {
            if (!error) {
                try {
                    const payload = this.transformPayload(values);
                    this.setState({ createCatalogLoading: true });

                    const { result } = await CatalogAPI.createCatalog(payload);

                    this.props.history.push(
                        `/dashboard/categorycatalogs/edit/${result.catalogId}/categories`
                    );
                } catch (error) {
                    console.log('Error create: ', error);

                    this.setState({ createCatalogLoading: false });
                    Notification(
                        'error',
                        'Ocorreu um erro ao tentar criar o Catálogo, tente novamente!'
                    );
                }
            }
        });
    };

    transformPayload = (values) => {
        const { defaultType } = this.state;
        const { name, type } = values;

        return {
            name,
            type: type || defaultType,
        };
    };

    handleCreateCatalogModal = () => {
        this.setState((state: State) => {
            return { showCreateCatalogModal: !state.showCreateCatalogModal };
        });
    };

    loadMore = () => {
        const loadMoreScoped = loadMore.bind(this);

        return loadMoreScoped('fetchCatalogs', this.getAndSetInitialData);
    };

    handleSort = (pagination, filters, sorter) => {
        const handleSortScoped = handleSort.bind(this);

        return handleSortScoped(sorter, this.getAndSetInitialData);
    };

    transformColumns = () => {
        return [
            {
                title: 'Título',
                key: 'name',
                dataIndex: 'name',
                width: '50%',
                align: 'left',
                sorter: true,
            },
            {
                title: 'Tipo',
                key: 'type',
                dataIndex: 'type',
                type: 'select',
                options: [
                    { value: 'default', name: 'Padrão' },
                    { value: 'custom', name: 'Personalizado' },
                ],
                sorter: true,
                align: 'left',
                width: '40%',
            },
            {
                title: '',
                key: 'actions',
                dataIndex: 'actions',
                hidden: true,
                align: 'right',
                width: '10%',
                render: (catalog: Object) => {
                    return (
                        <Fragment>
                            <Can do="read" this={Object.assign(catalog, { __type: 'Catalog' })}>
                                <Link
                                    to={`${this.props.location.pathname}/show/${
                                        catalog.catalogId
                                    }/categories`}
                                >
                                    <Icon
                                        type="eye"
                                        style={Object.assign(
                                            {},
                                            { cursor: style.icon.cursor },
                                            { fontSize: style.icon.fontSize }
                                        )}
                                    />
                                </Link>
                            </Can>
                            <Can do="update" this={Object.assign(catalog, { __type: 'Catalog' })}>
                                <Link
                                    to={`${this.props.location.pathname}/edit/${
                                        catalog.catalogId
                                    }/categories`}
                                    style={{ marginLeft: 10 }}
                                >
                                    <Icon
                                        type="edit"
                                        style={Object.assign(
                                            {},
                                            { cursor: style.icon.cursor },
                                            { fontSize: style.icon.fontSize }
                                        )}
                                    />
                                </Link>
                            </Can>
                            <Can do="delete" this={Object.assign(catalog, { __type: 'Catalog' })}>
                                <a
                                    href="Javascript:Void(0);" // eslint-disable-line
                                    onClick={() => {
                                        return DeleteConfirm(
                                            () => {
                                                return this.delete(catalog.catalogId);
                                            },
                                            {
                                                title:
                                                    'Deseja realmente apagar esse catálogo de categorias?',
                                            }
                                        );
                                    }}
                                    style={{ marginLeft: 10 }}
                                >
                                    <Icon
                                        type="delete"
                                        style={Object.assign(
                                            {},
                                            { cursor: style.icon.cursor },
                                            { fontSize: style.icon.fontSize }
                                        )}
                                    />
                                </a>
                            </Can>
                        </Fragment>
                    );
                },
            },
        ];
    };

    transformData = () => {
        const { result: catalogs } = this.state.fetchCatalogs;

        const data = catalogs.map((catalog) => {
            return {
                id: catalog.catalogId,
                name: catalog.name,
                type: this.getCatalogType(catalog.type),
                actions: catalog,
            };
        });

        return data;
    };

    render() {
        const {
            createCatalogLoading,
            error,
            fetchCatalogs,
            loading,
            showCreateCatalogModal,
        } = this.state;
        const { hasMore, total } = fetchCatalogs;
        const { getFieldDecorator } = this.props.form;
        const columns = this.transformColumns();
        const data = this.transformData();

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Catálogos de categorias</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage
                        title="Catálogo de categorias"
                        subtitle="Crie ou personalize novos catálogos de categorias."
                    />
                    <div className="content-filters">
                        <DynamicFilter
                            onSubmit={(): Function => {
                                return this.getAndSetInitialData(true);
                            }}
                            filters={columns}
                        />
                        <HeaderInfo
                            showing={data.length}
                            total={total}
                            actions={
                                <Button
                                    icon="plus"
                                    onClick={this.handleCreateCatalogModal}
                                    type="primary"
                                >
                                    Criar
                                </Button>
                            }
                        />
                    </div>
                    <Modal
                        confirmLoading={createCatalogLoading}
                        okText="Salvar"
                        onCancel={this.handleCreateCatalogModal}
                        onOk={this.createCatalog}
                        title="Criar catálogo"
                        visible={showCreateCatalogModal}
                    >
                        <Row gutter={16}>
                            <Col span={ability.can('create-with-type', 'Catalog') ? 12 : 24}>
                                <FormItem label="Nome">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Digite o nome do catálogo',
                                            },
                                        ],
                                    })(<Input />)}
                                </FormItem>
                            </Col>
                            <Can do="create-with-type" a="Catalog" passThrought>
                                {(can) => {
                                    return (
                                        <Col span={12}>
                                            <FormItem label="Tipo">
                                                {getFieldDecorator('type', {
                                                    rules: [
                                                        {
                                                            required: can,
                                                            message: 'Selecione o tipo do catálogo',
                                                        },
                                                    ],
                                                })(
                                                    <Select style={{ width: '100%' }}>
                                                        {catalogTypes.map((type: Object) => {
                                                            return (
                                                                <Option value={type.value}>
                                                                    {type.label}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                )}
                                            </FormItem>
                                        </Col>
                                    );
                                }}
                            </Can>
                        </Row>
                    </Modal>
                    <div
                        className="infinite-container"
                        style={{
                            height: window.innerHeight - 306,
                            overflow: 'auto',
                        }}
                    >
                        <InfiniteScroll
                            hasMore={hasMore}
                            initialLoad={false}
                            loadMore={this.loadMore}
                            threshold={20}
                            useWindow={false}
                        >
                            <Table
                                columns={columns}
                                data={data}
                                error={error}
                                loading={loading}
                                onChange={this.handleSort}
                                refreshData={() => {
                                    return this.getAndSetInitialData(true);
                                }}
                                pagination={false}
                            />
                        </InfiniteScroll>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Form.create()(List);
