import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { decode } from 'he';
import qs from 'query-string';
import debounce from 'debounce-promise';
import { Breadcrumb, Form, Tooltip, Typography, Tag, Button, Icon, Divider, Modal, Select, Alert } from 'antd';

import DynamicFilter from '~/components/Filters/DynamicFilter';
import HeaderInfo from '~/components/Header/HeaderInfo';
import InfiniteScroll from '~/components/InfiniteScroller';
import Table from '~/components/Table/AntTable';
import { TitlePage } from '~/components/Header/TitlePage';
import InputNumber from '~/components/Input/Number';
import InputMoney from '~/components/Input/Money';

import Notification from '~/components/Notification/AntNotification';
import { loadMore } from '~/lib/InfiniteScroll';
import { handleSort } from '~/lib/Sort';
import { InventoryAPI, SupplierAPI, ProductAPI } from '~/lib/api';
import { paths } from '~/routes';

const { Text } = Typography;
const { confirm } = Modal;

class Edit extends Component {

    dataSource = {};

    state = {
        selectedRowKeys: [],
        error: false,
        fetchInventoryProducts: {
            hasMore: false,
            loading: false,
            pagination: {},
            result: [],
            total: 0,
        },
        fetchInventory: {},
        fetchSuppliers: [],
        fetchPriceTables: [],
        values: {},
        margins: {},
        statuses: {},
        confirmLoading: false,
        lineLoading: {},
        loading: true,
        visible: false,
        valueInputType: 'margin',
    };

    componentDidMount() {
        document.title = 'Grid2B | Produtos do inventário';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async (refresh = false) => {
        try {
            if (refresh) {
                this.setState({
                    error: false,
                    loading: true,
                    selectedRowKeys: [],
                });
            }

            const { inventoryId } = this.props.match.params;

            const [
                fetchInventoryProducts,
                fetchInventory,
                fetchSuppliers,
                fetchPriceTables,
            ] = await Promise.all([
                this.fetchInventoryProducts(),
                InventoryAPI.fetchInventory(inventoryId),
                SupplierAPI.fetchSuppliers(),
                ProductAPI.fetchPriceTables(),
            ]);

            this.setState((state) => {
                return {
                    fetchInventoryProducts: {
                        ...state.fetchInventoryProducts,
                        ...fetchInventoryProducts,
                        hasMore: Boolean(fetchInventoryProducts.pagination.next),
                    },
                    fetchInventory: fetchInventory.result,
                    fetchSuppliers: fetchSuppliers.result,
                    fetchPriceTables: fetchPriceTables.result,
                    loading: false,
                };
            });
        } catch (error) {
            this.setState({
                error: true,
                loading: false,
            });
        }
    };

    prepareQueryString = (queryString, options = {}) => {
        const queryObject = qs.parse(queryString);

        const filters = Object.assign({}, queryObject, options);
        const { page, ...filtersWithoutPage } = filters;

        this.props.history.push({
            search: qs.stringify(filtersWithoutPage),
        });

        return filters;
    }

    fetchInventoryProducts = (options = {}) => {
        const filters = this.prepareQueryString(this.props.location.search, options);
        const { inventoryId } = this.props.match.params;
        return InventoryAPI.fetchInventoryProducts(inventoryId, filters);
    };

    loadMoreProductList = () => {
        const loadMoreScoped = loadMore.bind(this);
        return loadMoreScoped('fetchInventoryProducts', this.fetchInventoryProducts);
    };

    handleSort = (pagination, filters, sorter) => {
        const handleSortScoped = handleSort.bind(this);
        return handleSortScoped(sorter, this.getAndSetInitialData, true);
    };

    delay = (ms, result = undefined) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(result);
            }, ms);
        });
    }

    getValidSelectedRowKeys = () => {
        const { selectedRowKeys } = this.state;
        return selectedRowKeys.filter((rowKey) => {
            return /^([A-Za-z0-9-]{2,}-){3}[A-Za-z0-9]+/.test(rowKey);
        });
    }

    filterParams = (fixedParams = {}) => {
        const filter = this.props.location.search;
        const data = { ...fixedParams };
        return { data, filter };
    };

    selectionParams = (fixedParams = {}) => {
        const key = Object.keys(fixedParams).pop();

        const selectedRowKeys = key === 'status'
            ? this.state.selectedRowKeys
            : this.getValidSelectedRowKeys();

        const data = selectedRowKeys.map((rowKey) => {
            return {
                supplierId: this.dataSource[rowKey].supplier.id,
                reference: this.dataSource[rowKey].reference,
                priceTableId: this.dataSource[rowKey].priceTableId,
                distributionCenter: this.dataSource[rowKey].distributionCenter,
                ...fixedParams,
            };
        });
        return { data };
    }

    checkFilterAction = () => {
        const { selectedRowKeys } = this.state;
        return selectedRowKeys.length === 0;
    }

    getTotalAffected = () => {
        const isFilterAction = this.checkFilterAction();
        return isFilterAction
            ? this.state.fetchInventoryProducts.total
            : this.state.selectedRowKeys.length;
    }

    updateMultipleInventoryProducts = async (params) => {

        const getMargin = (key, { action, value, type }) => {
            value = Number(value);

            const values = [
                this.state.margins[key],
                this.dataSource[key].margin,
                this.state.fetchInventory?.defaultMargin,
                0,
            ];

            const margin = values.find((value) => {
                return !Number.isNaN(Number(value));
            });

            if (type === 'margin') {
                switch (action) {
                    case 'INCREASE':
                        return margin + value;
                    case 'DECREASE':
                        return Math.max(0, margin - value);
                    default:
                        return value;
                }
            }

            if (type === 'price') {
                const currentPrice = this.dataSource[key].costPrice;
                const currentSellPrice = this.calculateSellPrice(currentPrice, margin);

                switch (action) {
                    case 'FIX':
                        return this.calculateSellMargin(currentPrice, value);
                    case 'INCREASE':
                        return this.calculateSellMargin(currentPrice, currentSellPrice + value);
                    case 'DECREASE':
                        const newMargin = this.calculateSellMargin(
                            currentPrice, currentSellPrice - value
                        );
                        return Math.max(0, newMargin);
                    default:
                        return margin;
                }
            }
        };

        try {
            const { inventoryId } = this.props.match.params;

            const isFilterAction = this.checkFilterAction();

            if (isFilterAction && this.getTotalAffected() > 4000) {
                await this.delay(1000);
                Notification('warning', 'Total de produtos filtrados não pode ultrapassar 4000 itens', undefined, 15);
                return {};
            }

            const getParams = isFilterAction ? this.filterParams : this.selectionParams;
            const { data, filter } = getParams(params);
            await InventoryAPI.updateInventoryProducts(inventoryId, data, filter);

            const value = Object.values(params).pop();
            const isMargin = typeof value === 'object';

            if (isFilterAction) {
                const { fetchInventoryProducts } = this.state;

                const keys = fetchInventoryProducts.result.reduce((result, product) => {

                    if (!product.inventory.prices.length) {
                        product.inventory.prices.push({});
                    }

                    product.inventory.prices.forEach((price) => {
                        const key = this.buildKey(product, price);
                        const validKey = /^([A-Za-z0-9-]{2,}-){3}[A-Za-z0-9]+/.test(key);

                        if (isMargin && !validKey) {
                            return;
                        }

                        result[key] = isMargin ? getMargin(key, value) : value;
                    });
                
                    return result;
                }, {});

                return keys;
            } else {
                const selectedRowKeys = isMargin
                    ? this.getValidSelectedRowKeys()
                    : this.state.selectedRowKeys;
                return selectedRowKeys.reduce((result, key) => {
                    result[key] = isMargin ? getMargin(key, value) : value;
                    return result;
                }, {});
            }

        } catch (error) {
            if (error.response.data.message === 'inventory-process-already-running-limit-exceeded') {
                Notification('warning', 'Aguarde alguns instantes antes de realizar uma nova atualização neste inventário', undefined, 15);
                return;
            }

            console.log('ERROR updateMultipleInventoryProducts: ', error);
            Notification('error', 'Ocorreu um erro ao atualizar os produtos');
        }
    }

    statusButtonClick = (title, status) => {

        const isFilterAction = this.checkFilterAction();

        const action = status ? 'ATIVO' : 'INATIVO';
        const type = isFilterAction ? 'filtrado' : 'selecionado';
        const count = this.getTotalAffected();

        confirm({
            title,
            content: (<span>Deseja prosseguir e alterar para <strong>{action}</strong> o status de {count} produto(s) {type}(s)?</span>),
            onOk: async () => {
                const keys = await this.updateMultipleInventoryProducts({ status });

                this.setState((prev) => {
                    const statuses = { ...prev.statuses, ...keys };
                    return { statuses };
                });

            },
        });
    }

    activateButtonClick = () => {
        this.statusButtonClick('Ativação de produtos', true);
    }

    deactivateButtonClick = () => {
        this.statusButtonClick('Desativação de produtos', false);
    }

    toogleLoadingLine = async (index) => {
        const stateToggle = (prevState) => {
            let lineLoading = {};

            if (prevState.lineLoading[index]) {
                const { [index]: excluded, ...props } = prevState.lineLoading;
                lineLoading = props;
            } else {
                lineLoading = { ...prevState.lineLoading, [index]: true };
            }

            return { lineLoading };
        };

        return new Promise((resolve) => {
            this.setState(stateToggle, resolve);
        });
    }

    priceChangeButtonClick = () => {
        this.setState({
            visible: true,
        });
    }

    updateInventoryProducts = async ({ key, ...record }, name, value) => {
        const { inventoryId } = this.props.match.params;

        const params = {
            reference: record.reference,
            supplierId: record.supplier.id,
            priceTableId: record.priceTableId,
            distributionCenter: record.distributionCenter,
            [name]: value,
        };

        try {
            await this.toogleLoadingLine(key);

            if (Object.keys(this.state.lineLoading).length > 1) {
                Notification('warning', 'Aguarde a operação atual terminar!');
                return false;
            }

            if (value === undefined) {
                await this.delay(500);
                return true;
            }

            await InventoryAPI.updateInventoryProducts(inventoryId, params);

            return true;
        } catch (error) {
            console.log('ERROR updateInventoryProducts: ', error);
            Notification('error', 'Ocorreu um erro ao atualizar o produto!');
        } finally {
            await this.toogleLoadingLine(key);
        }
    }

    priceUpdateButtonClick = async (record) => {
        const { key } = record;
        const value = this.state.values[key];
        const result = await this.updateInventoryProducts(record, 'priceParams', value !== undefined && {
            value: Math.min(1000, value),
            action: 'FIX',
            type: 'margin',
        });

        if (!result) {
            return;
        }

        this.setState((prev) => {
            const { [key]: value, ...values } = prev.values;
            const margins = { ...prev.margins, [key]: value };
            return { values, margins };
        });
    }

    saveAllValues = async () => {
        if (Object.keys(this.state.lineLoading).length > 0) {
            Notification('warning', 'Aguarde a operação atual terminar!');
            return false;
        }

        const { inventoryId } = this.props.match.params;
        const values = { ...this.state.values };

        this.setState({ lineLoading: values });

        const params = Object.entries(values).map(([key, value]) => {
            return {
                supplierId: this.dataSource[key].supplier.id,
                reference: this.dataSource[key].reference,
                priceTableId: this.dataSource[key].priceTableId,
                distributionCenter: this.dataSource[key].distributionCenter,
                priceParams: {
                    value: Math.min(1000, value),
                    action: 'FIX',
                    type: 'margin',
                },
            };
        });

        try {
            await InventoryAPI.updateInventoryProducts(inventoryId, params);

            this.setState(({ margins }) => {
                return {
                    values: {},
                    lineLoading: {},
                    margins: { ...margins, ...values },
                };
            });

            Notification('success', 'Produtos atualizados com sucesso!');
        } catch (error) {
            console.log('ERROR saveAllValues: ', error);
            Notification('error', 'Ocorreu um erro ao atualizar os produtos!');
        }
    }

    statusToggleButtonClick = async (params) => {
        const status = !params.status;
        const result = await this.updateInventoryProducts(params, 'status', status);

        if (!result) {
            return;
        }

        this.setState((prevState) => {
            return {
                statuses: {
                    ...prevState.statuses,
                    [params.key]: status,
                },
            };
        });
    }

    calculateSellPrice = (price, margin = 0) => {
        const sellPrice = Number(price) * ((Number(margin) / 100) + 1);
        return Number(sellPrice.toFixed(2));
    };

    calculateSellMargin = (costPrice, sellPrice) => {
        const sellMargin = (sellPrice - costPrice) / (costPrice / 100);
        return Math.max(0, Number(sellMargin.toFixed(3)));
    };

    handleInputFocus = (event) => {
        event.target.select();
    }

    handleInputKeyDown = (event, record) => {
        if (event.which === 13 || event.keyCode === 13) {
            event.target.blur();

            const value = event.target.value.replace(/,/g, '.');

            if (Number.isNaN(Number(value))) {
                Notification('warning', 'Apenas valores numéricos são permitidos');
                return false;
            }

            setTimeout(() => {
                this.priceUpdateButtonClick(record);
            }, 400);
            return false;
        }
        return true;
    }

    transformColumns = () => {

        const getMargin = (key, margin) => {
            const values = [
                this.state.values[key], 
                this.state.margins[key],
                margin,
                this.state.fetchInventory?.defaultMargin,
                0,
            ];

            return values.find((value) => {
                return !Number.isNaN(Number(value));
            });
        };

        const { fetchPriceTables, fetchSuppliers } = this.state;

        const supplierNames = fetchSuppliers.reduce((result, supplier) => {
            result[supplier.id] = supplier.name;
            return result;
        }, {});

        const options = fetchPriceTables.map((supplier) => {
            const priceTables = supplier.priceTables.map((priceTable) => {
                const distributionCenters = priceTable.distributionCenters
                    .map((distributionCenter) => {
                        return {
                            value: distributionCenter.id,
                            label: distributionCenter.id,
                        };
                    });

                if (distributionCenters.length <= 1) {
                    return {
                        value: priceTable.id,
                        label: priceTable.id,
                    };    
                }

                distributionCenters.unshift(
                    { value: 'distribution-center', disabled: true, label: 'Centro de Distribuição' },
                    { value: null, label: 'Todos os centros' }
                );
                return {
                    value: priceTable.id,
                    label: priceTable.id,
                    children: distributionCenters,
                };
            });

            if (priceTables.length <= 1 && !priceTables[0]?.children) {
                return {
                    value: supplier.id,
                    label: supplierNames[supplier.id],
                };
            }

            if (priceTables.length > 1) {
                priceTables.unshift(
                    { value: null, label: 'Todas as tabelas' }
                );
            }

            priceTables.unshift(
                { value: 'price-table', disabled: true, label: 'Tabela de Preço' },
            );

            return {
                value: supplier.id,
                label: supplierNames[supplier.id],
                children: priceTables,
            };
        });

        let supplierType = 'select';

        const hasChild = options.some((option) => {
            return (option.children || []).length > 0;
        });

        if (hasChild) {
            options.unshift({ value: 'supplier', disabled: true, label: 'Distribuidor' });
            supplierType = 'cascader';
        }

        const columns = [
            {
                title: 'Referência',
                key: 'reference',
                dataIndex: 'reference',
                width: '5%',
            },
            {
                title: 'Título',
                key: 'title',
                dataIndex: 'title',
                className: 'overflow',
                width: '45%',
                sorter: true,
                render: (title, record) => {
                    return (
                        <Fragment>
                            <Tooltip title={title}>
                                <Text>{title}</Text>
                            </Tooltip>
                            <br />
                            <Tooltip title={record.main_category}>
                                <Text type="secondary">{record.main_category}</Text>
                            </Tooltip>
                        </Fragment>
                    );
                },
            },
            {
                title: 'Distribuidor',
                key: 'supplier',
                dataIndex: 'supplier',
                alias: 'supplierId',
                type: supplierType,
                options: options,
                width: '5%',
                render: (supplier, { priceTableId, distributionCenter }) => {
                    return (
                        <Fragment>
                            <div><Text>{supplier.name}</Text></div>
                            {(priceTableId && distributionCenter) && (
                                <div>
                                    <Tooltip title={(
                                        <Fragment>
                                            <div>{`Tabela de Preço: ${priceTableId}`}</div>
                                            <div>{`Centro de Distribuição: ${distributionCenter}`}</div>
                                        </Fragment>
                                    )}>
                                        <Tag>
                                            <span>{priceTableId}</span>
                                            <Divider type="vertical"/>
                                            <span>{distributionCenter}</span>
                                        </Tag>
                                    </Tooltip>
                                </div>
                            )}
                        </Fragment>
                    );
                },
            },
            {
                title: 'Custo',
                key: 'costPrice',
                dataIndex: 'costPrice',
                width: '9%',
                hidden: true,
                render: (costPrice, record) => {
                    if (costPrice) {
                        return  `R$ ${(costPrice).toFixed(2)}`;
                    }

                    const status = this.state.statuses[record.key] !== undefined ?
                        this.state.statuses[record.key] : record.status;

                    const text = status
                        ? 'Aguardando próxima exportação'
                        : 'Ative e exporte para ver os valores';

                    return {
                        children: (                            
                            <Text
                                type="secondary"
                                style={{ fontStyle: 'italic' }}
                                children={text}
                            />
                        ),
                        props: { colSpan: 3, align: 'center' },
                    };
                },
            },
            {
                title: 'Margem (%)',
                key: 'margin',
                dataIndex: 'margin',
                width: '9%',
                hidden: true,
                render: (margin, record) => {
                    const { key, costPrice } = record;

                    if (!costPrice) {
                        return { props: { colSpan: 0 } };
                    }

                    const currentMargin = getMargin(key, margin);

                    return (
                        <InputNumber
                            style={{ width: '100%' }}
                            min={0}
                            max={1000}
                            value={currentMargin}
                            onChange={debounce((value) => {

                                if (value === currentMargin) {
                                    return;
                                }

                                this.setState((prevState) => {
                                    return {
                                        values: {
                                            ...prevState.values,
                                            [key]: Number(value) || 0,
                                        },
                                    };
                                });
                            }, 100)}
                            onFocus={this.handleInputFocus}
                            onKeyDown={(event) => {
                                this.handleInputKeyDown(event, record);
                            }}
                        />
                    );
                },
            },
            {
                title: 'Preço (R$)',
                key: 'price',
                width: '12%',
                hidden: true,
                render: (text, row) => {
                    const { key, margin, costPrice } = row;

                    if (!costPrice) {
                        return { props: { colSpan: 0 } };
                    }

                    const currentMargin = getMargin(key, margin);
                    const price = this.calculateSellPrice(costPrice, currentMargin);               

                    return (
                        <InputMoney
                            hideDollarSign={true}
                            style={{ width: '100%' }}
                            min={0}
                            value={Number.isNaN(price) ? 0 : price.toFixed(2)}
                            onChange={debounce((value) => {
                                const newMargin = this.calculateSellMargin(row.costPrice, value);

                                if (newMargin === currentMargin) {
                                    return;
                                }

                                this.setState((prevState) => {
                                    return {
                                        values: {
                                            ...prevState.values,
                                            [key]: newMargin,
                                        },
                                    };
                                });
                            }, 100)}
                            onFocus={this.handleInputFocus}
                            onKeyDown={(event) => {
                                this.handleInputKeyDown(event, row);
                            }}
                        />
                    );
                },
            },
            {
                title: 'Adicionado',
                key: 'createdAt',
                dataIndex: 'createdAt',
                type: 'date',
                sorter: true,
                hidden: true,
                defaultSortOrder: 'descend',
                width: '5%',
            },
            {
                title: 'Atualização',
                key: 'updatedAt',
                dataIndex: 'updatedAt',
                type: 'date',
                sorter: true,
                hidden: true,
                width: '5%',
            },
            {
                title: ' ',
                hidden: true,
                sorter: false,
                align: 'center',
                render: (record) => {
                    if (this.state.lineLoading[record.key]) {
                        return (
                            <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                <Icon type="loading"/>
                            </div>
                        );
                    }

                    const status = this.state.statuses[record.key] !== undefined ?
                        this.state.statuses[record.key] : record.status;

                    return (
                        <Fragment>
                            <Tooltip title={status ? 'Inativar' : 'Ativar'}>
                                <Button
                                    onClick={() => {
                                        this.statusToggleButtonClick({ ...record, status });
                                    }}
                                    children={(<Icon type="poweroff"/>)}
                                    style={{ padding: 0, width: 32 }}
                                    type={status ? 'primary' : 'danger'}
                                    size="small"
                                />
                            </Tooltip>
                            {record.costPrice && (
                                <Fragment>
                                    <Divider type="vertical"/>
                                    <Tooltip title="Salvar alteração">
                                        <Button
                                            className="update-inventory-button"
                                            onClick={() => {this.priceUpdateButtonClick(record);}}
                                            children={(<Icon type="sync"/>)}
                                            style={{ padding: 0, width: 32 }}
                                            size="small"
                                        />
                                    </Tooltip>
                                </Fragment>
                            )}
                        </Fragment>
                    );
                },
            },
        ];

        return columns;
    };

    buildKey = (product, price = {}) => {
        const { supplierId, reference } = product;
        const { priceTableId, distributionCenter } = price;
        const keys = [supplierId, reference];

        if (priceTableId && distributionCenter) {
            keys.push(priceTableId, distributionCenter);
        }

        return keys.join('-');
    };

    transformData = () => {
        const { fetchInventoryProducts, fetchSuppliers } = this.state;

        const suppliers = fetchSuppliers.reduce((result, supplier) => {
            result[supplier.id] = supplier.name;
            return result;
        }, {});

        return fetchInventoryProducts.result.map((product) => {
            const item = {
                image: { imageMain: product.thumbnail, title: product.title },
                title: decode(product.title || ''),
                reference: product.reference,
                main_category: (product.category || '').split('##').join(' / '),
                createdAt: moment(product.createdAt).format('DD/MM/YYYY'),
                updatedAt: moment(product.updatedAt).format('DD/MM/YYYY'),
                supplier: {
                    id: product.supplierId,
                    name: suppliers[product.supplierId],
                },
            };

            if (!product?.inventory?.prices?.length) {
                item.key = this.buildKey(product);
                item.status = product?.inventory?.status || false;
                return item;
            }

            const prices = [...product.inventory.prices];
            const firstPrice = prices.shift();

            item.key = this.buildKey(product, firstPrice);
            item.margin = firstPrice.margin;
            item.distributionCenter = firstPrice.distributionCenter;
            item.priceTableId = firstPrice.priceTableId;
            item.costPrice = firstPrice.costPrice;
            item.status = firstPrice.status;

            const children = prices.map((price) => {
                return {
                    ...item,
                    key: this.buildKey(product, price),
                    margin: price.margin,
                    distributionCenter: price.distributionCenter,
                    priceTableId: price.priceTableId,
                    costPrice: price.costPrice,
                    status: price.status,
                };
            });

            if (children.length > 0) {
                item.children = children;
            }

            return item;
        });
    };

    render() {
        const { error, fetchInventoryProducts, fetchInventory, loading } = this.state;
        const { form } = this.props;
        const { hasMore, total } = fetchInventoryProducts;
        const columns = this.transformColumns();
        const data = this.transformData();

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={paths.inventories}>
                            Inventários
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Produtos</Breadcrumb.Item>
                </Breadcrumb>
                <div id="inventory-products" className="inside-container">
                    <TitlePage
                        title="Produtos do inventário"
                        subtitle="Adicione produtos que você deseja importar."
                        extraTitle={fetchInventory?.name}
                    />
                    <div className="content-filters">
                        <DynamicFilter
                            onSubmit={() => {
                                return this.getAndSetInitialData(true);
                            }}
                            filters={[
                                ...columns,
                                {
                                    title: 'Categoria',
                                    key: 'category',
                                    dataIndex: 'category',
                                },
                                {
                                    title: 'Status',
                                    key: 'status',
                                    dataIndex: 'status',
                                    type: 'select',
                                    options: [
                                        { name: 'Ativo', value: true },
                                        { name: 'Inativo', value: false },
                                    ],
                                },
                            ]}
                        />
                        <HeaderInfo
                            showing={data.length}
                            total={total}
                            actions={(
                                <Fragment>
                                    {Object.keys(this.state.values).length > 0 && (
                                        <Button
                                            type="primary"
                                            onClick={this.saveAllValues}
                                            loading={Object.keys(this.state.lineLoading).length > 1}
                                        >
                                            Salvar tudo
                                        </Button>
                                    )}
                                    <Button disabled={data.length === 0} type="primary" onClick={this.activateButtonClick}>Ativar</Button>
                                    <Button disabled={data.length === 0} type="primary" onClick={this.deactivateButtonClick}>Desativar</Button>
                                    <Button disabled={data.length === 0} type="primary" onClick={this.priceChangeButtonClick}>Reajuste de preços</Button>
                                </Fragment>
                            )}
                        />
                    </div>
                    <div
                        className="infinite-container"
                        style={{
                            height: window.innerHeight - 307,
                            overflow: 'auto',
                        }}
                    >
                        <InfiniteScroll
                            hasMore={hasMore}
                            initialLoad={false}
                            loadMore={this.loadMoreProductList}
                            threshold={20}
                            useWindow={false}
                        >
                            <Table
                                columns={columns}
                                data={data}
                                error={error}
                                loading={loading}
                                onChange={this.handleSort}
                                pagination={false}
                                refreshData={() => {
                                    return this.getAndSetInitialData(true);
                                }}
                                rowSelection={{
                                    selectedRowKeys: this.state.selectedRowKeys,
                                    onChange: (selectedRowKeys) => {
                                        if (selectedRowKeys.length > 100) {
                                            Notification('warning', 'Total de produtos selecionados não pode ultrapassar 100 itens', undefined, 15);
                                            selectedRowKeys.splice(100);
                                        }

                                        this.setState({ selectedRowKeys });
                                    },
                                }}
                                rowClassName={(record) => {
                                    const previousValue = this.state.margins[record.key] || record.margin;
                                    const currentValue = this.state.values[record.key];

                                    const editing = currentValue !== undefined && currentValue !== previousValue;
                                    return editing ? 'line-editing' : '';
                                }}
                                onRow={({ key, children, ...record }) => {
                                    this.dataSource[key] = record;
                                    (children || []).forEach(({ key: childKey, ...data }) => {
                                        this.dataSource[childKey] = data;
                                    });
                                }}
                            />
                        </InfiniteScroll>
                    </div>
                    <Modal
                        title="Reajuste de preços"
                        visible={this.state.visible}
                        confirmLoading={this.state.confirmLoading}
                        onOk={async (event) => {
                            this.props.form.validateFields(async (error, fields) => {
                                if (error) {
                                    return;
                                }

                                this.setState({ confirmLoading: true });

                                const validKeys = this.getValidSelectedRowKeys();
                                const priceParams = {
                                    value: fields.value,
                                    action: fields.action,
                                    type: this.state.valueInputType,
                                };

                                const keys = this.checkFilterAction() || validKeys.length > 0
                                    ? await this.updateMultipleInventoryProducts({ priceParams })
                                    : await this.delay(1000, {});

                                form.resetFields();
                                this.setState((prev) => {
                                    return {
                                        margins: { ...prev.margins, ...keys },
                                        visible: false,
                                        confirmLoading: false,
                                        valueInputType: 'margin',
                                    };
                                });
                            });
                        }}
                        onCancel={(event) => {
                            this.setState({
                                visible: false,
                                valueInputType: 'margin',
                            });
                            form.resetFields();
                        }}
                    >
                        <Alert
                            showIcon
                            type="info"
                            message="Informações adicionais"
                            style={{ marginBottom: 10 }}
                            description={(
                                <ul style={{ margin: 0, paddingLeft: 18 }}>
                                    <li>
                                        Ação será executada para {this.getTotalAffected()} produto(s) {this.checkFilterAction() ? 'filtrado' : 'selecionado'}(s).
                                    </li>
                                    <li>Produtos sem preço de custo não serão ajustados.</li>
                                </ul>
                            )}
                        />
                        <Form.Item style={{ marginBottom: 10 }} label="Ação">
                            {form.getFieldDecorator('action', {
                                initialValue: 'FIX',
                                rules: [
                                    {
                                        required: true,
                                        message: 'Campo obrigatório',
                                    },
                                ],
                            })(
                                <Select style={{ width: '100%' }}>
                                    <Select.Option value="FIX">Fixar</Select.Option>
                                    <Select.Option value="INCREASE">Acrescer</Select.Option>
                                    <Select.Option value="DECREASE">Descontar</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 10 }} label="Valor">
                            {form.getFieldDecorator('value', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Campo obrigatório',
                                    },
                                ],
                            })(
                                this.state.valueInputType === 'margin'
                                    ? <InputNumber className="inventory-products input-number"/>
                                    : <InputMoney className="inventory-products input-number input-money"/>
                            )}
                            <div
                                className="inventory-products ant-input-group-addon"
                                children={this.state.valueInputType === 'margin' ? '%' : 'R$'}
                                onClick={() => {
                                    const valueInputType = this.state.valueInputType === 'margin' ? 'price' : 'margin';
                                    this.setState({ valueInputType });
                                }}
                            />
                        </Form.Item>
                    </Modal>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentUser } = state.user;
    return { currentUser };
};

export default connect(mapStateToProps)(Form.create()(Edit));
