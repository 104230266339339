import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Col, Form, Row, Typography } from 'antd';
import { countBy, toUpper } from 'ramda';
import FormItem from 'antd/lib/form/FormItem';
import Card from '~/components/Card';

import {
    createParamsPayload,
    getAndSetInitialData,
    saveSupplierSettings,
    confirmInventoryUse,
} from './Settings.actions';

import { LinkStyled, Title, Text, TextGroup, Tag } from './styles/Settings';

import Loading from '~/components/Loading';

import config from '~/config';
import states from '~/lib/States';
import { paths } from '~/routes';

class SupplierSettings extends Component {
    state = {
        loading: true,
        saveLoading: false,
        settingsLoading: true,
        settings: {},
        supplier: {
            distributionCenters: [],
            paramsPayload: [],
        },
    };

    createParamsPayload = createParamsPayload.bind(this);
    getAndSetInitialData = getAndSetInitialData.bind(this);
    saveSupplierSettings = saveSupplierSettings.bind(this);
    confirmInventoryUse = confirmInventoryUse.bind(this);

    componentDidMount() {
        document.title = 'Grid2B | Distribuidores';
        this.getAndSetInitialData();
    }

    renderDistributionCenters = () => {
        const { supplier } = this.state;

        if (!supplier.infos) {
            return false;
        }

        const getLocation = () => {
            const locations = supplier.infos.distributionCenters.map((distributionCenter) => {
                const stockLocation = distributionCenter.distributionCenter.slice(0, 2);

                return stockLocation;
            });

            return locations;
        };

        const distributionCentersGrouped = countBy(toUpper)(getLocation());

        return Object.keys(distributionCentersGrouped).map((distributionCenter, index) => {
            const distributionCenterState = states.find((state) => {
                return state.uf === distributionCenter;
            });

            return (
                <Tag key={`distribution-center-${index}`}>
                    {distributionCenterState?.name || 'Indefinido'}: {distributionCentersGrouped[distributionCenter]}
                </Tag>
            );
        });
    };

    render() {
        const { loading, saveLoading, settingsLoading, settings, supplier } = this.state;

        const hasParams = (supplier?.paramsPayload?.supplier || []).length;
        const isActive = settings?.status;

        let formText = '';

        if (supplier?.paramsPayload?.supplierRedirect) {
            formText = supplier?.paramsPayload?.supplierRedirect?.text;
        } else if (hasParams) {
            formText = 'Insira abaixo seus dados de acesso fornecidos pelo distribuidor.';
        } else {
            if (isActive) {
                formText = 'Inative o distribuidor caso queira parar de utilizá-lo no Grid2B.';
            } else {
                formText = 'Ative o distribuidor e comece a utilizá-lo no Grid2B';
            }
        }

        const buildObservationContent = (observations = []) => {
            const buildItem = (item, index) => {
                return (
                    <TextGroup key={index}>
                        {item.title && (<Text type="secondary">{item.title}</Text>)}
                        <Text>{item.description}</Text>
                    </TextGroup>
                );
            };

            return observations.map((observation) => {
                return (
                    <Fragment key={observation.paragraph}>
                        <Title>{observation.paragraph}</Title>
                        {observation.items.map(buildItem)}
                    </Fragment>
                );
            });
        };

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={paths.suppliers}>Distribuidores</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={`${paths.suppliers}/${this.props.match.params.supplierId}/settings`}
                        >
                            {supplier.name}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Configurações</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <Loading loading={loading}>
                        <div style={{ height: 60, display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                            <img
                                alt={supplier.name}
                                src={`${config.app.cdn}/dashboard/${supplier.logoUrl}`}
                                style={{ width: 'auto', maxHeight: 60, visibility: loading ? 'hidden' : 'visible' }}
                            />
                        </div>
                        <Typography.Paragraph>
                            {this.state.supplier.description}
                        </Typography.Paragraph>
                    </Loading>
                    <Row gutter={20} type="flex">
                        <Col xs={24} sm={12} xl={6}>
                            <Card loading={loading} style={{ marginBottom: 20 }}>
                                <Title>Dados do distribuidor</Title>
                                <TextGroup>
                                    <Text type="secondary">Razão social</Text>
                                    <Text>{supplier?.infos?.legalName}</Text>
                                </TextGroup>
                                {supplier?.infos?.documents.map((document, index) => {
                                    return (
                                        <TextGroup key={`document-${index}`}>
                                            <Text type="secondary">{document.name.toUpperCase()}</Text>
                                            <Text>{document.value}</Text>
                                        </TextGroup>
                                    );
                                }
                                )}
                                <Title>Informações para contato</Title>
                                {supplier?.infos?.contacts.map((contact, index) => {
                                    return (
                                        <TextGroup key={`contact-${index}`}>
                                            <Text type="secondary">{contact.type}</Text>
                                            <Text>{document.value}</Text>
                                            {contact.type === 'Site' ? (
                                                <a href={contact.value} target="_blank" rel="noopener noreferrer">
                                                    <span>{contact.value}</span>
                                                </a>
                                            ) : (
                                                <Text>{contact.value}</Text>
                                            )}
                                        </TextGroup>
                                    );
                                })}
                                {buildObservationContent(supplier?.infos?.observations?.card1)}
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Card loading={loading} style={{ marginBottom: 20 }}>
                                <div>
                                    <Title style={{ 'display': 'inline-block' }}>Produtos</Title>
                                    <LinkStyled style={{ 'float': 'right' }} to={`/dashboard/products?supplier=${supplier.id}`}>
                                        Listar
                                    </LinkStyled>
                                </div>
                                <TextGroup>
                                    <Text type="secondary">SKU's</Text>
                                    <Text>{supplier?.infos?.products.total.quantity} ({supplier?.infos?.products.total.text})</Text>
                                </TextGroup>
                                <TextGroup>
                                    <Text type="secondary">Em estoque</Text>
                                    <Text>{supplier?.infos?.products.inStock.quantity} ({supplier?.infos?.products.inStock.text})</Text>
                                </TextGroup>
                                <Title>Atualizações</Title>
                                {supplier?.infos?.updates?.types.map((type, index) => {
                                    return (
                                        <TextGroup key={`update-${index}`}>
                                            <Text type="secondary">{type.label}</Text>
                                            <Text>{type.text}</Text>
                                        </TextGroup>
                                    );
                                })}
                                {buildObservationContent(supplier?.infos?.observations?.card2)}
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Card loading={loading} style={{ marginBottom: 20 }}>
                                <Title>Centros de Distribuição</Title>
                                {this.renderDistributionCenters()}
                            </Card>
                            {!loading && (
                                <Card style={{ marginBottom: 20 }}>
                                    <Title>Principais Categorias</Title>
                                    {supplier?.infos?.categories?.main?.map((category, index) => {
                                        return (
                                            <Tag key={`category-${index}`}>
                                                {category.name}
                                            </Tag>
                                        );
                                    })}
                                </Card>
                            )}
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Card loading={settingsLoading} style={{ marginBottom: 20 }}>
                                <Title>Configurações</Title>
                                <Text style={{ marginBottom: 14 }}>
                                    {formText}
                                </Text>
                                {this.createParamsPayload()}
                                {!settingsLoading && settings.status !== 'undefined' && (
                                    <FormItem style={{ float: 'right', marginRight: 0 }}>
                                        {supplier?.paramsPayload?.supplierRedirect ? (
                                            <Button type="primary" onClick={() => {
                                                this.props.history.push(supplier?.paramsPayload?.supplierRedirect?.link);
                                                window.location.reload();
                                            }}>
                                                Redirecionar
                                            </Button>
                                        ) : settings.status ? (
                                            <Fragment>
                                                {!settings.inventory && (
                                                    <Button
                                                        type="primary"
                                                        style={{ marginRight: 10 }}
                                                        onClick={(event) => { return this.confirmInventoryUse(); }}
                                                    >
                                                        Gerenciar preços
                                                    </Button>     
                                                )}
                                                <Button
                                                    loading={saveLoading}
                                                    type="danger"
                                                    onClick={(event) => {return this.saveSupplierSettings(false)(event);}}
                                                >
                                                    Inativar
                                                </Button>
                                            </Fragment>
                                        ) : (
                                            <Button
                                                loading={saveLoading}
                                                type="primary"
                                                onClick={(event) => {return this.saveSupplierSettings(true)(event);}}
                                            >
                                                Ativar
                                            </Button>
                                        )}
                                    </FormItem>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentUser } = state.user;
    return { currentUser };
};

export default connect(mapStateToProps)(Form.create()(SupplierSettings));
