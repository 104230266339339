import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'query-string';
import { Breadcrumb, Button, Col, Form, Icon, Input, Modal, Row, Select } from 'antd';

import Can from '~/components/Can';
import DynamicFilter from '~/components/Filters/DynamicFilter';
import HeaderInfo from '~/components/Header/HeaderInfo';
import InfiniteScroll from '~/components/InfiniteScroller';
import Notification from '~/components/Notification/AntNotification';
import Table from '~/components/Table/AntTable';
import { TitlePage } from '~/components/Header/TitlePage';

import ability from '~/ability';
import utils from '~/lib/utils';
import { CategoryConnectorAPI, CatalogAPI, SupplierAPI } from '~/lib/api';
import { categoryConnectorTypes } from '~/config/types';
import { get, del, post } from '~/services/api/rest/client';
import { loadMore } from '~/lib/InfiniteScroll';
import { paths } from '~/routes';

const { Item: FormItem } = Form;
const { Option } = Select;
const { confirm } = Modal;
const style = {
    icon: {
        marginRight: 10,
        fontSize: 18,
        cursor: 'pointer',
    },
};
const showDeleteConfirm = (deleteFunc, id) => {
    confirm({
        title: 'Deseja realmente apagar esse conector?',
        okText: 'Sim',
        cancelText: 'Não',
        onOk() {
            return deleteFunc(id);
        },
        onCancel() {},
    });
};

const SelectStyled = styled(Select)`
    width: 100%;
`;

const mapStateToProps = (state) => {
    const { currentUser } = state.user;

    return { currentUser };
};

class ConnectorCategoryList extends Component {
    state = {
        catalogModal: false,
        catalogs: [],
        connectors: [],
        fetchCategoryConnectors: {
            hasMore: false,
            result: [],
            total: 0,
        },
        defaultType: 'custom',
        error: false,
        hasMore: true,
        loading: true,
        newConnectorCategoryLoading: false,
        supplier: {},
    };

    componentDidMount() {
        document.title = 'Grid2B | Conector de categorias';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async (refresh = false) => {
        try {
            if (refresh) {
                this.setState({
                    error: false,
                    loading: true,
                });
            }

            const { supplierId } = this.props.match.params;

            const [fetchCategoryConnectors, fetchCatalogs, fetchSupplier] = await Promise.all([
                CategoryConnectorAPI.fetchCategoryConnectors(
                    supplierId,
                    this.props.location.search
                ),
                CatalogAPI.fetchCatalogs(),
                SupplierAPI.fetchSupplier(supplierId),
            ]);

            this.setState((state) => {
                return {
                    catalogs: fetchCatalogs.result,
                    fetchCategoryConnectors: {
                        ...state.fetchCategoryConnectors,
                        ...fetchCategoryConnectors,
                        hasMore: Boolean(fetchCategoryConnectors.pagination.next),
                    },
                    loading: false,
                    supplier: fetchSupplier.result,
                };
            });
        } catch (error) {
            Notification('error', 'Ocorreu um erro ao buscar seus conectores, tente novamente!');
            this.setState({
                error: true,
                loading: false,
            });
        }
    };

    getConnectors = async (options = {}) => {
        try {
            const { supplierId } = this.props.match.params;
            const queryString = qs.stringify(options);
            const { result } = await get(
                `/categories/connectors?supplierId=${supplierId}&${queryString}`
            );

            return result;
        } catch (error) {
            throw error;
        }
    };

    getCatalogs = async () => {
        try {
            const { result } = await get('/categories/catalogs');

            return result;
        } catch (error) {
            throw error;
        }
    };

    loadMoreCategoryConnectors = loadMore.bind(
        this,
        'fetchCategoryConnectors',
        this.getConnectors.bind(this)
    );

    delete = async (connectorId) => {
        try {
            await del(`categories/connectors/${connectorId}`);

            this.setState((prevState) => {
                return {
                    fetchCategoryConnectors: {
                        ...prevState.fetchCategoryConnectors,
                        result: prevState.fetchCategoryConnectors.result.filter((connector) => {
                            return connector.connectorId !== connectorId;
                        }),
                    },
                };
            });

            Notification('success', 'Conector apagado!');
        } catch (error) {
            console.log('ERROR delete: ', error);

            if (error?.response?.data?.details) {
                const { dependencies } = error.response.data.details;
                const errors = dependencies.reduce((errors, error) => {
                    const liError = <li>{error.name}</li>;
                    errors.push(liError);

                    return errors;
                }, []);

                Notification(
                    'error',
                    'Ocorreu um erro ao apagar o mapeamento de categoria!',
                    <Fragment>
                        <span>Ela está sendo usado nos seguintes agendamentos.</span>
                        <ul>{errors}</ul>
                    </Fragment>
                );

                return;
            }

            Notification(
                'error',
                'Ocorreu um erro ao apagar o mapeamento de categoria, tente novamente!'
            );
        }
    };

    refreshData = () => {
        this.setState({ error: false, loading: true });
        this.getAndSetInitialData();
    };

    transformColumns = () => {
        return [
            {
                title: 'Título',
                key: 'title',
                alias: 'name',
                dataIndex: 'title',
                width: '30%',
                align: 'left',
            },
            {
                title: 'Catálogo de categoria',
                key: 'categoryCatalog',
                dataIndex: 'categoryCatalog',
                hidden: true,
                width: '30%',
                align: 'left',
            },
            {
                title: 'Tipo',
                key: 'type',
                dataIndex: 'type',
                type: 'select',
                options: categoryConnectorTypes.map(({ label, value }) => {
                    return {
                        name: label,
                        value,
                    };
                }),
                width: '30%',
                align: 'left',
            },
            {
                title: '',
                key: 'actions',
                dataIndex: 'actions',
                hidden: true,
                align: 'right',
                width: '10%',
                render: (connector) => {
                    return (
                        <Fragment>
                            <Can
                                do="update"
                                on={Object.assign(connector, { __type: 'CategoryConnector' })}
                            >
                                <Link
                                    to={`${this.props.location.pathname}/edit/${
                                        connector.connectorId
                                    }/items`}
                                >
                                    <Icon
                                        type="edit"
                                        style={{
                                            cursor: style.icon.cursor,
                                            fontSize: style.icon.fontSize,
                                        }}
                                    />
                                </Link>
                            </Can>
                            <Can
                                do="delete"
                                on={Object.assign(connector, { __type: 'CategoryConnector' })}
                            >
                                <a
                                    href="Javascript:Void(0);" // eslint-disable-line
                                    onClick={() => {
                                        return showDeleteConfirm(
                                            this.delete,
                                            connector.connectorId
                                        );
                                    }}
                                    style={{ marginLeft: 10 }}
                                >
                                    <Icon
                                        type="delete"
                                        style={{
                                            cursor: style.icon.cursor,
                                            fontSize: style.icon.fontSize,
                                        }}
                                    />
                                </a>
                            </Can>
                        </Fragment>
                    );
                },
            },
        ];
    };

    transformData = () => {
        const connectorsTransformed = this.state.fetchCategoryConnectors.result.map((connector) => {
            const catalog = this.state.catalogs.find((catalog) => {
                return catalog.catalogId === connector.catalogId;
            });

            return {
                id: connector.connectorId,
                title: connector.name,
                categoryCatalog: catalog && catalog.name,
                type: this.getType(connector.type),
                actions: connector,
            };
        });

        return connectorsTransformed;
    };

    getType = (type) => {
        switch (type) {
            case 'default':
                return 'Padrão';
            case 'custom':
                return 'Personalizado';
            default:
                return '';
        }
    };

    handleCatalog = () => {
        this.setState((state) => {
            return { catalogModal: !state.catalogModal };
        });
    };

    confirmCatalog = () => {
        const { currentUser } = this.props;
        const { supplierId } = this.props.match.params;
        const supplierConfig = currentUser?.suppliers?.find((supplierConfig) => {
            return supplierConfig.supplierId === supplierId;
        });

        if (
            ability.can('create', {
                status: supplierConfig.status || false,
                __type: 'ListProductsImport',
            })
        ) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.setState({ newConnectorCategoryLoading: true });
                    const { defaultType } = this.state;
                    const { supplierId } = this.props.match.params;
                    const { catalog, name, type } = values;
                    const payload = {
                        name,
                        catalogId: catalog,
                        supplierId,
                        type: type || defaultType,
                    };

                    this.creaAndRedirectToCategoryConnector(payload);
                }
            });

            return;
        }

        Notification('error', 'Ative o distribuidor para criar um mapeamento de categorias');
    };

    creaAndRedirectToCategoryConnector = async (payload) => {
        try {
            const connector = await this.createCategoryConnector(payload);
            this.props.history.push(`${this.props.match.url}/edit/${connector.connectorId}/items`);

            Notification('success', 'Conector de categoria criado!');
        } catch (error) {
            this.setState({ newConnectorCategoryLoading: false });

            Notification(
                'error',
                'Ocorreu um erro durante a criação do conector, tente novamente!'
            );
        }
    };

    createCategoryConnector = async (payload) => {
        try {
            const { result } = await post('/categories/connectors', payload);

            return result;
        } catch (error) {
            throw error;
        }
    };

    render() {
        const {
            catalogs,
            catalogModal,
            error,
            loading,
            newConnectorCategoryLoading,
            fetchCategoryConnectors: { hasMore, total },
            supplier,
        } = this.state;
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const columns = this.transformColumns();
        const data: Array<?Object> = this.transformData();

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={paths.suppliers}>Distribuidores</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={`${paths.suppliers}/${this.props.match.params.supplierId}/settings`}
                        >
                            {supplier.name}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Mapeamentos de categorias</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage
                        title="Categorias"
                        subtitle="Personalize ou crie novos mapeamentos de categorias para exportação."
                    />
                    <div className="content-filters">
                        <DynamicFilter
                            onSubmit={() => {
                                return this.getAndSetInitialData(true);
                            }}
                            filters={columns}
                        />
                        <HeaderInfo
                            showing={data.length}
                            total={total}
                            actions={
                                <Button type="primary" icon="plus" onClick={this.handleCatalog}>
                                    Criar
                                </Button>
                            }
                        />
                        <Modal
                            confirmLoading={newConnectorCategoryLoading}
                            okText="Salvar"
                            onOk={this.confirmCatalog}
                            onCancel={this.handleCatalog}
                            title="Novo conector de categorias"
                            visible={catalogModal}
                        >
                            {/* <p>Selecione o catálogo de categorias que deseja relacionar</p> */}
                            <Row gutter={16}>
                                <Col
                                    span={
                                        ability.can('create-with-type', 'CategoryConnector')
                                            ? 12
                                            : 24
                                    }
                                >
                                    <FormItem label="Nome">
                                        {getFieldDecorator('name', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Insira o nome do conector!',
                                                },
                                            ],
                                        })(<Input />)}
                                    </FormItem>
                                </Col>
                                <Can do="create-with-type" a="CategoryConnector" passThrought>
                                    {(can) => {
                                        return (
                                            <Col span={12}>
                                                <FormItem label="Tipo">
                                                    {getFieldDecorator('type', {
                                                        rules: [
                                                            {
                                                                required: can,
                                                                message:
                                                                    'Selecione o tipo do conector!',
                                                            },
                                                        ],
                                                    })(
                                                        <Select style={{ width: '100%' }}>
                                                            {categoryConnectorTypes.map((type) => {
                                                                return (
                                                                    <Option
                                                                        key={type.value}
                                                                        value={type.value}
                                                                    >
                                                                        {type.label}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </Col>
                                        );
                                    }}
                                </Can>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <FormItem label="Catálogo">
                                        {getFieldDecorator('catalog', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Selecione um catálogo de categorias',
                                                },
                                            ],
                                        })(
                                            <SelectStyled
                                                filterOption={(input, option) => {
                                                    return utils.filterOptions(input, option);
                                                }}
                                                showSearch
                                            >
                                                {catalogs.map((catalog) => {
                                                    return (
                                                        <SelectStyled.Option
                                                            key={catalog.catalogId}
                                                            value={catalog.catalogId}
                                                        >
                                                            {catalog.name}
                                                        </SelectStyled.Option>
                                                    );
                                                })}
                                            </SelectStyled>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                        </Modal>
                    </div>
                    <div
                        className="infinite-container"
                        style={{ height: window.innerHeight - 307, overflow: 'auto' }}
                    >
                        <InfiniteScroll
                            hasMore={hasMore}
                            initialLoad={false}
                            loadMore={this.loadMoreCategoryConnectors}
                            threshold={20}
                            useWindow={false}
                        >
                            <Table
                                columns={columns}
                                data={data}
                                error={error}
                                infiniteScroll
                                loading={loading}
                                pagination={false}
                                refreshData={() => {
                                    return this.getAndSetInitialData(true);
                                }}
                            />
                        </InfiniteScroll>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps)(Form.create()(ConnectorCategoryList));
