import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Form, Tooltip } from 'antd';
import qs from 'query-string';
import debounce from 'debounce-promise';
import { AsyncCreatable } from 'react-select';

import DynamicFilter from '~/components/Filters/DynamicFilter';
import HeaderInfo from '~/components/Header/HeaderInfo';
import InfiniteScroll from '~/components/InfiniteScroller';
import Notification from '~/components/Notification/AntNotification';
import Table from '~/components/Table/AntTable';
import WithSupplier from '~/components/HOC/WithSupplier';
import { TitlePage } from '~/components/Header/TitlePage';

import utils from '~/lib/utils';
import { get, post } from '~/services/api/rest/client';
import { loadMore } from '~/lib/InfiniteScroll';
import { paths } from '~/routes';
import ability from '~/ability';

class EditCategoryConnector extends Component {
    getCatalogItemsDebounced: Function;

    constructor(props) {
        super(props);

        this.state = {
            associatedCategories: [],
            catalog: {},
            catalogModalShowing: false,
            catalogs: [],
            connector: {},
            createLoading: false,
            createNewCategoryLoading: false,
            error: false,
            errorCatalogModal: false,
            loading: true,
            loadingCatalogSelect: false,
            loadingNewCategory: false,
            supplierCategoriesData: {
                hasMore: false,
                pagination: {},
                result: [],
                total: 0,
            },
            userCategories: [],
        };

        this.getCatalogItemsDebounced = debounce(this.getCatalogItems, 500);
    }

    componentDidMount() {
        document.title = 'Grid2B | Conector de categorias';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async (refresh = false) => {
        try {
            if (refresh) {
                this.setState({
                    loading: true,
                    error: false,
                });
            }

            const [supplierCategoriesData, connector, mappings] = await Promise.all([
                this.getSupplierCategories(),
                this.getCategoryConnector(),
                this.getCategoryConnectorItems(),
            ]);
            const [catalog, categories] = await Promise.all([
                this.getCatalog(connector.catalogId),
                this.getCatalogItems(connector.catalogId, { root: true }),
            ]);

            connector.mappings = mappings;

            this.setState((state) => {
                return {
                    catalog,
                    connector,
                    loading: false,
                    supplierCategoriesData: {
                        ...state.supplierCategoriesData,
                        ...supplierCategoriesData,
                        hasMore: Boolean(supplierCategoriesData.pagination.next),
                    },
                    userCategories: categories,
                };
            }, this.createAssociatedCategories);
        } catch (error) {
            console.log('ERROR getAndSetInitialData: ', error);

            this.setState({
                error: true,
                loading: false,
            });
        }
    };

    getSupplierCategories = async (options = {}) => {
        try {
            const { connectorId, supplierId } = this.props.match.params;
            const { search } = this.props.location;
            const currentFilters = qs.parse(search);
            const filters = {
                ...options,
                ...currentFilters,
                orderBy: 'name',
                sort: 'asc',
            };

            if (filters.name) {
                delete filters.orderBy;
                delete filters.sort;
            }

            if (currentFilters.mapped) {
                Object.assign(filters, { connectorId });
            }

            const queryString = qs.stringify(filters);
            const data = await get(`/categories/catalogs/${supplierId}/items?${queryString}`);

            return data;
        } catch (error) {
            throw error;
        }
    };

    getCategoryConnector = async () => {
        try {
            const { connectorId } = this.props.match.params;
            const { result } = await get(`/categories/connectors/${connectorId}`);

            return result;
        } catch (error) {
            console.log('ERROR getCategoryConnector: ', error);
            throw error;
        }
    };

    getCategoryConnectorItems = async () => {
        try {
            const { connectorId } = this.props.match.params;
            const { result } = await get(`/categories/connectors/${connectorId}/items`);

            return result;
        } catch (error) {
            console.log('ERROR getCategoryConnectorItems: ', error);
            throw error;
        }
    };

    getCatalog = async (catalogId, options = {}) => {
        try {
            const queryString = qs.stringify(options);
            const { result } = await get(`categories/catalogs/${catalogId}?${queryString}`);

            return result;
        } catch (error) {
            console.log('Error getCatalog: ', error);
            throw error;
        }
    };

    getCatalogItems = async (catalogId, options = {}) => {
        try {
            const filters = {
                ...options,
                orderBy: 'name',
                sort: 'asc',
            };

            if (filters.name) {
                delete filters.orderBy;
                delete filters.sort;
            }

            const queryString = qs.stringify(filters);
            const { result } = await get(`categories/catalogs/${catalogId}/items?${queryString}`);

            return result;
        } catch (error) {
            console.log('Error getCatalog: ', error);
            throw error;
        }
    };

    createAssociatedCategories = () => {
        const {
            supplierCategoriesData: { result: supplierCategories },
            connector: { mappings = [] },
        } = this.state;
        const associatedCategories = supplierCategories.map((supplierCategory) => {
            const categoryMapped = mappings.find((mapping) => {
                return mapping.sourceId === supplierCategory.categoryId;
            });

            if (categoryMapped) {
                return {
                    key: supplierCategory.categoryId,
                    name: utils.normalizeCategoryName(supplierCategory.name),
                    label: utils.normalizeCategoryName(categoryMapped.targetName),
                    value: categoryMapped.targetId,
                    isLoading: false,
                };
            }

            return {
                key: supplierCategory.categoryId,
                name: supplierCategory.name,
                label: '',
                value: '',
                isLoading: false,
            };
        });

        this.setState({ associatedCategories });
    };

    loadMoreSupplierCategories = () => {
        const loadMoreScoped = loadMore.bind(this);

        return loadMoreScoped(
            'supplierCategoriesData',
            this.getSupplierCategories,
            this.createAssociatedCategories
        );
    };

    transformCategoriesToOptions = (categories) => {
        const options = categories.map(({ categoryId, name }) => {
            const normalizeName = utils.normalizeCategoryName(name);

            return {
                value: categoryId,
                label: normalizeName,
            };
        });

        return options;
    };

    createAndSetCategory = (categoryToCreate) => {
        return async (supplierCategoryId) => {
            if (categoryToCreate) {

                const catalog = Object.assign(this.state.catalog, { __type: 'Catalog' });
                const cannotUpdate = ability.cannot('update', catalog);

                if (cannotUpdate) {
                    Notification('info', 'Não é possível adicionar uma categoria à um catálogo padrão!');
                    return true;
                }

                const {
                    supplierCategoriesData: { result: supplierCategories },
                } = this.state;
                this.setState({ createNewCategoryLoading: true });

                try {
                    this.setSelectLoadingState(supplierCategoryId, true);

                    const categories = await this.createCategory(categoryToCreate);
                    const categorySelected = categories.filter(
                        (category): boolean => {
                            return category.name === categoryToCreate;
                        }
                    );
                    const supplierCategory = supplierCategories.find(
                        (supplierCategory): boolean => {
                            return supplierCategory.categoryId === supplierCategoryId;
                        }
                    );

                    this.setState(
                        (state) => {
                            return {
                                userCategories: utils.sort(
                                    state.userCategories.concat(categories),
                                    'asc',
                                    'name'
                                ),
                            };
                        },
                        () => {
                            this.setStateAssociatedCategories(
                                this.transformCategoriesToOptions(categorySelected)[0],
                                supplierCategory
                            );
                        }
                    );

                    this.setSelectLoadingState(supplierCategoryId, false);
                    Notification('success', 'Categoria criada!');

                    return true;
                } catch (error) {
                    console.log('ERROR createAndSetCategory: ', error);

                    this.setState({ createNewCategoryLoading: false });
                    Notification('error', 'Ocorreu um erro ao criar a categoria, tente novamente!');

                    return false;
                }
            }
        };
    };

    setSelectLoadingState = (supplierCategoryId, isLoading) => {
        const supplierCategory = this.state.supplierCategoriesData.result.find(
            (supplierCategory) => {
                return supplierCategory.categoryId === supplierCategoryId;
            }
        );

        this.setState((state) => {
            return {
                ...state,
                associatedCategories: state.associatedCategories.map((associatedCategory) => {
                    if (associatedCategory.key === supplierCategory.categoryId) {
                        return {
                            ...associatedCategory,
                            isLoading,
                        };
                    }
                    return associatedCategory;
                }),
            };
        });
    };

    create = (event) => {
        event.preventDefault();

        this.props.form.validateFields(async (err) => {
            try {
                this.setState({ createLoading: true });

                const { connectorId } = this.props.match.params;
                const payload = this.transformPayload();
                await post(`/categories/connectors/${connectorId}/items`, payload);

                Notification('success', 'Mapeamento salvo!');
            } catch (error) {
                console.log('ERROR create: ', error);

                Notification('error', 'Falha ao criar conector, tente novamente!');
            } finally {
                this.setState({ createLoading: false });
            }
        });
    };

    transformPayload = () => {
        const connectorCategory = this.state.associatedCategories
            .filter((associatedCategory) => {
                return associatedCategory.value;
            })
            .map((associatedCategory) => {
                return {
                    sourceId: associatedCategory.key,
                    targetId: associatedCategory.value,
                };
            });

        return { mappings: connectorCategory };
    };

    createCategory = async (category) => {
        try {
            const { catalogId } = this.state.connector;
            const { result } = await post(
                `/categories/catalogs/${catalogId}/items`,
                this.transformCreateCategoryPayload(category)
            );

            return result;
        } catch (error) {
            throw error;
        }
    };

    transformCreateCategoryPayload(category) {
        return {
            name: category,
            createParents: true,
        };
    }

    handleChange = (categorySelected) => {
        return (supplierCategoryId) => {
            const {
                supplierCategoriesData: { result: supplierCategories },
            } = this.state;
            const supplierCategory = supplierCategories.find((supplierCategory) => {
                return supplierCategory.categoryId === supplierCategoryId;
            });

            this.setStateAssociatedCategories(categorySelected, supplierCategory);
        };
    };

    setStateAssociatedCategories = (categorySelected, supplierCategory) => {
        const associatedCategories = this.state.associatedCategories.map((associatedCategory) => {
            if (associatedCategory.key === supplierCategory.categoryId) {
                return {
                    ...categorySelected,
                    key: supplierCategory.categoryId,
                    name: supplierCategory.name,
                };
            }

            return associatedCategory;
        });

        this.setState({ associatedCategories });
    };

    filterOptions = async (inputValue) => {
        let categories = this.state.userCategories;
        const promiseFilter = new Promise(async (resolve, reject) => {
            try {
                if (Boolean(inputValue)) {
                    const result = await this.getCatalogItemsDebounced(
                        this.state.connector.catalogId,
                        { name: inputValue }
                    );
                    categories = result;
                }

                resolve(this.transformCategoriesToOptions(categories));
            } catch (error) {
                console.log('ERROR filterOptions: ', error);
                reject(error);
            }
        });

        return promiseFilter;
    };

    transformFilters = () => {
        const defaultFilters = this.transformColumns();
        const mappedFilter = {
            title: 'Categoria mapeada',
            key: 'mapped',
            type: 'select',
            options: [{ value: true, name: 'Sim' }, { value: false, name: 'Não' }],
        };

        defaultFilters.push(mappedFilter);

        return defaultFilters;
    };

    transformColumns = () => {
        const columns = [
            {
                alias: 'name',
                className: 'overflow',
                dataIndex: 'supplierCategory',
                key: 'supplierCategory',
                title: 'Categorias do Distribuidor',
                width: '45%',
                render: (name) => {
                    return (
                        <Tooltip title={name}>
                            <span className="overflow-hidden">{name}</span>
                        </Tooltip>
                    );
                },
            },
            {
                dataIndex: 'userCategory',
                hidden: true,
                key: 'userCategory',
                title: (
                    <Fragment>
                        Suas Categorias{' '}
                        <Link
                            className="decorate-link"
                            to={`${paths.categories}/show/${
                                this.state.catalog.catalogId
                            }/categories`}
                        >
                            ({this.state.catalog.name})
                        </Link>
                    </Fragment>
                ),
                width: '55%',
                render: (associated) => {
                    const isLoading = associated.userCategory && associated.userCategory.isLoading;
                    const valueProps = {
                        ...(associated.userCategory &&
                            Boolean(associated.userCategory.value) && {
                            value: associated.userCategory,
                        }),
                    };

                    return (
                        <AsyncCreatable
                            key={JSON.stringify(this.state.userCategories)}
                            cacheOptions={false}
                            defaultOptions
                            formatCreateLabel={(inputValue) => {
                                return <div>Criar nova categoria '{inputValue}'.</div>;
                            }}
                            isDisabled={isLoading}
                            loadOptions={this.filterOptions}
                            onChange={(value) => {
                                return this.handleChange(value)(associated.supplierCategory);
                            }}
                            onCreateOption={(value) => {
                                return this.createAndSetCategory(value)(
                                    associated.supplierCategory
                                );
                            }}
                            placeholder={'Selecione ou crie uma categoria'}
                            {...valueProps}
                        />
                    );
                },
            },
        ];

        return columns;
    };

    transformData = () => {
        const { associatedCategories } = this.state;
        const data = associatedCategories.map((category) => {
            return {
                key: category.key,
                supplierCategory: utils.normalizeCategoryName(category.name),
                userCategory: {
                    supplierCategory: category.key,
                    userCategory: {
                        label: category.label,
                        value: category.value,
                        isLoading: category.isLoading,
                    },
                },
            };
        });

        return data;
    };

    render() {
        const {
            connector,
            createLoading,
            error,
            loading,
            supplierCategoriesData: { hasMore, total },
        } = this.state;
        const { supplier } = this.props;
        const filters = this.transformFilters();
        const columns = this.transformColumns();
        const data = this.transformData();

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={paths.suppliers}>Distribuidores</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={`${paths.suppliers}/${this.props.match.params.supplierId}/settings`}
                        >
                            {supplier.name}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={`${paths.suppliers}/${
                                this.props.match.params.supplierId
                            }/categories`}
                        >
                            Mapeamentos de categorias
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Editar</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage
                        title="Editar conector de categorias"
                        extraTitle={connector.name}
                        subtitle="Mapeamento de categorias para exportação."
                        actions={[
                            <Button
                                onClick={this.create}
                                icon="save"
                                key="save"
                                loading={createLoading}
                                type="primary"
                            >
                                Salvar
                            </Button>,
                        ]}
                    />
                    <div className="content-filters">
                        <DynamicFilter
                            onSubmit={() => {
                                return this.getAndSetInitialData(true);
                            }}
                            filters={filters}
                        />
                        <HeaderInfo showing={data.length} total={total} />
                    </div>
                    <Form>
                        <div
                            className="infinite-container"
                            style={{ height: window.innerHeight - 306, overflow: 'auto' }}
                        >
                            <InfiniteScroll
                                hasMore={hasMore}
                                initialLoad={false}
                                loadMore={this.loadMoreSupplierCategories}
                                threshold={20}
                                useWindow={false}
                            >
                                <Table
                                    columns={columns}
                                    data={data}
                                    error={error}
                                    loading={loading}
                                    pagination={false}
                                    refreshData={() => {
                                        return this.getAndSetInitialData(true);
                                    }}
                                />
                            </InfiniteScroll>
                        </div>
                    </Form>
                </div>
            </Fragment>
        );
    }
}

export default WithSupplier(Form.create()(EditCategoryConnector));
