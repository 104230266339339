import React, { useState, useEffect, Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Col, Form, Icon, Input, Modal, Row, Progress, Tooltip } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Can from '~/components/Can';
import DynamicFilter from '~/components/Filters/DynamicFilter';
import HeaderInfo from '~/components/Header/HeaderInfo';
import InfiniteScroll from '~/components/InfiniteScroller';
import Notification from '~/components/Notification/AntNotification';
import Table from '~/components/Table/AntTable';
import { DeleteConfirm } from '~/components/Confirm';
import { TitlePage } from '~/components/Header/TitlePage';
import InputNumber from '~/components/Input/Number';

import states from '~/lib/States';
import { InventoryAPI, TransactionAPI, EventAPI } from '~/lib/api';
import { handleSort } from '~/lib/Sort';
import { loadMore } from '~/lib/InfiniteScroll';
import { paths } from '~/routes';

const style = {
    icon: {
        marginRight: 10,
        fontSize: 18,
        cursor: 'pointer',
    },
};

const { Item: FormItem } = Form;

const PercentItem = styled(FormItem)`
    .ant-input-number-input-wrap {
        display: inline-block;
        position: relative;
        width: 90%;
    }

    .ant-input-number-input-wrap::after {
        position: absolute;
        content: '%';
    }
`;

const InventoryProgress = (props) => {
    const [percent, setPercent] = useState(0);
    const { startDate, endDate, description } = props;
    const totalDiff = moment(endDate).diff(startDate, 's');

    const updatePercent = () => {
        const currentDiff = moment(endDate).diff(undefined, 's');
        const currentPercent = Math.round((totalDiff - currentDiff) / totalDiff * 100);
        setPercent(currentPercent);
    };

    useEffect(() => {
        if (!startDate && !endDate) {
            return;
        }

        updatePercent();
        const intervalID = setInterval(updatePercent, 15000);
        return () => {
            clearInterval(intervalID);
        };
    }, [startDate, endDate]);

    return (
        <Tooltip title={description}>
            <Progress
                status={percent < 100 ? 'active' : null}
                percent={Math.min(100, percent)}
                size="small"
            />
        </Tooltip>
    );
};

class InventoryList extends Component {

    constructor() {
        super();
        this.state = {
            createInventoryLoading: false,
            errors: false,
            fetchInventories: {
                hasMore: true,
                pagination: {},
                result: [],
                total: 0,
            },
            transactions: [],
            loading: true,
            showInventoryModal: false,
            total: 0,
            inventoryId: '',
        };
    }

    componentDidMount() {
        document.title = 'Grid2B | Inventários';
        this.getAndSetInitialData();
        this.inventoryUpdateProgress();
    }

    getAndSetInitialData = async (refresh = false) => {
        try {
            if (refresh) {
                this.setState({
                    errors: false,
                    loading: true,
                });
            }

            const [
                fetchInventories,
                fetchTransactions,
            ] = await Promise.all([
                InventoryAPI.fetchInventories(),
                TransactionAPI.fetchTransactions(),
            ]);

            this.setState((prevState) => {
                return {
                    fetchInventories: {
                        ...prevState.fetchInventories,
                        ...fetchInventories,
                        hasMore: Boolean(fetchInventories.pagination.next),
                    },
                    loading: false,
                    total: fetchInventories.total,
                    transactions: this.transformTransactions(fetchTransactions.result),
                };
            });
        } catch (error) {
            console.log('ERROR getAndSetInitialData: ', error);

            this.setState({
                errors: true,
                loading: false,
            });
        }
    };

    inventoryUpdateProgress = () => {
        EventAPI.bind('inventory-update-progress', (data) => {
            const { exportId, total, processed } = data;
            this.setState((prevState) => {
                const transactions = [...prevState.transactions];

                const index = transactions.findIndex((transaction) => {
                    return transaction.transactionId === exportId;
                });

                if (index === -1) {
                    transactions.push({
                        transactionId: exportId, total, processed,
                    });
                } else if (processed > (transactions[index].processed || 0)) {
                    transactions[index].processed = processed;
                    transactions[index].total = total;
                }

                return { transactions };
            });
        });
    }

    transformTransactions = (transactions) => {
        return transactions
            .filter((transaction) => {
                const totalProcessed = transaction.events.find((event) => {
                    return event?.description === 'export-finished';
                })?.metadata?.totalProcessed;

                return transaction?.details?.inventoryProgress
                    && moment(transaction.updatedAt).isAfter(moment().subtract(15, 'minutes'))
                    && transaction.status !== 'error'
                    && (totalProcessed === undefined || totalProcessed > 0)
                    && !transaction?.details?.inventoryProgress?.finishAt;
            })
            .map((transaction) => {
                const { transactionId, details } = transaction;
                const { processed, total } = details.inventoryProgress;
                return { transactionId, total, processed };
            });
    }

    saveInventory = () => {
        this.props.form.validateFields(async (error, values) => {
            if (!error) {
                try {
                    this.setState({ createInventoryLoading: true });
                    const payload = this.makePayload(values);
                    const { result } = this.state.inventoryId === ''
                        ? await InventoryAPI.createInventory(payload)
                        : await InventoryAPI.updateInventory(this.state.inventoryId, payload);

                    this.setState(({ fetchInventories }) => {
                        const inventories = [...fetchInventories.result];
                        const index = inventories.findIndex((inventory) => {
                            return inventory.inventoryId === result.inventoryId;
                        });

                        if (index === -1) {
                            inventories.push(result);
                        } else {
                            inventories[index] = result;
                        }

                        return {
                            fetchInventories: {
                                ...fetchInventories,
                                result: inventories,
                            },
                            showInventoryModal: false,
                        };
                    });

                    Notification('success', 'Inventário salvo com sucesso!');
                } catch (error) {
                    console.log('Error: ', error);
                    Notification(
                        'error',
                        'Ocorreu um erro ao tentar salvar o inventário, tente novamente!'
                    );
                } finally {
                    this.setState({ createInventoryLoading: false });
                }
            }
        });
    };

    makePayload = (values) => {
        return {
            name: values.name,
            allowedPrice: true,
            defaultMargin: values.defaultMargin,
        };
    };

    delete = async (inventoryId) => {
        try {
            await InventoryAPI.deleteInventory(inventoryId);

            this.setState((prevState) => {
                return {
                    fetchInventories: {
                        ...prevState.fetchInventories,
                        result: prevState.fetchInventories.result.filter((inventory) => {
                            return inventory.inventoryId !== inventoryId;
                        }),
                    },
                };
            });
            Notification('success', 'Inventário apagado!');
        } catch (error) {
            console.log('ERROR delete: ', error);

            if (error?.response?.data?.details) {
                const { dependencies } = error.response.data.details;
                const errors = dependencies.reduce((errors, error) => {
                    const liError = <li>{error.name}</li>;
                    errors.push(liError);

                    return errors;
                }, []);

                Notification(
                    'error',
                    'Ocorreu um erro ao apagar o inventário!',
                    <Fragment>
                        <span>Ela está sendo usado nos seguintes agendamentos.</span>
                        <ul>{errors}</ul>
                    </Fragment>
                );

                return;
            }

            Notification(
                'error',
                'Ocorreu um erro ao apagar o inventário, tente novamente!'
            );
        }
    };

    handleInventoryModal = () => {
        this.props.form.resetFields();
        this.setState((state) => {
            return {
                showInventoryModal: !state.showInventoryModal,
                inventoryId: '',
            };
        });
    };

    handleUpdateInventoryModal = (inventory = {}) => {
        if (inventory.inventoryId) {
            this.props.form.setFieldsValue({
                name: inventory.name,
                defaultMargin: inventory.defaultMargin,
            });
        }

        this.setState((state) => {
            return {
                showInventoryModal: !state.showInventoryModal,
                inventoryId: inventory.inventoryId,
            };
        });
    };

    loadMoreInventories = () => {
        const loadMoreScoped = loadMore.bind(this);

        return loadMoreScoped('fetchInventories', this.getAndSetInitialData);
    };

    handleSort = (pagination, filters, sorter) => {
        const handleSortScoped = handleSort.bind(this);

        return handleSortScoped(sorter, this.getAndSetInitialData);
    };

    getDistributionCenterName = (distributionCenter) => {
        const distributionCenterUF = distributionCenter.slice(0, 2);
        const state = states.find((state) => {
            return state.uf === distributionCenterUF;
        });

        if (!state) {
            return distributionCenter;
        }

        return `${distributionCenter} (${state.name})`;
    };

    transformColumns = () => {
        const columns = [
            {
                title: 'Título',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                width: '90%',
                render: (name, record, index) => {
                    const transactionProcess = this.state.transactions.length > 0;
                    const supplierProcess = this.props.currentUser?.supplierBackgroundProcess;
                    const inventoryProcess = Object.keys(record.actions.backgroundProcess).length > 0
                        ? record.actions.backgroundProcess
                        : false;

                    if (!transactionProcess && !supplierProcess && !inventoryProcess) {
                        return name;
                    }

                    let description = '';
                    let startDate = '';
                    let endDate = '';

                    if (transactionProcess) {
                        const [transaction] = this.state.transactions;
                        const percent = (100 / transaction.total) * transaction.processed;
                        return (
                            <div>
                                <span>{name}</span>
                                <div style={{ float: 'right', width: '15%' }}>
                                    <Tooltip title="Atualização de preços">
                                        <Progress
                                            status={percent < 100 ? 'active' : null}
                                            percent={Math.round(Math.min(100, percent)) || 0}
                                            size="small"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        );
                    }

                    if (inventoryProcess) {
                        description = 'Atualização de inventário';
                        startDate = inventoryProcess.started;
                        endDate = inventoryProcess.endDate;
                    }

                    if (supplierProcess) {
                        if (typeof supplierProcess === 'object') {
                            const suppliers = supplierProcess.suppliers.join(', ').replace(/, ((?:.(?!, ))+)$/, ' e $1');
                            description = `Importação de produtos ${suppliers}`;
                            startDate = supplierProcess.startDate;
                            endDate = supplierProcess.endDate;
                        } else {
                            description = 'Calculando tempo para importação';
                        }
                    }

                    return (
                        <div>
                            <span>{name}</span>
                            <div style={{ float: 'right', width: '15%' }}>
                                <InventoryProgress
                                    startDate={startDate}
                                    endDate={endDate}
                                    description={description}
                                />
                            </div>
                        </div>
                    );
                },
            },
            {
                title: '',
                dataIndex: 'actions',
                hidden: true,
                align: 'right',
                render: (inventory) => {
                    return (
                        <Fragment>
                            <Can
                                do="update"
                                this={Object.assign(inventory, { __type: 'ListProductsImport' })}
                            >
                                <Link to={`${this.props.match.url}/${inventory.inventoryId}/products/`}>
                                    <Icon type="eye" style={style.icon} />
                                </Link>
                            </Can>
                            <Can
                                do="update"
                                this={Object.assign(inventory, { __type: 'ListProductsImport' })}
                            >
                                <a
                                    href="Javascript:Void(0)" /* eslint-disable-line */
                                    onClick={() => {
                                        this.handleUpdateInventoryModal(inventory);
                                    }}
                                >
                                    <Icon type="edit" style={style.icon}/>
                                </a>
                            </Can>
                            <Can
                                do="delete"
                                this={Object.assign(inventory, { __type: 'ListProductsImport' })}
                            >
                                <a
                                    href="Javascript:Void(0)" /* eslint-disable-line */
                                    onClick={() => {
                                        return DeleteConfirm(
                                            () => {
                                                return this.delete(inventory.inventoryId);
                                            },
                                            {
                                                title:
                                                    'Deseja realmente apagar este inventário?',
                                                okText: 'Sim',
                                                cancelText: 'Não',
                                            }
                                        );
                                    }}
                                >
                                    <Icon
                                        type="delete"
                                        style={{
                                            cursor: style.icon.cursor,
                                            fontSize: style.icon.fontSize,
                                        }}
                                    />
                                </a>
                            </Can>
                        </Fragment>
                    );
                },
            },
        ];

        return columns;
    };

    transformData = () => {
        const { result: inventories } = this.state.fetchInventories;

        const data = inventories.map((inventory) => {
            return {
                key: inventory.inventoryId,
                name: inventory.name,
                actions: inventory,
            };
        });

        return data;
    };

    render() {
        const {
            createInventoryLoading,
            errors,
            fetchInventories,
            loading,
            showInventoryModal,
            inventoryId,
        } = this.state;
        const { hasMore, total } = fetchInventories;
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const columns = this.transformColumns();
        const data = this.transformData();

        const modalProps = inventoryId === ''
            ? {
                title: 'Criar inventário',
                onCancel: this.handleInventoryModal,
            }
            : {
                title: 'Editar inventário',
                onCancel: this.handleUpdateInventoryModal,
            };

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Inventários</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage
                        title="Inventários"
                        subtitle="Personalize ou cria novas listas de importação de produtos."
                    />
                    <div className="content-filters">
                        <DynamicFilter
                            onSubmit={() => {
                                return this.getAndSetInitialData(true);
                            }}
                            filters={columns}
                        />
                        <HeaderInfo
                            showing={data.length}
                            total={total}
                            actions={
                                <Button
                                    type="primary"
                                    className="success"
                                    icon="plus"
                                    onClick={this.handleInventoryModal}
                                >
                                    Criar
                                </Button>
                            }
                        />
                    </div>
                    <Modal
                        confirmLoading={createInventoryLoading}
                        okText="Salvar"
                        onCancel={() => {
                            modalProps.onCancel();
                        }}
                        onOk={this.saveInventory}
                        title={modalProps.title}
                        visible={showInventoryModal}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <FormItem label="Nome">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Insira o nome do inventário',
                                            },
                                        ],
                                    })(<Input />)}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <PercentItem label="Margem de preço padrão">
                                    {getFieldDecorator('defaultMargin', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Insira uma margem padrão para os produto',
                                            },
                                        ],
                                    })(
                                        <InputNumber style={{ width: '100%' }} min={0} max={1000}/>
                                    )}
                                </PercentItem>
                            </Col>
                        </Row>
                    </Modal>
                    <div
                        className="infinite-container"
                        style={{ height: window.innerHeight - 307, overflow: 'auto' }}
                    >
                        <InfiniteScroll
                            hasMore={hasMore}
                            initialLoad={false}
                            loadMore={this.loadMoreInventories}
                            threshold={20}
                            useWindow={false}
                        >
                            <Table
                                columns={columns}
                                data={data}
                                error={errors}
                                loading={loading}
                                onChange={this.handleSort}
                                pagination={false}
                                refreshData={() => {
                                    return this.getAndSetInitialData(true);
                                }}
                            />
                        </InfiniteScroll>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentUser } = state.user;
    return { currentUser };
};

export default connect(mapStateToProps)(Form.create()(InventoryList));
