import React from 'react';
import { Row, Col, Icon } from 'antd';
import * as R from 'ramda';

import { Item, Description, Label, StepsStyled, StepStyled } from '../styles/Export';
import Card from '~/components/Card';
import utils from '~/lib/utils';

export default function CreditBuy(props) {

    function getEventTitle(description, type) {
        switch (description) {

            case 'credits-buy-starting':
                return 'Processo de compra de créditos iniciado';

            case 'credits-buy-awaiting-payment':
                return 'Status do Pagamento: "Aguardando"';

            case 'credits-buy-in-review':
                return 'Status do Pagamento: "Em análise"';

            case 'credits-buy-pay':
                return 'Status do Pagamento: "Pago"';

            case 'credits-buy-canceled':
                return 'Status do Pagamento: "Cancelado"';

            default:
                break;
        }
    }

    const paymentMethods = {
        'bankBillet': 'Boleto',
        'onlineDebit': 'Débito Online',
        'creditCard': 'Cartão de Crédito',
    };

    const paymentLink = R.prop('paymentLink', props.details);
    const planDescription = R.prop('planDescription', props.details);
    const transactionId = R.prop('transactionId', props);

    return (
        <Row gutter={20}>
            <Col xs={24} sm={12} lg={8}>
                <Card style={{ marginBottom: 20 }}>
                    <Item>
                        <Label type="secondary">ID da transação</Label>
                        <Description style={{ wordWrap: 'break-word' }}>{(
                            transactionId.includes('_') ? transactionId.substring(14) : transactionId
                        )}</Description>
                    </Item>
                    {typeof planDescription !== 'undefined' && (
                        <Item>
                            <Label type="secondary">Descrição</Label>
                            <Description>{planDescription}</Description>
                        </Item>
                    )}
                    <Item>
                        <Label type="secondary">Quantidade de créditos</Label>
                        <Description>{utils.toFixed(R.prop('quantity', props.details))}</Description>
                    </Item>
                    <Item>
                        <Label type="secondary">Valor da compra</Label>
                        <Description>{utils.toCurrency(R.prop('totalPrice', props.details))}</Description>
                    </Item>
                    <Item>
                        <Label type="secondary">Método de pagamento</Label>
                        <Description>{paymentMethods[R.prop('payment', props.details)]}</Description>
                    </Item>
                    {(props.status === 'pending' && paymentLink) && (
                        <Item>
                            <Label type="secondary">
                                <a target="_blank" href={paymentLink}>
                                    <Icon type="download" style={{ marginRight: 10 }}/>
                                    Link para pagamento
                                </a>
                            </Label>
                        </Item>
                    )}
                </Card>
            </Col>
            <Col xs={24} sm={12} lg={8}>
                <Card style={{ marginBottom: 20 }}>
                    <Item>
                        <h3>Histórico de Eventos</h3>
                    </Item>
                    <StepsStyled direction="vertical" progressDot current={props.events.length}>
                        {props.events.map((event, index) => {
                            return (
                                <StepStyled
                                    key={index}
                                    title={getEventTitle(event.description, event?.metadata?.type)}
                                    description={new Date(event.timestamp).toLocaleString()}
                                    status={event.status ? 'finish' : 'error'}
                                />
                            );
                        })}
                    </StepsStyled>
                </Card>
            </Col>
        </Row>
    );
}
