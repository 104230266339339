import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Form, Icon, Input, Button } from 'antd';
import { FaLongArrowAltLeft } from 'react-icons/fa';

/** Components */
import Notification from '~/components/Notification/AntNotification';

/** Styles */
import {
    Description,
    DescriptionContainer,
    FormItemStyled as FormItem,
    LinkStyled as Link,
} from '../styles';

class PasswordRecovery extends Component {
    state = { loading: false };

    componentDidMount() {
        document.title = 'Grid2B | Esqueci minha senha';
    }

    recovery = (event) => {
        event.preventDefault();

        this.props.form.validateFields(async (err, values) => {
            if (!err) {

                const { email } = values;

                try {
                    this.setState({ loading: true });

                    await Auth.forgotPassword(email);

                    this.props.history.push({
                        pathname: '/auth/login/password-renew',
                        state: { email },
                    });
                } catch (error) {
                    this.setState({ loading: false });

                    switch (error.code) {
                        case 'UserNotFoundException':
                            Notification('error', 'Usuário não encontrado!');
                            break;
                        case 'NotAuthorizedException':
                            Notification(
                                'error',
                                'Usuário desativado! Entre em contato com nossa equipe'
                            );
                            break;
                        case 'LimitExceededException':
                            Notification(
                                'warning',
                                'Limite de tentativas excedido para esta operação. Tente novamente mais tarde',
                            );
                            break;
                        case 'InvalidParameterException':
                            if (error.message === 'Cannot reset password for the user as there is no registered/verified email or phone_number') {
                                Notification(
                                    'warning',
                                    'Por favor, confirme seu e-mail antes de alterar a senha',
                                );

                                this.props.history.push({
                                    pathname: '/auth/confirm',
                                    state: { email },
                                });

                                break;
                            }
                            // eslint-disable-next-line no-fallthrough
                        default:
                            Notification(
                                'error',
                                'Ocorreu um erro ao enviar seu código de confirmação!'
                            );
                            break;
                    }
                }
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const validateStatusAll = 'validating';

        return (
            <Form onSubmit={this.recovery}>
                <DescriptionContainer>
                    <Description>Esqueci minha senha</Description>
                </DescriptionContainer>
                <FormItem
                    validateStatus={validateStatusAll}
                >
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                type: 'email',
                                message: 'Formato de email inválido!',
                            },
                            {
                                required: true,
                                message: 'Por favor, insira seu email!',
                            },
                        ],
                    })(
                        <Input
                            autoFocus
                            placeholder="Email"
                        />
                    )}
                </FormItem>
                <FormItem>
                    <Button loading={this.state.loading} type="primary" block htmlType="submit">
                        Enviar
                    </Button>
                </FormItem>
                <DescriptionContainer last>
                    <Description>
                        <Link to="/auth/login">
                            <FaLongArrowAltLeft /> Voltar para o login
                        </Link>
                    </Description>
                </DescriptionContainer>
            </Form>
        );
    }
}

export default Form.create()(PasswordRecovery);
