import React from 'react';
import { Row, Col } from 'antd';
import * as R from 'ramda';

import { Item, Description, Label, Link, StepsStyled, StepStyled } from '../styles/Export';
import Card from '~/components/Card';
import utils from '~/lib/utils';

export default function Export(props) {
    function getExtraDetails(events) {
        const descriptionEquals = R.propEq('description', 'export-finished');
        const event = R.find(descriptionEquals)(events);
        const [credits, creditsAfterDiscount, totalProcessed, url, chunks] = R.props(
            ['credits', 'creditsAfterDiscount', 'totalProcessed', 'url', 'chunks'],
            R.prop('metadata', event)
        );

        const details = {
            credits,
            creditsAfterDiscount,
            totalProcessed,
            chunks: Array.isArray(chunks) ? chunks : [],
        };

        if (url && !chunks) {
            details.chunks.push({ outputUrl: url });
        }

        return details;
    }

    function getEventTitle(event) {
        const { description } = event;
        const type = event?.metadata?.eventName || event?.metadata?.type;

        switch (description) {
            case 'export-started':
                return 'Começou';

            case 'export-finished':
                return 'Terminou';

            case 'export-notification':
                return `Notificação por ${type}`;

            case 'export-notification-started':
                return `Notificação por ${type} iniciou`;

            case 'export-notification-finished':
                return `Notificação por ${type} terminou`;

            case 'export-without-credits':
                return 'Sem créditos para exportação';

            case 'export-unauthorized':
                return 'Não autorizado';

            case 'export-empty-inventory':
                return 'Inventário não possui produtos ativos';

            case 'export-supplier-error':
                return 'Falha no distribuidor';

            case 'export-error':
                return 'Falha na exportação';

            case 'export-without-any-import':
                return 'Falha na exportação - A primeira janela de atualização ainda não foi processada.';

            default:
                break;
        }
    }

    function getEventDescription(event) {
        if (event?.metadata?.error?.message) {
            return (
                <Item>
                    Erro: {event?.metadata?.error?.message} <br/>
                    {new Date(event.timestamp).toLocaleString()}
                </Item>
            );
        }

        return new Date(event.timestamp).toLocaleString();
    }

    return (
        <Row gutter={20}>
            <Col xs={24} sm={12} lg={8}>
                <Card style={{ 'margin-bottom': 20 }}>
                    <Item>
                        <Label type="secondary">ID da transação</Label>
                        <Description style={{ wordWrap: 'break-word' }}>{R.prop('transactionId', props).substring(14)}</Description>
                    </Item>
                    <Item>
                        <Label type="secondary">Agendamento</Label>
                        <Description>{R.prop('scheduleName', props.details)}</Description>
                    </Item>
                    <Item>
                        <Label type="secondary">Conector</Label>
                        <Description>{R.prop('connectorName', props.details)}</Description>
                    </Item>
                    <Item>
                        <Label type="secondary">Inventário de produtos</Label>
                        <Description>{R.prop('inventoryName', props.details) || 'Todos os produtos'}</Description>
                    </Item>
                    <Item>
                        <Label type="secondary">Tipo</Label>
                        <Description>{R.prop('delta', props.details) ? 'Delta' : 'Full'}</Description>
                    </Item>
                    <Item>
                        <Label type="secondary">Distribuidor</Label>
                        <Description>{R.prop('supplierName', props.details)}</Description>
                    </Item>
                </Card>
            </Col>
            <Col xs={24} sm={12} lg={8}>
                <Card style={{ 'margin-bottom': 20 }}>
                    <Item>
                        <Label type="secondary">Quantidade de créditos consumidos</Label>
                        <Description>
                            {utils.toFixed(['success', 'warning'].includes(props.status) ? R.prop('credits', getExtraDetails(props.events)) : 0)}
                        </Description>
                    </Item>
                    {['success', 'warning'].includes(props.status) && (
                        <Item>
                            <Label type="secondary">Quantidade de créditos após a operação</Label>
                            <Description>{utils.toFixed(R.prop('creditsAfterDiscount', getExtraDetails(props.events)))}</Description>
                        </Item>
                    )}
                    <Item>
                        <Label type="secondary">Quantidade de produtos processados</Label>
                        {['success', 'warning'].includes(props.status) ? R.prop('totalProcessed', getExtraDetails(props.events)) : 0}
                    </Item>
                    {(
                        ['success', 'warning'].includes(props.status)
                        && R.prop('chunks', getExtraDetails(props.events)).length > 0
                        && (R.prop('totalProcessed', getExtraDetails(props.events)) || 0) > 0
                    ) && (
                        R.prop('chunks', getExtraDetails(props.events)).map((chunk, index) => {
                            const suffix = (R.prop('chunks', getExtraDetails(props.events)).length > 1) ? ` - Parte ${index + 1}` : '';
                            return (
                                <Item>
                                    <Label>
                                        <Link
                                            href={R.prop('outputUrl', chunk)}
                                            target="_blank"
                                            alt="url-import"
                                        >
                                            Ver produtos processados{suffix}
                                        </Link>
                                    </Label>
                                </Item>
                            );
                        })
                    )}
                </Card>
            </Col>
            <Col xs={24} sm={12} lg={8}>
                <Card style={{ 'margin-bottom': 20 }}>
                    <Item>
                        <h3>Histórico de Eventos</h3>
                    </Item>
                    <StepsStyled direction="vertical" progressDot current={props.events.length}>
                        {props.events.map((event, index) => {
                            return (
                                <StepStyled
                                    key={index}
                                    title={getEventTitle(event)}
                                    description={getEventDescription(event)}
                                    status={event.status ? 'finish' : 'error'}
                                />
                            );
                        })}
                    </StepsStyled>
                </Card>
            </Col>
        </Row>
    );
}
