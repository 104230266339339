export default {
    reference: 'Referência do produto no distribuidor. (Alfanumérico)',
    reference_unique: 'Referência do produto no Grid2b. (Alfanumérico)',
    title: 'Nome do produto. (Alfanumérico)',
    categories: 'Categoria do produto. É possível definir um separador, exemplo: "|" (Informática|Monitor|LCD). (Alfanumérico)',
    category_1: '1ª categoria da árvore. (Alfanumérico)',
    category_2: '2ª categoria da árvore. (Alfanumérico)',
    category_3: '3ª categoria da árvore. (Alfanumérico)',
    category_4: '4ª categoria da árvore. (Alfanumérico)',
    category_5: '5ª categoria da árvore. (Alfanumérico)',
    description: 'Descrição do produto. (Alfanumérico)',
    brand: 'Marca. (Alfanumérico)',
    height: 'Altura em centímetros. (Numérico)',
    width: 'Largura em centímetros. (Numérico)',
    length: 'Comprimento em centímetros. (Numérico)',
    weight: 'Peso em gramas. (Numérico)',
    weight_kg: 'Peso em quilos. (Numérico)',
    barcode: 'Código de barras. (Numérico)',
    multiple: 'Múltiplo, exemplo caixa com 5, 10, 20 un. (Numérico)',
    unit: 'Unidade do produto. (Alfanumérico)',
    ncm: 'NCM. (Alfanumérico)',
    ppb: 'PPB. (Booleano)',
    origem: 'Origem fiscal. (Alfanumérico)',
    fiscal_name: 'Nome fiscal. (Alfanumérico)',
    metadata: 'Informações adicionais. (Alfanumérico)',
    quantity: 'Quantidade em estoque. (Numérico)',
    price: 'Preço de custo. (Numérico)',
    slug: 'Nome do produto formatado para URL. (Alfanumérico)',
    sell_price: 'Preço de venda. (Numérico)',
    images: 'Imagens do produto (principal + adicionais). É possível definir um separador, exemplo: "|" (img1|img2|img3). (Alfanumérico)',
    other_images: 'Imagens adicionais. É possível definir um separador, exemplo: "|" (img2|img3). (Alfanumérico)',
    image_1: '1ª imagem do produto (Alfanumérico)',
    image_2: '2ª imagem do produto. (Alfanumérico)',
    image_3: '3ª imagem do produto. (Alfanumérico)',
    image_4: '4ª imagem do produto. (Alfanumérico)',
    image_5: '5ª imagem do produto. (Alfanumérico)',
    grids: 'Variações do produto. É possível definir separadores para o tipo da variação e para o valor dela, exemplo: 1º separador = ":", 2º separador = "|" retornaria Cor:Preto|Voltagem:110 Volts. (Alfanumérico)',
    distribution_center: 'Código do centro de distribuição. (Alfanumérico)',
    aliquot_ipi: 'Alíquota IPI. (Numérico)',
    aliquot_reducao: 'Alíquota Redução. (Numérico)',
    aliquot_cofins: 'Alíquota Cofins. (Numérico)',
    aliquot_pis: 'Alíquota PIS. (Numérico)',
    with_st: 'Produto com ST. (Booleano)',
    NENHUM: 'Campo adicional para informações fixas.',
};
