import styled from 'styled-components';
import { Avatar } from 'antd';

const AvatarStyled = styled(Avatar)`
    transition: all .2s ease-in-out;

    &:active, &:hover {
        transform: scale(2);
    }
`;

export default AvatarStyled;
