import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Divider, Form, Icon, Input, Radio, Select, Spin } from 'antd';
import { prop } from 'ramda';
import InputMask from 'react-input-mask';
import moment from 'moment';

import Loading from '~/components/Loading';
import Notification from '~/components/Notification/AntNotification';
import { TitlePage } from '~/components/Header/TitlePage';

import states from '~/lib/States';
import utils from '~/lib/utils';
import { UserAPI, ZipcodeAPI } from '~/lib/api';
import { setCurrentUser } from '~/store/actions/user';

const { Item: FormItem } = Form;
const { Option } = Select;
const { Group: RadioGroup } = Radio;

const INITIAL_ADDRESS = {
    city: '',
    district: '',
    state: '',
    street: '',
    type: '',
    zipcode: '',
};

class Profile extends Component {
    state = {
        personType: 'legal',
        searchingAddress: false,
        user: {
            email: '',
            document: '',
            mainPhone: '',
            additionalPhone: '',
            site: '',
            address: INITIAL_ADDRESS,
        },
        cellPhone: '',
        initialLoading: true,
        saveLoading: false,
    };

    componentDidMount() {
        document.title = 'Grid2B | Minha conta | Perfil';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async () => {
        try {
            const fetchUser = await UserAPI.fetchUser();
            this.setState((prevState) => {
                return {
                    user: fetchUser.result,
                    initialLoading: false,
                    personType: fetchUser.result?.typePerson || prevState.personType,
                };
            });
        } catch (error) {
            console.log('ERROR getInitialData: ', error);
            this.setState({
                error: true,
                initialLoading: false,
            });
        }
    };

    save = (event) => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                try {
                    this.setState({ saveLoading: true });
                    const payload = this.buildPayload(values);

                    const updateUser = await UserAPI.updateUser(payload);
                    this.props.dispatch(setCurrentUser(updateUser.result));

                    Notification('success', 'Perfil atualizado!');
                } catch (error) {
                    console.log('ERROR save: ', error);
                    Notification(
                        'error',
                        'Ocorreu um erro ao atualizar o perfil, tente novamente!'
                    );
                } finally {
                    this.setState({ saveLoading: false });
                }
            }
        });
    };

    buildPayload = (values) => {
        const payload = {
            name: values.name,
            additionalPhone: (values.additionalPhone || '').replace(/\D/g, '') || null,
            mainPhone: (values.cellPhone || '').replace(/\D/g, ''),
            site: values.site,
            typePerson: this.state.personType,
            address: {
                street: values.street,
                number: values.number,
                zipcode: values.zipcode,
                complement: values.complement || null,
                city: values.city,
                state: values.state,
                district: values.district,
            },
        };

        if (payload.typePerson === 'physical') {
            payload.cpf = values.cpf;
            payload.rg = values.rg || null;
            payload.genre = values.genre;
            payload.birthday = moment(values.birthday, 'DD-MM-YYYY').format('YYYY-MM-DD');
        } else if (payload.typePerson === 'legal') {
            payload.cnpj = values.cnpj;
            payload.fantasyName = values.fantasyName || null;
            payload.ie = values.ie || null;
        }

        return payload;
    };

    changeCellPhone = (event) => {
        this.setState({
            cellPhone: event.target.value.replace(/\D/g, ''),
        });
    };

    changePersonType = (event) => {
        try {
            const { value: personType } = event.target;
            this.setState({ personType });
        } catch (error) {
            console.log('ERROR changePersonType: ', error);
        }
    };

    changeZipcode = async (event) => {
        const { form } = this.props;
        const zipcode = event.target.value.replace(/\D/, '');

        if (zipcode.length === 0) {
            this.setState(
                {
                    address: INITIAL_ADDRESS,
                },
                () => {
                    return form.resetFields([
                        'number',
                        'city',
                        'complement',
                        'district',
                        'state',
                        'street',
                        'zipcode',
                    ]);
                }
            );
        }

        if (zipcode.length === 8) {
            this.setState({ searchingAddress: true });
            const { data } = await ZipcodeAPI.fetchAddress(zipcode);
            this.setState(
                (state) => {
                    return {
                        searchingAddress: false,
                        user: {
                            ...state.user,
                            address: Object.keys(data.result).reduce((address, field) => {
                                address[field] = data.result[field].trim();
                                return address;
                            }, {}),
                        },
                    };
                },
                () => {
                    return form.resetFields([
                        'number',
                        'city',
                        'complement',
                        'district',
                        'state',
                        'street',
                        'zipcode',
                    ]);
                }
            );
        }
    };

    validateCPF = (rule, value, callback) => {
        let valid = false;
        if (typeof value !== 'undefined') {
            const document = value.replace(/\D/g, '');

            if (document.length < 11) {
                callback();
            }

            if (document.length === 11) {
                valid = utils.cpfValidator(document);
            }

            if (!valid && document.length === 11) {
                callback('CPF inválido');
            }

            callback();
        }
    };

    validateCNPJ = (rule, value, callback) => {
        let valid = false;
        if (typeof value !== 'undefined') {
            const document = value.replace(/\D/g, '');

            if (document.length < 14) {
                callback();
            }

            if (document.length === 14) {
                valid = utils.cnpjValidator(document);
            }

            if (!valid && document.length === 14) {
                callback('CNPJ inválido');
            }

            callback();
        }
    };

    render() {
        const { initialLoading, searchingAddress, personType, saveLoading, user } = this.state;
        const { address } = user;
        const { getFieldDecorator } = this.props.form;
        const suffixLoading = searchingAddress ? (
            <Spin spinning={searchingAddress} indicator={<Icon type="loading" spin />} />
        ) : null;

        const formFirstItemRow = {
            labelCol: {
                sm: { span: 6 },
            },
            wrapperCol: {
                sm: { span: 18 },
            },
        };
        const formItemLayout = {
            labelCol: {
                sm: { span: 8 },
            },
            wrapperCol: {
                sm: { span: 12 },
            },
        };
        const selectDdi = (
            <span>+55</span>
        );

        return (
            <div className="client-new-form">
                <Loading loading={initialLoading}>
                    <Form onSubmit={this.save} layout="horizontal">
                        <TitlePage
                            title="Dados Principais"
                            actions={
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={saveLoading}
                                    icon="save"
                                >
                                    Salvar
                                </Button>
                            }
                        />
                        <Divider />
                        <div className="form-row-item">
                            <div className="form-item">
                                <FormItem {...formFirstItemRow} label="Pessoa">
                                    <RadioGroup defaultValue={personType} value={personType} onChange={this.changePersonType}>
                                        <Radio value="legal">Jurídica</Radio>
                                        <Radio value="physical">Física</Radio>
                                    </RadioGroup>
                                </FormItem>
                            </div>
                            <div className="form-item-second" />
                        </div>
                        <div className="form-row-item">
                            <div className="form-item">
                                <FormItem {...formFirstItemRow} label="Nome">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Campo "Nome" é obrigatório',
                                            },
                                        ],
                                        initialValue: user.name,
                                    })(<Input />)}
                                </FormItem>
                            </div>
                            <div className="form-item-second">
                                {this.state.personType === 'physical' ? (
                                    <FormItem {...formItemLayout} label="Data de Nascimento">
                                        {getFieldDecorator('birthday', {
                                            validateFirst: true,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Campo "Data de Nascimento" é obrigatório',
                                                },
                                                {
                                                    validator: (rule, value, callback) => {
                                                        const validFormat = /\d{2}\/\d{2}\/\d{4}/.test(value);
                                                        const validDate = validFormat && moment(value, 'DD/MM/YYYY').isValid();

                                                        if (!validDate) {
                                                            return callback(true);
                                                        }

                                                        return callback();
                                                    },
                                                    message: 'Data inválida',
                                                },
                                            ],
                                            initialValue: user.birthday && moment(
                                                user.birthday,
                                                'YYYY/MM/DD'
                                            ).format('DD/MM/YYYY'),
                                        })(
                                            <InputMask
                                                mask="99/99/9999"
                                                maskChar={null}
                                                disabled={false}
                                            >
                                                {(inputProps) => {
                                                    return <Input {...inputProps} />;
                                                }}
                                            </InputMask>
                                        )}
                                    </FormItem>
                                ) : (
                                    <FormItem {...formItemLayout} label="Nome Fantasia">
                                        {getFieldDecorator('fantasyName', {
                                            initialValue: user.fantasyName,
                                        })(<Input />)}
                                    </FormItem>
                                )}
                            </div>
                        </div>
                        <div className="form-row-item">
                            <div className="form-item">
                                <FormItem {...formFirstItemRow} label="* E-mail">
                                    <Input value={user.email} readOnly/>
                                </FormItem>
                            </div>
                            <div className="form-item-second">
                                {this.state.personType === 'physical' && (
                                    <FormItem {...formItemLayout} label="Gênero">
                                        {getFieldDecorator('genre', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Campo "Gênero" é obrigatório',
                                                },
                                            ],
                                            initialValue: user.genre,
                                        })(
                                            <RadioGroup>
                                                <Radio value="male">Masculino</Radio>
                                                <Radio value="female">Feminino</Radio>
                                            </RadioGroup>
                                        )}
                                    </FormItem>
                                )}
                            </div>
                        </div>
                        <div className="form-row-item">
                            <div className="form-item">
                                <FormItem {...formFirstItemRow} label="Celular">
                                    {getFieldDecorator('cellPhone', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Campo "Celular" é obrigatório',
                                            },
                                        ],
                                        initialValue: user.mainPhone,
                                    })(
                                        <InputMask
                                            mask="(99) 99999-9999"
                                            maskChar={null}
                                            disabled={false}
                                        >
                                            {(inputProps) => {
                                                return (
                                                    <Input
                                                        {...inputProps}
                                                        addonBefore={selectDdi}
                                                    />
                                                );
                                            }}
                                        </InputMask>
                                    )}
                                </FormItem>
                            </div>
                            <div className="form-item-second">
                                <FormItem {...formItemLayout} label="Outro Telefone">
                                    {getFieldDecorator('additionalPhone', {
                                        initialValue: user.additionalPhone,
                                    })(
                                        <InputMask
                                            mask="(99) 9999-9999"
                                            maskChar={null}
                                            disabled={false}
                                        >
                                            {(inputProps) => {
                                                return (
                                                    <Input
                                                        {...inputProps}
                                                        addonBefore={selectDdi}
                                                    />
                                                );
                                            }}
                                        </InputMask>
                                    )}
                                </FormItem>
                            </div>
                        </div>
                        <div className="form-row-item">
                            {this.state.personType === 'physical' ? (
                                <Fragment>
                                    <div className="form-item">
                                        <FormItem {...formFirstItemRow} label="CPF">
                                            {getFieldDecorator('cpf', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Campo "CPF" é obrigatório',
                                                    },
                                                    { validator: this.validateCPF },
                                                ],
                                                initialValue: user.cpf || '',
                                            })(
                                                <InputMask
                                                    mask="999.999.999-99"
                                                    maskChar={null}
                                                    disabled={false}
                                                >
                                                    {(inputProps) => {
                                                        return <Input {...inputProps} />;
                                                    }}
                                                </InputMask>
                                            )}
                                        </FormItem>
                                    </div>
                                    <div className="form-item-second">
                                        <FormItem {...formItemLayout} label="RG">
                                            {getFieldDecorator('rg', {
                                                initialValue: user.rg,
                                            })(<Input />)}
                                        </FormItem>
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <div className="form-item">
                                        <FormItem {...formFirstItemRow} label="CNPJ">
                                            {getFieldDecorator('cnpj', {
                                                rules: [
                                                    { required: true, message: 'Campo "CNPJ" é obrigatório' },
                                                    { validator: this.validateCNPJ },
                                                ],
                                                initialValue: user.cnpj || '',
                                            })(
                                                <InputMask
                                                    mask="99.999.999/9999-99"
                                                    maskChar={null}
                                                    disabled={false}
                                                >
                                                    {(inputProps) => {
                                                        return <Input {...inputProps} />;
                                                    }}
                                                </InputMask>
                                            )}
                                        </FormItem>
                                    </div>
                                    <div className="form-item-second">
                                        <FormItem {...formItemLayout} label="IE">
                                            {getFieldDecorator('ie', {
                                                initialValue: user.ie,
                                            })(<Input />)}
                                        </FormItem>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                        <TitlePage title="Endereço" />
                        <Divider />
                        <div className="form-row-item">
                            <div className="form-item">
                                <FormItem {...formFirstItemRow} label="CEP">
                                    {getFieldDecorator('zipcode', {
                                        rules: [{ required: true, message: 'Campo "CEP" é obrigatório' }],
                                        initialValue: prop('zipcode', address),
                                    })(
                                        <InputMask
                                            mask="99999-999"
                                            maskChar={null}
                                            disabled={false}
                                            onChange={this.changeZipcode}
                                            suffix={suffixLoading}
                                        >
                                            {(inputProps) => {
                                                return <Input {...inputProps} />;
                                            }}
                                        </InputMask>
                                    )}
                                </FormItem>
                            </div>
                            <div className="form-item-second">
                                <FormItem {...formItemLayout} label="Estado">
                                    {getFieldDecorator('state', {
                                        rules: [
                                            { required: true, message: 'Campo "Estado" é obrigatório' },
                                        ],
                                        initialValue: prop('state', address),
                                    })(
                                        <Select>
                                            {states.map((state) => {
                                                return (
                                                    <Option key={state.uf} value={state.uf}>
                                                        {state.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </div>
                        </div>
                        <div className="form-row-item">
                            <div className="form-item">
                                <FormItem {...formFirstItemRow} label="Cidade">
                                    {getFieldDecorator('city', {
                                        rules: [
                                            { required: true, message: 'Campo "Cidade" é obrigatório' },
                                        ],
                                        initialValue: prop('city', address),
                                    })(<Input />)}
                                </FormItem>
                            </div>
                            <div className="form-item-second">
                                <FormItem {...formItemLayout} label="Bairro">
                                    {getFieldDecorator('district', {
                                        rules: [
                                            { required: true, message: 'Campo "Bairro" é obrigatório' },
                                        ],
                                        initialValue: prop('district', address),
                                    })(<Input />)}
                                </FormItem>
                            </div>
                        </div>
                        <div className="form-row-item">
                            <div className="form-item">
                                <FormItem {...formFirstItemRow} label="Endereço">
                                    {getFieldDecorator('street', {
                                        rules: [
                                            { required: true, message: 'Campo "Endereço" é obrigatório' },
                                        ],
                                        initialValue: prop('street', address),
                                    })(<Input />)}
                                </FormItem>
                            </div>
                            <div className="form-item-second">
                                <FormItem {...formItemLayout} label="Número">
                                    {getFieldDecorator('number', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Campo "Número" é obrigatório',
                                            },
                                        ],
                                        initialValue: prop('number', address),
                                    })(<Input />)}
                                </FormItem>
                            </div>
                        </div>
                        <div className="form-row-item">
                            <div className="form-item">
                                <FormItem {...formFirstItemRow} label="Complemento">
                                    {getFieldDecorator('complement', {
                                        initialValue: prop('complement', address),
                                    })(<Input />)}
                                </FormItem>
                            </div>
                            <div className="form-item-second" />
                        </div>
                    </Form>
                </Loading>
            </div>
        );
    }
}

export default connect()(Form.create()(Profile));
