const config = {
    links: {
        home: 'https://grid2b.com'
    },
    oauth: {
        domain: 'grid2b.auth.us-west-2.amazoncognito.com',
        scope: ['email', 'profile', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://grid2b.com/auth/login/social',
        redirectSignOut: 'https://grid2b.com/auth/login',
        responseType: 'code',
    },
    cognito: {
        REGION: 'us-west-2',
        USER_POOL_ID: 'us-west-2_0Enw7kcyJ',
        APP_CLIENT_ID: '6jljr1p1ij0tkurbodrqup9h7a',
        IDENTITY_POOL_ID: 'us-west-2:c9182759-4c97-48bd-8c5b-c0a76283c1c7',
    },
    app: {
        domain: 'https://grid2b.com',
        cdn: 'https://images.grid2b.com',
    },
    api: {
        url: 'https://grid2b.com/api/v1',
        headers: {
            'Content-Type': 'application/json',
        },
    },
    endpoints: {
        search: 'https://a4ucq19b2a.execute-api.us-west-2.amazonaws.com/prod/grid2b/search',
        zipcode: 'https://cep.wdna.com.br/zipcode/search',
        pagseguro: 'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js',
        websocket: 'wss://8ux0opryc4.execute-api.us-west-2.amazonaws.com/prod/',
    },
    catalog: {
        G2B: '1553099705976_499411bbd9ff48b4a9e3ebb57153896a-grid2b',
    },
};

export default config;
