import {
    ADD_PAYMENT_METHOD,
    GET_PAYMENT_METHODS,
} from '../actions/transactions';

const initialState = {
    paymentMethods: [],
};

function transactions(state = initialState, action) {
    const { type } = action;

    switch (type) {
        case ADD_PAYMENT_METHOD:
            const { payload } = action;

            return {
                ...state,
                paymentMethods: [payload],
            };
        case GET_PAYMENT_METHODS: {
            return {
                ...state,
                paymentMethods: [...state.paymentMethods],
            };
        }
        default:
            return state;
    }
}

export default transactions;