import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Form, Input, Icon, Button, Spin, Alert } from 'antd';
import { FaLongArrowAltLeft } from 'react-icons/fa';

/** Components */
import Notification from '~/components/Notification/AntNotification';

/** Styles */
import {
    Description,
    DescriptionContainer,
    FormItemStyled as FormItem,
    LinkStyled as Link,
    SignInLink as LinkActive,
} from '../styles';

class PasswordRenew extends Component {
    state = {
        loading: false,
        reSendConfirmationCodeLoading: false,
        passwordVisible: false,
        confirmPasswordVisible: false,
    };

    componentDidMount() {
        document.title = 'Grid2B | Nova senha';
    }

    renew = (event) => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                try {
                    this.setState({ loading: true });

                    const { email, confirmationCode, password } = values;
                    await Auth.forgotPasswordSubmit(email, confirmationCode, password);

                    Notification('success', 'Senha alterada!');
                    this.props.history.push('/auth/login');
                } catch (error) {
                    switch (error.code) {
                        case 'ExpiredCodeException':
                            Notification('error', 'Código de confirmação inválido ou expirado!');
                            break;
                        default:
                            Notification('error', 'Ocorreu um erro ao alterar sua senha!');
                            break;
                    }
                }
            }

            this.setState({ loading: false });
        });
    };

    reSendConfirmationCode = (event) => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll(['email'], async (err, values) => {
            if (!err) {
                try {
                    this.setState({ reSendConfirmationCodeLoading: true });

                    const { email } = values;
                    await Auth.forgotPassword(email);

                    Notification('success', 'Código de confirmação enviado para seu email');
                } catch (error) {
                    switch (error.code) {
                        case 'UserNotFoundException':
                            Notification('error', 'Usuário não encontrado!');
                            break;
                        case 'NotAuthorizedException':
                            Notification(
                                'error',
                                'Usuário desativado! Entre em contato com nossa equipe'
                            );
                            break;
                        case 'LimitExceededException':
                            Notification(
                                'warning',
                                'Limite de tentativas excedido para esta operação. Tente novamente mais tarde',
                            );
                            break;
                        default:
                            Notification(
                                'error',
                                'Ocorreu um erro ao enviar seu código de confirmação!'
                            );
                            break;
                    }
                } finally {
                    this.setState({ reSendConfirmationCodeLoading: false });
                }
            }
        });
    };

    handleChange = (e) => {
        const state = {};
        const { name } = e.target;
        const errors = this.state.errors;

        state[name] = e.target.value;

        if (errors[name]) {
            delete errors[name];
        }

        const states = Object.assign({}, errors, state);
        this.setState(states);
    };

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('As duas senhas não coincidem!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    getHistoryState = (key) => {
        const { state } = this.props.location;

        return state?.[key];
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { passwordVisible } = this.state;
        const { confirmPasswordVisible } = this.state;
        const validateStatusAll = 'validating';

        return (
            <Fragment>
                <Form onSubmit={this.renew}>
                    <DescriptionContainer>
                        <Description>Criar uma nova senha</Description>
                    </DescriptionContainer>
                    <DescriptionContainer>
                        <Description>
                            <Alert description="Informe o código de confirmação enviado para seu email" type="warning"/>
                        </Description>
                    </DescriptionContainer>
                    <FormItem
                        validateStatus={validateStatusAll}
                    >
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'E-mail inválido!',
                                },
                                {
                                    required: true,
                                    message: 'Por favor, insira o seu email!',
                                },
                                {
                                    min: 6,
                                    message: '',
                                },
                            ],
                            initialValue: this.getHistoryState('email'),
                        })(
                            <Input
                                autoFocus
                                disabled={Boolean(this.getHistoryState('email'))}
                                placeholder="Email"
                            />
                        )}
                    </FormItem>
                    <FormItem
                        validateStatus={validateStatusAll}
                    >
                        {getFieldDecorator('confirmationCode', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Insira o código de confirmação!',
                                },
                            ],
                        })(
                            <Input
                                placeholder="Código de confirmação"
                                type="text"
                            />
                        )}
                    </FormItem>
                    <FormItem
                        validateStatus={validateStatusAll}
                    >
                        {getFieldDecorator('password', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Insira a senha!',
                                },
                                {
                                    validator: this.validateToNextPassword,
                                },
                                {
                                    min: 6,
                                    message: 'Mínimo 6 caracteres!',
                                },
                            ],
                        })(
                            <Input
                                placeholder="Senha"
                                suffix={
                                    passwordVisible ? (
                                        <Icon
                                            type="eye-invisible"
                                            style={
                                                {
                                                    color: '#a7adc2',
                                                    fontSize: '18px'
                                                }
                                            }
                                            onClick={() => {
                                                return this.setState({
                                                    passwordVisible: false,
                                                });
                                            }}
                                        />
                                    ) : (
                                        <Icon
                                            type="eye"
                                            style={
                                                {
                                                    color: '#a7adc2',
                                                    fontSize: '18px'
                                                }
                                            }
                                            onClick={() => {
                                                return this.setState({
                                                    passwordVisible: true,
                                                });
                                            }}
                                        />
                                    )
                                }
                                type={passwordVisible ? 'text' : 'password'}
                            />
                        )}
                    </FormItem>
                    <FormItem
                        validateStatus={validateStatusAll}
                    >
                        {getFieldDecorator('confirm', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Confirme sua senha!',
                                },
                                {
                                    validator: this.compareToFirstPassword,
                                },
                            ],
                        })(
                            <Input
                                placeholder="Confirme sua senha"
                                suffix={
                                    confirmPasswordVisible ? (
                                        <Icon
                                            type="eye-invisible"
                                            style={
                                                {
                                                    color: '#a7adc2',
                                                    fontSize: '18px'
                                                }
                                            }
                                            onClick={() => {
                                                return this.setState({
                                                    confirmPasswordVisible: false,
                                                });
                                            }}
                                        />
                                    ) : (
                                        <Icon
                                            type="eye"
                                            style={
                                                {
                                                    color: '#a7adc2',
                                                    fontSize: '18px'
                                                }
                                            }
                                            onClick={() => {
                                                return this.setState({
                                                    confirmPasswordVisible: true,
                                                });
                                            }}
                                        />
                                    )
                                }
                                type={confirmPasswordVisible ? 'text' : 'password'}
                                onBlur={this.handleConfirmBlur}
                            />
                        )}
                    </FormItem>
                    <FormItem>
                        <Description>
                            Não tem um código?{' '}
                            <LinkActive to="#" onClick={this.reSendConfirmationCode}>
                                Clique aqui
                            </LinkActive>
                            {this.state.reSendConfirmationCodeLoading && (
                                <Spin
                                    indicator={<Icon type="loading" />}
                                    spinning
                                    style={{
                                        color: '#fff',
                                        marginLeft: 5,
                                    }}
                                />
                            )}
                        </Description>
                    </FormItem>
                    <FormItem>
                        <Button loading={this.state.loading} type="primary" block htmlType="submit">
                            Salvar
                        </Button>
                    </FormItem>
                </Form>
                <DescriptionContainer last>
                    <Description>
                        <Link to="/auth/login">
                            <FaLongArrowAltLeft /> Voltar para o login
                        </Link>
                    </Description>
                </DescriptionContainer>
            </Fragment>
        );
    }
}

export default withRouter(Form.create()(PasswordRenew));
