import { CategoryConnectorAPI, ConnectorAPI, InventoryAPI, ScheduleAPI, SupplierAPI } from '~/lib/api';

const defaultCategoryConnectorOption = [
    {
        connectorId: false,
        name: 'Sem mapeamento',
    },
];

const defaultInventoryOption = [
    {
        inventoryId: false,
        name: 'Todos os produtos',
    },
];

export async function getAndSetInitialData(supplierId) {
    try {
        const [
            fetchCategoryConnectors,
            fetchConnectors,
            fetchInventories,
            fetchSupplier,
            fetchSupplierDistributionCenters,
        ] = await Promise.all([
            CategoryConnectorAPI.fetchCategoryConnectors(supplierId),
            ConnectorAPI.fetchConnectors(),
            InventoryAPI.fetchInventories(),
            SupplierAPI.fetchSupplier(supplierId),
            SupplierAPI.fetchSupplierDistributionCenters(supplierId),
        ]);

        const transformedCategoryConnectors = transformCategoryConnectors(
            fetchCategoryConnectors.result
        );
        const transformedInventories = transformInventories(fetchInventories.result);

        return {
            categoryConnectors: transformedCategoryConnectors,
            connectors: fetchConnectors.result.filter((connector) => {
                return connector.available;
            }),
            distributionCenters: fetchSupplierDistributionCenters.result,
            inventories: transformedInventories,
            supplier: fetchSupplier.result,
        };
    } catch (error) {
        throw error;
    }
}

export async function create(supplier, data) {
    await ScheduleAPI.createSchedule(supplier.id, data);
}

export function transformPayload(supplier, data) {
    const { paramsPayload } = supplier;
    const { name, categoryConnector, connector, inventory, delta, defaultMargin } = data;
    const filterDistributionCenters = getFilterDistributionCenter(data.priceFilter);
    const configBySchedule = getConfigBySchedule(paramsPayload.schedule, data);

    const basePayload = {
        name,
        params: {
            ...(categoryConnector && { categoryConnectorId: categoryConnector }),
            connectorId: connector,
            delta,
            defaultMargin,
            ...(inventory && { inventoryId: inventory }),
            ...configBySchedule,
        },
    };

    if (data.priceFilter && !data.priceFilter.includes(false)) {
        basePayload.params = {
            ...basePayload.params,
            filterDistributionCenters,
        };
    }

    const schedules = Object.keys(data.schedules)
        .map((scheduleId) => {
            const schedule = data.schedules[scheduleId];
            const days = Object.keys(schedule).filter((day) => {
                return schedule[day];
            });

            return {
                scheduleId,
                days,
            };
        })
        .filter((schedule) => {
            return schedule.days.length;
        });

    return Object.assign(basePayload, { schedules });
}

export function getSupplierConfig(currentUser, supplierId) {
    const supplierConfig = currentUser?.suppliers?.find((supplierConfig) => {
        return supplierConfig.supplierId === supplierId;
    });

    return supplierConfig;
}

function transformCategoryConnectors(categoryConnectors) {
    const transformedCategoryConnectors = defaultCategoryConnectorOption.concat(categoryConnectors);

    return transformedCategoryConnectors;
}

function transformInventories(inventories) {
    const transformedInventories = defaultInventoryOption.concat(inventories);
    return transformedInventories;
}

function getFilterDistributionCenter(filter = []) {
    return filter.reduce((filterDistributionCenters, value) => {
        if (!Object.keys(filterDistributionCenters).length) {
            filterDistributionCenters.type = value;

            return filterDistributionCenters;
        }

        filterDistributionCenters.values = filterDistributionCenters.values
            ? filterDistributionCenters.values.push(value)
            : [value];

        return filterDistributionCenters;
    }, {});
}

function getConfigBySchedule(schedule = [], data) {
    return schedule.reduce((payload, param) => {
        payload[param.key] = data[param.key];
        return payload;
    }, {});
}
