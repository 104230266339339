import axios from 'axios';
import { Auth } from 'aws-amplify';

import config from '~/config';

async function makeRequest(uri, method, data) {
    const jwtToken = await getToken();
    let AuthorizationType = '';

    if (!uri.match(/(https|http)/g)) {
        AuthorizationType = 'Bearer ';
    }

    const headers = {
        Authorization: `${AuthorizationType}${jwtToken}`,
    };
    
    const accountId = window.localStorage.getItem('accountId');
    if (accountId) {
        headers['Account-id'] = accountId;
    }

    const client = axios.create({
        baseURL: config.api.url,
        headers: Object.assign(headers, config.api.headers),
    });

    return client[method](uri, data)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export const getToken = async () => {
    const session = await Auth.currentSession();
    const { jwtToken } = session.getIdToken();
    return jwtToken;
};

export const get = async (uri) => {
    try {
        const request = await makeRequest(uri, 'get', '');
        return request.data;
    } catch (err) {
        throw err;
    }
};

export const post = async (uri, data) => {
    try {
        const request = await makeRequest(uri, 'post', data);
        return request.data;
    } catch (err) {
        throw err;
    }
};

export const put = async (uri, data) => {
    try {
        const request = await makeRequest(uri, 'put', data);
        return request.data;
    } catch (err) {
        throw err;
    }
};

export const del = async (uri) => {
    try {
        const request = await makeRequest(uri, 'delete', '');
        return request.data;
    } catch (err) {
        throw err;
    }
};
