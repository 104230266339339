import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Hub } from 'aws-amplify';
import { Spin, Icon } from 'antd';

/** Styles */
import { DescriptionContainer, Description } from './styles';

/** Components */
import Notification from '~/components/Notification/AntNotification';

/** Actions */
import { setUserToken } from '~/store/actions/auth';
import { fetchCurrentUser } from '~/store/actions/user';

import ability, { defineRulesFor } from '~/ability';

import user from '~/lib/user';

const mapStateToProps = (state) => {
    const { userToken, isLoadingUserToken } = state.auth;
    return { userToken, isLoadingUserToken };
};

class SocialLogin extends Component {
    componentDidMount() {
        Hub.listen('auth', (data) => {
            const { channel, payload } = data;

            switch (channel) {
                case 'auth':
                    if (payload.event === 'signIn') {
                        this.checkUser();

                        break;
                    }

                    if (payload.event === 'signIn_failure') {
                        Notification('error', 'Ocorreu um erro');
                        this.props.history.push('/auth/login');

                        break;
                    }

                    break;
                default:
                    Notification('error', 'Ocorreu um erro');
                    this.props.history.push('/auth/login');

                    break;
            }
        });
    }

    checkUser = async () => {
        try {
            const { jwtToken: userToken } = await user.token();
            this.updateRulesFor(userToken);
            this.props.dispatch(fetchCurrentUser());

            this.props.dispatch(setUserToken(userToken, false));
        } catch (e) {
            this.props.dispatch(setUserToken(null, false));
        }
    };

    updateRulesFor = (userToken) => {
        const authenticatedUser = user.get(userToken);
        const { rules } = defineRulesFor(authenticatedUser);
        ability.update(rules);
    };

    render() {
        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

        return (
            <DescriptionContainer>
                <Description>
                    Redirecionando. <Spin indicator={antIcon} style={{ color: '#fff' }} />
                </Description>
            </DescriptionContainer>
        );
    }
}

export default connect(mapStateToProps)(SocialLogin);
