import React from 'react';

const hasError = (ErrorComponent) => {
    return (WrappedComponent) => {
        const HasError = (props) => {
            return props.error ? (
                <ErrorComponent onClick={props.refreshData} />
            ) : (
                <WrappedComponent {...props} />
            );
        };

        return HasError;
    };
};

export default hasError;
