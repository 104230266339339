import { Ability, AbilityBuilder } from '@casl/ability';

export default new Ability([], {
    subjectName(subject) {
        if (!subject || typeof subject === 'string') {
            return subject;
        }

        return subject.__type;
    },
});

export function defineRulesFor(user) {
    return AbilityBuilder.define((can, cannot) => {
        if (user.isAdmin) {
            can('manage', 'all');
        } else {
            can('create', ['Catalog', 'Client', 'Connector', 'Schedule']);
            can(['new', 'edit'], ['SupplierSchedule']);
            can(
                ['create', 'update'],
                ['CategoryConnector', 'ListProductsImport', 'SupplierSchedule'],
                { status: true }
            );
            can('read', [
                'Catalog',
                'CategoryConnector',
                'Checkout',
                'Client',
                'Connector',
                'Filter',
                'ListProductsImport',
                'MyAccount',
                'Panel',
                'Products',
                'Schedule',
                'Supplier',
                'SupplierSchedule',
                'SupplierSetting',
                'Transaction',
                'Inventory',
                'Order',
            ]);
            can(['select', 'show-actions'], 'Supplier', { status: true });
            can(['update', 'delete'], 'ListProductsImport');
            can(['update', 'delete'], ['Catalog', 'CategoryConnector', 'Connector'], {
                type: 'custom',
            });
            can(['update', 'delete'], 'Connector', { type: 'wdna' });
            can('save', 'Connector', { type: 'custom' });
            can(['create-with-format', 'edit-with-format'], 'Connector', { template: 'custom' });
            cannot('update', 'Product');
            cannot(
                [
                    'create-with-type',
                    'create-with-template',
                    'edit-with-type',
                    'edit-with-template',
                ],
                ['Catalog', 'CategoryConnector', 'Connector', 'ListProductsImport']
            );
        }
    });
}
