import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Form, Input, Modal, Row, Select, message } from 'antd';
import Cards from 'react-credit-cards';
import MaskedInput from 'react-text-mask';

import { addPaymentMethod } from '~/store/actions/transactions';
import utils from '~/lib/utils';

import 'react-credit-cards/es/styles-compiled.css';

const FormItem = Form.Item;
const Option = Select.Option;

const initialState = {
    number: '',
    name: '',
    cvc: '',
    month: '',
    year: '',
    expiry: 'xx/xxxx',
    focused: '',
    loading: false,
    cardNumberProperties: {},
};

class CreditCard extends Component {
    state = initialState;

    componentDidMount() {
        const { currentUser } = this.props;

        if (currentUser?.typePerson !== 'physical') {
            return;
        }

        this.setState({ name: currentUser.name });

        this.props.form.setFieldsValue({
            cardHolderName: currentUser.name,
            phone: currentUser.mainPhone,
            document: currentUser.cpf,
            birthdayDate: currentUser.birthday
                .substring(0, 10)
                .split('-')
                .reverse()
                .join('/'),
        });
    }

    getCreditCardBrand = (cardNumber) => {
        const cardBin = cardNumber.replace(/\D/g, '').substr(0, 6);
        return new Promise((success, error) => {
            // eslint-disable-next-line no-undef
            PagSeguroDirectPayment.getBrand({
                cardBin, success, error,
            });
        });
    };

    async getCreditCardToken(cardInfo) {
        const {
            cardNumber,
            securityCode,
            expiryMonth,
            expiryYear,
        } = cardInfo;

        const { brand } = await this.getCreditCardBrand(cardNumber);

        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-undef
            PagSeguroDirectPayment.createCardToken({
                brand: brand.name,
                cardNumber: cardNumber.replace(/\D/g, ''),
                cvv: securityCode.replace(/\D/g, ''),
                expirationMonth: expiryMonth,
                expirationYear: expiryYear,
                success: resolve,
                error: reject,
            });
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ loading: true });
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                // const {
                //     birthdayDate,
                //     cardHolderName,
                //     cardNumber,
                //     document,
                //     expiryMonth,
                //     expiryYear,
                //     phone,
                //     securityCode,
                // } = values;

                values.cardToken = await this.getCreditCardToken(values)
                    .then(({ card }) => { return card.token; })
                    .catch((error) => {
                        console.error(error);
                        return false; 
                    });

                if (!values.cardToken) {
                    this.setState({
                        loading: false,
                        cardNumberProperties: {
                            hasFeedback: true,
                            validateStatus: 'error',
                            help: 'Dados do cartão de crédito inválidos',
                        },
                    });
                    return;
                }

                try {
                    this.props.dispatch(addPaymentMethod(values));
                    await setTimeout(() => {
                        this.props.closeModal();
                        this.setState({ ...initialState });
                        this.props.form.resetFields();
                    }, 500);
                } catch (e) {
                    message.error(e.message);
                }
            }

            this.setState({ loading: false });
        });
    };

    onChangeSelect = (name) => {
        return (selected) => {
            this.setState((prevState) => {
                // console.log('selecionado: ', selected);
                // console.log(prevState.expiry);

                if (name === 'month') {
                    const expiry = prevState.expiry.slice(2);

                    return {
                        [name]: selected,
                        expiry: `${selected}${expiry}`,
                    };
                }

                const expiry = prevState.expiry.slice(0, 3);

                return {
                    [name]: selected,
                    expiry: `${expiry}${selected}`,
                };
            });
        };
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: name === 'name' ? value : value.replace(/\D/g, ''),
        });
    };

    validateCPF = (rule, value, callback) => {
        let valid = false;
        if (typeof value !== 'undefined') {
            const document = value.replace(/\D/g, '');

            if (document.length < 11) {
                callback();
            }

            if (document.length === 11) {
                valid = utils.cpfValidator(document);
            }

            if (!valid && document.length === 11) {
                callback('CPF inválido');
            }

            callback();
        }
    };

    render() {
        const { number, name, cvc, month, year, expiry, focused, loading } = this.state;
        const { getFieldDecorator } = this.props.form;

        const [creditCard] = this.props.creditCard;

        return (
            <Modal
                confirmLoading={loading}
                okText="Salvar"
                onCancel={() => {
                    this.setState({ ...initialState }, () => {
                        this.props.closeModal();
                    });
                }}
                onOk={this.handleSubmit}
                title="Informe os dados do cartão"
                visible={this.props.open}
                width={720}
                destroyOnClose
            >
                <div className="credit-card">
                    <Row gutter={16} type="flex" justify="center">
                        <Col xs={24} sm={12}>
                            <Form
                                className="payments"
                                onSubmit={this.handleSubmit}
                                layout="vertical"
                            >
                                <Row>
                                    <Col span={24}>
                                        <FormItem {...this.state.cardNumberProperties}>
                                            {getFieldDecorator('cardNumber', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: ' ',
                                                    },
                                                ],
                                                initialValue: creditCard?.cardNumber,
                                            })(
                                                <MaskedInput
                                                    className="ant-input"
                                                    mask={[
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        ' ',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        ' ',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        ' ',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                    ]}
                                                    name="number"
                                                    onChange={(event) => {
                                                        this.handleChange(event);
                                                        this.setState({ cardNumberProperties: {} });
                                                    }}
                                                    placeholder="Número do cartão"
                                                    onFocus={(e) => {
                                                        return this.setState({
                                                            focused: e.target.name,
                                                        });
                                                    }}
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormItem>
                                            {getFieldDecorator('cardHolderName', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: ' ',
                                                    },
                                                ],
                                                initialValue: creditCard?.cardHolderName,
                                            })(
                                                <Input
                                                    onChange={this.handleChange}
                                                    name="name"
                                                    maxLength={25}
                                                    onFocus={(e) => {
                                                        return this.setState({
                                                            focused: e.target.name,
                                                        });
                                                    }}
                                                    placeholder="Nome impresso no cartão"
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <FormItem>
                                            {getFieldDecorator('expiryMonth', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: ' ',
                                                    },
                                                ],
                                                initialValue: creditCard?.expiryMonth,
                                            })(
                                                <Select
                                                    selected={month}
                                                    onChange={(e) => {
                                                        return this.onChangeSelect('month')(e);
                                                    }}
                                                    placeholder="Validade mês"
                                                >
                                                    {utils.months.map((month) => {
                                                        return (
                                                            <Option
                                                                key={month.value}
                                                                disabled={month.disabled}
                                                                value={month.value}
                                                            >
                                                                {month.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem>
                                            {getFieldDecorator('expiryYear', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: ' ',
                                                    },
                                                ],
                                                initialValue: creditCard?.expiryYear,
                                            })(
                                                <Select
                                                    selected={year}
                                                    onChange={(e) => {
                                                        return this.onChangeSelect('year')(e);
                                                    }}
                                                    placeholder="Validade ano"
                                                >
                                                    {utils.years.map((year) => {
                                                        return (
                                                            <Option
                                                                key={year.value}
                                                                disabled={year.disabled}
                                                                value={year.value}
                                                            >
                                                                {year.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <FormItem>
                                            {getFieldDecorator('securityCode', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: ' ',
                                                    },
                                                ],
                                                initialValue: creditCard?.securityCode,
                                            })(
                                                <MaskedInput
                                                    className="ant-input"
                                                    mask={[/\d/, /\d/, /\d/]}
                                                    name="cvc"
                                                    onChange={this.handleChange}
                                                    onFocus={(e) => {
                                                        return this.setState({
                                                            focused: e.target.name,
                                                        });
                                                    }}
                                                    onBlur={() => {
                                                        return this.setState({ focused: '' });
                                                    }}
                                                    placeholder="Código segurança"
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem>
                                            {getFieldDecorator('birthdayDate', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: ' ',
                                                    },
                                                ],
                                                initialValue: creditCard?.birthdayDate,
                                            })(
                                                <MaskedInput
                                                    className="ant-input"
                                                    mask={[
                                                        /\d/,
                                                        /\d/,
                                                        '/',
                                                        /\d/,
                                                        /\d/,
                                                        '/',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                    ]}
                                                    name="birthday-date"
                                                    onChange={this.handleChange}
                                                    onFocus={(e) => {
                                                        return this.setState({
                                                            focused: e.target.name,
                                                        });
                                                    }}
                                                    placeholder="Data de Nascimento"
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <FormItem>
                                            {getFieldDecorator('document', {
                                                rules: [
                                                    { required: true, message: ' ' },
                                                    { validator: this.validateCPF },
                                                ],

                                                initialValue: creditCard?.document,
                                            })(
                                                <MaskedInput
                                                    className="ant-input"
                                                    mask={[
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        '.',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        '.',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        '-',
                                                        /\d/,
                                                        /\d/,
                                                    ]}
                                                    name="cvv"
                                                    onChange={this.handleChange}
                                                    onFocus={(e) => {
                                                        return this.setState({
                                                            focused: e.target.name,
                                                        });
                                                    }}
                                                    placeholder="CPF"
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem>
                                            {getFieldDecorator('phone', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: ' ',
                                                    },
                                                ],
                                                initialValue: (creditCard?.completePhone),
                                            })(
                                                <MaskedInput
                                                    className="ant-input"
                                                    mask={[
                                                        '(',
                                                        /\d/,
                                                        /\d/,
                                                        ')',
                                                        ' ',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        '-',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                    ]}
                                                    name="document"
                                                    onChange={this.handleChange}
                                                    onFocus={(e) => {
                                                        return this.setState({
                                                            focused: e.target.name,
                                                        });
                                                    }}
                                                    placeholder="DDD + Telefone Fixo"
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col xs={24} md={12}>
                            <Cards
                                number={!number && creditCard?.cardNumber ? creditCard.cardNumber : number}
                                name={!name && creditCard?.cardHolderName ? creditCard.cardHolderName : name}
                                expiry={expiry !== 'xx/xxxx' && creditCard?.expiry ? creditCard.expiry : expiry}
                                cvc={!cvc && creditCard?.securityCode ? creditCard.securityCode : cvc}
                                focused={focused}
                                placeholders={{ name: 'SEU NOME AQUI' }}
                                locale={{ valid: 'VÁLIDO ATÉ' }}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>
        );
    }
}

const WrapedCreditCard = Form.create()(CreditCard);

const mapStateToProps = (state) => {
    const { currentUser } = state.user;
    return { currentUser };
};

export default connect(mapStateToProps)(WrapedCreditCard);
