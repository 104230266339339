import { get, put, post } from '~/services/api/rest/client';
import utils from '~/lib/utils';

/** product requests */
export const ProductAPI = {
    loadProducts: (data) => {
        return post('products/load', data);
    },
    fetchProducts: (filters = {}) => {
        const query = utils.stringifyQueryString(filters);
        return get(`/products?${query}`);
    },
    fetchPriceTables: () => {
        return get('/products/price-tables');
    },
    updateSupplierProduct: (supplierId, reference, data) => {
        return put(`/admin/suppliers/${supplierId}/products/${reference}`, data);
    },
};
