import React, { Component, createContext, Fragment, PureComponent } from 'react';
import { Button, Icon, Input, InputNumber, Form, Table } from 'antd';

/** Components */
import ErrorLoading from '~/components/ErrorLoading/ErrorData';
import Loading from '~/components/Loading';
import hasError from '~/components/HOC/HasError';

const style = {
    icon: {
        marginRight: 10,
        fontSize: 18,
        cursor: 'pointer',
    },
};
const FormItem = Form.Item;
const EditableContext = createContext();

const EditableRow = ({ form, index, ...props }) => {
    return (
        <EditableContext.Provider value={form}>
            <tr {...props} />
        </EditableContext.Provider>
    );
};

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber />;
        }

        return <Input />;
    };

    validateInputValue = (value) => {
        const normalized = value.replace(/#/g, '');

        return normalized;
    };

    render() {
        const { editing, dataIndex, title, inputType, record, index, ...restProps } = this.props;

        return (
            <EditableContext.Consumer>
                {(form) => {
                    const { getFieldDecorator } = form;
                    return (
                        <td {...restProps}>
                            {editing ? (
                                <FormItem style={{ margin: 0 }}>
                                    {getFieldDecorator(dataIndex, {
                                        normalize: this.validateInputValue,
                                        rules: [
                                            {
                                                required: true,
                                                message: `${title} obrigatório!`,
                                            },
                                        ],
                                        initialValue: record.name.lastName,
                                    })(this.getInput())}
                                </FormItem>
                            ) : (
                                restProps.children
                            )}
                        </td>
                    );
                }}
            </EditableContext.Consumer>
        );
    }
}

class EditableTable extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { editingKey: '' };
    }

    isEditing = (record) => {
        return record.key === this.state.editingKey;
    };

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    save(form, key) {
        form.validateFields(async (error, row) => {
            if (error) {
                return;
            }

            try {
                await this.props.onSave(key, row);
                this.setState({ editingKey: '' });
            } catch (error) {
                this.setState({ editingKey: '' });
            }
        });
    }

    edit(key) {
        this.setState({ editingKey: key });
    }

    addPropRenderToColumns = () => {
        const columns = this.props.columns.map((column) => {
            if (column.key === 'actions') {
                return {
                    ...column,
                    render: (text, record, index) => {
                        if (index === 0) {
                            return { props: { colSpan: 0 } };
                        }

                        const editable = this.isEditing(record);
                        return (
                            <Fragment>
                                {editable ? (
                                    <span>
                                        <EditableContext.Consumer>
                                            {(form) => {
                                                return (
                                                    <Button
                                                        loading={this.props.updateLoading}
                                                        onClick={() => {
                                                            return this.save(form, record);
                                                        }}
                                                        size="small"
                                                        style={{ marginRight: 8 }}
                                                        type="primary"
                                                    >
                                                        Salvar
                                                    </Button>
                                                );
                                            }}
                                        </EditableContext.Consumer>
                                        <Button
                                            onClick={() => {
                                                return this.cancel(record.key);
                                            }}
                                            size="small"
                                            type="default"
                                        >
                                            Cancelar
                                        </Button>
                                    </span>
                                ) : (
                                    <Fragment>
                                        {/* eslint-disable-next-line */}
                                        <a onClick={() => this.edit(record.key)}>
                                            <Icon type="edit" style={style.icon} />
                                        </a>
                                        {/* eslint-disable-next-line */}
                                        <a
                                            href="Javascript:Void(0);" // eslint-disable-line
                                            onClick={() => {
                                                return this.props.onDelete(record.key);
                                            }}
                                        >
                                            <Icon
                                                type="delete"
                                                style={Object.assign(
                                                    {},
                                                    { cursor: style.icon.cursor },
                                                    { fontSize: style.icon.fontSize }
                                                )}
                                            />
                                        </a>
                                    </Fragment>
                                )}
                            </Fragment>
                        );
                    },
                };
            }
            return column;
        });

        return columns;
    };

    render() {
        const { loading } = this.props;
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };

        const columns = this.addPropRenderToColumns().map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => {
                    return {
                        record,
                        inputType: col.type || 'text',
                        dataIndex: col.dataIndex,
                        title: col.title,
                        editing: this.isEditing(record),
                    };
                },
            };
        });

        return (
            <Loading loading={loading}>
                <Table
                    columns={columns}
                    components={components}
                    dataSource={this.props.data}
                    id="table-responsive"
                    locale={{ emptyText: ' ' }}
                    pagination={this.props.pagination}
                    rowClassName="editable-row"
                />
            </Loading>
        );
    }
}

export default hasError(ErrorLoading)(EditableTable);
