import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import { Breadcrumb, Button, Modal, Tooltip } from 'antd';
import FileSaver from 'file-saver';

import DynamicFilter from '~/components/Filters/DynamicFilter';
import HeaderInfo from '~/components/Header/HeaderInfo';
import InfiniteScroll from '~/components/InfiniteScroller';
import Notification from '~/components/Notification/AntNotification';
import Table from '~/components/Table/AntTable';
import { TitlePage } from '~/components/Header/TitlePage';

import utils from '~/lib/utils';
import { CatalogAPI } from '~/lib/api/category';
import { del, get, post, put } from '~/services/api/rest/client';
import { loadMore } from '~/lib/InfiniteScroll';
import { paths } from '~/routes';

const confirm = Modal.confirm;

class Show extends Component {
    state = {
        catalog: {},
        catalogItemsData: {
            hasMore: false,
            pagination: {},
            result: [],
            total: 0,
        },
        categoriesToImport: '',
        createLoading: false,
        error: false,
        exportLoading: false,
        loading: true,
        showCategoryModal: false,
    };

    componentDidMount() {
        document.title = 'Grid2B | Catálogo de categorias';
        this.getAndSetInitialData();
    }

    getAndSetInitialData = async (refresh = false, sorter = true) => {
        try {
            if (refresh) {
                this.setState({
                    error: false,
                    loading: true,
                });
            }

            const search = qs.parse(this.props.location.search);
            const filters = {
                ...search,
                orderBy: 'name',
                sort: 'asc',
            };

            if (!sorter || filters.name) {
                delete filters.orderBy;
                delete filters.sort;
            }

            const [catalogData, catalogItemsData] = await Promise.all([
                CatalogAPI.fetchCatalog(this.props.match.params.catalogId),
                CatalogAPI.fetchCatalogItems(
                    this.props.match.params.catalogId,
                    qs.stringify(filters)
                ),
            ]);

            this.setState((prevState) => {
                return {
                    catalogItemsData: {
                        ...prevState.catalogItemsData,
                        ...catalogItemsData,
                        hasMore: Boolean(catalogItemsData.pagination.next),
                    },
                    catalog: catalogData.result,
                    loading: false,
                };
            });
        } catch (error) {
            console.log('ERROR getAndSetInitialData: ', error);

            this.setState({
                error: true,
                loading: false,
            });
        }
    };

    fetchCatalogItems = async (options = {}) => {
        const search = qs.parse(this.props.location.search);
        const queryString = {
            ...search,
            ...options,
            orderBy: 'name',
            sort: 'asc',
        };

        if (queryString.name) {
            delete queryString.orderBy;
            delete queryString.sort;
        }

        const catalogData = await CatalogAPI.fetchCatalogItems(
            this.props.match.params.catalogId,
            qs.stringify(queryString)
        );

        return catalogData;
    };

    getCatalogItems = async (options = {}) => {
        try {
            const { catalogId } = this.props.match.params;
            const queryString = qs.stringify(options);
            const data = await get(`/categories/catalogs/${catalogId}/items?${queryString}`);
            return data;
        } catch (error) {
            throw error;
        }
    };

    getCategories = async () => {
        try {
            const { result } = await get('/category');
            return result;
        } catch (error) {
            throw error;
        }
    };

    handleCategoriesToImport = (e) => {
        this.setState({
            categoriesToImport: e.target.value,
        });
    };

    importAndSetCategory = async () => {
        try {
            this.setLoadingState('importLoading', true);
            const categoriesToImport = this.transformCategoriesToImport();
            const categoriesImported = await this.importCategories(categoriesToImport);

            this.setCategory(categoriesImported);
            this.setLoadingState('importLoading', false);
            this.hideImportCategoryModal();

            Notification('success', 'Lista de categorias importada!');

            return true;
        } catch (error) {
            this.setLoadingState('importLoading', false);
            Notification('error', 'Ocorreu um erro na importação de categorias, tente novamente!');

            return false;
        }
    };

    accumulatedAndRemoveRepeated(matrixCategories) {
        const categories = matrixCategories.reduce((groupedCategories, arrayCategories) => {
            groupedCategories = groupedCategories.concat(arrayCategories);
            return groupedCategories;
        }, []);

        const categoriesFilteredRepeated = categories.filter((category, index) => {
            return categories.indexOf(category) === index;
        });

        return categoriesFilteredRepeated;
    }

    transformCategoriesToImport = () => {
        const categoriesToImport = this.state.categoriesToImport.split('\n');
        const categoriesToImportFiltered = categoriesToImport.filter((category) => {
            return category;
        });
        return categoriesToImportFiltered;
    };

    createAndSetCategory = async (value) => {
        if (value) {
            try {
                this.setLoadingState('createLoading', true);
                const category = await this.createCategory(value);

                this.setCategory(category);
                this.setLoadingState('createLoading', false);
                Notification('success', 'Categoria criada!');

                return true;
            } catch (error) {
                this.setLoadingState('createLoading', false);
                Notification('error', 'Ocorreu um erro ao criar a categoria, tente novamente!');

                return false;
            }
        }
    };

    setLoadingState = (stateName, loading) => {
        this.setState({
            [stateName]: loading,
        });
    };

    createCategory = async (value) => {
        try {
            const { catalogId } = this.props.match.params;
            const { result } = await post(
                `/categories/catalogs/${catalogId}/items`,
                this.transformCreateCategoryPayload(value)
            );
            return result;
        } catch (error) {
            throw error;
        }
    };

    importCategories = async (categories) => {
        try {
            const { catalogId } = this.props.match.params;
            const { result } = await post(
                `/categories/catalogs/${catalogId}/items/import`,
                this.transformImportCategoriesPayload(categories)
            );
            return result;
        } catch (error) {
            throw error;
        }
    };

    getCategoriesToCreate = (categories, categoryHierachy) => {
        const categorySplitted = categoryHierachy.split('##');
        let categoryLevel = '';

        const categoriesToCreate = categorySplitted
            .map((item, index) => {
                categoryLevel = this.getCategoryLevel(item, categoryLevel, index);

                const results = categories.filter((category) => {
                    return category.name === categoryLevel;
                });

                if (results.length === 0) {
                    return categoryLevel;
                }

                return null;
            })
            .filter((category) => {
                return category;
            });

        return categoriesToCreate;
    };

    getCategoryLevel(item, categoryLevel, index) {
        if (index === 0) {
            categoryLevel = item;
        } else {
            categoryLevel += `##${item}`;
        }

        return categoryLevel;
    }

    updateCategory = async (data, row) => {
        try {
            const { catalogId } = this.props.match.params;
            const { result } = await put(`categories/catalogs/${catalogId}/items/${data.key}`, row);
            this.filterOldCategory(result);
            Notification('success', 'Categoria atualizada!');
        } catch (error) {
            console.log(error);
            Notification('error', 'Ocorreu um erro ao atualizar a categoria, tente novamente!');
        }
    };

    filterOldCategory = (category) => {
        const categories = this.state.categories.filter((oldCategory) => {
            return oldCategory.categoryId !== category.categoryId;
        });
        this.setState({
            categories: categories.concat(category).sort((catA, catB) => {
                return utils.orderBy(catA, catB, 'name');
            }),
        });
    };

    setCategory = (category) => {
        this.setState((state) => {
            return {
                categories: category.concat(state.categories),
            };
        });
    };

    transformCreateCategoryPayload(category) {
        return {
            name: category,
            createParents: true,
        };
    }

    transformImportCategoriesPayload(categories) {
        return {
            categories: categories.map((category) => {
                return { name: category };
            }),
            createParents: true,
        };
    }

    deleteCategory = async (categoryId, categoriesChildren = []) => {
        if (categoriesChildren.length === 0) {
            const categoryName = this.getCategoryName(categoryId);
            categoriesChildren = this.getCategoriesChildren(categoryName);
        }
        const categoriesIdsToDelete = [];

        try {
            const { catalogId } = this.props.match.params;
            const categoriesPromise = categoriesChildren.map(async (category) => {
                await del(`/categories/catalogs/${catalogId}/items/${category.categoryId}`);
                categoriesIdsToDelete.push(category.categoryId);

                return;
            });

            await Promise.all(categoriesPromise);
            this.removeCategoriesDeletedFromState(categoriesIdsToDelete);

            Notification('success', 'Categoria(s) apagada(s)!');
        } catch (error) {
            Notification(
                'error',
                'Ocorreu um erro ao tentar deletar a categoria, tente novamente!'
            );
        }
    };

    removeCategoriesDeletedFromState(categoriesIds) {
        categoriesIds.forEach((categoryId) => {
            return this.removeCategoryState(categoryId);
        });
    }

    getCategoryName = (categories, categoryId) => {
        const category = categories.find((category) => {
            return category.categoryId === categoryId;
        });

        if (category) {
            return category.name;
        }

        return null;
    };

    getCategoriesChildren = (categories, categoryNameParent) => {
        const categoriesFound = categories.filter((category) => {
            const categoryName = `##${category.name}##`;
            return categoryName.indexOf(`##${categoryNameParent}##`) !== -1;
        });

        return categoriesFound;
    };

    removeCategoryState(categoryId) {
        this.setState((state) => {
            return {
                categories: state.categories.filter((category) => {
                    return category.categoryId !== categoryId;
                }),
            };
        });
    }

    showDeleteConfirm = (id) => {
        const { categories } = this.state;
        const categoryName = this.getCategoryName(categories, id);
        const categoriesChildren = this.getCategoriesChildren(categories, categoryName);
        const content = this.contentDeleteConfirm(categoriesChildren);

        confirm({
            title: 'Deseja realmente apagar essa(s) categoria(s)?',
            content,
            okText: 'Sim',
            cancelText: 'Não',
            width: 500,
            onOk: () => {
                return this.deleteCategory(id, categoriesChildren);
            },
            onCancel: () => {},
        });
    };

    contentDeleteConfirm(categories) {
        return (
            <Fragment>
                <span>As seguintes categorias serão deletadas: </span>
                {this.contentDeleteConfirmList(categories)}
            </Fragment>
        );
    }

    contentDeleteConfirmList(categories) {
        return (
            <ul>
                {categories.map((category) => {
                    return this.contentDeleteConfirmLi(category.name);
                })}
            </ul>
        );
    }

    contentDeleteConfirmLi(categoryName) {
        return <li>{this.transformCategoryName(categoryName)}</li>;
    }

    openImportCategoryModal = () => {
        this.setState({ showCategoryModal: true });
    };

    hideImportCategoryModal = () => {
        this.setState({ showCategoryModal: false });
    };

    createTableColumns() {
        return [
            {
                title: 'Título',
                dataIndex: 'name',
                key: 'name',
                editable: true,
                width: '95%',
                render: (name) => {
                    return (
                        <Tooltip title={this.transformCategoryName(name)}>
                            {this.transformCategoryName(name)}
                        </Tooltip>
                    );
                },
            },
        ];
    }

    transformData() {
        const categories = this.state.catalogItemsData.result.map((category) => {
            return {
                key: category.categoryId,
                name: category.name,
            };
        });
        const dataSource = categories;

        return dataSource;
    }

    transformCategoryName(categoryName, reverse = false) {
        if (reverse) return categoryName.replace(/ \/ /g, '##');

        return categoryName.replace(/##/g, ' / ');
    }

    exportCategories = async () => {
        try {
            const { catalog } = this.state;
            const { location, match } = this.props;
            const { search } = location;
            const { catalogId } = match.params;

            this.setState({ exportLoading: true });

            const categories = await CatalogAPI.exportCatalogItems(catalogId, search);
            const blob = new Blob([this.transformCategoriesToExport(categories.result)], {
                type: 'text/plain;charset=utf-8',
            });

            FileSaver.saveAs(blob, `${catalog.name}-categorias.txt`);
        } catch (error) {
            Notification('error', 'Ocorreu um erro na exportação, tente novamente!');
        } finally {
            this.setState({ exportLoading: false });
        }
    };

    transformCategoriesToExport = (categories) => {
        const categoriesToExport = categories.reduce((exportCategories, category) => {
            if (!exportCategories) return category.name;

            exportCategories += `\n${category.name}`;
            return exportCategories;
        }, '');

        return categoriesToExport;
    };

    loadMoreCatalogItems = () => {
        const loadMoreScoped = loadMore.bind(this);

        return loadMoreScoped('catalogItemsData', this.fetchCatalogItems);
    };

    render() {
        const { catalog, catalogItemsData, error, exportLoading, loading } = this.state;
        const { hasMore } = catalogItemsData;
        const columns = this.createTableColumns();
        const data = this.transformData();

        return (
            <Fragment>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={paths.base}>Painel</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={paths.categories}>Catálogo de categorias</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{catalog.name}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="inside-container">
                    <TitlePage
                        title="Catálogo de categorias"
                        subtitle="Confira as categorias desse catálogo."
                        extraTitle={catalog.name}
                    />
                    <div className="content-filters">
                        <DynamicFilter onSubmit={this.getAndSetInitialData} filters={columns} />
                        <HeaderInfo
                            showing={catalogItemsData.result.length}
                            total={catalogItemsData.total}
                            actions={
                                <Button
                                    type="primary"
                                    className="success"
                                    icon="download"
                                    loading={exportLoading}
                                    onClick={this.exportCategories}
                                >
                                    Exportar
                                </Button>
                            }
                        />
                    </div>
                    <div
                        className="infinite-container"
                        style={{
                            height: window.innerHeight - 307,
                            overflow: 'auto',
                        }}
                    >
                        <InfiniteScroll
                            hasMore={hasMore}
                            initialLoad={false}
                            loadMore={this.loadMoreCatalogItems}
                            threshold={20}
                            useWindow={false}
                        >
                            <Table
                                columns={columns}
                                data={data}
                                error={error}
                                loading={loading}
                                pagination={false}
                                refreshData={() => {
                                    return this.getAndSetInitialData(true);
                                }}
                            />
                        </InfiniteScroll>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Show;
