export const catalogTypes = [
    { label: 'Personalizado', value: 'custom' },
    { label: 'Padrão', value: 'default' },
];

export const categoryConnectorTypes = [
    { label: 'Personalizado', value: 'custom' },
    { label: 'Padrão', value: 'default' },
];

export const connectorScopes = [
    { label: 'Produtos', value: 'product_export' },
    { label: 'Pedidos', value: 'order' },
];

export const connectorFormats = [
    { label: 'XML', value: 'xml' },
    { label: 'JSON', value: 'json' },
    { label: 'CSV', value: 'csv' },
    { label: 'XLSX', value: 'xlsx' },
];

export const connectorTypes = [
    { label: 'Personalizado', value: 'custom' },
    { label: 'Padrão', value: 'default' },
];

export const connectorTemplates = [
    { label: 'Personalizado', value: 'custom', formats: ['xml', 'json', 'csv', 'xlsx'] },
    { label: 'WDNA', value: 'wdna', formats: ['xml'] },
    { label: 'Bling', value: 'bling', formats: ['json'] },
];

export const listProductsImport = [
    { label: 'Personalizado', value: 'custom' },
    { label: 'Padrão', value: 'default' },
];
